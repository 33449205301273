import React from "react";
import {
	FieldValues,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";
import { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../components/Utils";
import { store } from "app/store";
import { getAgreementsList } from "features/Slice/AgreementSlice";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { getCurrentAppForm } from "features/Slice/ApplicationFormSlice";
import AgreementUploadField from "components/Forms/AgreementUploadField";

interface ComponentProps {
	applicationFormId: string;
	applicationFormCultureCode: string;
	register: UseFormRegister<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	isReadOnly: boolean;
	sectionId: number;
}

const ApplicantAgreementForm: React.FC<ComponentProps> = ({
	applicationFormId,
	applicationFormCultureCode,
	register,
	setValue,
	getValues,
	isReadOnly,
	sectionId
}) => {
	const isRequestingAgreementList = useSelector((state: RootState) => state.agreementRdc.isRequestingList);
	const agreementList = useSelector((state: RootState) => state.agreementRdc.agreementsList);
	const isPostSuccess = useSelector((state: RootState) => state.agreementRdc.isPostSuccess);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getAgreementsList(applicationFormId));
	}, []);

	React.useEffect(() => {
		if(isPostSuccess)
		{
			store.dispatch(getCurrentAppForm(applicationFormId, true));
		}
	}, [isPostSuccess]);

	return (
		isRequestingAgreementList || !agreementList ?
			<SpinnerOnTop />
			:
			<React.Fragment>
				{agreementList.map((agreement, index) => {
					return(
						<AgreementUploadField
							agreement={agreement}
							applicationFormCultureCode={applicationFormCultureCode}
							applicationFormId={applicationFormId}
							getValues={getValues}
							index={index}
							isReadOnly={isReadOnly}
							register={register}
							setValue={setValue}
							key={agreement.agreementUID}
							sectionId={sectionId}
						/>
					)
				}
				)}
			</React.Fragment>
	);
};

export default ApplicantAgreementForm;
