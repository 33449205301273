import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { customSelectStyles, SelectInterface } from "components/Utils";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import Select, { MultiValue, SingleValue } from "react-select";

interface ComponentProps
{
	name: string;
	label: string|null;
	defaultValue?: SelectInterface|SelectInterface[]|null;
	options: SelectInterface[];
	required: boolean;
	multiple: boolean;
	setValue?: UseFormSetValue<FieldValues>;
	classes: string;
	readOnly: boolean;
	customOnChange?: (newValue: MultiValue<SelectInterface> | SingleValue<SelectInterface>) => void
}

const SelectField: React.FC<ComponentProps> = ({name, label, defaultValue, required, multiple, options, setValue, classes, readOnly, customOnChange}: ComponentProps) => {

	React.useEffect(() => {
		if(setValue)
		{
			setValue(name, multiple ? (defaultValue ? (defaultValue as SelectInterface[]).map((val: SelectInterface) => val.value) : []) : (defaultValue ? (defaultValue as SelectInterface).value : null));
		}
	}, []);

	function onChange(newValue: MultiValue<SelectInterface> | SingleValue<SelectInterface>): void
	{
		if(setValue)
		{
			setValue(name, multiple ? (newValue ? (newValue as SelectInterface[]).map((val: SelectInterface) => val.value) : []) : (newValue ? (newValue as SelectInterface).value : null));
		}
		if(customOnChange)
		{
			customOnChange(newValue);
		}
	}
	
	return (
		<div className={classes}>
			{label && label.length > 0 ? (
				<label className={required ? "col col-form-label required" : "col col-form-label" }>{label}</label>
			) : null}
			<Select
				isDisabled={readOnly}
				name={name}
				className="form-group"
				options={options}
				defaultValue={defaultValue}
				isMulti={multiple}
				// @ts-ignore
				styles={customSelectStyles}
				onChange={newValue => onChange(newValue)}
				isClearable={true}
			/>
		</div>
	);
}

export default SelectField;