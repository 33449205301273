import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { getCurrentAppForm } from "features/Slice/ApplicationFormSlice";
import React from "react";
import { useSelector } from "react-redux";
import t, { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../components/Utils";

interface ComponentProps
{
	applicationFormUid: string;
}

const ApplicantFormDetails: React.FC<ComponentProps> = ({applicationFormUid}) => {
	const applicationForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	const isRequestionApplicationForms = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		if(!isRequestionApplicationForms && applicationForm === null)
		{
			store.dispatch(getCurrentAppForm(applicationFormUid));
		}
	}, []);

	return (
		<div className="row mb-4">
			<div className="col-xl-6 xl-50 box-col-12">
				<div className="widget-joins card widget-arrow">
					<div className="row">
						<div className="col-sm-6 pe-0">
							<div className="media">
								<div className="align-self-center me-3 text-start">
									<h5 className="mb-0">{t('host_country')}</h5>
								</div>
								<div className="media-body">
									<span className="mb-1">{applicationForm?.destinationCouuntry}</span>
								</div>
							</div>
						</div>
						<div className="col-sm-6 ps-0">
							<div className="media">
								<div className="align-self-center me-3 text-start">
									<h5 className="mb-0">{t('duration')}</h5>
								</div>
								<div className="media-body ps-2">
									<span className="mb-1">{applicationForm?.duration}</span>
								</div>
							</div>
						</div>
						<div className="col-sm-6 pe-0">
							<div className="media">
								<div className="align-self-center me-3 text-start">
									<h5 className="mb-0">{t('departure_period')}</h5>
								</div>
								<div className="media-body">
									<span className="mb-1">{applicationForm?.departurePeriod ?? ""}</span>
								</div>
							</div>
						</div>
						<div className="col-sm-6 ps-0">
							<div className="media">
								<div className="align-self-center me-3 text-start">
									<h5 className="mb-0">{t('year_departure')}</h5>
								</div>
								<div className="media-body ps-2">
									<span className="mb-1">{applicationForm?.yearOfDeparture}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-6 xl-50 box-col-12">
				<div className="widget-joins card widget-arrow widget-joins-no-before widget-joins-no-after-responsive">
					<div className="row">
						<div className="col-lg-12 col-sm-6 col-1024-6 pe-0">
							<div className="media">
								<div className="align-self-center me-3 text-start">
									<h5 className="mb-0">{t('program_type')}</h5>
								</div>
								<div className="media-body ps-2">
									<span className="mb-1">{applicationForm?.typeOfProduct}</span>
								</div>
							</div>
						</div>
						<div className="col-lg-12 col-sm-6 col-1024-6 pe-0">
							<div className="media">
								<div className="align-self-center me-3 text-start">
									<h5 className="mb-0">{t('native_language')}</h5>
								</div>
								<div className="media-body ps-2">
									<span className="mb-1">{applicationForm?.nativeLanguage}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApplicantFormDetails;
