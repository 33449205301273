import { customSelectStyles, SelectInterface } from "components/Utils";
import { getApplicantFormApplicantName } from "components/UtilsForApplicationForm";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import React, { useState } from "react";
import Select from "react-select";

interface ComponentProps {
	applicationForms: ApplicationForm[];
	currentApplicationForm: ApplicationForm;
	onChange: (newValue: ApplicationForm) => void;
}

const ApplicationFormSelect: React.FC<ComponentProps> = ({ applicationForms, currentApplicationForm, onChange }) => {
	const [applicationFormOptions] = useState<SelectInterface[]>(applicationForms.map(appForm => { return { label: getApplicantFormApplicantName(appForm) + " - " + appForm.label, value: appForm.uid } }));
	const [defaultValue] = useState<SelectInterface>({ label: getApplicantFormApplicantName(currentApplicationForm) + " - " + currentApplicationForm.label, value: currentApplicationForm.uid });

	return (
		<div className="col-sm-12 col-md-6">
			<Select
				name={"select-application-form"}
				options={applicationFormOptions}
				isClearable={false}
				defaultValue={defaultValue}
				isMulti={false}
				//@ts-ignore
				styles={customSelectStyles}
				onChange={newValue => {
					const selectedApplicationForm = applicationForms.find(appForm => appForm.uid === newValue?.value);
					if (selectedApplicationForm) {
						onChange(selectedApplicationForm);
					}
				}}
			/>
		</div>
	);
};

export default ApplicationFormSelect;
