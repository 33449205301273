import React  from 'react';
import { GetCurrentLocale, pathname, pathnamesWithNoHeaders } from "../components/Utils";
import Header from "../features/Layout/Header";
import { changeLanguage } from "../components/I18N/TranslateHelpers";

function AppHeader() {
  const locale = GetCurrentLocale();
	changeLanguage(locale);
  return (
    !pathnamesWithNoHeaders().includes(pathname) ? <Header/> : ""
  );
}
export default AppHeader;
