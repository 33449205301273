import React, { useMemo, useState } from "react";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, SelectInterface } from "../../components/Utils"; import PageTitle from "./PageTitle";
import { RootState, store } from "app/store";
import { useSelector } from "react-redux";
import { getApplicationFormSectionsAndRedirectToFirst, getApplicationFormsWithPagination } from "features/Slice/ApplicationFormSlice";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { listApplicationForms } from "components/UtilsForApplicationForm";
import { getCultures } from "features/Slice/CultureSlice";
import { FieldValues, useForm } from "react-hook-form";
import { getPrograms, getDurations, getYears, getDestinationCountries, getApplicationFormsFiltered, getDeparturePeriods, getPartners } from "features/Slice/ApplicationFormSelectSlice";
import { createAppFormButton } from "components/UtilsForPartner";
import ReactPaginate from 'react-paginate';
import SelectField from "components/Forms/SelectField";
import { renderInputText } from "components/Forms/FormsComponent";
import { ProgramTypeEnum } from "features/Entity/ApplicationForm";

const ApplicationFormSelectionPage: React.FC = () => {
	const isRequestingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const applicationForms = useSelector((state: RootState) => state.applicationFormRdc.applicationForms);
	const applicationFormsPager = useSelector((state: RootState) => state.applicationFormRdc.applicationFormsPager);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
	const isRequestingCultures = useSelector((state: RootState) => state.cultureRdc.isRequestingAll);
	const cultures = useSelector((state: RootState) => state.cultureRdc.cultures);
	const programs = useSelector((state: RootState) => state.applicationFormSelectRdc.programs);
	const durations = useSelector((state: RootState) => state.applicationFormSelectRdc.durations);
	const years = useSelector((state: RootState) => state.applicationFormSelectRdc.years);
	const departurePeriods = useSelector((state: RootState) => state.applicationFormSelectRdc.departurePeriods);
	const isRequestingPrograms = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingPrograms);
	const isRequestingDurations = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingDurations);
	const isRequestingYears = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingYears);
	const isRequestingDeparturePeriods = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingDeparturePeriods);
	const isRequestingPartners = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingPartners);
	const destinationCountries = useSelector((state: RootState) => state.applicationFormSelectRdc.destinationCountries);
	const isFiltering = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingApplicationForms);
	const applicationFormsFiltered = useSelector((state: RootState) => state.applicationFormSelectRdc.applicationForms);
	const partners = useSelector((state: RootState) => state.applicationFormSelectRdc.partners);

	/* pagination */
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(20)
	const [filters, setFilters] = useState({});
	const handlePageChange = (e: any) => {
		setCurrentPage(e.selected + 1)
	};

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		if (!isRequestingApplicationForm && !applicationForms) {
			if(applicationFormsFiltered){
				store.dispatch(getApplicationFormsFiltered(filters, itemsPerPage, currentPage));
			}
			else{
				store.dispatch(getApplicationFormsWithPagination(itemsPerPage, currentPage));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);


	React.useEffect(() => {
		if (applicationForms && applicationForms.length === 1 && itemsPerPage !== 1) {
			store.dispatch(getApplicationFormSectionsAndRedirectToFirst(applicationForms[0]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applicationForms]);

	React.useEffect(() => {
		if (userInfo?.isStaff || userInfo?.isSendingPartner) {
			store.dispatch(getCultures(userInfo.isSendingPartner));
			store.dispatch(getPrograms());
			store.dispatch(getDurations());
			store.dispatch(getYears());
			store.dispatch(getDestinationCountries());
			store.dispatch(getDeparturePeriods());
			store.dispatch(getPartners());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo]);

	const cultureSelect: SelectInterface[] = useMemo(() => {
		if (!cultures) {
			return [];
		}
		return cultures.filter(cu => cu.isActive).map(cu => { return { label: cu.name, value: cu.id } })
	}, [cultures]);

	const programsSelect: SelectInterface[] = useMemo(() => {
		if (!programs) {
			return [];
		}
		return programs.map(pr => { return { label: pr, value: pr } })
	}, [programs]);

	const durationsSelect: SelectInterface[] = useMemo(() => {
		if (!durations) {
			return [];
		}
		return durations.map(du => { return { label: du, value: du } })
	}, [durations]);

	const yearsSelect: SelectInterface[] = useMemo(() => {
		if (!years) {
			return [];
		}
		return years.map(ye => { return { label: ye.toString(), value: ye } })
	}, [years]);

	const destinationCountriesSelect: SelectInterface[] = useMemo(() => {
		if (!destinationCountries) {
			return [];
		}
		return destinationCountries.map(de => { return { label: de, value: de } })
	}, [destinationCountries]);

	const departurePeriodsSelect: SelectInterface[] = useMemo(() => departurePeriods ? departurePeriods.map(depPeriod => { return { label: depPeriod, value: depPeriod } }) : [], [departurePeriods]);
	const partnersSelect: SelectInterface[] = useMemo(() => partners ? partners.map(partner => { return { label: partner, value: partner } }) : [], [partners]);

	const { register, handleSubmit, setValue } = useForm();

	function applyFilters(filters: FieldValues) {
		Object.keys(filters).forEach(filter => {
			if (typeof (filters[filter]) === "string" && filters[filter].length === 0) {
				filters[filter] = null;
			}
		});
		setFilters(filters);
			store.dispatch(getApplicationFormsFiltered(filters, itemsPerPage, 1));
	}

	return (
		<div className="row starter-main" id="container-staff-search">
			<PageTitle title={t("application_form")} />
			{
				userInfo?.isStaff || userInfo?.isSendingPartner ?
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">
								<h5>{t('search_an_application')}</h5>
							</div>
							<div className="card-body pt-0">
								<div className="row">
									{
										isRequestingCultures ?
											<SpinnerOnTop />
											:
											<SelectField
												classes="col-lg-3 col-md-6 col-sm-12 mb-3"
												name="appFormCultureIds"
												options={cultureSelect}
												defaultValue={null}
												multiple={true}
												label={userInfo.isSendingPartner ? t('select_destination') : t('culture')}
												required={false}
												readOnly={false}
												customOnChange={undefined}
												setValue={setValue}
											/>
									}
									{
										isRequestingPrograms ? <SpinnerOnTop />
											:
											<SelectField
												classes="col-lg-3 col-md-6 col-sm-12 mb-3"
												name="appFormTypeOfProduct"
												options={programsSelect}
												defaultValue={null}
												multiple={true}
												label={t('select_program')}
												required={false}
												readOnly={false}
												customOnChange={undefined}
												setValue={setValue}
											/>
									}
									{
										isRequestingDurations ? <div className="small-spinner"><SpinnerOnTop /></div>
											:
											<SelectField
												classes="col-lg-3 col-md-6 col-sm-12 mb-3"
												name="appFormDuration"
												options={durationsSelect}
												defaultValue={null}
												multiple={true}
												label={t('select_duration')}
												required={false}
												readOnly={false}
												customOnChange={undefined}
												setValue={setValue}
											/>
									}
									{
										isRequestingYears ? <SpinnerOnTop />
											:
											<SelectField
												classes="col-lg-3 col-md-6 col-sm-12 mb-3"
												name="appFormYearOfDeparture"
												options={yearsSelect}
												defaultValue={null}
												multiple={true}
												label={t('select_year')}
												required={false}
												readOnly={false}
												customOnChange={undefined}
												setValue={setValue}
											/>
									}
									{
										!userInfo.isSendingPartner ?
											<SelectField
												classes="col-lg-3 col-md-6 col-sm-12 mb-3"
												name="appFormDestinationCountry"
												options={destinationCountriesSelect}
												defaultValue={null}
												multiple={true}
												label={t('select_country')}
												required={false}
												readOnly={false}
												customOnChange={undefined}
												setValue={setValue}
											/>
											: null
									}

									{renderInputText(
										t('search_by_firstname'),
										"applicantFirstName",
										null,
										"",
										register,
										false,
										"col-lg-3 col-md-6 col-sm-12 mb-3",
										false,
										false
									)}
									{renderInputText(
										t('search_by_lastname'),
										"applicantLastName",
										null,
										"",
										register,
										false,
										"col-lg-3 col-md-6 col-sm-12 mb-3",
										false,
										false
									)}
									{renderInputText(
										t('search_by_email'),
										"applicantEmail",
										null,
										"",
										register,
										false,
										"col-lg-3 col-md-6 col-sm-12 mb-3",
										false,
										false
									)}
									{
										isRequestingDeparturePeriods ? <div className="small-spinner"><SpinnerOnTop /></div>
											:
											<SelectField
												classes="col-lg-3 col-md-6 col-sm-12 mb-3"
												name="appFormDeparturePeriod"
												options={departurePeriodsSelect}
												defaultValue={null}
												multiple={true}
												label={t('search_by_departure_period')}
												required={false}
												readOnly={false}
												customOnChange={undefined}
												setValue={setValue}
											/>
									}
									{renderInputText(
										t('search_by_order_number'),
										"appFormOrderNumber",
										null,
										"",
										register,
										false,
										"col-lg-3 col-md-6 col-sm-12 mb-3",
										false,
										false
									)}
									{
										isRequestingPartners ? <div className="small-spinner"><SpinnerOnTop /></div>
											:
											<SelectField
												classes="col-lg-3 col-md-6 col-sm-12 mb-3"
												name="partners"
												options={partnersSelect}
												defaultValue={null}
												multiple={true}
												label={t('search_partners')}
												required={false}
												readOnly={false}
												customOnChange={undefined}
												setValue={setValue}
											/>
									}
									{/*<div className="col-lg-3 col-md-6 col-sm-12 mb-3">*/}
									{/*	<label className="form-label" htmlFor="programType">{t('search_by_program_type')}</label>*/}
									{/*	<select {...register("programType")} className="form-select btn-square">*/}
									{/*		{(Object.values(ProgramTypeEnum)).filter(value => typeof value === 'string' && value !== ProgramTypeEnum[ProgramTypeEnum.Both]).map((select, index) => (*/}
									{/*			<option	key={index} value={ProgramTypeEnum[index]}>*/}
									{/*				{ProgramTypeEnum[index]}*/}
									{/*			</option>*/}
									{/*		))}*/}
									{/*	</select>*/}
									{/*</div>*/}
								</div>
								<div className="row g-0">
									<div className="col-md-8 col-sm-12">
									</div>
									<div className="col-md-4 col-sm-12">
										{
											isFiltering && <div className="small-spinner"><SpinnerOnTop /></div>
										}
										<button type="button" disabled={isFiltering} onClick={handleSubmit(applyFilters)} className="pull-right btn btn-primary small d-flex align-items-center">
											<i className="icon-search mr-2"></i> {t('apply_filters')}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					: null
			}
			<div className="col-md-12 documents-container mb-5">
				<div className="card mb-0">
					{
						!applicationFormsPager || applicationFormsPager?.results.length === 0 ?
							<React.Fragment>
								<div className="card-header row g-0">
									<div className="col-md-8 col-sm-12">
										<h6>{t("no_application_form_found")}</h6>
									</div>
									{
										userInfo?.isSendingPartner ?
											<div className="col-md-4 col-sm-12">
												{createAppFormButton()}
											</div>
											: null
									}
								</div>
							</React.Fragment>
							:
							<>
								<div className="card-header row g-0">
									<div className="col-md-6 col-sm-12">
										<h5>{t("applications_list")}</h5>
									</div>
									{
										userInfo?.isSendingPartner ?
											<div className="col-md-6 col-sm-12">
												<a href={t('url_create_application_form')} className="pull-right btn btn-primary small d-flex align-items-center">
													<i className="icon-plus mr-2"></i> {t('create_new_application_form')}
												</a>
											</div>
											: null
									}
								</div>
								<div className="card-body pt-0">
									{
										applicationFormsFiltered?.results && applicationFormsFiltered?.results.length === 0 ?
											t('no_result') : null
									}
									<ul className="list-applicationforms">
										{
											isFiltering || isRequestingApplicationForm ? 
												<SpinnerOnTop /> 
											:
											(applicationFormsFiltered ?? applicationFormsPager).results.map(applicationForm =>
												listApplicationForms(applicationForm, userInfo)
											)
												
										}
									</ul>
								</div>
							</>
					}
					<ReactPaginate
						previousLabel={t('previous')}
						nextLabel={t('next')}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={handlePageChange}
						pageCount={applicationFormsFiltered ? applicationFormsFiltered.pageCount : applicationFormsPager ? applicationFormsPager.pageCount : 1}
						pageClassName="page-item"
						pageLinkClassName="page-link"
						previousClassName="page-item"
						previousLinkClassName="page-link"
						nextClassName={"page-item"}
						nextLinkClassName="page-link"
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName="page-link"
						containerClassName="pagination d-flex justify-content-end mr-3"
						activeClassName="active"
						forcePage={Math.ceil(currentPage - 1)}
					/>
				</div>
			</div>
		</div>
	);
};

export default ApplicationFormSelectionPage;
