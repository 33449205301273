import axios  from "components/AxiosUtils";
import { Culture } from "features/Entity/Culture";
import { WepOfficeInfo } from "features/Entity/WepOfficeInfo";

export async function getWepOfficeInfoRep(cultureCode: string): Promise<WepOfficeInfo> {
    try
    {
        const response = await axios.get<WepOfficeInfo>(
            `/Culture/GetWepOfficeInfo/${cultureCode}`,
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getCulturesRep(isSendingPartner: boolean): Promise<Culture[]>{
    try{
        const response = await axios.get<Culture[]>(
            `/Culture/GetList?onlyInbound=${isSendingPartner}`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}
