import axios  from "components/AxiosUtils";
import { Country } from "features/Entity/Country";

export async function getCountriesRep(): Promise<Country[]>{
    try{
        const response = await axios.get<Country[]>(
            `/Country/GetList`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}
