import { RootState, store } from "app/store";
import UploadWithPreview from "components/Forms/UploadWithPreview";
import { GetByteSize } from "components/Forms/UtilsForFormValidation";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { getMediaAlbum, getMediaAlbumFile } from "features/Slice/MediaAlbumSlice";
import React from "react";
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useSelector } from "react-redux";
import { changeLanguage } from "../../components/I18N/TranslateHelpers";
import {
	GetCurrentLocale,
} from "../../components/Utils";
import { getMediasImages } from "components/UtilsForUpload";
interface ComponentProps {
	applicationFormId: string;
	register: UseFormRegister<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	onSubmit: (formData: any) => Promise<void>;
	isReadOnly: boolean;
	setValue: UseFormSetValue<FieldValues>;
}

const PhotoAlbum: React.FC<ComponentProps> = ({
	applicationFormId,
	register,
	getValues,
	onSubmit,
	isReadOnly,
	setValue
}) => {
	const isRequestingMediaAlbum = useSelector((state: RootState) => state.mediaAlbumRdc.isRequesting);
	const mediaAlbum = useSelector((state: RootState) => state.mediaAlbumRdc.mediaAlbum);
	const isSaving = useSelector((state: RootState) => state.applicationFormRdc.isRequestPost);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getMediaAlbum(applicationFormId));
	}, []);

	function getSingleAlbumSubmitData(mediaAlbumFieldId: number, mediaResourceFieldId: number): {} | null {
		const prefix = `mediaAlbum[${mediaAlbumFieldId}]`;
		const resourcePrefix = `${prefix}.mediaRessources[${mediaResourceFieldId}]`

		let returnData: {} = {
			mediaAlbum: [
				{
					applicationFormId: getValues(`${prefix}.applicationFormId`),
					id: getValues(`${prefix}.id`),
					mediaRessources:
						[
							{
								description: getValues(`${resourcePrefix}.description`),
								id: getValues(`${resourcePrefix}.id`),
								mediaAlbumId: getValues(`${resourcePrefix}.mediaAlbumId`),
								mediaFile: getValues(`${resourcePrefix}.mediaFile`)
							}
						]
				}
			]
		};

		return returnData ?? null;
	}

	const downloadButton = (resourceId: number|null) => resourceId ? (
		<button type="button" className="btn btn-primary" onClick={() => store.dispatch(getMediaAlbumFile(applicationFormId, resourceId))}>
			<i className="icofont icofont-download-alt"></i>
		</button>
	)
	:<></>
	;

	return (
		<div className="row">
			<div className="col-md-12 box-col-12">
				<div className="file-content">
					<div className="card mb-3 section-photo-album">
						<div className="card-body row">
							{
								isRequestingMediaAlbum ?
									<SpinnerOnTop />
									: mediaAlbum && mediaAlbum.length > 0 ?
										mediaAlbum.map((mediaElement, mediaElementIndex) => {
											const prefix = `mediaAlbum[${mediaElementIndex}]`;

											return (
												<React.Fragment key={mediaElementIndex}>
													<input
														type="hidden"
														id={`${prefix}.id`}
														value={mediaElement.id}
														{...register(`${prefix}.id`)}
													/>
													<input
														type="hidden"
														id={`${prefix}.applicationFormId`}
														value={mediaElement.applicationFormId}
														{...register(`${prefix}.applicationFormId`)}
													/>
													{
														Array.from(Array(mediaElement.maxNumberOfResources)).map((element, index) => {
															const mediaResource = mediaElement.mediaRessources[index] ?? null;

															const resourcePrefix = `${prefix}.mediaRessources[${index}]`;

															return (
																<React.Fragment key={index}>
																	<input
																		type="hidden"
																		id={`${resourcePrefix}.id`}
																		value={mediaResource ? mediaResource.id : 0}
																		{...register(`${resourcePrefix}.id`)}
																	/>
																	<input
																		type="hidden"
																		id={`${resourcePrefix}.mediaAlbumId`}
																		value={mediaElement.id}
																		{...register(`${resourcePrefix}.mediaAlbumId`)}
																	/>
																	<UploadWithPreview
																		register={register}
																		id={`${resourcePrefix}.mediaFile`}
																		classes="col-lg-4 col-md-6 col-sm-12"
																		description={mediaResource ? (mediaResource.description ?? "") : ""}
																		label={mediaElement.mediaAlbumTypeName.replace(/([A-Z])/g, ' $1').trim()}
																		styleOfInput=""
																		image={mediaResource ? (mediaResource.mediaFileUrl ?? "") : ""}
																		key={`${mediaElement.mediaAlbumTypeName}-${index}`}
																		descriptionId={`${resourcePrefix}.description`}
																		submit={() => onSubmit(getSingleAlbumSubmitData(mediaElementIndex, index))}
																		hideDescription={!mediaElement.withDescription}
																		disabled={isReadOnly}
																		setValue={setValue}
																		accept={getMediasImages()}
																		maxSize={GetByteSize(5)}
																		descriptionRequired={true}
																		downloadButton={mediaResource ? (mediaResource.mediaFileUrl ? () => downloadButton(mediaResource?.id ?? null) : undefined) : undefined}
																		disabledButton={isSaving}					
																	/>
																</React.Fragment>
															)
														}
														)
													}
												</React.Fragment>
											)
										})
										: ""
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PhotoAlbum;
