import React, { useState } from "react";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import t, { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, SelectInterface } from "../../../components/Utils";
import { renderRadio, renderTextArea } from "../../../components/Forms/FormsComponent";
import { Applicant, HobbyInfo, InterestEnumType } from "features/Entity/Applicant";
import SelectField from "components/Forms/SelectField";
import { MultiValue, SingleValue } from "react-select";

interface ComponentProps {
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	applicantData: Applicant;
	setValue: UseFormSetValue<FieldValues>;
	isReadOnly: boolean;
}

const ApplicantHobbiesForm: React.FC<ComponentProps> = ({
	register,
	errors,
	applicantData,
	setValue,
	isReadOnly,
}) => {
	const getHobbiesByType = () => {
		let allHobbiesByType: { [index: string]: HobbyInfo[] } = {};

		for (const hobbyType of hobbyTypes) {
			const byType = applicantData.hobbyApplicants.filter(hobby => hobby.hobbyTypeName === hobbyType);
			allHobbiesByType[hobbyType] = byType;
		}

		return allHobbiesByType;
	}

	const getDefaultValuesHobbiesByType = (interestType: InterestEnumType) => {
		let selectedHobbiesByType: { [index: string]: HobbyInfo[] } = {};

		Object.keys(hobbiesByType).forEach(hobbyType => {
			selectedHobbiesByType[hobbyType] = applicantData.hobbyApplicants.filter(hobby => hobby.hobbyTypeName === hobbyType && hobby.interestType === interestType && hobby.isSelected);
		});

		return selectedHobbiesByType;
	}

	const getSelectedHobbiesIds = (selectedHobbies: { [index: string]: HobbyInfo[] }) => {
		let selectedIds: number[] = [];

		for (const hobbies of Object.values(selectedHobbies)) {
			for (const hobby of hobbies) {
				selectedIds.push(hobby.hobbyId);
			}
		}

		return selectedIds;
	}

	function updateSelectedHobbies(
		currentData: { [index: string]: HobbyInfo[] },
		setNewData: React.Dispatch<React.SetStateAction<{ [index: string]: HobbyInfo[] }>>,
		newValue: MultiValue<SelectInterface> | SingleValue<SelectInterface>,
		hobbyType: string,
		formFieldId: string
	) {
		let newData = { ...currentData };

		let newValuesHobbies: HobbyInfo[] = [];

		if (Array.isArray(newValue)) {
			for (const val of (newValue as MultiValue<SelectInterface>)) {
				const hobby = applicantData.hobbyApplicants.find(hobby => hobby.hobbyId === parseInt(val.value));

				if (hobby) {
					newValuesHobbies.push(hobby);
				}
			}
		}
		else {
			const hobby = applicantData.hobbyApplicants.find(hobby => hobby.hobbyId === parseInt((newValue as SingleValue<SelectInterface>)?.value));

			if (hobby) {
				newValuesHobbies.push(hobby);
			}
		}

		newData[hobbyType] = newValuesHobbies;
		setNewData(newData);

		if (InterestEnumType.Interested) {
			setValue(formFieldId, getSelectedHobbiesIds(newData));
		}
	}

	function onHobbyChange(newValue: MultiValue<SelectInterface> | SingleValue<SelectInterface>, interestType: InterestEnumType, hobbyType: string) {
		if (interestType === InterestEnumType.Doing) {
			updateSelectedHobbies(selectedHobbiesPracticing, setSelectedHobbiesPracticing, newValue, hobbyType, hobbiesPracticingId);
		}
		else if (interestType === InterestEnumType.Interested) {
			updateSelectedHobbies(selectedHobbiesInterested, setSelectedHobbiesInterested, newValue, hobbyType, hobbiesInterestedId);
		}
	}

	const hobbiesPracticingId = "hobbyDoingIds";
	const hobbiesInterestedId = "hobbyInterestedIds";

	const [hobbyTypes] = useState<string[]>(Array.from(new Set(applicantData.hobbyApplicants.map(hobby => hobby.hobbyTypeName))));
	const [hobbiesByType] = useState<{ [index: string]: HobbyInfo[] }>(getHobbiesByType());
	const [selectedHobbiesPracticing, setSelectedHobbiesPracticing] = useState<{ [index: string]: HobbyInfo[] }>(getDefaultValuesHobbiesByType(InterestEnumType.Doing));
	const [selectedHobbiesInterested, setSelectedHobbiesInterested] = useState<{ [index: string]: HobbyInfo[] }>(getDefaultValuesHobbiesByType(InterestEnumType.Interested));

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		setValue(hobbiesPracticingId, getSelectedHobbiesIds(selectedHobbiesPracticing));
		setValue(hobbiesInterestedId, getSelectedHobbiesIds(selectedHobbiesInterested));
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<h6 className="mb-0">{t("hobbies_already_practicing")}</h6>
			<small className="mb-1">{t('you_can_select_more_one')}</small>
			<input
				type="hidden"
				id={hobbiesPracticingId}
				{...register(hobbiesPracticingId)}
			/>

			{
				Object.keys(hobbiesByType).map((hobbyType, index) => {
					return (
						<React.Fragment key={index}>
							<SelectField
							classes="col-md-6 mb-3"
							name={`hobbyTypes.${InterestEnumType.Doing.toString()}.${hobbyType}`}
							options={hobbiesByType[hobbyType].filter(hobby => hobby.interestType === InterestEnumType.Doing).map(hobby => ({ label: hobby.hobbyName, value: hobby.hobbyId }))}
							defaultValue={hobbiesByType[hobbyType].filter(hobby => hobby.isSelected && hobby.interestType === InterestEnumType.Doing).map(hobby => ({ label: hobby.hobbyName, value: hobby.hobbyId }))}
							multiple={true}
							label={hobbyType}
							required={false}
							readOnly={isReadOnly}
							customOnChange={(newVal) => onHobbyChange(newVal, InterestEnumType.Doing, hobbyType)}
							/>
						</React.Fragment>
					);
				}
				)
			}
			<h6 className="mb-0">{t("hobbies_interested_in")}</h6>
			<small className="mb-1">{t('you_can_select_more_one')}</small>
			<input
				type="hidden"
				id={hobbiesInterestedId}
				{...register(hobbiesInterestedId)}
			/>
			{
				Object.keys(hobbiesByType).map((hobbyType, index) => {
					return (
						<React.Fragment key={index}>
							<SelectField
								classes="col-md-6 mb-3"
								name={`hobbyTypes.${InterestEnumType.Interested.toString()}.${hobbyType}`}
								options={hobbiesByType[hobbyType].filter(hobby => hobby.interestType === InterestEnumType.Interested).map(hobby => ({ label: hobby.hobbyName, value: hobby.hobbyId }))}
								defaultValue={hobbiesByType[hobbyType].filter(hobby => hobby.isSelected && hobby.interestType === InterestEnumType.Interested).map(hobby => ({ label: hobby.hobbyName, value: hobby.hobbyId }))}
								multiple={true}
								label={hobbyType}
								required={false}
								readOnly={isReadOnly}
								customOnChange={(newVal) => onHobbyChange(newVal, InterestEnumType.Interested, hobbyType)}
							/>
						</React.Fragment>
					);
				}
				)
			}
			<hr />
			{
				renderTextArea(
					t('other_interests'),
					"applicantProfile.otherInterests",
					applicantData?.applicantProfile?.otherInterests,
					errors.applicantProfile?.otherInterests,
					register,
					false,
					"col-lg-6 col-m-12 col-sm-12 mb-3",
					false,
					applicantData.applicantProfile?.initializedFields?.includes("OtherInterests") || isReadOnly,
					3,
					true
				)
			}
			{
				renderTextArea(
					t('list_musical_instruments_played'),
					"applicantProfile.musicalInstruments",
					applicantData?.applicantProfile?.musicalInstruments,
					errors.applicantProfile?.musicalInstruments,
					register,
					false,
					"col-lg-6 col-m-12 col-sm-12 mb-3",
					false,
					applicantData.applicantProfile?.initializedFields?.includes("MusicalInstruments") || isReadOnly,
					3,
					true
				)
			}
		</React.Fragment>
	);
};

export default ApplicantHobbiesForm;
