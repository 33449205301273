import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Culture } from "features/Entity/Culture";
import { WepOfficeInfo } from "features/Entity/WepOfficeInfo";
import { getCulturesRep, getWepOfficeInfoRep } from "features/Repository/CultureRepository";
import { AppThunk } from "../../app/store";

interface CultureState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  wepOfficeInfo: WepOfficeInfo|null,
  isRequestingAll: boolean,
  isRequestAllFailed: boolean,
  isRequestAllSuccess: boolean,
  cultures: Culture[]|null
}

const initialState: CultureState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  wepOfficeInfo: null,
  isRequestingAll: false,
  isRequestAllFailed: false,
  isRequestAllSuccess: false,
  cultures: null
}

function startRequestRdc(state: CultureState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: CultureState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestWepOfficeInfoSuccessRdc(state: CultureState, action: PayloadAction<WepOfficeInfo>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.wepOfficeInfo = action.payload;
}

function startRequestAllRdc(state: CultureState) {
  state.isRequestingAll = true;
  state.isRequestAllFailed = false;
  state.error = null;
}

function requestFailedAllRdc(state: CultureState, action: PayloadAction<AxiosError>) {
  state.isRequestingAll = false;
  state.isRequestAllSuccess = false;
  state.isRequestAllFailed = true;
  state.error = action.payload;
}
function requestAllCulturesSuccessRdc(state: CultureState, action: PayloadAction<Culture[]>) {
  state.isRequestingAll = false;
  state.isRequestAllSuccess = true;
  state.isRequestAllFailed = false;
  state.error = null;
  state.cultures = action.payload;
}

const CultureSlice = createSlice({
    name: 'Cultures',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestWepOfficeInfoSuccessAct: requestWepOfficeInfoSuccessRdc,
      requestFailedAct: requestFailedRdc,
      startRequestAllAct: startRequestAllRdc,
      requestFailedAllAct: requestFailedAllRdc,
      requestAllCulturesSuccessAct: requestAllCulturesSuccessRdc
    }
  }
)

export const {
  startRequestAct,
  requestWepOfficeInfoSuccessAct,
  requestFailedAct,
  startRequestAllAct,
  requestFailedAllAct,
  requestAllCulturesSuccessAct
} = CultureSlice.actions;

export default CultureSlice.reducer;

export const getWepOfficeInfo = (cultureCode: string): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getWepOfficeInfoRep(cultureCode);
        dispatch(requestWepOfficeInfoSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getCultures = (isSendingPartner: boolean): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestAllAct());
    const apiResponse = await getCulturesRep(isSendingPartner);
    dispatch(requestAllCulturesSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAllAct(err as AxiosError))
  }
}