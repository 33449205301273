export interface DocumentFolder
{
    id: number;
    applicationFormId: number;
    status: StatusTypeEnum;
    documentFileTypeName: string|null;
    documentFiles: DocumentFile[];
    documentFileTypeDescription: string|null;
}

export interface DocumentFile
{
    id: number;
    physicalFile: string|null;
    documentFolderId: number;
    createdOn: string
}

export enum StatusTypeEnum
{
    Active = 0,
    Inactive = 1
}
