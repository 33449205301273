import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getExternalInfoConfigRep, getExternalInfoConfigTemplateRep, getExternalInfoConfigUploadedFileRep } from "features/Repository/ExternalInfoRepository";
import { AppThunk } from "../../app/store";
import { RelAppFormExternalInfoConfiguration } from "features/Entity/ExternalInfo";
import saveAs from "file-saver";

interface ExternalInfoState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  relAppFormExternalInfoConfigs: RelAppFormExternalInfoConfiguration[]|null
}

const initialState: ExternalInfoState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  relAppFormExternalInfoConfigs: null
}

function startRequestRdc(state: ExternalInfoState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: ExternalInfoState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: ExternalInfoState, action: PayloadAction<RelAppFormExternalInfoConfiguration[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.relAppFormExternalInfoConfigs = action.payload;
}

const ExternalInfoSlice = createSlice({
    name: 'ExternalInfos',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct
} = ExternalInfoSlice.actions;

export default ExternalInfoSlice.reducer;

export const getExternalInfoConfig = (applicationFormUid: string): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getExternalInfoConfigRep(applicationFormUid);
        dispatch(requestSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getExternalInfoConfigTemplateFile = (externalInfoId: number, filename: string): AppThunk => async dispatch =>
{
    try
    {
      const apiResponse = await getExternalInfoConfigTemplateRep(externalInfoId, filename);

      saveAs(apiResponse.fileBlob, apiResponse.filename);
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getExternalInfoConfigUploadedFile = (externalInfoId: number, filename: string): AppThunk => async dispatch =>
{
    try
    {
      const apiResponse = await getExternalInfoConfigUploadedFileRep(externalInfoId, filename);

      saveAs(apiResponse.fileBlob, apiResponse.filename);
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}
