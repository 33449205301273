import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Country } from "features/Entity/Country";
import { getCountriesRep } from "features/Repository/CountryRepository";
import { AppThunk } from "../../app/store";

interface CountryState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  countries: Country[]|null
}

const initialState: CountryState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  countries: null
}

function startRequestRdc(state: CountryState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: CountryState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: CountryState, action: PayloadAction<Country[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.countries = action.payload;
}

const CountrySlice = createSlice({
    name: 'countries',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestFailedAct: requestFailedRdc,
      requestSuccessAct: requestSuccessRdc
    }
  }
)

export const {
  startRequestAct,
  requestFailedAct,
  requestSuccessAct
} = CountrySlice.actions;

export default CountrySlice.reducer;

export const getCountries = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestAct());
    const apiResponse = await getCountriesRep();
    dispatch(requestSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}