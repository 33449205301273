import axios  from "components/AxiosUtils";
import { Applicant } from "../Entity/Applicant";

export async function getApplicantFormDtoRep(applicationFormUid: string, applicationFormSectionId: number): Promise<Applicant> {
    try
    {
        const response = await axios.get<Applicant>(
            `/ApplicationForm/${applicationFormUid}/GetApplicantFormDTO/section/${applicationFormSectionId}/component/form`,
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getApplicantFileRep(applicationFormUid: string, filename: string): Promise<Blob>
{
    try
    {
        const response = await axios.get<ArrayBuffer>(
            `/Applicant/DownloadFile/${applicationFormUid}?file=${filename}`,
            {headers: {"Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        return blob;
    }
    catch (err: any)
    {
        throw err;
    }
}