export const GetPasswordRegex = () => {
  return new RegExp(/^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=(.*[\W|_])).{4,}$/);
}

export const GetEmailRegex = () => {
  return new RegExp(/(^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*))$/);
}

export function FormatBytes(bytes: number, decimals = 0) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const GetByteSize = (bytesNumber: number = 1) => 1048576 * bytesNumber;