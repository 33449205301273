import axios  from "components/AxiosUtils";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import { Pager } from "features/Entity/Pager";
import { FieldValues } from "react-hook-form";

export async function getProgramsRep(): Promise<string[]>{
    try{
        const response = await axios.get<string[]>(
            `/ApplicationForm/GetAvailablePrograms`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}

export async function getDurationsRep(): Promise<string[]>{
    try{
        const response = await axios.get<string[]>(
            `/ApplicationForm/GetAvailableDurations`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}

export async function getYearsRep(): Promise<number[]>{
    try{
        const response = await axios.get<number[]>(
            `/ApplicationForm/GetAvailableYears`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}

export async function getDestinationCountrieRep(): Promise<string[]>{
    try{
        const response = await axios.get<string[]>(
            `/ApplicationForm/GetAvailableDestinationCountries`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}

export async function getDeparturePeriodsRep(): Promise<string[]>{
    try{
        const response = await axios.get<string[]>(
            `/ApplicationForm/GetAvailableDeparturePeriods`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}

export async function getApplicationFormsFilteredRep(formData: FieldValues, limit: number, page: number): Promise<Pager<ApplicationForm>> {
    try {
        const response = await axios.post<Pager<ApplicationForm>>(
            `/ApplicationForm/SearchAppForm?limit=${limit}&page=${page}`,
            formData,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getPartnersRep(): Promise<string[]>{
    try{
        const response = await axios.get<string[]>(
            `/ApplicationForm/GetAvailablePartners`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}