import { ApplicationForm } from 'features/Entity/ApplicationForm'
import { ApplicationFormSection } from 'features/Entity/ApplicationFormSection'
import { ApplicationFormSectionStatusEnum } from 'features/Entity/SectionStaffInfo'
import React from 'react'
import t, { changeLanguage } from '../../../components/I18N/TranslateHelpers'
import { GetCurrentLocale } from '../../../components/Utils'

interface ComponentProps {
  applicationForm: ApplicationForm;
  section: ApplicationFormSection;
}

const ApplicantFormValidationInfo: React.FC<ComponentProps> = ({applicationForm, section}) => {
  React.useEffect(() => {
    const locale = GetCurrentLocale();
    changeLanguage(locale);
  }, []);

  const sectionApproved = section.statusOfValidator === ApplicationFormSectionStatusEnum.Approved;
  const sectionIncomplete = section.statusOfValidator === ApplicationFormSectionStatusEnum.Incomplete;

   return (
     <>
       {
         sectionApproved || sectionIncomplete ?
           <div className="nopaddingl col-xl-3 col-sm-6 box-col-3">
             <div className="card card-secondary">
               <div className="card-body align-items-center">
                  <div className="row nomargin">
                    <div className="col-md-4 text-center">
                      <div className={`rounded-circle-img is-${sectionApproved ? "complete" : "incomplete"} mb-2`}>
                        <i className={`icon-${sectionApproved ? "check" : "close"} status-is-${sectionApproved ? "complete" : "incomplete"} mb-2`}></i>
                      </div>
                    </div>
                    <div className="col-md-8 text-center">
                      <p className='mb-0'>{section.label} {t('form').toLowerCase()}</p>
                      <h5 className={`status-is-${sectionApproved ? "complete" : "incomplete"}`}>{t(`${sectionApproved ? "is_approved": "is_incomplete"}`)}</h5>
                    </div>
                    <div className="text-center col-md-12">
                      <small className="strong">
                        { t('submit_app_form_after_editing')}
                      </small>
                    </div>
                  </div>
               </div>
             </div>
           </div>
           : null
       }
       {
         section.commentOfValidator && section.commentOfValidator.length > 0 ?
           <div className="col-xl-3 col-sm-6 box-col-3">
             <div className="card card-secondary">
               <div className="card-body align-items-center">
                 <label className="form-label" htmlFor="comment">
                   {section.label} {t('comment').toLowerCase()}
                 </label>
                 <textarea className="form-control" id="comment" rows={5} readOnly>
                   {section.commentOfValidator}
                 </textarea>
               </div>
             </div>
           </div>
         :null
       }
       {
         applicationForm.commentOfValidator && applicationForm.commentOfValidator.length > 0 ?
         <div className={ section.commentOfValidator && section.commentOfValidator.length > 0 ? "col-xl-6 col-sm-6 box-col-3 nopaddingr" : "col-xl-9 col-sm-9 box-col-3 nopaddingr"}>
           <div className="card card-secondary">
             <div className="card-body align-items-center">
               <label className="form-label" htmlFor="comment">
                 {t('application_form')} {t('comment').toLowerCase()}
               </label>
               <textarea className="form-control" id="comment" rows={5} readOnly>
                 {applicationForm.commentOfValidator}
               </textarea>
             </div>
           </div>
         </div>
         :null
       }
     </>
   );
}

export default ApplicantFormValidationInfo
