import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { MotherTongue } from "features/Entity/MotherTongue";
import { getMotherTonguesRep } from "features/Repository/MotherTongueRepository";
import { AppThunk } from "../../app/store";

interface MotherTongueState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  motherTongues: MotherTongue[]|null
}

const initialState: MotherTongueState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  motherTongues: null
}

function startRequestRdc(state: MotherTongueState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: MotherTongueState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: MotherTongueState, action: PayloadAction<MotherTongue[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.motherTongues = action.payload;
}

const MotherTongueSlice = createSlice({
    name: 'motherTongues',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestFailedAct: requestFailedRdc,
      requestSuccessAct: requestSuccessRdc
    }
  }
)

export const {
  startRequestAct,
  requestFailedAct,
  requestSuccessAct
} = MotherTongueSlice.actions;

export default MotherTongueSlice.reducer;

export const getMotherTongues = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestAct());
    const apiResponse = await getMotherTonguesRep();
    dispatch(requestSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}