import axios  from "components/AxiosUtils";
import { getFileNameFromContentDisposition } from "components/Utils";
import { MediaAlbumElement } from "features/Entity/MediaAlbumElement";

export async function getMediaAlbumRep(applicationFormUid: string): Promise<MediaAlbumElement[]> {
    try
    {
        const response = await axios.get<MediaAlbumElement[]>(
            `/MediaAlbum/${applicationFormUid}/GetMediaAlbums`,
        );

        return response.data;
    }
    catch (err: any) 
    {
        throw err;
    }
}

export async function getMediaAlbumFileRep(applicationFormUid: string, resourceId: number): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/MediaAlbum/DownloadFile/${applicationFormUid}?resourceId=${resourceId}`,
            { headers: { "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);

        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "Picture.png"};
    }
    catch (err: any) {
        throw err;
    }
}
