import React from "react";
import { Tutorial } from "features/Entity/Tutorial";
import t from "../components/I18N/TranslateHelpers";
import { FRONT_URL } from "app/AppConfig";

export const renderTutorial = (tutorial: Tutorial) => {
    return(
        <>
        <div className="col-md-12 col-sm-12 mx-auto">
            {tutorial.content ?
                <div dangerouslySetInnerHTML={{ __html: tutorial.content }}></div>
                : ""}
            {tutorial.file && tutorial.file.length > 0 ?
                <a href={tutorial.file} target="_blank">
                    <i className="icofont icofont-download-alt mr-2"></i>{" "}
                    {t("download_pdf")}
                </a>
                : ""}
        </div>
        <div className="col-md-6 col-sm-12 mx-auto">
            {tutorial.video ?
                <video width="100%" height="auto" controls>
                    <source src={FRONT_URL + tutorial.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                : ""}
        </div>
        </>
    )
}