import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { GetCurrentLocale } from "components/Utils";
import { getPhysician } from "features/Slice/PhysicianSlice";
import React, { useState } from "react";
import {
	FieldValues,
	UseFormRegister,
} from "react-hook-form";
import { useSelector } from "react-redux";
import {
	renderEmailWithPattern,
	renderInputText,
} from "../../components/Forms/FormsComponent";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
interface ComponentProps {
	applicationFormId: string;
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	isReadOnly: boolean;
}

const PhysicianInfos: React.FC<ComponentProps> = ({
	applicationFormId,
	register,
	errors,
	isReadOnly
}) => {
	const isRequesting = useSelector((state: RootState) => state.physicianRdc.isRequesting);
	const physician = useSelector((state: RootState) => state.physicianRdc.physician);

	const [startedRequest, setStartedRequest] = useState<boolean>(false);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getPhysician(applicationFormId));
		setStartedRequest(true);
	}, []);

	return (
		isRequesting || !startedRequest ?
			<SpinnerOnTop />
			:
			<div className="row mt-3">
				<input
					type="hidden"
					id="id"
					value={physician?.id ?? 0}
					{...register("id")}
				/>
				<input
					type="hidden"
					id="applicationFormId"
					value={physician?.applicationFormId ?? 0}
					{...register("applicationFormId")}
				/>
				{renderInputText(
					t('first_name') + " " + t('of_the_physician'),
					"firstName",
					physician?.firstName,
					errors.firstName,
					register,
					true,
					"col-md-6 col-sm-6 mb-3",
					false,
					isReadOnly
				)}
				{renderInputText(
					t('last_name') + " " + t('of_the_physician'),
					"lastName",
					physician?.lastName,
					errors.lastName,
					register,
					true,
					"col-md-6 col-sm-6 mb-3",
					false,
					isReadOnly
				)}
				{renderEmailWithPattern(
					t('email') + " " + t('of_the_physician'),
					physician?.email,
					register,
					"email",
					true,
					errors.email,
					"col-sm-12 col-md-6 mb-3",
					undefined,
					isReadOnly
				)}
			</div>
	);
};

export default PhysicianInfos;
