import React from "react";
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch, UseFormGetValues, Control } from "react-hook-form";
import { changeLanguage } from "components/I18N/TranslateHelpers";
import { GetCurrentLocale, SelectInterface } from "components/Utils";
import { RootState, store } from "app/store";
import { useSelector } from "react-redux";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { getSurvey } from "features/Slice/SurveySlice";
import { ISurveyFormRenderersDictionary } from "features/Entity/Survey/ISurveyFormRenderersDictionary";
import SurveyFormRendererDefault from "./SurveyFormRendererDefault";
import SurveyFormRendererMedicalInfos from "./SurveyFormRendererMedicalInfos";
import SurveyFormRendererImmunisation from "./SurveyFormRendererImmunisation";
import SurveyFormRendererDietary from "./SurveyFormRendererDietary";
import SurveyFormRendererImmunisationTB from "./SurveyFormRendererImmunisationTB";

interface ComponentProps {
	applicationFormId: string;
	surveyCode: string;
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValue: UseFormGetValues<FieldValues>;
	surveyActionsIds: string | null;
	isReadOnly: boolean,
	control: Control<FieldValues, any>;
}

const SurveyForm: React.FC<ComponentProps> = ({
	applicationFormId,
	surveyCode,
	register,
	unregister,
	watch,
	setValue,
	getValue,
	surveyActionsIds,
	isReadOnly,
	control
}) => {
	const locale = GetCurrentLocale();
	changeLanguage(locale);

	// AJA : pas encore utilisé, à voir comment inclure un composant dont la fonction est référencé par un élement de tableau.
	let surveyFormRenderers = {} as ISurveyFormRenderersDictionary;

	surveyFormRenderers["medicalinfos"] = SurveyFormRendererMedicalInfos;
	surveyFormRenderers["default_render"] = SurveyFormRendererDefault;

	const isRequestingApplicantData = useSelector(
		(state: RootState) => state.surveyRdc.isRequesting,
	);
	const survey = useSelector(
		(state: RootState) => state.surveyRdc.surveys[surveyCode],
	);
	const surveyActions = useSelector(
		(state: RootState) => state.surveyRdc.surveyActions,
	);

	React.useEffect(() => {
		store.dispatch(getSurvey(applicationFormId, surveyCode));
	}, []);

	if (!survey) {
		return (
			<SpinnerOnTop />
		)
	}
	else {
		switch (surveyCode.toLocaleLowerCase()) {
			case "medicalinfos":
				return (
					<SurveyFormRendererMedicalInfos
						applicationFormId={applicationFormId}
						survey={survey}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isReadOnly}
					/>
				);
			case "immunisation":
				return (
					<SurveyFormRendererImmunisation
						applicationFormId={applicationFormId}
						survey={survey}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isReadOnly}
						control={control}
					/>
				);
				break;
			case "immunisationtuberculinskintest" :
				return(
						<SurveyFormRendererImmunisationTB
							applicationFormId={applicationFormId}
							survey={survey}
							register={register}
							unregister={unregister}
							watch={watch}
							setValue={setValue}
							getValue={getValue}
							isSurveyFormReadOnly={isReadOnly}
							control={control}
						/>
				);
				break;
			default:
				return (
					<SurveyFormRendererDefault
						applicationFormId={applicationFormId}
						survey={survey}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isReadOnly}
						control={control}
					/>
				);
				break
		};
	}
}

export default SurveyForm;
