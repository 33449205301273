import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Agreement } from "features/Entity/Agreement";
import { getAgreementListRep, getAgreementRep, getUserDownloadedFileRep, postAgreementSingleFileRep } from "features/Repository/AgreementRepository";
import { AppThunk } from "../../app/store";
import t from "../../components/I18N/TranslateHelpers";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { AxiosError } from "axios";
import { toastAxiosError } from "components/Utils";

interface AgreementState {
  isPostingSingleFile: boolean;
  isRequestingList: boolean;
  isRequestSuccess: boolean;
  isPostSuccess: boolean;
  isRequestFailed: boolean;
  isPostingSingleFileFailed: boolean;
  error: AxiosError | null;
  agreementsList: Agreement[]|null;
}

const initialState: AgreementState = {
  isPostingSingleFile: false,
  isRequestingList: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  isPostingSingleFileFailed: false,
  error: null,
  agreementsList: null,
  isPostSuccess: false
}

function startRequestListRdc(state: AgreementState) {
  state.isRequestingList = true;
  state.isRequestFailed = false;
  state.error = null;
}

function startPostSingleFileRdc(state: AgreementState) {
  state.isPostSuccess = false;
  state.isPostingSingleFile = true;
  state.isPostingSingleFileFailed = false;
  state.error = null;
}

function requestListFailedRdc(state: AgreementState, action: PayloadAction<AxiosError>) {
  state.isRequestingList = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestDownloadFailedRdc(state: AgreementState, action: PayloadAction<AxiosError>) {
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function postSingleFileFailedRdc(state: AgreementState, action: PayloadAction<AxiosError>) {
  state.isPostingSingleFile = false;
  state.isPostingSingleFileFailed = true;
  state.error = action.payload;
  
  toastAxiosError(action.payload);
}

function requestListSuccessRdc(state: AgreementState, action: PayloadAction<Agreement[]>) {
  state.isRequestingList = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.agreementsList = action.payload;
}

function postSingleFileSuccessRdc(state: AgreementState) {
  state.isPostSuccess = true;
  state.isPostingSingleFile = false;
  state.isPostingSingleFileFailed = false;
  state.error = null;
  toast.success(t("files_successfully_uploaded"));
}

const AgreementSlice = createSlice({
  name: 'Agreements',
  initialState: initialState,
  reducers: {
    startRequestListAct: startRequestListRdc,
    requestListFailedAct: requestListFailedRdc,
    requestListSuccessAct: requestListSuccessRdc,
    startPostSingleFileAct: startPostSingleFileRdc,
    postSingleFileFailedAct: postSingleFileFailedRdc,
    postSingleFileSuccessAct: postSingleFileSuccessRdc,
    requestDownloadFailedAct: requestDownloadFailedRdc
  }
})

export const {
  startRequestListAct,
  requestListFailedAct,
  requestListSuccessAct,
  startPostSingleFileAct,
  postSingleFileFailedAct,
  postSingleFileSuccessAct,
  requestDownloadFailedAct
} = AgreementSlice.actions;

export default AgreementSlice.reducer;

export const getAgreementsList = (applicationFormUid: string): AppThunk => async dispatch =>
{
  try
  {
    dispatch(startRequestListAct());
    const apiResponse = await getAgreementListRep(applicationFormUid);
    dispatch(requestListSuccessAct(apiResponse));
  } catch (err)
  {
    dispatch(requestListFailedAct(err as AxiosError));
  }
}

export const getAgreement = (agreement: Agreement, cultureCode: string, filename: string): AppThunk => async dispatch =>
{
  try
  {
    const apiResponse = await getAgreementRep(agreement.agreementUID, cultureCode, filename);

    saveAs(apiResponse.fileBlob, apiResponse.filename);
  } catch (err)
  {
    dispatch(requestDownloadFailedAct(err as AxiosError));
  }
}

export const getUserDownloadedAgreement = (applicationFormUid: string, agreementUid: string, filename: string): AppThunk => async dispatch =>
{
  try
  {
    const apiResponse = await getUserDownloadedFileRep(applicationFormUid, agreementUid, filename);

    saveAs(apiResponse.fileBlob, apiResponse.filename);
  } catch (err)
  {
    dispatch(requestDownloadFailedAct(err as AxiosError));
  }
}

export const uploadAgreementFile = (applicationFormUid: string, agreementUid: string, agreementFile: FileList, sectionId: number): AppThunk => async dispatch =>
{
  try
  {
    dispatch(startPostSingleFileAct());
    await postAgreementSingleFileRep(applicationFormUid, agreementUid, agreementFile, sectionId);
    dispatch(postSingleFileSuccessAct());
  } catch (err)
  {
    dispatch(postSingleFileFailedAct(err as AxiosError));
  }
}
