import React from "react";
import t, {
    changeLanguage,
} from "../../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../../components/Utils";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";
import FileUploadField from "components/Forms/FileUploadField";
import { RootState, store } from "app/store";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import { getExternalInfoConfig, getExternalInfoConfigTemplateFile, getExternalInfoConfigUploadedFile } from "features/Slice/ExternalInfoSlice";
import { useSelector } from "react-redux";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { renderCheckbox } from "components/Forms/FormsComponent";
import { getMediasPDFAndImages } from "components/UtilsForUpload";

interface ComponentProps {
    register: UseFormRegister<FieldValues>;
    isReadOnly: boolean;
    applicationForm: ApplicationForm;
}

const PartnerApplicationForm: React.FC<ComponentProps> = ({register, isReadOnly, applicationForm}) => {
    const isRequesting = useSelector((state: RootState) => state.externalInfoRdc.isRequesting);
    const relAppFormExternalInfoConfigs = useSelector((state: RootState) => state.externalInfoRdc.relAppFormExternalInfoConfigs);

    React.useEffect(() => {
        const locale = GetCurrentLocale();
        changeLanguage(locale);
        store.dispatch(getExternalInfoConfig(applicationForm.uid));
    }, []);

    return (
        <div className="row">
            {isRequesting || !relAppFormExternalInfoConfigs ?
            <SpinnerOnTop />
                :
                relAppFormExternalInfoConfigs.map((externalInfoConfig, index) => {
                    const hasFormFile = externalInfoConfig.formFile !== null;
                    const hasFormLink = externalInfoConfig.formLink !== null && externalInfoConfig.formLink.length > 0;
                    var stepIndex = 1;
    
                    return (
                        <div key={index} className="col-md-6">
                            <div className="card mb-3 partner-section">
                                <div className="card-header">
                                    <h5>
                                        {externalInfoConfig.label ?? t("partner_section")}
                                    </h5>
                                </div>
    
                                <input
                                    type="hidden"
                                    id={`dto[${index}].Id`}
                                    value={externalInfoConfig.id}
                                    {...register(`dto[${index}].Id`)}
                                />
    
                                <div className="card-body stepper stepper-pills">

                                    {hasFormFile && (
                                        <div className="stepper-wrapper d-flex align-items-center mb-4">
                                            <div className="stepper-icon">
                                                <span className="stepper-number">{ stepIndex++ }</span>
                                            </div>
                                            <div className="stepper-label">
                                                <span className="form-label">
                                                    {t('download_template')}
                                                </span>
                                                <div className="stepper-desc">
                                                    <button type="button" onClick={() => store.dispatch(getExternalInfoConfigTemplateFile(externalInfoConfig.externalInfoConfigurationId, externalInfoConfig.formFile as string))}>
                                                        <i className="icofont icofont-download-alt mr-2"></i> {externalInfoConfig.formFile}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
    
                                    { (hasFormLink || externalInfoConfig.isLinkUniqueByApplicant) && (
                                        <div className="stepper-wrapper d-flex mb-4">
                                            <div className="stepper-icon">
                                                <span className="stepper-number">{ stepIndex++ }</span>
                                            </div>
                                            <div className="stepper-label">
                                                <span className="form-label">
                                                    {t('access_application_form')}
                                                </span>
                                                <div className="stepper-desc">
                                                    {
                                                        hasFormLink ? 
                                                        <>
                                                            <div className="d-flex align-items-center mb-2 pt-2">
                                                                <i className="icon-arrow-circle-right me-2"></i>
                                                                <a href={externalInfoConfig.formLink as string} target="_blank">Click here</a>
                                                            </div>
                                                            {
                                                                renderCheckbox(
                                                                    t('i_completed_partner_online_form'),
                                                                    `dto[${index}].IsFormLinkCompleted`,
                                                                    false,
                                                                    register,
                                                                    false,
                                                                    externalInfoConfig.isFormLinkCompleted ?? false,
                                                                    "",
                                                                    isReadOnly
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        /* Message d'alerte si le lien unique n'est pas encore disponible */
                                                        <div className="alert alert-warning block py-2 px-2">
                                                            {t('partner_form_not_yet_available')}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
    
                                    {hasFormFile && (
                                        <div className="stepper-wrapper d-flex">
                                            <div className="stepper-icon">
                                                <span className="stepper-number">{ stepIndex++ }</span>
                                            </div>
                                            <div className="stepper-label">
                                                <div className="stepper-desc">
                                                <FileUploadField
                                                    id={`dto[${index}].NewFormFileUploaded`}
                                                    label={t("upload_filled_template")}
                                                    description={t('only_image_or_pdf_upload')}
                                                    classes="col-12"
                                                    required={false}
                                                    disabled={isReadOnly ?? false}
                                                    errorMessages=""
                                                    isValid={false}
                                                    name={`dto[${index}].NewFormFileUploaded`}
                                                    onFieldChange=""
                                                    value={externalInfoConfig.formFileUploaded ?? null}
                                                    register={register}
                                                    accept={getMediasPDFAndImages()}
                                                    downloadFileAction={() => externalInfoConfig.formFileUploaded !== null ? store.dispatch(getExternalInfoConfigUploadedFile(externalInfoConfig.id, externalInfoConfig.formFileUploaded)) : undefined}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default PartnerApplicationForm;
