import { createIntl, createIntlCache } from "react-intl";
import {messagesIntl} from "./messages/messagesIntl";
import {LocalParams} from "../LocalParams";

export type MessageIntl =
  {
    [key:string] : any;
  }

const cache = createIntlCache();

let int = createIntl(
  {
    locale: "fr",
    messages: messagesIntl["fr"]
  },
  cache
);

export function changeLanguage(lang: string) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messagesIntl[lang]
    },
    cache
  );
  int = newIntl;
}

const translate = (id: string, values?: {}) => {
  let currentChannel = LocalParams.getItem('CURRENT_CHANNEL');
  let idMessage = id + '_' + currentChannel;
  const stringExists = !!int.messages[idMessage];
  if (stringExists) {
    return int.formatMessage({id: idMessage}, values);
  }
  else {
    return int.formatMessage({id}, values);
  }
};

export default translate;
