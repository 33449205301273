import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Alert } from "features/Entity/Alert";
import { getAlertsRep, markAlertAsReadRep } from "features/Repository/AlertRepository";
import { AppThunk } from "../../app/store";

interface AlertState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  alerts: Alert[]|null
}

const initialState: AlertState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  alerts: null
}

function startRequestRdc(state: AlertState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: AlertState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: AlertState, action: PayloadAction<[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.alerts = action.payload;
}

const AlertSlice = createSlice(
  {
    name: 'Alerts',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct
} = AlertSlice.actions;

export default AlertSlice.reducer;

export const getAlerts = (applicationFormUid: string): AppThunk => async dispatch =>
{
  try
  {
    //dispatch(startRequestAct());
    //const apiResponse = await getAlertsRep(applicationFormUid);
    //dispatch(requestSuccessAct(apiResponse));
  } catch (err)
  {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const markAlertAsRead = (applicationFormUid: string, alertId: number): AppThunk => async dispatch =>
{
  try
  {
    // await markAlertAsReadRep(applicationFormUid, alertId);
  } catch (err)
  {
    dispatch(requestFailedAct(err as AxiosError));
  }
}
