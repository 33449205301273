import axios  from "components/AxiosUtils";
import { Teacher } from "features/Entity/Teacher";

export async function getTeacherRep(applicationFormUid: string, isLanguageTeacher: boolean): Promise<Teacher>
{
    try
    {
        const response = await axios.get<Teacher>(
            `/Teacher/${applicationFormUid}/${isLanguageTeacher ? "GetLanguageTeacher" : "GetTeacher"}`,
        );

        return response.data;
    }
    catch (err: any) 
    {
        throw err;
    }
}
