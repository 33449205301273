import React  from 'react';
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import { getUserToken } from "../components/UserUtils";
import { pathname, redirectTo, redirectToLogin } from "../components/Utils";
import ForgottenPasswordPage from "../features/Pages/ForgottenPasswordPage";
import LoginPage from "../features/Pages/LoginPage";
import t from "../components/I18N/TranslateHelpers";
import { ResetPasswordPageType } from 'features/Entity/ResetPasswordFormData';

function AppLogin()
{
  if(pathname === "/")
  {
    if(getUserToken())
    {
      redirectTo(t("url_dashboard", true));
    }
    else
    {
      redirectToLogin();
    }
  }

  const AppLoginRoutes = () => useRoutes([
    { path: "/", element: <LoginPage /> },
    { path: "/login", element: <LoginPage /> },
    { path: "/forgotten-password", element: <ForgottenPasswordPage pageType={ResetPasswordPageType.ForgottenPassword} /> },
    { path: "/reset-password", element: <ForgottenPasswordPage pageType={ResetPasswordPageType.ResetPassword} /> },
    { path: "/activate-account", element: <ForgottenPasswordPage pageType={ResetPasswordPageType.ActivateAccount} /> },
  ]);

  return (
    <Router>
      <AppLoginRoutes />
    </Router>
  );
}

export default AppLogin;
