import React, { useRef, useState } from "react";
import { FieldValues, Control, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface ComponentProps {
	id: string;
	name: string;
	label: string | null;
	readonly: boolean;
	defaultValue: Date | null | undefined;
	control: Control<FieldValues, any>;
	required?: boolean;
	inputRequired?: boolean;
	errors?: { [x: string]: any },
}

const DatePickerField: React.FC<ComponentProps> = ({ id, name, label, readonly, defaultValue, control, required, inputRequired, errors }: ComponentProps) => {
	return (
		<div className="form-group row form-datepicker">
			{label && label.length > 0 ? (
				<label className={required ? "col col-form-label required" : "col col-form-label"}>{label}</label>
			) : null}
			<div className={"col-md-12"}>
				<div className={!readonly ? "input-group" : "input-group input-readonly"}>
					<div className={errors && errors[id] ? "datepicker-here form-control digits is-invalid" : "datepicker-here form-control digits"}>
						<Controller
							control={control}
							name={name}
							render={({
								field: { onChange, onBlur, value, name, ref },
							}) => {
								if (!value && defaultValue) {
									onChange(defaultValue);
								}
								return (
									<label className="width100 d-flex align-items-center mb-0">
										<DatePicker
											className="hide-border"
											showMonthDropdown
											showYearDropdown
											scrollableYearDropdown
											onBlur={onBlur}
											ref={ref}
											id={id}
											placeholderText={"dd/mm/yyyy"}
											dateFormat={"dd/MM/yyyy"}
											name={name}
											closeOnScroll={true}
											readOnly={readonly}
											// maxDate={new Date()}
											selected={value ?? (defaultValue ?? null)}
											onChange={(date: Date) => onChange(date)}
											yearDropdownItemNumber={50}
											wrapperClassName="w-full"
											required={inputRequired}
										/>
										<div className="input-group-text">
											<i className="fa fa-calendar"></i>
										</div>
									</label>
								)
							}}
						/>
					</div>
				</div>
			</div>
		</div >
	);
}

export default DatePickerField;