import axios from "components/AxiosUtils";
import { AccountActivationDateDTO, UserData } from "features/Entity/UserData";
import { ResetPasswordFormData, ResetPasswordToken } from "../Entity/ResetPasswordFormData";

export async function userLoginRep(email: string, password: string): Promise<string> {
    try
    {
        const response = await axios.post<string>(
            `/Account/Login`,
            { email: email, password: password },
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function userLogoutRep(): Promise<string> {
    try
    {
        const response = await axios.post<string>(
            `/Account/Logout`,
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function changePasswordRep(formData: ResetPasswordFormData): Promise<void> {
    try
    {
        await axios.post(
            `/Account/ChangePassword`,
            {email: formData.email, newPassword: formData.newPassword, confirmNewPassword: formData.confirmNewPassword},
            {headers: {"resetToken": formData.resetToken}}
        );
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function postPasswordChangeTokenRep(email: string): Promise<string> {
    try
    {
        const response = await axios.post<string>(
            `/Account/PasswordChangeToken?email=${email}`
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getCurrentUserInfoRep(): Promise<UserData> {
    try
    {
        const response = await axios.get<UserData>(
            `/Account/GetCurrentUserInfo`,
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getAccountActivationDateRep(email: string, token: string): Promise<AccountActivationDateDTO> {
    try
    {
        const response = await axios.get<AccountActivationDateDTO>(
            `/Account/GetAccountActivationDate?email=${email}&token=${token}`
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getActivateUserRep(email: string, token: string): Promise<ResetPasswordToken> {
    try
    {
        const response = await axios.get<ResetPasswordToken>(
            `/Account/PasswordChangeTokenActivation?email=${email}&token=${token}`
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}
