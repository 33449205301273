import {
	Answer,
	AnswerTemplate,
	QuestionTemplate,
	Survey,
} from "features/Entity/Survey/Survey";
import React, { useState } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { renderCheckbox } from "./SurveyQuestionComponents";
import { renderInputText } from "components/Forms/FormsComponent";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";
import { getSurveyWidgetDisplayMode } from "components/Utils";
import { SurveyWidgetDisplayMode } from "features/Entity/Survey/SurveyWidgetDisplayMode";

interface ComponentProps {
	survey: Survey;
	questionTemplate: QuestionTemplate;
	register: UseFormRegister<FieldValues>;
	isSurveyFormReadOnly: boolean;
	labelInline?: boolean;
	description?: string;
	withComment?: boolean;
}

const QuestionMCMA: React.FC<ComponentProps> = ({
	survey,
	questionTemplate,
	register,
	isSurveyFormReadOnly,
	labelInline,
	description,
	withComment,
}) => {
	const surveyActions = useSelector(
		(state: RootState) => state.surveyRdc.surveyActions,
	);
	var isChecked: boolean | undefined = true;
	function checkIfRadioSelected(answerTemplate: AnswerTemplate): boolean {
		var isSelected = false;
		if (!survey) {
			return false;
		}
		survey.answers.forEach((answer) => {
			if (answer.answerTemplateId == answerTemplate.id) {
				isSelected = true;
				return;
			}
		});
		return isSelected;
	}

	//DUPLICATE FUNCTION
	function getAnswer(answerTemplate: AnswerTemplate): Answer | null {
		var returnAnswer = null;
		if (!survey) {
			return returnAnswer;
		}
		survey.answers.forEach((answer) => {
			if (answer.answerTemplateId == answerTemplate.id) {
				returnAnswer = answer;
				return;
			}
		});
		return returnAnswer;
	}
	return (
		<li
			className="list-group-item py-3 row d-flex g-0 align-items-center checkbox-render"
			key={questionTemplate.code}
		>
			<div className={labelInline ? "col-md-12 mb-3" : "col-md-3 pr-2"}>
				<label htmlFor="" className={questionTemplate.mandatory ? "form-label mb-0 required" : "form-label mb-0"}>{questionTemplate.label}</label>
				{description ? (
					<p>
						<small>{description}</small>
					</p>
				) : null}
			</div>
			<div
				className={
					labelInline ? "col-md-12 column-count-2" : "col-md-9 column-count-3"
				}
			>
				<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
					{questionTemplate.answerTemplates.map((answerTemplate: AnswerTemplate, index: number) => {
						isChecked = checkIfRadioSelected(answerTemplate);
						const answer = getAnswer(answerTemplate);
						return (
							<div className={answerTemplate.description || answerTemplate.commentLabel !== "" ? "d-flex checkbox-with-description" : ""} key={answerTemplate + "-" + questionTemplate + "-" + index}>
								{renderCheckbox(
									`${answerTemplate.label}`,
									`SURVEY-${survey.id}.QUESTION-${questionTemplate.id}`,
									`SURVEY-${survey.id}.ANSWER-${answerTemplate.id}`,
									false,
									register,
									false,
									isChecked,
									"",
									answerTemplate.description,
									getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false
								)}
								{answerTemplate.withComment && answerTemplate.commentLabel !== null ? (
									<>
										<small> {answerTemplate.commentLabel} </small>
										{renderInputText(
											"",
											`SURVEY-${survey.id}.ANSWER-COMMENT-${answerTemplate.id}`,
											answer?.commentValueText,
											"error",
											register,
											false,
											"ml-2 top-neg-5",
											false,
											getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false
										)}
									</>
								) : null}
							</div>
						);
					},
					)}
				</div>
			</div>
		</li>
	);
};

export default QuestionMCMA;
