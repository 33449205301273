import React, { useEffect } from "react";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, getHostnameLogo, redirectTo } from "../../components/Utils";
import { store } from "../../app/store";
import { userLogout } from "../Slice/UserSlice";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";
import { BO_URL } from "app/AppConfig";

const Header: React.FC = () => {
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);

	useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
		document.dispatchEvent(new Event("getToggleSidebar"));
	 }, []);
	 
	return (
		<div className="page-main-header">
			<div className="main-header-right row m-0">
				<div className="main-header-left">
					<div className="logo-wrapper">
						<a href={t('url_dashboard')}>
							<img
								className="img-fluid"
								src={getHostnameLogo(false)}
								alt=""
							/>
						</a>
					</div>
					<div className="dark-logo-wrapper">
						<a href={t('url_dashboard')}>
							<img
								className="img-fluid"
								src={getHostnameLogo(true)}
								alt=""
							/>
						</a>
					</div>
					<div className="toggle-sidebar">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-align-center status_toggle middle"
							id="sidebar-toggle"
						>
							<line x1="18" y1="10" x2="6" y2="10"></line>
							<line x1="21" y1="6" x2="3" y2="6"></line>
							<line x1="21" y1="14" x2="3" y2="14"></line>
							<line x1="18" y1="18" x2="6" y2="18"></line>
						</svg>
					</div>
				</div>
				<div className="nav-right col pull-right right-menu p-0">
					<ul className="nav-menus">
						<li className="onhover-dropdown">
							<button className="btn btn-primary-light">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users mr-2"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
								{
									userInfo ?
										<>
											{
												userInfo.isStaff ? t('staff') + " - " 
												:
												userInfo.isSendingPartner && !userInfo.isStaff ?
													t('partner') + " - "
												: "" 
											}
											{
												userInfo.lastname !== null ? userInfo.lastname : userInfo.username
											}
										</>
									: null
								}
							</button>
							<div className="bookmark-dropdown onhover-show-div">
							<ul>
								{
								userInfo && userInfo.hasAccessToBO ?
									<li className="noti-primary">
										<div onClick={() => BO_URL ? redirectTo(BO_URL, false) : undefined} className="media">
											<span className="notification-bg bg-light-primary">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
												<path d="M64 96c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V352H512V96H128V352H64V96zM0 403.2C0 392.6 8.6 384 19.2 384H620.8c10.6 0 19.2 8.6 19.2 19.2c0 42.4-34.4 76.8-76.8 76.8H76.8C34.4 480 0 445.6 0 403.2zM281 209l-31 31 31 31c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-48-48c-9.4-9.4-9.4-24.6 0-33.9l48-48c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM393 175l48 48c9.4 9.4 9.4 24.6 0 33.9l-48 48c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l31-31-31-31c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"/></svg>
											</span>
											{t("to_back_office")}
										</div>
									</li>
									:null
								}	
								<li className="noti-primary">
									<div  className="media" onClick={() => store.dispatch(userLogout(false))}>
										<span className="notification-bg bg-light-primary"><svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
												className="feather feather-log-out"
											>
												<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
												<polyline points="16 17 21 12 16 7"></polyline>
												<line x1="21" y1="12" x2="9" y2="12"></line>
											</svg>
										</span>
										{t("logout")}
									</div>
								</li>
							</ul>
							</div>
						</li>
					</ul>
				</div>
				<div className="d-lg-none mobile-toggle pull-right w-auto">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="feather feather-more-horizontal"
					>
						<circle cx="12" cy="12" r="1"></circle>
						<circle cx="19" cy="12" r="1"></circle>
						<circle cx="5" cy="12" r="1"></circle>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default Header;
