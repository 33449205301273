import { QuestionTemplate, Survey, SurveyAction } from "features/Entity/Survey/Survey";
import { SurveyActionConditionTypeEnum } from "features/Entity/Survey/SurveyActionConditionTypeEnum";
import { store } from "app/store";
import { addSurveyAction, removeSurveyAction } from "features/Slice/SurveySlice";
import { SurveyActionTypeEnum } from "features/Entity/Survey/SurveyActionTypeEnum";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";

/**
 * This class is used to supply the services concerning the Survey Actions
 */
export class SurveyActionService {
    survey: Survey;
    actionsToAdd: SurveyAction[];
    actionsToRemove: SurveyAction[];

    constructor(survey: Survey) {
        this.survey = survey;
        this.actionsToAdd = [];
        this.actionsToRemove = []
    }
    surveyActions = useSelector(
        (state: RootState) => state.surveyRdc.surveyActions,
    );
    /**
     * This method is checking if, for a question and a current answer, the action condition eventually associated with this question is verified.
     * @param currentValue 
     * @param questionTemplate 
     * @returns 
     */
    checkIfConditionVerified(currentValue: string, questionTemplate: QuestionTemplate): boolean {

        let result = false;
        // Check if we have a current value to evaluate (the current answer of a question)
        // check if the survey template of the current survey has conditions to evaluate
        if ((currentValue) && (this.survey.surveyTemplate.surveyActionConditions != null) && (this.survey.surveyTemplate.surveyActionConditions.length > 0)) {
            // We iterate in the survey template conditions to evalute each condition
            // We need to filter on the condition related to the current questionTemplate
            // 
            this.survey.surveyTemplate.surveyActionConditions.filter(rst => rst.questionTemplateId == questionTemplate.id).forEach((surveyActionCondition, index) => {
                // We need to extract the answer id of the current value answer
                const regExp = new RegExp(".*-([0-9]+)$", "g");
                const res = regExp.exec(currentValue);
                let answerTemplateIdInAction = 0;
                if ((res != null) && (res.length > 0)) {
                    answerTemplateIdInAction = +res[1];
                }
                if (surveyActionCondition.surveyActionConditionType == SurveyActionConditionTypeEnum.QuestionIsEqualToAnswer) {
                    if (answerTemplateIdInAction == surveyActionCondition.answerTemplateId) {
                        this.actionsToAdd.push(surveyActionCondition.surveyAction);
                        result = true;
                    }
                    else {
                        this.actionsToRemove.push(surveyActionCondition.surveyAction);
                    }
                }
                else if (surveyActionCondition.surveyActionConditionType == SurveyActionConditionTypeEnum.QuestionIsNOTEqualToAnswer) {
                    if (answerTemplateIdInAction != surveyActionCondition.answerTemplateId) {
                        this.actionsToAdd.push(surveyActionCondition.surveyAction);
                        result = true;
                    }
                    else {
                        this.actionsToRemove.push(surveyActionCondition.surveyAction);
                    }
                }

            })
        }
        return (result);

    }

    /**
     * This method is checking if, for a question and a current answer, the action condition eventually associated with this question is verified.
     * @param currentValue 
     * @param questionTemplate 
     * @returns 
     */
    checkIfConditionVerifiedAndTriggerAction(currentValue: string, questionTemplate: QuestionTemplate): boolean {
        let condVerified = false;

        if (currentValue == undefined) {
            return condVerified;
        }
        condVerified = this.checkIfConditionVerified(currentValue, questionTemplate);
        if (condVerified) {
            if (this.actionsToRemove != null) {
                store.dispatch(removeSurveyAction(this.actionsToRemove));
            }
            if (this.actionsToAdd != null) {
                store.dispatch(addSurveyAction(this.actionsToAdd));
            }

        }
        else {
            if (this.actionsToRemove != null) {
                store.dispatch(removeSurveyAction(this.actionsToRemove));
            }
        }

        return (condVerified);
    }

    checkIfActionTriggered(surveyTemplateId: number, surveyActionType: SurveyActionTypeEnum): boolean {
        let actionTriggered = false

        Object.keys(this.surveyActions).map(surveyActionId => {
            let item = this.surveyActions[surveyActionId];
            if (item !== null) {
                if ((item.surveyTemplateId == surveyTemplateId) && (item.surveyActionType == surveyActionType)) {
                    actionTriggered = true;
                    return
                }
            }

        })
        return actionTriggered
    }
}