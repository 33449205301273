import React from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { addErrorToaster } from "components/Utils";
import { validateExtensionType } from "components/UtilsForApplicationForm";
interface ComponentProps
{
	id: string,
	label: string,
	description: string|null,
	classes: string,
	required: boolean,
	disabled: boolean,
	errorMessages: any,
	isValid: boolean,
	name: string,
	onFieldChange: any,
	value: string|null,
	register?: UseFormRegister<FieldValues>
	accept?: string,
	downloadFileAction?: any
}

const FileUploadField: React.FC<ComponentProps> = ({id,label,description,classes,required,disabled,register,errorMessages,isValid,name,onFieldChange,value,accept,downloadFileAction}: ComponentProps) => {
	return (
			<div className={classes}>
				{
					label !== "" ?
					<label htmlFor={id} className={ required ? "form-label required width100" : "form-label width100"}>
						{label}
					</label>
				: null
				}
					<label htmlFor={id} className="label-input-file">
						{t('select_file')}
					</label> 
					<input
						autoComplete="off"
						type="file"
						className="form-control input-file-type"
						id={id}
						key={id}
						disabled={disabled}
						accept={accept}
						{...(register !== undefined ? register(name, { onChange: (e) => validateExtensionType(e, accept, addErrorToaster, onFieldChange, id)}) : undefined)}
					/>
				{value && value.length > 0 && downloadFileAction ?
					<React.Fragment>
						<button type="button" onClick={() => downloadFileAction()}>
							<i className="icofont icofont-download-alt mr-2"></i>{" "}
							{value}
						</button><br/>
					</React.Fragment>
					: ""}
				{description ? <small>{description}</small> : ""}
				{!isValid ? (
					<span className="field-required">{errorMessages}</span>
				) : (
					""
				)}
			</div>
		);
}

export default FileUploadField
