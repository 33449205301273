import React, { useState } from "react";
import {
	Control,
	FieldValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormUnregister,
	UseFormWatch,
	UseFormGetValues,
	useForm,
} from "react-hook-form";
import t, { changeLanguage } from "components/I18N/TranslateHelpers";
import { AnswerTemplate, Survey } from "features/Entity/Survey/Survey";
import QuestionMCOA from "./QuestionMCOA";
import QuestionFreeText from "./QuestionFreeText";
import QuestionMCMA from "./QuestionMCMA";
import DatePickerField from "components/Forms/DatePickerField";
import { renderInputText, renderTextArea } from "./SurveyQuestionComponents";
import { getValueText } from "components/Utils";
import QuestionNote from "./QuestionNote";
interface ComponentProps {
	applicationFormId: string;
	survey: Survey;
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValue: UseFormGetValues<FieldValues>;
	isSurveyFormReadOnly: boolean;
}

const SurveyFormRendererMedicalInfos: React.FC<ComponentProps> = ({
	applicationFormId,
	survey,
	register,
	unregister,
	watch,
	setValue,
	getValue,
	isSurveyFormReadOnly,
}) => {
	const IllnessSymtomsType =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "IllnessSymtomsType",
		);

	const questionIllnessSymtomsType = IllnessSymtomsType.length > 0 ? IllnessSymtomsType[0] : null

	const IllnessSymtomsExplanation =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "IllnessSymtomsExplanation",
		);

	const questionIllnessSymtomsExplanation = IllnessSymtomsExplanation.length > 0 ? IllnessSymtomsExplanation[0] : null

	const UseDrugsMedication =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "UseDrugsMedication",
		);

	const questionUseDrugsMedication = UseDrugsMedication.length > 0 ? UseDrugsMedication[0] : null

	const HasStudentHospitalised =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "HasStudentHospitalised",
		);
	const questionHasStudentHospitalised = HasStudentHospitalised.length > 0 ? HasStudentHospitalised[0] : null


	const HasStudentConsultedSpecialist =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "HasStudentConsultedSpecialist",
		);

	const questionHasStudentConsultedSpecialist = HasStudentConsultedSpecialist.length > 0 ? HasStudentConsultedSpecialist[0] : null

	const CandidateImpairment =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "CandidateImpairment",
		);

	const questionCandidateImpairment = CandidateImpairment.length > 0 ? CandidateImpairment[0] : null

	const CandidateImpairmentExplaination =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "CandidateImpairmentExplaination",
		);
	const questionCandidateImpairmentExplaination = CandidateImpairmentExplaination.length > 0 ? CandidateImpairmentExplaination[0] : null

	const HasCandidateConsulted =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "HasCandidateConsulted",
		);
	const questionHasCandidateConsulted = HasCandidateConsulted.length > 0 ? HasCandidateConsulted[0] : null

	const CandidateHealth =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "CandidateHealth",
		);
	const questionCandidateHealth = CandidateHealth.length > 0 ? CandidateHealth[0] : null

	const StudentHeightM =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentHeightMeter",
		);
	const questionStudentHeightM = StudentHeightM.length > 0 ? StudentHeightM[0] : null

	const StudentHeightCM =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentHeightCM",
		);

	const questionStudentHeightCM = StudentHeightCM.length > 0 ? StudentHeightCM[0] : null

	const StudentHeightInches =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentHeightInches",
		);

	const questionStudentHeightInches = StudentHeightInches.length > 0 ? StudentHeightInches[0] : null

	const StudentHeightFeet =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentHeightFeet",
		);
	const questionStudentHeightFeet = StudentHeightFeet.length > 0 ? StudentHeightFeet[0] : null


	const StudentWeightKG =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentWeightKG",
		);

	const questionStudentWeightKG = StudentWeightKG.length > 0 ? StudentWeightKG[0] : null

	const StudentWeightLBS14 =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentWeightLBS14",
		);
	const questionStudentWeightLBS14 = StudentWeightLBS14.length > 0 ? StudentWeightLBS14[0] : null

	const StudentWeightST =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentWeightST",
		);
	const questionStudentWeightST = StudentWeightST.length > 0 ? StudentWeightST[0] : null

	const StudentBloodGroup =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentBloodGroup",
		);
	const questionStudentBloodGroup = StudentBloodGroup.length > 0 ? StudentBloodGroup[0] : null

	const StudentParticipationSportActivities =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "StudentParticipationSportActivities",
		);
	const questionStudentParticipationSportActivities = StudentParticipationSportActivities.length > 0 ? StudentParticipationSportActivities[0] : null


	const DescribeeachDisease =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "NoteDescribeeachDisease",
		);
	const NoteDescribeeachDisease = DescribeeachDisease.length > 0 ? DescribeeachDisease[0] : null

	const StudentWeightSum =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "QStudentWeightSum",
		);
	const questionStudentWeightSum = StudentWeightSum.length > 0 ? StudentWeightSum[0] : null

	const {
		control,
		formState: { errors },
	} = useForm({
		mode: "onChange",
	});

	return (
		<>
			{
				questionIllnessSymtomsType !== null ?
					<QuestionMCMA
						survey={survey}
						questionTemplate={questionIllnessSymtomsType?.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
						labelInline={true}
						description={questionIllnessSymtomsType?.questionTemplate.description}
						withComment={true}
					/>
					: null
			}

			{
				questionIllnessSymtomsExplanation !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionIllnessSymtomsExplanation?.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}

			{
				questionUseDrugsMedication !== null ?
					<QuestionMCOA
						survey={survey}
						questionTemplate={questionUseDrugsMedication.questionTemplate}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}

			{
				questionHasStudentHospitalised !== null ?
					<QuestionMCOA
						survey={survey}
						questionTemplate={questionHasStudentHospitalised.questionTemplate}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}

			{
				questionHasStudentConsultedSpecialist !== null ?
					<QuestionMCOA
						survey={survey}
						questionTemplate={questionHasStudentConsultedSpecialist.questionTemplate}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>

					: null
			}
			{
				questionCandidateImpairment !== null ?
					<QuestionMCMA
						survey={survey}
						questionTemplate={questionCandidateImpairment.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
						labelInline={true}
						description={questionCandidateImpairment.questionTemplate.description}
						withComment={true}
					/>
					: null
			}
			{
				questionCandidateImpairmentExplaination !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionCandidateImpairmentExplaination.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionHasCandidateConsulted !== null ?
					<QuestionMCMA
						survey={survey}
						questionTemplate={questionHasCandidateConsulted?.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
						labelInline={true}
						description={questionHasCandidateConsulted?.questionTemplate.description}
						withComment={true}
					/>
					: null
			}

			{
				NoteDescribeeachDisease !== null ?
					<QuestionNote
						survey={survey}
						questionTemplate={NoteDescribeeachDisease.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}

			<li className="list-group-item py-3 row d-flex g-0 align-items-center">
				<label htmlFor="" className="form-label required">
					{t("height")}
				</label>
				{
					questionStudentHeightM !== null ?
						renderInputText(
							`${questionStudentHeightM.questionTemplate.label}`,
							`SURVEY-${survey.id}.QUESTION-${questionStudentHeightM?.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentHeightM.questionTemplate.answerTemplates[0].id}`,
							`SURVEY-${survey.id}.ANSWER-${questionStudentHeightM.questionTemplate.answerTemplates[0].id}`,
							getValueText(questionStudentHeightM.questionTemplate.answerTemplates[0], survey),
							"",
							register,
							false,
							"col-md-2 col-sm-2 mb-3",
							false,
							false
						)
						: null
				}
				<span className="ml-2 mr-2 withauto col-sm-2">m x 39.37 = </span>
				{
					questionStudentHeightCM !== null ?
						renderInputText(
							"",
							`SURVEY-${survey.id}.QUESTION-${questionStudentHeightCM?.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentHeightCM?.questionTemplate.answerTemplates[0].id}`,
							`SURVEY-${survey.id}.ANSWER-${questionStudentHeightCM?.questionTemplate.answerTemplates[0].id}`,
							getValueText(questionStudentHeightCM.questionTemplate.answerTemplates[0], survey),
							errors.questionStudentHeightCM,
							register,
							true,
							"col-md-2 col-sm-2 mb-3",
							false,
						)
						: null

				}
				<span className="ml-2 mr-2 withauto col-sm-2">inches ÷ 12 =</span>
				{
					questionStudentHeightInches !== null ?

						renderInputText(
							"",
							`SURVEY-${survey.id}.QUESTION-${questionStudentHeightInches?.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentHeightInches?.questionTemplate.answerTemplates[0].id}`,
							`SURVEY-${survey.id}.ANSWER-${questionStudentHeightInches?.questionTemplate.answerTemplates[0].id}`,
							getValueText(questionStudentHeightInches?.questionTemplate.answerTemplates[0], survey),
							errors.questionStudentHeightInches,
							register,
							true,
							"col-md-2 col-sm-2 mb-3",
							false,
						)
						: null
				}
				<span className="ml-2 mr-2 withauto col-sm-1">feet</span>
				{
					questionStudentHeightFeet !== null ?
						renderInputText(
							"",
							`SURVEY-${survey.id}.QUESTION-${questionStudentHeightFeet?.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentHeightFeet?.questionTemplate.answerTemplates[0].id}`,
							`SURVEY-${survey.id}.ANSWER-${questionStudentHeightFeet?.questionTemplate.answerTemplates[0].id}`,
							getValueText(questionStudentHeightFeet?.questionTemplate.answerTemplates[0], survey),
							errors.questionStudentHeightFeet,
							register,
							true,
							"col-md-2 col-sm-2 mb-3",
							false,
						)
						: null}

				<span className="ml-2 mr-2 withauto col-sm-1">inches</span>
			</li>
			<li className="list-group-item py-3 row d-flex g-0 align-items-center">
				<label htmlFor="" className="form-label required">
					{t("weight")}
				</label>
				{
					questionStudentWeightKG !== null ?
						renderInputText(
							"",
							`SURVEY-${survey.id}.QUESTION-${questionStudentWeightKG.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentWeightKG.questionTemplate.answerTemplates[0].id}`,
							`SURVEY-${survey.id}.ANSWER-${questionStudentWeightKG.questionTemplate.answerTemplates[0].id}`,
							getValueText(questionStudentWeightKG.questionTemplate.answerTemplates[0], survey),
							errors.questionStudentWeightKG,
							register,
							true,
							"col-md-2 col-sm-2 mb-3",
							false,
						)
						: null
				}
				<span className="ml-2 mr-2 withauto col-sm-2"> kg x 2.2 = </span>
				{
					questionStudentWeightLBS14 !== null ?
						renderInputText(
							"",
							`SURVEY-${survey.id}.QUESTION-${questionStudentWeightLBS14.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentWeightLBS14.questionTemplate.answerTemplates[0].id}`,
							`SURVEY-${survey.id}.ANSWER-${questionStudentWeightLBS14.questionTemplate.answerTemplates[0].id}`,
							getValueText(questionStudentWeightLBS14.questionTemplate.answerTemplates[0], survey),
							errors.questionStudentWeightLBS14,
							register,
							true,
							"col-md-2 col-sm-2 mb-3",
							false,
						)
						: null
				}
				<span className="ml-2 mr-2 withauto col-sm-2"> lbs ÷ 14</span>
				{
					questionStudentWeightSum !== null ?
						renderInputText(
							"",
							`SURVEY-${survey.id}.QUESTION-${questionStudentWeightSum.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentWeightSum.questionTemplate.answerTemplates[0].id}`,
							`SURVEY-${survey.id}.ANSWER-${questionStudentWeightSum.questionTemplate.answerTemplates[0].id}`,
							getValueText(questionStudentWeightSum.questionTemplate.answerTemplates[0], survey),
							errors.questionStudentWeightSum,
							register,
							true,
							"col-md-2 col-sm-2 mb-3",
							false,
						) : null
				}
				<span className="ml-2 mr-2 withauto col-sm-1">st</span>
				{questionStudentWeightST !== null ?
					renderInputText(
						"",
						`SURVEY-${survey.id}.QUESTION-${questionStudentWeightST.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentWeightST.questionTemplate.answerTemplates[0].id}`,
						`SURVEY-${survey.id}.ANSWER-${questionStudentWeightST.questionTemplate.answerTemplates[0].id}`,
						getValueText(questionStudentWeightST.questionTemplate.answerTemplates[0], survey),
						errors.questionStudentWeightST,
						register,
						true,
						"col-md-2 col-sm-2 mb-3",
						false,
					) : null}
				<span className="ml-2 mr-2 withauto col-sm-1">lbs</span>
			</li>
			<li className="list-group-item py-3 row d-flex g-0 align-items-center">
				<label htmlFor="" className={questionStudentBloodGroup?.questionTemplate.mandatory ? "form-label required col-sm-3" : "form-label col-sm-3"}>
					{t("blood_group")}
				</label>
				{questionStudentBloodGroup !== null ?
					renderInputText(
						"",
						`SURVEY-${survey.id}.QUESTION-${questionStudentBloodGroup.questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionStudentBloodGroup.questionTemplate.answerTemplates[0].id}`,
						`SURVEY-${survey.id}.ANSWER-${questionStudentBloodGroup.questionTemplate.answerTemplates[0].id}`,
						getValueText(questionStudentBloodGroup.questionTemplate.answerTemplates[0], survey),
						errors.questionStudentBloodGroup,
						register,
						true,
						"col-md-2 col-sm-4",
						false,
					) : null}
				<span className="ml-2 mr-2 withauto col-sm-4">Rh factor</span>
			</li>
			{
				questionStudentParticipationSportActivities !== null ?
					<QuestionMCOA
						survey={survey}
						questionTemplate={questionStudentParticipationSportActivities.questionTemplate}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}

			{
				questionCandidateHealth !== null ?
					<QuestionMCOA
						survey={survey}
						questionTemplate={questionCandidateHealth.questionTemplate}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
		</>
	);
};

export default SurveyFormRendererMedicalInfos;
