import React from "react";
import t from "../../components/I18N/TranslateHelpers";

interface ComponentProps {
	label: string,
	name: string,
	defaultValue: string,
	errors: any,
	register: any,
	required: boolean,
	classes: string,
	isDisabled?: boolean,
	readOnly?: boolean,
	rows?: number,
	isLabelShow?: boolean,
	countCharacterMax: number,
	countCharacterMin: number,
}

const RenderTextAreaWithLimitCharacters: React.FC<ComponentProps> = ({
	label,
	name,
	defaultValue,
	errors,
	register,
	required,
	classes,
	isDisabled,
	readOnly,
	rows,
	isLabelShow,
	countCharacterMax,
	countCharacterMin,
}) => {

	const [counter, setCharCounter] = React.useState(0);

	React.useEffect(() => {
		let defaultValueCountCharacter: number = defaultValue?.length;
		setCharCounter(defaultValueCountCharacter);
	}, []);

	const inputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setCharCounter(e.target.value.length);
	};

	return (
		<div className={classes}>
			{
				isLabelShow ?
					label !== "" ? <label
						htmlFor={name}
						className={required ? "form-label required" : "form-label "}
					>
						{label}
					</label> : null
					: null
			}
			
					<textarea
						className={errors ? "form-control is-invalid" : "form-control"}
						type="text"
						id={name}
						defaultValue={defaultValue}
						disabled={isDisabled}
						readOnly={readOnly}
						rows={rows}
						maxLength={countCharacterMax}
						{...register ? { ...register(name), onChange: (e) => { inputHandler(e) } } : undefined}
					/>
					<p>{t("letter_length_counter", { minLength: countCharacterMin !== null ? countCharacterMin : "0", maxLength: countCharacterMax !== null ? countCharacterMax : "∞", curLength: counter })}</p>
		</div>
	);
};

export default RenderTextAreaWithLimitCharacters
