import React from "react";
import {
	FieldValues,
	UseFormRegister
} from "react-hook-form";
import { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../components/Utils";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { getLetter } from "features/Slice/LetterSlice";
import { LetterTypeEnum } from "features/Entity/Letter";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import RenderTextAreaWithLimitCharacters from "components/Forms/RenderTextAreaWithLimitCharacters";

interface ComponentProps {
	applicationFormId: string;
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	letterType: LetterTypeEnum;
	isReadOnly: boolean;
}

const LetterForm: React.FC<ComponentProps> = ({
	applicationFormId,
	register,
	errors,
	letterType,
	isReadOnly,
}) => {
	const isRequestingLetters = useSelector((state: RootState) => state.letterRdc.isRequesting);
	const letters = useSelector((state: RootState) => state.letterRdc.letters);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getLetter(applicationFormId, letterType));
	}, []);

	return (
		<React.Fragment>
			{
				isRequestingLetters ?
					<SpinnerOnTop/>
				:
				letters !== null ?
					letters.map((letter, index) => (
						<React.Fragment key={index}>
							<input
								type="hidden"
								id={`letters[${index}].id`}
								value={letter.id}
								{...register(`letters[${index}].id`)}
							/>
							<input
								type="hidden"
								id={`letters[${index}].applicationFormId`}
								value={letter.applicationFormId}
								{...register(`letters[${index}].applicationFormId`)}
							/>
							<input
								type="hidden"
								id={`letters[${index}].letterTemplate.id`}
								value={letter.letterTemplate?.id}
								{...register(`letters[${index}].letterTemplate.id`)}
							/>
							<input
								type="hidden"
								id={`letters[${index}].letterTemplate.letterType`}
								value={letter.letterTemplate?.letterType}
								{...register(`letters[${index}].letterTemplate.letterType`)}
							/>
							<input
								type="hidden"
								id={`letters[${index}].letterTemplate.letterLanguage`}
								value={letter.letterTemplate?.letterLanguage}
								{...register(`letters[${index}].letterTemplate.letterLanguage`)}
							/>

							<RenderTextAreaWithLimitCharacters
								label={letter.letterTemplate.description ?? ""} 
								name={`letters[${index}].letterText`}
								defaultValue={letter.letterText ?? ""}
								errors={errors.letterText}
								register={register}
								required={false}
								classes={"col-md-12 col-sm-12 mb-3"}
								isDisabled={false}
								readOnly={isReadOnly}
								rows={25}
								isLabelShow={true}
								countCharacterMax={letter.maxLenght}
								countCharacterMin={letter.minLenght}
							/>
						</React.Fragment>
					))
				: ""
			}
		</React.Fragment>
	);
};

export default LetterForm;
