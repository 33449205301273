import React from 'react';
import { createRoot } from 'react-dom/client';
import { store } from './app/store';
import { Provider } from 'react-redux';

const container = document.getElementById('root')!;
const containerHeader = document.getElementById('header')!;
const containerSidebar = document.getElementById('sidebar')!;
const containerFooter = document.getElementById('footer')!;
const containerLogin = document.getElementById('rootLogin')!;

const root = createRoot(container);
const header = createRoot(containerHeader);
const sidebar = createRoot(containerSidebar);
const footer = createRoot(containerFooter);
const login = createRoot(containerLogin);

function renderRoot() {
  const App = require('./app/App').default;
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    );
}


function renderHeader() {
  const AppHeader = require('./app/AppHeader').default;
  header.render(
      <React.StrictMode>
        <Provider store={store}>
          <AppHeader/>
        </Provider>
      </React.StrictMode>,
    );
}

function renderSidebar() {
  const AppSidebar = require('./app/AppSidebar').default;
  sidebar.render(
      <React.StrictMode>
        <Provider store={store}>
          <AppSidebar/>
        </Provider>
      </React.StrictMode>,
    );
}

function renderFooter() {
  const AppFooter = require('./app/AppFooter').default;
  footer.render(
      <React.StrictMode>
        <Provider store={store}>
          <AppFooter/>
        </Provider>
      </React.StrictMode>,
    );
}

function renderLogin() {
  const AppLogin = require('./app/AppLogin').default;
  login.render(
      <React.StrictMode>
        <Provider store={store}>
          <AppLogin/>
        </Provider>
      </React.StrictMode>,
    );
}

renderRoot();
renderHeader();
renderSidebar();
renderFooter();
renderLogin();