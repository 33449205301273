import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/RootReducer";
import { store } from "../../app/store";
import t from "../../components/I18N/TranslateHelpers";
import { SpinnerOnTop } from "../../components/SpinnerOnTop";
import { getChangePasswordToken } from "../Slice/UserSlice";

const ForgottenPasswordForm: React.FC = () => {
    const [email, setEmail] = useState<string | null>(null);

    const isLoading = useSelector((state: RootState) => state.userRdc.isRequesting);
    const requestingSuccess = useSelector((state: RootState) => state.userRdc.isRequestSuccess);

    function sendForgottenPassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!isLoading && email) {
            //TODO change API endpoint when available
            store.dispatch(getChangePasswordToken(email));
        }
    }

    return (
        <React.Fragment>
            {
                requestingSuccess ?
                    <div className="login-form needs-validation">
                        <h6 className="mb-0">{t('forgotten_password_submitted', { email: email })}</h6>
                    </div>
                    :
                    <form className="login-form needs-validation" onSubmit={(event) => sendForgottenPassword(event)}>
                        <h4>{t('forgot_password')}</h4>
                        <h6>{t('forgotten_password_email')}</h6>
                        <div className="form-group">
                            <label>{t("email_address")}</label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <i className="icon-email"></i>
                                </span>
                                <input
                                    className="form-control"
                                    type="email"
                                    placeholder="youremail@address.com"
                                    onChange={(newVal) => setEmail(newVal.target.value)}
                                    disabled={isLoading}
                                />
                                <div className="invalid-tooltip">
                                    {t("please_proper_mail")}
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                            {isLoading ? <SpinnerOnTop /> : ""}
                            <button className="btn btn-primary" type="submit" disabled={isLoading}>
                                {t('send')}
                            </button>
                            <a className="link" href={t("url_login")} style={{ pointerEvents: isLoading ? "none" : "all" }}>
                                {t('i_have_an_account')}
                            </a>
                        </div>
                    </form>
            }
        </React.Fragment>
    );
};

export default ForgottenPasswordForm;
