const userTokenKey = "userToken";

export function setUserTokenInLocalStorage(): void
{
  localStorage.setItem(userTokenKey, (Math.random() *100 + 1).toString(36).substring(2));
}

export function removeUserTokenFromLocalStorage(): void
{
  if(localStorage.getItem(userTokenKey) !== null)
  {
    localStorage.removeItem(userTokenKey);
  }
}

export const getUserToken = (): string|null =>
{
  return localStorage.getItem(userTokenKey);
}