import React, { useState } from "react";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, isResponsive } from "../../components/Utils";
import PageTitle from "./PageTitle";
import { RootState, store } from "app/store";
import { useSelector } from "react-redux";
import { getApplicationForms, getCurrentAppForm } from "features/Slice/ApplicationFormSlice";
import { ApplicationForm, ProgramTypeEnum } from "features/Entity/ApplicationForm";
import { showSelectAppForm } from "components/UtilsForApplicationForm";
import { getDocument, getDocumentsList } from "features/Slice/DocumentSlice";
import { SpinnerOnTop } from "components/SpinnerOnTop";

const Documents: React.FC = () => {
	const [typeSelected, setTypeSelected] = useState(t("all"));
	const [documentTypes, setDocumentTypes] = useState<string[]>([]);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [applicationForm, setApplicationForm] = useState<ApplicationForm | null>(null);

	const isRequestingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const applicationForms = useSelector((state: RootState) => state.applicationFormRdc.applicationForms);
	const isRequestingDocumentsList = useSelector((state: RootState) => state.documentRdc.isRequestingList);
	const documentsList = useSelector((state: RootState) => state.documentRdc.documentsList);
	const currentAppForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
	
	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	
	React.useEffect(() => {
		if(!currentAppForm){
			store.dispatch(getCurrentAppForm())		
		}
	}, [currentAppForm]);

	React.useEffect(() => {
		if(userInfo){
			if(!userInfo.isSendingPartner && !userInfo.isStaff){
				if (!isRequestingApplicationForm && !applicationForms) {
					store.dispatch(getApplicationForms());
				}
			}
		}
	}, [userInfo]);

	React.useEffect(() => {
		if (currentAppForm) {
			setApplicationForm(currentAppForm)
		}
	}, [currentAppForm]);

	React.useEffect(() => {
		if (applicationForm) {
			store.dispatch(getDocumentsList(applicationForm.uid));
		}
	}, [applicationForm]);

	React.useEffect(() => {
		if (documentsList && applicationForm) {
			setDocumentTypes([t("all"), ...(Array.from(new Set(documentsList.map(doc => doc.documentType))))]);
		}
	}, [documentsList]);

	const allDocuments = documentsList ? typeSelected === t("all") ? documentsList : documentsList.filter(doc => doc.documentType === typeSelected) : [];

	const showMobileMenuFilter = () => {
		return (
			<>
				<div className="menu-filters" onClick={() => setIsMenuOpen(!isMenuOpen)}>Tags</div>
				{
					isMenuOpen ?
						<div className="overlay-menu card">
							<div className="d-flex justify-content-end pb-2" onClick={() => setIsMenuOpen(!isMenuOpen)}>
								<i className="icofont icofont-ui-close"></i>
							</div>
							<ul className="filters-list">
								{ showSelectAppForm(userInfo, applicationForm, applicationForms, setApplicationForm) }
								{Tags()}
							</ul>
						</div>
						: null
				}
			</>
		);
	};

	const Tags = () =>
		documentTypes.map(documentType => {
			return (
				isResponsive ?
					<li onClick={() => [setTypeSelected(documentType), setIsMenuOpen(!isMenuOpen)]} className={typeSelected === documentType ? "active" : ""}>
						{documentType}
					</li>
					:
					<button
						className={
							typeSelected === documentType
								? "btn btn-primary filter mr-2"
								: "btn btn-primary-outline filter mr-2"
						}
						data-filter={documentType}
						onClick={() => setTypeSelected(documentType)}
						key={documentType}
					>
						{documentType}
					</button>
			);
		});


	return (
		userInfo?.isSendingPartner || userInfo?.isStaff || ( applicationForm?.programType !== ProgramTypeEnum.Inbound)?
		<div className="row starter-main">
			<PageTitle title={t("documents")} />
			<div className="col-md-12 documents-container mb-5">
				<div className="card mb-0">
					{
						isResponsive ?
							showMobileMenuFilter()
							:
							<React.Fragment>
								<div className="card-header pb-0">
									{ showSelectAppForm(userInfo, applicationForm, applicationForms, setApplicationForm) }
								</div>
								<div className="card-header pt-0 align-items-center justify-content-center d-flex filters">
									{Tags()}
								</div>
							</React.Fragment>
					}

					<div className="card-body p-0 documents">
						{
							isRequestingDocumentsList || !documentsList || !applicationForm ?
								<SpinnerOnTop />
								:
								allDocuments.map(documentItem => {
									return (
										<div
											className="row item-document m-0 px-4"
											//data-filter={documentItem.filters}
											key={documentItem.documentUID}
										>
											<div className="col-md-2">
												<h6 className="mb-0">{documentItem.title}</h6>
											</div>
										<div className="col-md-8">{documentItem.description}</div>
										{
											documentItem.filename && documentItem.filename.length > 0 ?
												<div className={isResponsive ? "col-md-12 mt-3" : "col-md-2 justify-content-center d-flex align-items-center"}>
													<button type="button" onClick={() => store.dispatch(getDocument(documentItem, applicationForm.cultureCode, documentItem.filename))} className={isResponsive ? "btn btn-primary-outline mr-2" : ""}>
														<i className="icofont icofont-download-alt mr-2"></i>{" "}
														{t("download")}
													</button>
												</div>
											: ""
										}
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</div>
		: 
		<div className="alert alert-warning">
			{ t('no_access_to_page')}
		</div>
	);
};

export default Documents;
