import { copy } from "@testing-library/user-event/dist/types/clipboard";
import { RootState } from "app/RootReducer";
import { renderInputText } from "components/Forms/FormsComponent";
import { GetCurrentLocale, getSurveyWidgetDisplayMode, SelectInterface } from "components/Utils";
import { QuestionUIWidgetTypeEnum } from "features/Entity/Survey/QuestionUIWidgetTypeEnum";
import { Answer, AnswerTemplate, QuestionTemplate, Survey } from "features/Entity/Survey/Survey";
import { SurveyActionConditionTypeEnum } from "features/Entity/Survey/SurveyActionConditionTypeEnum";
import { SurveyActionTypeEnum } from "features/Entity/Survey/SurveyActionTypeEnum";
import { SurveyWidgetDisplayMode } from "features/Entity/Survey/SurveyWidgetDisplayMode";
import React, { useState } from "react";
import { Control, FieldValues, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch, UseFormGetValues } from "react-hook-form";
import { useSelector } from "react-redux";
import { SurveyActionService } from "services/SurveyActionService";
import { renderRadio, renderSelect, renderTextArea } from "./SurveyQuestionComponents";

interface ComponentProps {
    survey: Survey;
    questionTemplate: QuestionTemplate;
    register: UseFormRegister<FieldValues>;
    unregister: UseFormUnregister<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    getValue: UseFormGetValues<FieldValues>;
    isSurveyFormReadOnly: boolean;
}

const QuestionMCOA: React.FC<ComponentProps> = ({
    survey,
    questionTemplate,
    register,
    unregister,
    watch,
    setValue,
    getValue,
    isSurveyFormReadOnly,
}) => {

    const locale = GetCurrentLocale();
    const [selectedValue, setSelectedValue] = useState<SelectInterface | null>(null);
    const [mcoaValue, setMcoaValue] = useState<string | null>(getSelectedValue(questionTemplate.id));
    const surveyActionService = new SurveyActionService(survey);
    const surveyActions = useSelector(
        (state: RootState) => state.surveyRdc.surveyActions,
    );

    var isChecked: boolean | undefined = undefined;
    let questionWatched = watch("SURVEY-" + survey.id + ".QUESTION-" + questionTemplate.id, getSelectedValue(questionTemplate.id));
    // var questionWatched = "";
    const selectValues: SelectInterface[] = [];
    if (questionTemplate.uiWidgetType == QuestionUIWidgetTypeEnum.Select) {
        questionTemplate.answerTemplates.map((answerTemplate: AnswerTemplate) => {
            const selectVal: SelectInterface = { label: answerTemplate.label, value: `SURVEY-${survey.id}.ANSWER-${answerTemplate.id}` };
            selectValues.push(selectVal);
        });
    }

    React.useEffect(() => {
        surveyActionService.checkIfConditionVerifiedAndTriggerAction(mcoaValue ?? "", questionTemplate);
        if (questionWatched != null) {
            setMcoaValue(questionWatched);
        }

    }, [mcoaValue, questionTemplate, questionWatched, surveyActionService]);

    function setData(questionScrutted: string) {
        surveyActionService.checkIfConditionVerifiedAndTriggerAction(questionScrutted, questionTemplate);
        if (questionScrutted != null) {
            setMcoaValue(questionScrutted);
        }
    }

    function getAnswer(answerTemplate: AnswerTemplate): Answer | null {
        var returnAnswer = null;
        if (!survey) {
            return returnAnswer;
        }
        survey.answers.forEach((answer) => {
            if (answer.answerTemplateId == answerTemplate.id) {
                returnAnswer = answer;
                return;
            }
        });
        return returnAnswer;
    }
    function getSelectedValue(questionTemplateId: number): string {
        var returnAnswer = "";
        if (!survey) {
            return returnAnswer;
        }
        survey.answers.forEach((answer) => {
            if (answer.questionTemplateId == questionTemplateId) {
                returnAnswer = "SURVEY-" + survey.id + "." + "ANSWER-" + answer.answerTemplateId;
                return;
            }
        });
        return returnAnswer;
    }
    function checkIfRadioSelected(answerTemplate: AnswerTemplate): boolean {
        var isSelected = false;
        if (!survey) {
            return false;
        }
        survey.answers.forEach((answer) => {
            if (answer.answerTemplateId === answerTemplate.id) {
                isSelected = true;
                return;
            }
        });
        return isSelected;
    }
    if (questionTemplate.uiWidgetType === QuestionUIWidgetTypeEnum.Standard || questionTemplate.uiWidgetType === QuestionUIWidgetTypeEnum.RadioButton) {
        return (
            <li className="list-group-item py-3 row d-flex g-0 align-items-center" key={questionTemplate.code}>
                <div className="col-md-6"><label htmlFor="" className={`form-label mb-0 ${questionTemplate.mandatory ? "required" : ""}`}>
                    {questionTemplate.label}
                </label>
                    {questionTemplate.description !== "" && questionTemplate.description !== "NO DESCRIPTION" ? <small className="block">{questionTemplate.description}</small> : null}
                </div>
                <div className="col col-radio-buttons">
                    <div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
                        {
                            questionTemplate.answerTemplates.map((answerTemplate: AnswerTemplate) => {
                                isChecked = (`SURVEY-${survey.id}.ANSWER-${answerTemplate.id}` === mcoaValue);
                                return (renderRadio(
                                    `${answerTemplate.label}`,
                                    `SURVEY-${survey.id}.QUESTION-${questionTemplate.id}`,
                                    `SURVEY-${survey.id}.ANSWER-${answerTemplate.id}`,
                                    false,
                                    register,
                                    setValue,
                                    false,
                                    setData,
                                    null,
                                    [],
                                    isChecked,
                                    getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly && !isChecked ? true : false,
                                ))
                            }
                            )
                        }
                    </div>
                </div>
                {
                    questionTemplate.answerTemplates.filter(at => at.withComment).map((answerTemplate: AnswerTemplate) => {
                        if (`SURVEY-${survey.id}.ANSWER-${answerTemplate.id}` === mcoaValue) {
                            const answer = getAnswer(answerTemplate);
                            return (<div key={"SURVEY-" + survey.id + ".ANSWER-" + answerTemplate.id} className="col-md-4">
                                {
                                    renderTextArea(
                                        answerTemplate.commentLabel,
                                        `SURVEY-${survey.id}.ANSWER-COMMENT-${answerTemplate.id}`,
                                        `SURVEY-${survey.id}.ANSWER-COMMENT-${answerTemplate.id}`,
                                        answer?.commentValueText,
                                        "",
                                        register,
                                        answerTemplate.commentMandatory ?? false,
                                        "col-sm-6 col-md-3 d-flex width100",
                                        false,
                                        getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false,
                                        1,
                                        true
                                    )
                                }
                            </div>)
                        }
                        else {
                            return (<div key={"SURVEY-" + survey.id + ".ANSWER-" + answerTemplate.id} className="col-md-4">
                                <div className="col-sm-6 col-md-3 d-flex width100"></div>
                            </div>)
                        }
                    })
                }
            </li>
        );
    }
    else if (questionTemplate.uiWidgetType === QuestionUIWidgetTypeEnum.Select) {
        return (
            <li className="list-group-item py-3 row d-flex g-0 align-items-center" key={questionTemplate.code}>
                <div className="col-md-12">
                    <div className="form-group m-checkbox-inline mb-0 custom-radio-ml row">
                        {
                            renderSelect(
                                locale,
                                questionTemplate.label,
                                `SURVEY-${survey.id}.QUESTION-${questionTemplate.id}`,
                                `SURVEY-${survey.id}.QUESTION-${questionTemplate.id}`,
                                questionTemplate.mandatory ?? false,
                                register,
                                selectValues,
                                getSelectedValue(questionTemplate.id),
                                "inline-select",
                                getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false
                            )
                        }
                    </div>
                </div>
            </li>
        );
    }
    else {
        return (
            <></>
        )
    }
};

export default QuestionMCOA;
