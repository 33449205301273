import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Physician } from "features/Entity/Physician";
import { getPhysicianRep } from "features/Repository/PhysicianRepository";
import { AppThunk } from "../../app/store";

interface PhysicianState {
  isRequesting: boolean;
  isRequestSuccess: boolean;
  isRequestFailed: boolean;
  error: AxiosError | null;
  physician: Physician|null;
}

const initialState: PhysicianState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  physician: null,
}

function startRequestRdc(state: PhysicianState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: PhysicianState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: PhysicianState, action: PayloadAction<Physician>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.physician = action.payload;
}

const PhysicianSlice = createSlice({
    name: 'Physicians',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct
} = PhysicianSlice.actions;

export default PhysicianSlice.reducer;

export const getPhysician = (applicationFormUid: string): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getPhysicianRep(applicationFormUid);
        dispatch(requestSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}
