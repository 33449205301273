import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentFile, DocumentFolder } from "features/Entity/VirtualFolder";
import { deleteVirtualFolderFileRep, getVirtualFolderFileRep, getVirtualFolderRep, postVirtualFolderRep } from "features/Repository/VirtualFolderRepository";
import { AppThunk } from "../../app/store";
import { saveAs } from "file-saver";
import { AxiosError } from "axios";
import { storeToast, toastAxiosError } from "components/Utils";
import { ToastType } from "components/Toasts/ToastType";

interface VirtualFolderState {
  isRequestingGet: boolean;
  isRequestingPost: boolean;
  isRequestGetSuccess: boolean;
  isRequestPostSuccess: boolean;
  isRequestFailed: boolean;
  error: AxiosError | null;
  virtualFolders: DocumentFolder[]|null;
}

const initialState: VirtualFolderState = {
  isRequestingGet: false,
  isRequestingPost: false,
  isRequestGetSuccess: false,
  isRequestPostSuccess: false,
  isRequestFailed: false,
  error: null,
  virtualFolders: null
}

function startGetRequestRdc(state: VirtualFolderState) {
  state.isRequestingGet = true;
  state.isRequestGetSuccess = false;
  state.error = null;
}

function requestGetSuccessRdc(state: VirtualFolderState, action: PayloadAction<DocumentFolder[]>) {
  state.isRequestingGet = false;
  state.isRequestGetSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.virtualFolders = action.payload;
}

function requestGetFailedRdc(state: VirtualFolderState, action: PayloadAction<AxiosError>) {
  state.isRequestingGet = false;
  state.isRequestGetSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function startPostRequestRdc(state: VirtualFolderState) {
  state.isRequestingPost = true;
  state.isRequestPostSuccess = false;
  state.error = null;
}

function requestPostSuccessRdc(state: VirtualFolderState) {
  state.isRequestingPost = false;
  state.isRequestPostSuccess = true;
  state.isRequestFailed = false;
  state.error = null;

  storeToast(ToastType.SUCCESS, "virtual_folder_updated");
  window.location.reload();
}

function requestPostFailedRdc(state: VirtualFolderState, action: PayloadAction<AxiosError>) {
  state.isRequestingPost = false;
  state.isRequestPostSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
  toastAxiosError(action.payload);
}

function removeFileRdc(state: VirtualFolderState, action: PayloadAction<DocumentFile>) {
  if(state.virtualFolders && state.virtualFolders.length > 0)
  {
    const currentFolder = state.virtualFolders.find(virt => virt. id === action.payload.documentFolderId);

    if(currentFolder)
    {
      let newFolders: DocumentFolder[] = [];

      for(const folder of state.virtualFolders)
      {
        if(folder.id === currentFolder.id)
        {
          let folderModified = {...folder};

          folderModified.documentFiles = folder.documentFiles.filter(docFile => docFile.id !== action.payload.id);

          newFolders.push(folderModified);
        }
        else
        {
          newFolders.push(folder);
        }
      }
      state.virtualFolders = newFolders;
    }
  }
}

const VirtualFolderSlice = createSlice({
  name: 'VirtualFolders',
  initialState: initialState,
  reducers: {
    startGetRequestAct: startGetRequestRdc,
    requestGetSuccessAct: requestGetSuccessRdc,
    requestGetFailedAct: requestGetFailedRdc,
    startPostRequestAct: startPostRequestRdc,
    requestPostSuccessAct: requestPostSuccessRdc,
    requestPostFailedAct: requestPostFailedRdc,
    removeFileAct: removeFileRdc,
  }
});

export const {
  startGetRequestAct,
  requestGetSuccessAct,
  requestGetFailedAct,
  startPostRequestAct,
  requestPostSuccessAct,
  requestPostFailedAct,
  removeFileAct
} = VirtualFolderSlice.actions;

export default VirtualFolderSlice.reducer;

export const getVirtualFolders = (applicationFormUid: string): AppThunk => async dispatch =>
{
  try
  {
    dispatch(startGetRequestAct());
    const apiResponse = await getVirtualFolderRep(applicationFormUid);
    dispatch(requestGetSuccessAct(apiResponse));
  } catch (err)
  {
    dispatch(requestGetFailedAct(err as AxiosError));
  }
}

export const postVirtualFolder = (folders: FormData): AppThunk => async dispatch =>
{
  try
  {
    dispatch(startPostRequestAct());
    await postVirtualFolderRep(folders);
    dispatch(requestPostSuccessAct());
  } catch (err)
  {
    dispatch(requestPostFailedAct(err as AxiosError));
  }
}

export const getVirtualFolderFile = (applicationFormUid: string, documentFileId: number, filename: string): AppThunk => async dispatch =>
{
  try
  {
    const apiResponse = await getVirtualFolderFileRep(applicationFormUid, documentFileId);

    saveAs(apiResponse, filename);
  } catch (err)
  {
    dispatch(requestGetFailedAct(err as AxiosError));
  }
}

export const deleteVirtualFolderFile = (applicationFormUid: string, documentFile: DocumentFile): AppThunk => async dispatch =>
{
  try
  {
    await deleteVirtualFolderFileRep(applicationFormUid, documentFile.id);
    dispatch(removeFileAct(documentFile));
  } catch (err)
  {
    dispatch(requestGetFailedAct(err as AxiosError));
  }
}
