import axios  from "components/AxiosUtils";
import { Nationality } from "features/Entity/Nationality";

export async function getNationalitiesRep(): Promise<Nationality[]>{
    try{
        const response = await axios.get<Nationality[]>(
            `/Nationality/GetList`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}
