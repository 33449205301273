export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const CLS_BASE_API_URL = process.env.REACT_APP_CLS_BASE_API_URL;
export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const CLS_URL = process.env.REACT_APP_CLS_FRONT_URL;
export const BO_URL = process.env.REACT_APP_BO_URL;
export const WEP_API_BASE_URL = process.env.REACT_APP_WEP_API_BASE_URL;
export const WEP_API_GET_PRODUCTS_YEAR_URL = process.env.REACT_APP_WEP_API_GET_PRODUCTS_YEAR_URL
export const WEP_API_AUTHORIZATION_TOKEN = process.env.REACT_APP_WEP_API_AUTHORIZATION_TOKEN
export const WEP_API_GET_PRODUCTS_URL = process.env.REACT_APP_WEP_API_GET_PRODUCTS_URL

export const paramHeaders = {
  'Access-Control-Allow-Origin' : '*',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Methods' : 'POST, PUT, GET, DELETE, OPTIONS',
  'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
};

export const paramHeadersPatch = {
  'Access-Control-Allow-Origin' : '*',
  'Content-Type': 'application/merge-patch+json',
  'Access-Control-Allow-Methods' : 'POST, PUT, GET, DELETE, PATCH, OPTIONS',
  'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
  'Accept-Patch' : 'application/merge-patch+json',
};