
import React from "react";
import t, {changeLanguage} from "../../components/I18N/TranslateHelpers";
import {GetCurrentLocale} from "../../components/Utils";
            
const Footer: React.FC = () => {
const locale = GetCurrentLocale();
changeLanguage(locale)
const currentYear = new Date().getFullYear();

return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 footer-copyright">
                    <p className="mb-0">{t('copyright', { year: currentYear })}</p>
                </div>
                <div className="col-md-6">
                    <p className="pull-right mb-0">
                        { t('developed_by')} <a href="https://expert-it.com/en" target="_blank" rel="noreferrer">Expert-IT</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
  )
}
            
export default Footer;
            