import { SpinnerOnTop } from "components/SpinnerOnTop";
import { FormatDateForDisplay, GetUrlParams, getHostnameType, isResponsive } from "components/Utils";
import { getActivateUser } from "features/Slice/UserSlice";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../app/RootReducer";
import { store } from "../../app/store";
import t from "../../components/I18N/TranslateHelpers";

const ActivateAccount: React.FC = () => {
	const [email] = useState<string>(GetUrlParams()["email"] ?? null);
	const [userId] = useState<string>(GetUrlParams()["token"] ?? null);

	const activateUserInfo = useSelector((state: RootState) => state.userRdc.activateUserInfo);
	const userActivation = useSelector((state: RootState) => state.userRdc.userActivation);
	const userError = useSelector((state: RootState) => state.userRdc.error);
    
    React.useEffect(() => {
        if(email && userId)
        {
            store.dispatch(getActivateUser(email, userId));
        }        
    }, []);

    React.useEffect(() => {
        if(activateUserInfo)
        {
            window.open(`${t('url_password_reset')}?email=${activateUserInfo.email}&token=${activateUserInfo.resetToken}&fromActivePage=true`, "_self");
        }
    }, [activateUserInfo]);

    React.useEffect(() => {
        if(activateUserInfo === null && userError)
        {
            toast.error(t('creating_account_error'));
        }
    }, [userError]);

	return (
        <>
            {
                !email || !userId ?
                    <div className="card login-form">
                        <p className="text-danger">{t('activate_needs_token_and_email')}</p>
                    </div>
                :
                userActivation?.accountActivatedOn ?
                    <>
                        <div className="container-fluid">
                            <div className="row overflow-hidden">
                                <div className={!isResponsive ? `col-xl-12 p-0 bg-image-home ${getHostnameType()}` : "col-xl-12 p-0"}>
                                    <div className="login-card">
                                        <div className="login-form">
                                            <h4 className="pb-3">{t('user_already_activated')}</h4>
                                            <div className="form-group">
                                                {`${t('user_activated_on')} ${FormatDateForDisplay(userActivation.accountActivatedOn, true)}`}
                                            </div>
                                            <div className="form-group pb-5">
                                                {t('can_login')} <a href={t('url_login')}>{t('here')}</a>
                                            </div>
                                             <div className="form-group">
                                                <a href={t('url_password_forgotten')}><small>{t('forgot_password')}</small></a>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                :
                    <div className="card login-form align-items-center text-center">
                        <SpinnerOnTop/>
                        <h6 className="pt-3">{t('creating_account')}</h6>
                    </div>
            }
        </>
	);
};

export default ActivateAccount;
