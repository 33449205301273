import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Teacher } from "features/Entity/Teacher";
import { getTeacherRep } from "features/Repository/TeacherRepository";
import { AppThunk } from "../../app/store";

interface TeacherState {
  isRequesting: boolean;
  isRequestSuccess: boolean;
  isRequestFailed: boolean;
  error: AxiosError | null;
  teacher: Teacher|null;
}

const initialState: TeacherState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  teacher: null,
}

function startRequestRdc(state: TeacherState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: TeacherState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: TeacherState, action: PayloadAction<Teacher>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.teacher = action.payload;
}

const TeacherSlice = createSlice({
    name: 'Teachers',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct
} = TeacherSlice.actions;

export default TeacherSlice.reducer;

export const getTeacher = (applicationFormUid: string, isLanguageTeacher: boolean): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getTeacherRep(applicationFormUid, isLanguageTeacher);
        dispatch(requestSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}
