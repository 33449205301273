export interface Letter {
  id: number,
  letterText: string|null,
  minLenght: number,
  maxLenght: number,
  letterTemplateId: number,
  applicationFormId: number,
  letterTemplate: {
    id: number,
    title: string|null,
    description: string|null,
    descriptionEng?: string|null,
    letterLanguage: LetterLanguageEnum,
    letterType: LetterTypeEnum
  }
}

export enum LetterTypeEnum
{
  Applicant = 0,
  Parent = 1
}

export enum LetterLanguageEnum
{
  Local = 0,
  English = 1
}