import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AccountActivationDateDTO, UserData } from "features/Entity/UserData";
import { AppThunk } from "../../app/store";
import { removeUserTokenFromLocalStorage, setUserTokenInLocalStorage } from "../../components/UserUtils";
import { pathname, redirectToLogin, toastAxiosError } from "../../components/Utils";
import { LoginFormData } from "../Entity/LoginFormData";
import { ResetPasswordFormData, ResetPasswordToken } from "../Entity/ResetPasswordFormData";
import { changePasswordRep, getActivateUserRep, getCurrentUserInfoRep, getAccountActivationDateRep, postPasswordChangeTokenRep, userLoginRep, userLogoutRep } from "../Repository/UserRepository";

export interface UserState {
  isRequesting: boolean;
  isRequestSuccess: boolean;
  isRequestFailed: boolean;
  isAuthenticated: boolean;
  error: AxiosError|null;
  resetPasswordToken: string|null;
  userInfo: UserData|null;
  activateUserInfo: ResetPasswordToken|null;
  userActivation: AccountActivationDateDTO|null
}

const initialState: UserState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  isAuthenticated: false,
  error: null,
  resetPasswordToken: null,
  userInfo: null,
  activateUserInfo: null,
  userActivation: null
}

function startRequestRdc(state: UserState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: UserState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
  state.isAuthenticated = false;
}

function requestLoginSuccessRdc(state: UserState) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.isAuthenticated = true;
  state.error = null;
}

function requestActivateUserSuccessRdc(state: UserState, action: PayloadAction<ResetPasswordToken>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.error = null;
  state.activateUserInfo = action.payload;
}

function requestUserAlreadyActivatedSuccessRdc(state: UserState, action: PayloadAction<AccountActivationDateDTO>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.error = null;
  state.userActivation = action.payload;
}

function requestSuccessRdc(state: UserState) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestPasswordTokenSuccessRdc(state: UserState, action: PayloadAction<string>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.resetPasswordToken = action.payload;
}

function requestUserDataSuccessRdc(state: UserState, action: PayloadAction<UserData>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.userInfo = action.payload;
}

function requestLogoutRdc(state: UserState) {
  state.isRequesting = initialState.isRequesting;
  state.isRequestSuccess = initialState.isRequestSuccess;
  state.isRequestFailed = initialState.isRequestFailed;
  state.error = initialState.error;
  state.resetPasswordToken = initialState.resetPasswordToken;
  state.isAuthenticated = false;
}

const UserSlice = createSlice({
    name: 'User',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestLoginSuccessAct: requestLoginSuccessRdc,
      requestPasswordTokenSuccessAct: requestPasswordTokenSuccessRdc,
      requestFailedAct: requestFailedRdc,
      requestLogoutAct: requestLogoutRdc,
      requestUserDataSuccessAct: requestUserDataSuccessRdc,
      requestActivateUserSuccessAct: requestActivateUserSuccessRdc,
      requestUserAlreadyActivatedSuccessAct: requestUserAlreadyActivatedSuccessRdc
    }
  }
);

export const {
  startRequestAct,
  requestSuccessAct,
  requestPasswordTokenSuccessAct,
  requestFailedAct,
  requestLogoutAct,
  requestLoginSuccessAct,
  requestUserDataSuccessAct,
  requestActivateUserSuccessAct,
  requestUserAlreadyActivatedSuccessAct
} = UserSlice.actions;

export default UserSlice.reducer;

export const userLogin = (formData: LoginFormData): AppThunk => async dispatch =>
{
    try
    {
      dispatch(startRequestAct());
      await userLoginRep(formData.email, formData.password);
      setUserTokenInLocalStorage();
      dispatch(requestLoginSuccessAct());
    } catch (err)
    {
      toastAxiosError(err as AxiosError);
      dispatch(requestFailedAct(err as AxiosError));
    }
}

export const userLogout = (addRedirectUrl: boolean): AppThunk => async dispatch =>
{
    try
    {
      removeUserTokenFromLocalStorage();
      await userLogoutRep();
      dispatch(requestLogoutAct());
      redirectToLogin(addRedirectUrl ? (pathname + window.location.search) : undefined);
    } catch (err)
    {
      dispatch(requestFailedAct(err as AxiosError));
    }
}

export const changePassword = (formData: ResetPasswordFormData): AppThunk => async dispatch =>
{
    try
    {
      dispatch(startRequestAct());
      await changePasswordRep(formData);
      dispatch(requestSuccessAct());
    } catch (err)
    {
      toastAxiosError(err as AxiosError);
      dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getChangePasswordToken = (email: string): AppThunk => async dispatch =>
{
    try
    {
      dispatch(startRequestAct());
      const apiResponse = await postPasswordChangeTokenRep(email);
      dispatch(requestPasswordTokenSuccessAct(apiResponse));
    } catch (err)
    {
      dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getGetCurrentUserInfo = (): AppThunk => async dispatch =>
{
    try
    {
      dispatch(startRequestAct());
      const apiResponse = await getCurrentUserInfoRep();
      dispatch(requestUserDataSuccessAct(apiResponse));
    } catch (err)
    {
      dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getActivateUser = (email: string, token: string): AppThunk => async dispatch =>
{
    try
    {
      dispatch(startRequestAct());

      const isActivatedDate = await getAccountActivationDateRep(email, token);

      if(isActivatedDate.accountActivatedOn !== null)
      {
        dispatch(requestUserAlreadyActivatedSuccessAct(isActivatedDate));
      }
      else
      {
        const apiResponse = await getActivateUserRep(email, token);
        dispatch(requestActivateUserSuccessAct(apiResponse));
      }
    } catch (err)
    {
      toastAxiosError(err as AxiosError);
      dispatch(requestFailedAct(err as AxiosError));
    }
}
