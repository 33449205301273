// getMediaImage
// getMediaVideo
// getMedia = image + video
// getMediaPDF = pdf
// getFiles = doc, pdf, excel
// getMediaAllExtension = image + video + pdf + files


const mediasImages = "image/png, image/gif, image/jpg, image/jpeg, image/svg, image/avif, image/bmp, image/heic, image/heic-sequence, image/ief, image/tiff, image/webp, image/x-canon-cr2, image/x-canon-crw, image/x-icon, image/x-panasonic-raw, .heic";
const mediasVideos = "video/mp4, video/mp3, video/mpeg, video/webm, video/mpeg, video/quicktime, video/webm, video/3gpp, video/3gpp2, video/h261, video/h263, video/h264";
const mediasPDF = "application/pdf"
const mediasFiles = "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv";


export const getMediasImages = () => {
    return mediasImages
}

export const getMediasVideos = () => {
    return mediasVideos
}

export const getMedias = () => {
    return mediasImages + ", " + mediasVideos
}

export const getMediasPDFs = () => {
    return mediasPDF
}

export const getMediasFiles = () => {
    return mediasPDF + ", " + mediasFiles
}

export const getMediasFilesAndImages = () => {
    return mediasPDF + ", " + mediasFiles + ", " + mediasImages
}
export const getMediasPDFAndImages = () => {
    return mediasPDF + ", " + mediasImages
}

export const getMediasAllExtensions = () => {
    return mediasImages + ", " + mediasVideos + ", " + mediasPDF + ", " + mediasFiles
}