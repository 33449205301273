import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { ApplicationForm, ProgramTypeEnum } from "features/Entity/ApplicationForm";
import { getWepOfficeInfo } from "features/Slice/CultureSlice";
import React from "react";
import { useSelector } from "react-redux";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../components/Utils";

interface ComponentProps
{
	applicationForm: ApplicationForm
}

const Contact: React.FC<ComponentProps> = ({applicationForm}) => {
	const isRequesting = useSelector((state: RootState) => state.cultureRdc.isRequesting);
	const wepOfficeInfo = useSelector((state: RootState) => state.cultureRdc.wepOfficeInfo);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
	
	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getWepOfficeInfo(applicationForm.cultureCode));
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if(applicationForm.programType === ProgramTypeEnum.Inbound && userInfo?.isStaff === false && userInfo?.isSendingPartner === false)
	{
		return null;
	}

	return (
		isRequesting || wepOfficeInfo ?
		<div className="dashboard-2-main col-xl-6 col-50 box-col-6 des-xl-50">
			<div className="col-xl-12">
				<div className="card custom-card">
					{
						isRequesting ?
							<SpinnerOnTop/>
						:
						wepOfficeInfo ?
							<React.Fragment>
								<div className="text-center profile-details">
									{
										wepOfficeInfo.webSiteUrl && wepOfficeInfo.webSiteUrl.length > 0 ?
											<a href={wepOfficeInfo.webSiteUrl} target="_blank" rel="noreferrer">
												<h4>{wepOfficeInfo.name}</h4>
												<p>{wepOfficeInfo.adresse}</p>
											</a>
										:
											<div>
												<h4>{wepOfficeInfo.name}</h4>
												<p>{wepOfficeInfo.adresse}</p>
											</div>
									}
								</div>
								<div className="card-footer row">
									<div className="col-md-4 col-sm-12 pb-0">
										<h6>{t('phone')}</h6>
										<span className="counter">{wepOfficeInfo.phone}</span>
									</div>
									<div className="col-md-4 col-sm-12 pb-0">
										<h6>{t('mail')}</h6>
										<span className="counter"><a href={`mailto:${wepOfficeInfo.email}`}>{wepOfficeInfo.email}</a></span>
									</div>
									{
										wepOfficeInfo.webSiteUrl && wepOfficeInfo.webSiteUrl.length > 0 ?
											<div className="col-md-4 col-sm-12 pb-0">
												<h6>{t('website')}</h6>
												<span className="counter"><a href={wepOfficeInfo.webSiteUrl}>{wepOfficeInfo.webSiteUrl}</a></span>
											</div>
										:null
									}
								</div>
							</React.Fragment>
						:null
					}
				</div>
			</div>
		</div>
		:null		
	);
};

export default Contact;
