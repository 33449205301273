import axios  from "components/AxiosUtils";
import { Survey } from "../Entity/Survey/Survey";

export async function getSurveyRep(applicationFormUid: string, surveyCode: string): Promise<Survey> {
    try
    {
        const response = await axios.get<Survey>(
            `/Survey/${applicationFormUid}/GetSurvey/${surveyCode}`,
        );

        return response.data;
    }
    catch (err: any) 
    {
        throw err;
    }
}