import axios  from "components/AxiosUtils";
import { Letter, LetterTypeEnum } from "features/Entity/Letter";

export async function getLettersRep(applicationFormUid: string, letterType: LetterTypeEnum): Promise<Letter[]> {
    try
    {
        const response = await axios.get<Letter[]>(
            `/Letter/${applicationFormUid}/GetLetter/${letterType}`,
        );

        try
        {
            return response.data.sort((a, b) => parseInt(a.letterTemplate?.letterLanguage.toString()) - parseInt(b.letterTemplate?.letterLanguage.toString()));
        }
        catch (err: any) 
        {
            return response.data;
        }
    }
    catch (err: any) 
    {
        throw err;
    }
}
