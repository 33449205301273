export interface Teacher
{
    id: number,
    applicationFormId: number,
    name: string|null,
    positionAtSchool: string|null,
    teacherEmail: string|null,
    phoneNumber: string|null,
    subjectsTaught: string|null,
    teacherType: TeacherTypeEnum,
    wepNewsInterest: boolean|null,
    isNewsletter: boolean|null,
    schoolId: number,
    teacherComments: TeacherComment[],
    languageTeacherRecommendations: LanguageTeacherRecommendation[],
    teacherRecommendations: TeacherRecommendation[],
    createTeacherUserAndNotify: boolean,
    teacherUserCreatedAndNotifiedOn: Date|null,
    schoolNameAddress: string
}

export interface TeacherRecommendation
{
    id: number,
    evaluationType: EvaluationTypeEnum,
    teacherId: number,
    teacherRecommendationCharacterId: number,
    applicationFormId: number,
    teacherRecommendationCharacter: TeacherRecommendationCharacter
}

export interface LanguageTeacherRecommendation
{
    id: number,
    evaluationType: EvaluationTypeEnum,
    teacherId: number,
    languageTeacherRecommendationProficiencyId: number,
    applicationFormId: number,
    languageTeacherRecommendationProficiency: TeacherRecommendationCharacter
}

export interface TeacherComment
{
    id: number,
    commentText: string|null,
    teacherCommentType: TeacherCommentTypeEnum,
    teacherId: number,
    applicationFormId: number
}

export interface TeacherRecommendationCharacter
{
    id: number,
    code: string|null,
    label: string|null
}

export enum TeacherTypeEnum
{
    LanguageTeacher = 0,
    Other = 1
}

export enum EvaluationTypeEnum
{
    Excellent = 0,
    Good = 1,
    Average = 2
}

export enum TeacherCommentTypeEnum {
    ApplicantMotivation = 0,
    LanguageApplicationMotivation = 1,
    LanguageApplicationMotivationInEnglish = 2,
    ApplicantMotivationInEnglish = 3
}