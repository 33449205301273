import axios  from "components/AxiosUtils";
import { DocumentFolder, StatusTypeEnum } from "features/Entity/VirtualFolder";

export async function getVirtualFolderRep(agreementUid: string): Promise<DocumentFolder[]>
{
    try
    {
        const response = await axios.get<DocumentFolder[]>(
            `/DocumentFolder/${agreementUid}/GetDocumentFolders`,
        );

        return response.data.filter(folder => folder.status === StatusTypeEnum.Active);
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function postVirtualFolderRep(folders: FormData): Promise<void>
{
    try
    {
        await axios.post<void>(
            `/DocumentFolder/SaveDocumentFolders`,
            folders,
            {headers: { "Content-Type": "multipart/form-data"}}
        );
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getVirtualFolderFileRep(applicationFormUid: string, documentFileId: number): Promise<Blob>
{
    try
    {
        const response = await axios.get<ArrayBuffer>(
            `/DocumentFolder/DownloadFile/${applicationFormUid}?documentFileId=${documentFileId}`,
            {headers: { "Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        return blob;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function deleteVirtualFolderFileRep(applicationFormUid: string, documentFileId: number): Promise<void>
{
    try
    {
        await axios.delete<void>(
            `/DocumentFolder/DeleteFile?appFormUid=${applicationFormUid}&documentFileId=${documentFileId}`,
        );
    }
    catch (err: any)
    {
        throw err;
    }
}
