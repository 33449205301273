import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { MediaAlbumElement } from "features/Entity/MediaAlbumElement";
import { getMediaAlbumFileRep, getMediaAlbumRep } from "features/Repository/MediaAlbumRepository";
import { saveAs } from "file-saver";
import { AppThunk } from "../../app/store";

interface MediaAlbumState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  mediaAlbum: MediaAlbumElement[]|null
}

const initialState: MediaAlbumState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  mediaAlbum: null
}

function startRequestRdc(state: MediaAlbumState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: MediaAlbumState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: MediaAlbumState, action: PayloadAction<MediaAlbumElement[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.mediaAlbum = action.payload;
}

const MediaAlbumSlice = createSlice({
    name: 'MediaAlbums',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct
} = MediaAlbumSlice.actions;

export default MediaAlbumSlice.reducer;

export const getMediaAlbum = (applicationFormUid: string): AppThunk => async dispatch =>
{
    try
    {
      dispatch(startRequestAct());
      const apiResponse = await getMediaAlbumRep(applicationFormUid);
      dispatch(requestSuccessAct(apiResponse));
    } catch (err)
    {
      dispatch(requestFailedAct(err as AxiosError));
    }
}

export const getMediaAlbumFile = (applicationFormUid: string, resourceId: number): AppThunk => async dispatch =>
{
    try
    {
      const apiResponse = await getMediaAlbumFileRep(applicationFormUid, resourceId);
      saveAs(apiResponse.blob, apiResponse.filename);
    } catch (err)
    {
      dispatch(requestFailedAct(err as AxiosError));
    }
}
