import axios  from "components/AxiosUtils";
import {Physician} from "features/Entity/Physician";

export async function getPhysicianRep(applicationFormUid: string): Promise<Physician>
{
    try
    {
        const response = await axios.get<Physician>(
            `/Physician/GetPhysician/${applicationFormUid}`,
        );

        return response.data;
    }
    catch (err: any) 
    {
        throw err;
    }
}
