import { ApplicationFormSectionStatusEnum } from "./SectionStaffInfo";

export interface ApplicationForm {
  orderNumber: string;
  typeOfProduct: string;
  destinationCouuntry: string;
  duration: string;
  yearOfDeparture: number;
  arrivalDate: Date|null;
  departurePeriod: string|null;
  uid: string;
  cultureCode: string;
  applicationFormTemplateGuid: string;
  nativeLanguage: string | null;
  label: string;
  applicationFormStatus: ApplicationFormSectionStatusEnum;
  submittedOn: Date | null;
  submittedByPartnerOn: Date | null;
  notifiedOfStaffValidationOn: Date | null;
  validationComment: string | null;
  avatarUrl: string | null;
  firstName: string;
  lastName: string;
  statusSynchronizedWithSupplierOn: Date|null;
  dataExportedToSupplierOn : Date|null;
  programType: ProgramTypeEnum;
  statusOfValidator: number;
  commentOfValidator: string | null;
  displayStatusOfValidatorDashboardSection: boolean;
  subLabel: string | null;
  cancelComment: string;
  hasUserPaxLgActive: boolean;
}

export interface ApplicationFormSectionStatus {
  uid: string,
  nbSectionApprouveds: number,
  nbSectionCompleteds: number,
  nbSectionIncompletes: number,
  nbAgreements: number,
  total: number
}

export interface ApplicationFormTemplate
{
  id: number,
  label: string,
  guid: string
}

export enum ProgramTypeEnum 
{
  Outbound = 0,
  Inbound = 1,
  Both = 2
}

export interface CreateAppFormResponseDTO
{
  applicationFormGuid: string
}

export enum DashboardSectionTypeEnum{
  ActiveWithStudent = 0,
  ActiveWithPartner = 1,
  ActiveWithWep = 2
}

export interface ApplicationFormDashboardTab {
  name: string;
  titleTab: string;
  description: string;
  listName: string;
  indexName: string;
}