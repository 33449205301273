import { Applicant } from 'features/Entity/Applicant'
import React from 'react'
import {
  FieldValues,
  UseFormRegister
} from 'react-hook-form'
import {
  renderEmailWithPattern,
  renderInputText
} from '../../../components/Forms/FormsComponent'
import t, { changeLanguage } from '../../../components/I18N/TranslateHelpers'
import { GetCurrentLocale } from '../../../components/Utils'

interface ComponentProps {
  register: UseFormRegister<FieldValues>
  errors: { [x: string]: any }
  applicantData: Applicant
  isReadOnly: boolean
}

const ApplicantEmergencyInfoForm: React.FC<ComponentProps> = ({
  register,
  errors,
  applicantData,
  isReadOnly
}) => {
  const locale = GetCurrentLocale();
  changeLanguage(locale);

  return (
    <>
      <input
        type='hidden'
        id='applicantProfile.id'
        value={applicantData.applicantProfile?.id ?? 0}
        {...register('applicantProfile.id')}
      />
      {renderInputText(
        t('emergency_contact_name'),
        'applicantProfile.emergencyContactName',
        applicantData.applicantProfile?.emergencyContactName,
        errors.applicantProfile?.emergencyContactName,
        register,
        true,
        'mb-3 col-md-6',
        false,
        applicantData.applicantProfile?.initializedFields?.includes(
          'EmergencyContactName'
        ) || isReadOnly
      )}
      {renderInputText(
        t('emergency_contact_phone'),
        'applicantProfile.emergencyContactHomePhoneNumber',
        applicantData.applicantProfile?.emergencyContactHomePhoneNumber,
        errors.applicantProfile?.emergencyContactHomePhoneNumber,
        register,
        false,
        'mb-3 col-md-6',
        false,
        applicantData.applicantProfile?.initializedFields?.includes(
          'EmergencyContactHomePhoneNumber'
        ) || isReadOnly
      )}
      {renderInputText(
        t('emergency_contact_mobile'),
        'applicantProfile.emergencyContactMobilePhoneNumber',
        applicantData.applicantProfile?.emergencyContactMobilePhoneNumber,
        errors.applicantProfile?.emergencyContactMobilePhoneNumber,
        register,
        true,
        'mb-3 col-md-6',
        false,
        applicantData.applicantProfile?.initializedFields?.includes(
          'EmergencyContactMobilePhoneNumber'
        ) || isReadOnly
      )}
      {renderInputText(
        t('relationship_to_student'),
        'applicantProfile.relationshipToTheStudent',
        applicantData.applicantProfile?.relationshipToTheStudent,
        errors.applicantProfile?.relationshipToTheStudent,
        register,
        true,
        'mb-3 col-md-6',
        false,
        applicantData.applicantProfile?.initializedFields?.includes(
          'RelationshipToTheStudent'
        ) || isReadOnly
      )}
      {renderEmailWithPattern(
        t('emergency_contact_email'),
        applicantData.applicantProfile?.emergencyContactEmail,
        register,
        'applicantProfile.emergencyContactEmail',
        true,
        errors.applicantProfile?.emergencyContactEmail,
        'mb-3 col-md-6',
        applicantData.applicantProfile?.initializedFields?.includes(
          'EmergencyContactEmail'
        ) || isReadOnly
      )}
    </>
  )
}

export default ApplicantEmergencyInfoForm
