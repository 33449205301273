import { GetPasswordRegex } from "components/Forms/UtilsForFormValidation";
import React, { useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../app/RootReducer";
import { store } from "../../app/store";
import t from "../../components/I18N/TranslateHelpers";
import { SpinnerOnTop } from "../../components/SpinnerOnTop";
import { GetUrlParams } from "../../components/Utils";
import { ResetPasswordFormData } from "../Entity/ResetPasswordFormData";
import { changePassword } from "../Slice/UserSlice";

const ResetPasswordForm: React.FC = () => {
    const [resetToken] = useState<string | null>(GetUrlParams()["token"]);
    const [email] = useState<string | null>(GetUrlParams()["email"]);
    const [fromActivePage] = useState<string>(GetUrlParams()["fromActivePage"] ?? null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

    const isLoading = useSelector((state: RootState) => state.userRdc.isRequesting);
    const requestingSuccess = useSelector((state: RootState) => state.userRdc.isRequestSuccess);

    const resolver: Resolver<ResetPasswordFormData> = async (values) => {
        return {
            values,
            errors:
                !values.newPassword
                    ? {
                        newPassword: {
                            type: 'required',
                            message: t("please_pwd"),
                        },
                    }
                    : !values.confirmNewPassword
                        ? {
                            confirmNewPassword: {
                                type: 'required',
                                message: t("please_pwd"),
                            },
                        }
                        : values.confirmNewPassword !== values.newPassword
                            ? {
                                confirmNewPassword: {
                                    type: 'validate',
                                    message: t("passwords_must_be_identical"),
                                }
                            }
                            :
                            !GetPasswordRegex().test(values.newPassword) ?
                                {
                                    newPassword: {
                                        type: 'pattern',
                                        message: t("passwords_regex"),
                                    }
                                }
                                : {},
        };
    };

    const { register, handleSubmit, formState: { errors } } = useForm<ResetPasswordFormData>({ resolver });

    const onSubmit = handleSubmit((formData) => store.dispatch(changePassword(formData)));

    return (
        <React.Fragment>
            {
                requestingSuccess ?
                    <div className="login-form needs-validation">
                        <h6 className="mb-0">{t('reset_password_submitted')} <a href={t("url_login")}>{t("login").toLowerCase()}</a>.</h6>
                    </div>
                    :
                    <form className="login-form needs-validation" onSubmit={onSubmit}>
                        {
                            fromActivePage === "true" ?
                            <>
                                <h4>{t('create_password')}</h4>
                                <h6 className="mb-2">{t('create_password_subtitle')}</h6>
                            </>
                            :
                            <>
                                <h4>{t('reset_password')}</h4>
                                <h6 className="mb-2">{t('reset_password_subtitle')}</h6>
                            </>
                        }
                        <input
                            type="hidden"
                            required
                            value={resetToken ?? ""}
                            {...register("resetToken")}
                        />
                        <input
                            type="hidden"
                            required
                            value={email ?? ""}
                            {...register("email")}
                        />
                        <div className="form-group">
                            <label>{t("password")}</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">
                                    <i className="icon-lock"></i>
                                </span>
                                <input
                                    className="form-control"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="*********"
                                    disabled={isLoading}
                                    required
                                    {...register("newPassword")}
                                />
                                <div className="invalid-tooltip">{t("please_pwd")}</div>
                                <div className="show-hide">
                                    <span className="show" onClick={() => setShowPassword(!showPassword)}> </span>
                                </div>
                            </div>
                        </div>
                        {errors?.newPassword && <div className="text-danger">{errors.newPassword.message}</div>}
                        <div className="form-group">
                            <label>{t("password_confirm")}</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">
                                    <i className="icon-lock"></i>
                                </span>
                                <input
                                    className="form-control"
                                    type={showPasswordConfirm ? "text" : "password"}
                                    placeholder="*********"
                                    disabled={isLoading}
                                    required
                                    {...register("confirmNewPassword")}
                                />
                                <div className="invalid-tooltip">{t("please_pwd")}</div>
                                <div className="show-hide">
                                    <span className="show" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}> </span>
                                </div>
                            </div>
                        </div>
                        {errors?.confirmNewPassword && <div className="text-danger">{errors.confirmNewPassword.message}</div>}
                        <small className="block mb-3">
                            {t('passwords_regex')}
                        </small>
                        <div className="form-group justify-content-end d-flex">
                            {isLoading ? <SpinnerOnTop /> : ""}
                            <button className="btn btn-primary" type="submit" disabled={isLoading}>
                                { fromActivePage  === "true" ? t('create_my_password') : t('update_password')}
                            </button>
                        </div>
                    </form>
            }
        </React.Fragment>
    );
};

export default ResetPasswordForm;
