import { Modal } from "@mui/material";
import { RootState, store } from "app/store";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { showSelectAppForm } from "components/UtilsForApplicationForm";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import { DocumentFile } from "features/Entity/VirtualFolder";
import { getApplicationForms, getCurrentAppForm } from "features/Slice/ApplicationFormSlice";
import { deleteVirtualFolderFile, getVirtualFolderFile, getVirtualFolders, postVirtualFolder } from "features/Slice/VirtualFolderSlice";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import DropzoneComponent from "../../components/Dropzone/DropzoneComponent";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../components/Utils";
import PageTitle from "./PageTitle";
import { getMediasAllExtensions, getMediasFiles, getMediasFilesAndImages, getMediasPDFAndImages } from "components/UtilsForUpload";

const VirtualFolder: React.FC = () => {
	const [selectedApplicationForm, setSelectedApplicationForm] = useState<ApplicationForm | null>(null);
	const [selectedFileToDelete, setSelectedFileToDelete] = useState<DocumentFile | null>(null);

	const isRequestingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const applicationForms = useSelector((state: RootState) => state.applicationFormRdc.applicationForms);
	const isRequestingVirtualFolder = useSelector((state: RootState) => state.virtualFolderRdc.isRequestingGet);
	const isPostingVirtualFolder = useSelector((state: RootState) => state.virtualFolderRdc.isRequestingPost);
	const virtualFolders = useSelector((state: RootState) => state.virtualFolderRdc.virtualFolders);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
	const currentAppForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	const prefix = "DocumentFolderDTOs";

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if(!currentAppForm){
			store.dispatch(getCurrentAppForm())		
		}
	}, [currentAppForm]);
	
	React.useEffect(() => {
		if(userInfo){
			if(!userInfo.isSendingPartner && !userInfo.isStaff){
				if (!isRequestingApplicationForm && !applicationForms) {
					store.dispatch(getApplicationForms());
				}
			}
		}
	}, [userInfo]);

	React.useEffect(() => {
		if (currentAppForm) {
			setSelectedApplicationForm(currentAppForm)
		}
	}, [currentAppForm]);

	React.useEffect(() => {
		if (selectedApplicationForm) {
			store.dispatch(getVirtualFolders(selectedApplicationForm.uid));
		}
	}, [selectedApplicationForm]);

	function deleteFile()
	{
		if(selectedFileToDelete !== null && selectedApplicationForm)
		{
			store.dispatch(deleteVirtualFolderFile(selectedApplicationForm.uid, selectedFileToDelete));
		}

		setSelectedFileToDelete(null);
	}
	
	const confirmDeleteModal = () => (
		<Modal open={selectedFileToDelete !== null}>
			<div className='card modal-dialog'>
			<div className="modal-header">
				<h5 className="modal-title" id="exportOmgtModalTitle">{t('confirm_delete_file_title')}</h5>
				<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setSelectedFileToDelete(null)}>
				<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div className='modal-body'>
				<p>{t("confirm_delete_file_text", {filename: selectedFileToDelete?.physicalFile})}</p>
				<p>{t('cannot_be_undone')}</p>
				<div className="d-flex">
				<button className='btn btn-primary mr-3' type='button'
					onClick={() => setSelectedFileToDelete(null)}
				>
					{t('cancel')}
				</button>
				<button className='btn btn-primary-outline' type='button'
					onClick={() => deleteFile()}
				>
					{t('confirm')}
				</button>
				</div>
			</div>
			</div>
		</Modal>
		);

	const { register, setValue, getValues, handleSubmit } = useForm({
		mode: "onChange",
	});

	const [notifyStaff, setNotifyStaff] = useState(false)

	async function onSubmit(formData: any) {
		let newFormData = new FormData();
		newFormData.append(`notify`, notifyStaff.toString());
		if (formData[prefix] && virtualFolders) {
			let curIndex = 0;
			for (const folderIndex in formData[prefix]) {
				const folder = formData[prefix][folderIndex];

				if (folder && folder.documentFiles && folder.documentFiles instanceof FileList) {
					newFormData.append(`${prefix}[${curIndex}].id`, virtualFolders[parseInt(folderIndex)].id.toString());
					for (let fileIndex = 0; fileIndex < folder.documentFiles.length; fileIndex++) {
						const docFile: File = folder.documentFiles[fileIndex];

						if (docFile) {
							newFormData.append(`${prefix}[${curIndex}].documentFiles[${fileIndex}].physicalFileBaseFile`, docFile);
							newFormData.append(`${prefix}[${curIndex}].documentFiles[${fileIndex}].id`, "0");
						}
					}
					curIndex++;
				}
			}
		}
		store.dispatch(postVirtualFolder(newFormData));
	}
	return (
		<div className="row starter-main">
			<PageTitle title={t('virtual_folder')} />
			{ 
				showSelectAppForm(userInfo, selectedApplicationForm, applicationForms, setSelectedApplicationForm) 
			}
			{
				isRequestingVirtualFolder ?
					<SpinnerOnTop />
					:
					virtualFolders && selectedApplicationForm ?
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row virtual-folder-container">
								{
									confirmDeleteModal()
								}
								{
									virtualFolders.map((virtualFolder, index) => {
										let classesForTabs = virtualFolder.documentFiles.length == 0 ? " active show" : ""
										return (
											<div className="col-xl-4 col-sm-6 col-xs-12" key={`virtual-folder-${virtualFolder.id}`}>
												<div className="card mb-3">
													<div className="card-header pb-0 row g-0">
														<div className="col">
															<h5>{virtualFolder.documentFileTypeName}</h5>
														</div>
														<div className="col justify-content-end d-flex">
															<ul className="nav nav-tabs mb-0 noborder" id="virtualFolderTabs" role="tablist">
																<li className="nav-item">
																	<a className={"nav-link active" + classesForTabs} id={"upload-tabs-"+virtualFolder.id} data-bs-toggle="tab" href={"#upload-" + virtualFolder.id} role="tab" aria-controls={"upload-"+virtualFolder.id} aria-selected="true">Upload</a>
																</li>
																{
																	virtualFolder.documentFiles.length > 0 ?
																		<li className="nav-item">
																			<a className="nav-link" id={"files-tabs-"+virtualFolder.id} data-bs-toggle="tab" href={"#files-" + virtualFolder.id} role="tab" aria-controls={"files-"+virtualFolder.id} aria-selected="false">My files</a>
																		</li>
																	: null
																}
															</ul>
														</div>
														{
															virtualFolder.documentFileTypeDescription !== null ?
															<div className="col-md-12">
																<p className="mb-0 pt-2">{virtualFolder.documentFileTypeDescription}</p>
															</div>
															: null
														}
														
													</div>
													<div className="tab-content" id="virtualFolderTabsContent">
														<div className={"tab-pane fade active show" + classesForTabs} id={"upload-"+virtualFolder.id} role="tabpanel" aria-labelledby={"upload-tabs-" + virtualFolder.id}>
															<div className={"card-body"}>
																<DropzoneComponent
																	nbOfFiles={100}
																	id={`${prefix}[${index}].documentFiles`}
																	label={""}
																	description="You may only upload a pdf jpg png file."
																	classes="width100"
																	required={false}
																	disabled={false}
																	errorMessages=""
																	isValid={false}
																	name={`${prefix}[${index}].documentFiles`}
																	onFieldChange=""
																	value={null}
																	register={register}
																	accept={getMediasFilesAndImages()}
																	setValue={setValue}
																	getValues={getValues}
																	onSubmit={onSubmit}
																	displayButton={false}
																/>
															</div>
														</div>
														{
															virtualFolder.documentFiles.length > 0 ?
																<div className="tab-pane fade" id={"files-"+virtualFolder.id} role="tabpanel" aria-labelledby={"files-"+virtualFolder.id}>
																	<ul className="list-virtual-folder">
																		{
																			virtualFolder.documentFiles.filter(doc => doc.physicalFile !== null && doc.physicalFile.length > 0).map(documentFile => {
																				let documentDate = documentFile.createdOn !== null ? documentFile.createdOn.split("T") : ""
																				
																				return(
																					<li key={`virtualfolder-${virtualFolder.id}-file-${documentFile.id}`}>
																						<a href="#" className="text-black" onClick={() => store.dispatch(getVirtualFolderFile(selectedApplicationForm.uid, documentFile.id, documentFile.physicalFile as string))}>
																							<i className="fa fa-file-text-o txt-info"></i>
																							{ 
																								documentFile.createdOn !== null ? 
																									<><strong>{documentDate[0]}</strong> - </> 
																								: 
																									""
																							}  
																							{documentFile.physicalFile}
																						</a>
																						<button type="button" onClick={() => setSelectedFileToDelete(documentFile)} style={{float: 'right'}}>
																							<i className="icofont icofont-close text-danger"></i>
																						</button>
																						<button  style={{float: 'right'}} type="button" className="text-black" onClick={() => store.dispatch(getVirtualFolderFile(selectedApplicationForm.uid, documentFile.id, documentFile.physicalFile as string))}>
																							<i className="icofont icofont-download-alt mr-2"></i>
																						</button>
																				</li>
																				)
																			})
																		}
																	</ul>
																</div>
																:
															null
															}
														
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
							{
								isPostingVirtualFolder ?
									<SpinnerOnTop />
									: null
							}
							<div className="col-md-12">
								<div className="py-5 d-flex justify-content-end">
									{
										userInfo?.isSendingPartner ? 
										<button disabled={isPostingVirtualFolder} className="btn btn-primary mr-2" onClick={() => setNotifyStaff(true)}>
											<i className="icofont icofont-save"></i> {t('save_notify_receiving_organization')}
										</button>
										: null
									}
									<button disabled={isPostingVirtualFolder} className="btn btn-primary mr-2" onClick={() => userInfo?.isSendingPartner ? setNotifyStaff(false) : setNotifyStaff(true)}>
										<i className="icofont icofont-save"></i> {t('save_virtual_folder')}
									</button>
								</div>
							</div>
						</form>
						: null
			}
		</div>
	);
};

export default VirtualFolder;
