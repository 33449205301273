import { getApplicationFormSectionStatus, getApplicationFormSectionStatusOfValidator, getCurrentAppForm } from "features/Slice/ApplicationFormSlice";
import React  from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { SpinnerOnTop } from "../../components/SpinnerOnTop";
import { GetCurrentLocale } from "../../components/Utils";
import { getApplicantFormApplicantName, getApplicationFormFirstIncompleteSection, getApplicationFormIdFromUrl, getApplicationFormSectionUrl } from "../../components/UtilsForApplicationForm";

const ApplicationStatus: React.FC = () => {
	const isRequestingApplicationFormStatus = useSelector((state: RootState) => state.applicationFormRdc.isRequestingSectionStatus);
	const isRequestingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const sections = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSections);
	const applicationFormStatus = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSectionStatus);
	const applicationFormStatusOfValidator = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSectionStatusOfValidator);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
	const currentAppForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	
	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if(!currentAppForm){
			if(!isRequestingApplicationForm)
			{
				store.dispatch(getCurrentAppForm());
			}
		}else{
			store.dispatch(getApplicationFormSectionStatus(currentAppForm.uid));
			store.dispatch(getApplicationFormSectionStatusOfValidator(currentAppForm.uid));
		}
	}, [currentAppForm]);


	const statusBox = (isPartner: boolean) => {
		return (
			<div className="dashboard-2-main col-xl-6 col-50 box-col-6 des-xl-50">
				<div className="card income-card">
					<div className="card-header pb-0">
						<div className="header-top d-sm-flex align-items-center">
							<h4 className="pb-0 mb-1">
								{
									!isPartner ?
										t('application_status')
										:
										t('status_with_wep')
								}
							</h4>
						</div>
						{
							currentAppForm && sections ?
								<a href={getApplicationFormSectionUrl(getApplicationFormFirstIncompleteSection(sections))}>
									<span className="counter">
										{`${getApplicantFormApplicantName(currentAppForm)} - ${currentAppForm.label}`}
									</span>
								</a>
								: ""
						}
					</div>
					{
						isRequestingApplicationFormStatus || !applicationFormStatus || !applicationFormStatusOfValidator ?
							<SpinnerOnTop />
							:
							<div className="card-body pl-14">
								<div className="row">
									<div className="col-md-12">
										<h6 className="text-center pb-3">On {applicationFormStatus.total} steps, you have :</h6>
									</div>
								</div>
								{
									!isPartner ?

										<div className="header-top d-sm-flex align-items-center">
											<div className="media-body mb-2 text-center b-r-light">
												<div className="redial-counter sections-approved" data-label="50%">
													<h5 className="mb-0">
														{applicationFormStatus.nbSectionApprouveds}
													</h5>
												</div>
												<span className="pt-2 block">{t('approved_steps')}</span>
											</div>
											<div className="media-body mb-2 text-center b-r-light">
												<div className="redial-counter sections-complete" data-label="50%">
													<h5 className="mb-0">
														{applicationFormStatus.nbSectionCompleteds}
													</h5>
												</div>
												<span className="pt-2 block">{t('completed_steps')}</span>
											</div>
											<div className="media-body mb-2 text-center">
												<div className="redial-counter sections-incomplete" data-label="50%">
													<h5 className="mb-0">
														{applicationFormStatus.nbSectionIncompletes}
													</h5>
												</div>
												<span className="pt-2 block">{t('incompleted_steps')}</span>
											</div>
										</div>
										:
										<div className="header-top d-sm-flex align-items-center">
											<div className="media-body mb-2 text-center b-r-light">
												<div className="redial-counter sections-approved-by-wep" data-label="50%">
													<h5 className="mb-0">
														{applicationFormStatusOfValidator.nbSectionApprouveds}
													</h5>
												</div>
												<span className="pt-2 block">{t('approved_steps') + " " + t('by_wep')}</span>
											</div>
											<div className="media-body mb-2 text-center">
												<div className="redial-counter sections-incomplete-by-wep" data-label="50%">
													<h5 className="mb-0">
														{applicationFormStatusOfValidator.nbSectionIncompletes}
													</h5>
												</div>
												<span className="pt-2 block">{t('incompleted_steps') + " " + t('by_wep')}</span>
											</div>
										</div>
								}

							</div>
					}
				</div>
			</div>
		)
	}


	return (
			currentAppForm !== null ?
			<>
				{ 
				!userInfo?.isSendingPartner || getApplicationFormIdFromUrl() !== null ?
					statusBox(false)
				: null
				}
				{ 
					currentAppForm.displayStatusOfValidatorDashboardSection ?
						statusBox(true)
					: null
				}
			</>
			: null
	);
};

export default ApplicationStatus;
