import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApplicationFormTemplate } from "features/Entity/ApplicationForm";
import { getApplicationFormTemplatesRep } from "features/Repository/ApplicationFormTemplateRepository";
import { AppThunk } from "../../app/store";

interface ApplicationFormTemplateState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  applicationFormTemplates: ApplicationFormTemplate[]|null
}

const initialState: ApplicationFormTemplateState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  applicationFormTemplates: null
}

function startRequestRdc(state: ApplicationFormTemplateState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: ApplicationFormTemplateState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: ApplicationFormTemplateState, action: PayloadAction<ApplicationFormTemplate[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicationFormTemplates = action.payload;
}


const ApplicationFormTemplateSlice = createSlice({
    name: 'ApplicationFormTemplates',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestFailedAct: requestFailedRdc,
      requestSuccessAct: requestSuccessRdc,
    }
  }
)

export const {
  startRequestAct,
  requestFailedAct,
  requestSuccessAct
} = ApplicationFormTemplateSlice.actions;

export default ApplicationFormTemplateSlice.reducer;


export const getApplicationFormTemplates = (programType: number): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestAct());
    const apiResponse = await getApplicationFormTemplatesRep(programType);
    dispatch(requestSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}