import { QuestionTemplate, Survey } from "features/Entity/Survey/Survey";
import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface ComponentProps {
	survey: Survey;
	questionTemplate: QuestionTemplate;
	register: UseFormRegister<FieldValues>;
	isSurveyFormReadOnly: boolean;
}

const QuestionNote: React.FC<ComponentProps> = ({
	survey,
	questionTemplate,
    register,
	isSurveyFormReadOnly
}) => {
    return (
        <li className="py-3 px-2 nomarker">
			<div dangerouslySetInnerHTML={{ __html: questionTemplate.description }}></div>
		</li>
    );
};

export default QuestionNote;
