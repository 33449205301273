import React from "react";
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch } from "react-hook-form";
import t, { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../components/Utils";
import { renderRadio } from "../../../components/Forms/FormsComponent";
import { Applicant } from "features/Entity/Applicant";

interface ComponentProps {
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	errors: { [x: string]: any };
	applicantData: Applicant;
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	isReadOnly: boolean;
}

const ApplicantSmokerForm: React.FC<ComponentProps> = ({
	register,
	unregister,
	watch,
	errors,
	applicantData,
	setValue,
	isReadOnly
}) => {

	const isSmoker = watch("applicantProfile.isSmoker");
	const canAdjustToSmokingFamily = watch("applicantProfile.isSmokerFamily");

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
		setValue("applicantProfile.isSmoker", applicantData.applicantProfile?.isSmoker !== null ? (applicantData.applicantProfile?.isSmoker === true ? "true" : "false") : undefined);
		setValue("applicantProfile.isSmokerFamily", applicantData.applicantProfile?.isSmokerFamily !== null ? (applicantData.applicantProfile?.isSmokerFamily === true ? "true" : "false") : undefined);
	}, []);

	return (
		<div className="pt-3 row">
			<div className="col-lg-6 col-m-12 col-sm-12 mb-3">
				<label htmlFor="applicantProfile.isSmoker" className="form-label required">{t('do_you_smoke')}</label>
				<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
					{renderRadio(t('yes'), "applicantProfile.isSmoker", "true", false, register, false, unregister, ["applicantProfile.isSmokerFamily"], applicantData?.applicantProfile?.isSmoker, applicantData.applicantProfile?.initializedFields?.includes("IsSmoker") || isReadOnly)}
					{renderRadio(t('no'), "applicantProfile.isSmoker", "false", false, register, false, unregister, ["applicantProfile.isSmokerReadyToStop"], !applicantData?.applicantProfile?.isSmoker, applicantData.applicantProfile?.initializedFields?.includes("IsSmoker") || isReadOnly)}
				</div>
			</div>
			{
				isSmoker === "true" ?
					<div className="col-lg-6 col-m-12 col-sm-12 mb-3">
						<label htmlFor="applicantProfile.isSmokerReadyToStop" className="form-label required">{t('smoke_ready_to_stop')}</label>
						<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
							{renderRadio(t('yes'), "applicantProfile.isSmokerReadyToStop", "true", false, register, false, unregister, [], applicantData?.applicantProfile?.isSmokerReadyToStop, applicantData.applicantProfile?.initializedFields?.includes("IsSmokerReadyToStop") || isReadOnly)}
							{renderRadio(t('no'), "applicantProfile.isSmokerReadyToStop", "false", false, register, false, unregister, [], !applicantData?.applicantProfile?.isSmokerReadyToStop, applicantData.applicantProfile?.initializedFields?.includes("IsSmokerReadyToStop") || isReadOnly)}
						</div>
					</div>
					:
					isSmoker === "false" ?
						<div className="col-lg-6 col-m-12 col-sm-12 mb-3">
							<label htmlFor="applicantProfile.isSmokerFamily" className="form-label required">{t('adjust_smoking_family')}</label>
							<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
								{renderRadio(t('yes'), "applicantProfile.isSmokerFamily", "true", false, register, false, unregister, [], applicantData?.applicantProfile?.isSmokerFamily, applicantData.applicantProfile?.initializedFields?.includes("IsSmokerFamily") || isReadOnly)}
								{renderRadio(t('no'), "applicantProfile.isSmokerFamily", "false", false, register, false, unregister, ["applicantProfile.isSmokerFamilyOnlyOutside"], !applicantData?.applicantProfile?.isSmokerFamily, applicantData.applicantProfile?.initializedFields?.includes("IsSmokerFamily") || isReadOnly)}
							</div>
						</div>
						: ""
			}
			{
				canAdjustToSmokingFamily === "true" ?
					<div className="col-lg-6 col-m-12 col-sm-12 mb-3">
						<label htmlFor="applicantProfile.isSmokerFamilyOnlyOutside" className="form-label required">{t('smoke_outside')}</label>
						<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
							{renderRadio(t('yes'), "applicantProfile.isSmokerFamilyOnlyOutside", "true", false, register, false, unregister, [], applicantData?.applicantProfile?.isSmokerFamilyOnlyOutside, applicantData.applicantProfile?.initializedFields?.includes("IsSmokerFamilyOnlyOutside") || isReadOnly)}
							{renderRadio(t('no'), "applicantProfile.isSmokerFamilyOnlyOutside", "false", false, register, false, unregister, [], !applicantData?.applicantProfile?.isSmokerFamilyOnlyOutside, applicantData.applicantProfile?.initializedFields?.includes("IsSmokerFamilyOnlyOutside") || isReadOnly)}
						</div>
					</div>
					: ""
			}
		</div>
	);
};

export default ApplicantSmokerForm;
