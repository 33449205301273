import axios  from "components/AxiosUtils";
import { SchoolTranscript } from "features/Entity/School";

export async function getSchoolRep(applicationFormUid: string): Promise<SchoolTranscript|null> {
    try
    {
        const response = await axios.get<SchoolTranscript[]>(
            `/School/${applicationFormUid}/GetRelsSchoolApplication`,
        );

        return response.data.length > 0 ? response.data[0] : null;
    }
    catch (err: any) 
    {
        throw err;
    }
}
