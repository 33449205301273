import React, { useState } from "react";
import {
	FieldValues,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";
import t from "../../components/I18N/TranslateHelpers";
import DropzoneComponent from "components/Dropzone/DropzoneComponent";
import { store } from "app/store";
import { getAgreement, getUserDownloadedAgreement, uploadAgreementFile } from "features/Slice/AgreementSlice";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";
import { Agreement } from "features/Entity/Agreement";
import { getMediasPDFAndImages } from "components/UtilsForUpload";

interface ComponentProps {
    agreement: Agreement;
	applicationFormId: string;
	applicationFormCultureCode: string;
	register: UseFormRegister<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValues: UseFormGetValues<FieldValues>;
	isReadOnly: boolean;
    index: number;
    sectionId: number;
}

const AgreementUploadField: React.FC<ComponentProps> = ({
    agreement,
	applicationFormId,
	applicationFormCultureCode,
	register,
	setValue,
	getValues,
	isReadOnly,
    index,
    sectionId
}) => {
	const isPostSuccess = useSelector((state: RootState) => state.agreementRdc.isPostSuccess);

	const getUserUploadedFileForAgreement = (agreement: Agreement) =>
	(
		uploadedFilename && uploadedFilename.length > 0 ?
			<React.Fragment>
				<button type="button" onClick={() => store.dispatch(getUserDownloadedAgreement(applicationFormId, agreement.agreementUID, uploadedFilename))}>
					<i className="icofont icofont-download-alt mr-2"></i>{" "}
					{uploadedFilename}
				</button>
			</React.Fragment>
			: <></>
	)

    const [uploadedFilename, setUploadedFilename] = useState<string|null>(agreement.filenameUploaded && agreement.filenameUploaded.length > 0 ? agreement.filenameUploaded : null);

    React.useEffect(() => {
        if(isPostSuccess)
        {
            const uploadingFilelist = getValues(`agreement.${index}.File`);

            if(uploadingFilelist)
            {
                try
                {
                    const uploadingFilename = (uploadingFilelist as FileList).item(0);

                    if(uploadingFilename && uploadingFilename.name)
                    {
                        setUploadedFilename(uploadingFilename.name);
                    }
                }
                catch(err){}
            }
        }
    }, [isPostSuccess]);

	return (
		<div className="mb-3 container-agreement card" key={agreement.agreementUID}>
            <div className="col-md-8 col-sm-12 nopadding">
                <div className="h-100">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 xl-100">
                                <h5 className="text-black">{agreement.title}</h5>
                                <h6 className="pb-3">{agreement.subTitle}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <p>{agreement.description} </p>
                        </div>
                        <div className="col-md-12 xl-100">
                            {
                                agreement.filename && agreement.filename.length > 0 ?
                                    <button type="button" onClick={() => store.dispatch(getAgreement(agreement, applicationFormCultureCode, agreement.filename))}
                                        className="btn btn-primary btn-sm job-apply-btn mt-3">
                                        <i className="icofont icofont-download-alt mr-2"></i>
                                        {t("download") + " " + agreement.title}
                                    </button>
                                    : <React.Fragment></React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-12 mt-0 nopadding">
                <div className="h-100 card-agreement-dropzone">
                    <div className="card-body">
                        <DropzoneComponent
                            nbOfFiles={1}
                            id={`agreement.${index}.File`}
                            label={""}
                            description="You may only upload a pdf jpg png file."
                            classes="d-flex align-items-center justify-content-center width100"
                            required={false}
                            disabled={isReadOnly}
                            errorMessages=""
                            isValid={false}
                            name={`agreement.${index}.File`}
                            onFieldChange=""
                            value={null}
                            register={register}
                            accept={getMediasPDFAndImages()}
                            setValue={setValue}
                            getValues={getValues}
                            onSubmit={(formData: any) => store.dispatch(uploadAgreementFile(applicationFormId, agreement.agreementUID, formData, sectionId))}
                            displayButton={true}
                            existingFilesDownload={getUserUploadedFileForAgreement(agreement)}
                        />
                    </div>
                </div>
            </div>
        </div>
	);
};

export default AgreementUploadField;
