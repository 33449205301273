import React from "react";
import { ToastContainer } from "react-toastify";
import { consumeToasts } from "../Utils";
import 'react-toastify/dist/ReactToastify.css';

const Toasts: React.FC = () => {
    React.useEffect(() => {
		consumeToasts();
	}, []);

    return <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />;
};

export default Toasts;