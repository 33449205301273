import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { ApplicationFormSection } from "features/Entity/ApplicationFormSection";
import { downloadApplicationFormSectionFile, getCurrentAppForm, uploadApplicationFormSectionFile } from "features/Slice/ApplicationFormSlice";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../components/I18N/TranslateHelpers";
import FileUploadField from "./FileUploadField";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { getMediasPDFAndImages } from "components/UtilsForUpload";

interface ComponentProps {
	applicationFormUid: string;
	section: ApplicationFormSection;
	isReadOnly: boolean;
	register: UseFormRegister<FieldValues>,
}

const UploadPDFSection: React.FC<ComponentProps> = ({ applicationFormUid, section, isReadOnly, register }: ComponentProps) => {
	const isRequesting = useSelector((state: RootState) => state.applicationFormRdc.isRequestPostSectionPdf);
	const uploadSuccess = useSelector((state: RootState) => state.applicationFormRdc.isRequestPostSectionPdfSuccess);

	const [uploadedFile, setUploadedFile] = useState<File | null>(null);
	const [uploadedFilename, setUploadedFilename] = useState<string | null>(section.uploadedFile && section.uploadedFile.length > 0 ? section.uploadedFile : null);
	React.useEffect(() => {
		if (uploadSuccess && uploadedFile) {
			setUploadedFilename(uploadedFile.name);
			store.dispatch(getCurrentAppForm(applicationFormUid, true));
		}
	}, [uploadSuccess]);

    function setNewFile(filelist: FileList) {
        if (filelist && filelist.length > 0) {
            const file = filelist[0];
            setUploadedFile(file);
        }
    }

    const handleFileChange = (e: any) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setNewFile(files);
        }
    };
	
	return (
		<div className="card" id="uploadPDFSection">
			<div className="card-body">
				<div className="row">
					<div className="col-md-3 col-sm-12 d-flex align-items-center">
						<label className={`form-label${section.isReuploadSectionPDFRequired ? " required" :""}`}>{t('upload_pdf_pre_filled')}</label>
					</div>
					<div className="col-md-6 col-sm-12">
						<FileUploadField
							id={"section-pdf-upload"}
							label={""}
							description={null}
							classes={""}
							required={section.isReuploadSectionPDFRequired}
							disabled={isReadOnly ?? false}
							errorMessages={undefined}
							isValid={false}
							name={"section-pdf-upload"}
							onFieldChange={handleFileChange}
							value={uploadedFilename ?? null}
							register={register}
							accept={getMediasPDFAndImages()}
							downloadFileAction={uploadedFilename ? () => store.dispatch(downloadApplicationFormSectionFile(applicationFormUid, section.id, uploadedFilename)) : null}
						/>
					</div>
					<div className="col-md-3 col-sm-12">
						<div className="form-group mb-0 me-0"></div>
						{isRequesting ? <SpinnerOnTop /> : null}
						{
							!isReadOnly ?
								<button type="button" disabled={isRequesting} className="btn btn-primary-outline small"
									onClick={() => uploadedFile ? store.dispatch(uploadApplicationFormSectionFile({ appFormUID: applicationFormUid, sectionId: section.id, file: uploadedFile })) : null}
								>
									{t('upload_pdf')}
								</button>
							: null
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default UploadPDFSection
