import React from "react";
import {
	FieldValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormUnregister,
	UseFormWatch,
	UseFormGetValues,
	useForm,
	Control
} from "react-hook-form";
import t from "components/I18N/TranslateHelpers";
import {
	AnswerTemplate,
	QuestionTemplate,
	Survey,
} from "features/Entity/Survey/Survey";
import QuestionFreeText from "./QuestionFreeText";
import QuestionMCOA from "./QuestionMCOA";
import QuestionMCMA from "./QuestionMCMA";
import QuestionNote from "./QuestionNote";
import { getSurveyWidgetDisplayMode, getValueText, isResponsive } from "components/Utils";
import QuestionDate from "./QuestionDate";
import { AnswerTypeEnum } from "features/Entity/Survey/AnswerTypeEnum";
import { renderInputText } from "./SurveyQuestionComponents";
import { SurveyWidgetDisplayMode } from "features/Entity/Survey/SurveyWidgetDisplayMode";
import { RootState } from "app/RootReducer";
import { useSelector } from "react-redux";
import DatePickerField from "components/Forms/DatePickerField";

interface ComponentProps {
	applicationFormId: string;
	survey: Survey;
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValue: UseFormGetValues<FieldValues>;
	isSurveyFormReadOnly: boolean;
	control: Control<FieldValues, any>;
}

const SurveyFormRendererImmunisation: React.FC<ComponentProps> = ({
	applicationFormId,
	survey,
	register,
	unregister,
	watch,
	setValue,
	getValue,
	isSurveyFormReadOnly,
	control
}) => {
	const numberOfColumns = 6;
	const columns = [
		"Vaccines",
		t("date_of") + " " + t("first_dose"),
		t("date_of") + " " + t("second_dose"),
		t("date_of") + " " + t("third_dose"),
		t("date_of") + " " + t("fourth_dose"),
		t("date_of") + " " + t("fifth_dose"),
		t("date_of") + " " + t("last_dose"),
	];

	const surveyActions = useSelector(
		(state: RootState) => state.surveyRdc.surveyActions,
	);

	const applicantData = useSelector(
		(state: RootState) => state.applicantRdc.applicantFormDto,
	);

	const NoteImmunisation =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "NoteImmunisation",
		);

	const ImmunisationQuestion = NoteImmunisation.length > 0 ? NoteImmunisation[0] : null

	const vaccineDTP =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineDTP",
		);
	const questionVaccineDTP = vaccineDTP.length > 0 ? vaccineDTP[0] : null


	const vaccineTad =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineTdap",
		);
	const questionVaccineTad = vaccineTad.length > 0 ? vaccineTad[0] : null

	const vaccinePolio =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccinePolio",
		);

	const questionVaccinePolio = vaccinePolio.length > 0 ? vaccinePolio[0] : null

	const vaccineMeasles =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineMeasles",
		);
	const questionVaccineMeasles = vaccineMeasles.length > 0 ? vaccineMeasles[0] : null

	const vaccineMumps =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineMumps",
		);
	const questionVaccineMumps = vaccineMumps.length > 0 ? vaccineMumps[0] : null

	const vaccineRubella =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineRubella",
		);
	const questionVaccineRubella = vaccineRubella.length > 0 ? vaccineRubella[0] : null

	const vaccineVaricella =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineVaricella",
		);
	const questionVaccineVaricella = vaccineVaricella.length > 0 ? vaccineVaricella[0] : null

	const vaccineHepatitisA =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineHepatitisA",
		);
	const questionVaccineHepatitisA = vaccineHepatitisA.length > 0 ? vaccineHepatitisA[0] : null

	const vaccineHepatitisB =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineHepatitisB",
		);
	const questionVaccineHepatitisB = vaccineHepatitisB.length > 0 ? vaccineHepatitisB[0] : null

	const vaccineMeningococcal =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineMeningococcal",
		);
	const questionVaccineMeningococcal = vaccineMeningococcal.length > 0 ? vaccineMeningococcal[0] : null

	const vaccineYellowFever =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineYellowFever",
		);
	const questionVaccineYellowFever = vaccineYellowFever.length > 0 ? vaccineYellowFever[0] : null

	const vaccineCovid19 =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineCovid19",
		);
	const questionVaccineCovid19 = vaccineCovid19.length > 0 ? vaccineCovid19[0] : null

	const hadCovid19 =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "HadDiseaseCovid",
		);
	const questionHadCovid19 = hadCovid19.length > 0 ? hadCovid19[0] : null

	const ImmunisationComments =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "ImmunisationComments",
		);

	const questionImmunisationComments = ImmunisationComments.length > 0 ? ImmunisationComments[0] : null

	const renderDisabledColumn = (
		answerTemplates: AnswerTemplate[],
		nbEmptyAnswer: number,
		lastAnswer: AnswerTemplate,
	) => {
		return nbEmptyAnswer > 0 &&
			(lastAnswer.label === "EMPTY" || lastAnswer.label === "") &&
			!isResponsive ? (
			<td
				colSpan={numberOfColumns - answerTemplates.length}
				style={{ height: "42px", background: "#fafbfc" }}
			></td>
		) : null;
	};

	const renderFieldsDateVaccine = (question: QuestionTemplate, changeOrder: boolean) => {
		const nbEmptyAnswer = numberOfColumns - question.answerTemplates.length;
		// get the last answer to count the disabled colspan
		const lastAnswer =
			question.answerTemplates[question.answerTemplates.length - 1];
		const labelDisplay = () => {
			return (
				<>
					<label className={question.mandatory ? "form-label required mb-0" : "form-label mb-0"}>{question.label}</label>
					{question.description ? (
						<small className="block">{question.description}</small>
					) : null}
				</>
			);
		};

		return (
			<>
				{!isResponsive ? (
					<td className="form-label mb-0" width={"20%"}>
						{labelDisplay()}
					</td>
				) : (
					<div className="block">{labelDisplay()}</div>
				)}

				{ changeOrder ?
					renderDisabledColumn(
						question.answerTemplates,
						nbEmptyAnswer,
						lastAnswer,
					)
					: null
				}	
				{question.answerTemplates.map(
					(answerTemplate: AnswerTemplate, index: number) => {
						const content = () => {
							return (
								<React.Fragment key={answerTemplate + "-" + index}>
									{
										//display label for responsive
										isResponsive ? (
											<label>{columns[index + 1]}</label>
										) : null
									}
									{
										answerTemplate.answerType === AnswerTypeEnum.Date ?
											<DatePickerField
												id={`SURVEY-${survey.id}.QUESTION-${question.id}-SURVEY-${survey.id}-FREETEXT-${answerTemplate.id}`}
												name={`SURVEY-${survey.id}.QUESTION-${question.id}-SURVEY-${survey.id}-FREETEXT-${answerTemplate.id}`}
												label={answerTemplate.label !== "" &&
													answerTemplate.label !== "EMPTY"
													? answerTemplate.label
													: ""}
												defaultValue={getValueText(question.answerTemplates[index], survey) !== "" ? new Date(getValueText(question.answerTemplates[index], survey)) : null}
												control={control}
												readonly={getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false}
												required={false}
											/>
											:
											renderInputText(
												answerTemplate.label !== "EMPTY" &&
													answerTemplate.label !== ""
													? answerTemplate.label : "",
												`SURVEY-${survey.id}.QUESTION-${question.id}-SURVEY-${survey.id}-FREETEXT-${answerTemplate.id}`,
												`SURVEY-${survey.id}.ANSWER-${question.answerTemplates[index].id}`,
												getValueText(question.answerTemplates[index], survey),
												"",
												register,
												false,
												"d-flex flex-basis-label",
												false,
												getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false,
												true,
											)

									}
								</React.Fragment>
							);
						};
						return !isResponsive ? (
							<td
								colSpan={
									question.answerTemplates.length !== numberOfColumns &&
										index === question.answerTemplates.length - 1 &&
										lastAnswer.label !== "EMPTY" &&
										lastAnswer.label !== ""
										? numberOfColumns - question.answerTemplates.length + 1
										: 1
								}
								className={
									question.answerTemplates.length !== numberOfColumns &&
										index === question.answerTemplates.length - 1 &&
										lastAnswer.label !== "EMPTY" &&
										lastAnswer.label !== ""
										? "inline-block"
										: ""
								}
								key={answerTemplate + "-" + index}
							>
								{content()}
							</td>
						) : (
							<div
								className={
									index === question.answerTemplates.length - 1
										? "col-md-6 mb-3 avoidbreak"
										: "col-md-3 col-sm-12 mb-3 avoidbreak"
								}
								key={answerTemplate + "-" + index}
							>
								{content()}
							</div>
						);
					},
				)}
				{ !changeOrder ?
					renderDisabledColumn(
						question.answerTemplates,
						nbEmptyAnswer,
						lastAnswer,
					)
					: null
				}
			</>
		);
	};
	const {
		formState: { errors },
	} = useForm({
		mode: "onChange",
	});
	const getAllFields = () => {
		return (
			<>
				{questionVaccineDTP !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineDTP?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineTad !== null ?
					<tr  className="questionVaccineTad">{renderFieldsDateVaccine(questionVaccineTad?.questionTemplate, true)}</tr>
					: null
				}
				{questionVaccinePolio !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccinePolio?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineMeasles !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineMeasles?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineMumps !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineMumps?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineRubella !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineRubella?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineVaricella !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineVaricella?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineHepatitisA !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineHepatitisA?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineHepatitisB !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineHepatitisB?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineMeningococcal !== null ?
					<tr className="vaccineMeningococcal">{renderFieldsDateVaccine(questionVaccineMeningococcal?.questionTemplate, false)}</tr>
					: null
				}
				{questionVaccineCovid19 !== null ?
					<tr>{renderFieldsDateVaccine(questionVaccineCovid19?.questionTemplate, false)}</tr>
					: null
				}
			</>
		);
	};

	return (
		<div id="vaccineContainer">
			<div className="row">
				<div className="col-md-6 col-ms-12 col-xs-12 col-1024-12 col-info-immunisation">
					{
						ImmunisationQuestion !== null ?
							<QuestionNote
								survey={survey}
								questionTemplate={ImmunisationQuestion?.questionTemplate}
								register={register}
								isSurveyFormReadOnly={isSurveyFormReadOnly}
							/>
							: null
					}
				</div>
				<div className="col-md-6 col-ms-12 col-xs-12 col-1024-12 mb-3 col-birthdate-immunisation">
					<DatePickerField
						id="birthDate"
						name="birthDate"
						label={t("birthdate")}
						defaultValue={
							applicantData?.birthDate
								? new Date(applicantData.birthDate)
								: null
						}
						control={control}
						readonly={true}
						required={true}
					/>
					<small>
						Vaccination requirements do change from time to time. WEP will
						advise if students are obliged to have additional vaccinations.
					</small>
				</div>
			</div>
			{!isResponsive ? (
				<table className="table table-hover table-striped">
					<thead>
						<tr>
							{columns.map((label: string, index: number) => {
								return <th key={label}>{label}</th>;
							})}
						</tr>
					</thead>
					<tbody>{getAllFields()}</tbody>
				</table>
			) : (
				getAllFields()
			)}
				{
					questionHadCovid19 !== null ?
						<QuestionMCOA
							survey={survey}
							questionTemplate={questionHadCovid19?.questionTemplate}
							register={register}
							unregister={unregister}
							watch={watch}
							setValue={setValue}
							getValue={getValue}
							isSurveyFormReadOnly={isSurveyFormReadOnly}
						/>
						: null
				}
				{
					questionVaccineYellowFever !== null ?
						<QuestionFreeText
							survey={survey}
							questionTemplate={questionVaccineYellowFever.questionTemplate}
							register={register}
							isSurveyFormReadOnly={isSurveyFormReadOnly}
						/>
						: null
				}
			<div className="row questionVaccineMeningococcal">
				{
					questionImmunisationComments !== null ?
						<QuestionFreeText
							survey={survey}
							questionTemplate={questionImmunisationComments.questionTemplate}
							register={register}
							isSurveyFormReadOnly={isSurveyFormReadOnly}
						/>
						: null
				}
			</div>

		</div>
	);
};

export default SurveyFormRendererImmunisation;
