import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toastAxiosError } from "components/Utils";
import saveAs from "file-saver";
import { AppThunk } from "../../app/store";
import { Tutorial } from "../Entity/Tutorial";
import { getDashboardTutorialRep, getTutorialFileRep, getTutorialRep } from "../Repository/TutorialRepository";

interface TutorialState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  tutorial: Tutorial | null,
  dashboardTutorial: Tutorial | null,
}

const initialState: TutorialState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  tutorial: null,
  dashboardTutorial: null
}

function startRequestRdc(state: TutorialState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: TutorialState, action: PayloadAction<any>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: TutorialState, action: PayloadAction<Tutorial | null>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.tutorial = action.payload;
}

function requestDashboardTutorialSuccessRdc(state: TutorialState, action: PayloadAction<Tutorial | null>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.dashboardTutorial = action.payload;
}

const TutorialSlice = createSlice({
  name: 'Tutorial',
  initialState: initialState,
  reducers: {
    startRequestAct: startRequestRdc,
    requestSuccessAct: requestSuccessRdc,
    requestFailedAct: requestFailedRdc,
    requestDashboardTutorialSuccessAct: requestDashboardTutorialSuccessRdc
  }
}
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct,
  requestDashboardTutorialSuccessAct
} = TutorialSlice.actions;

export default TutorialSlice.reducer;

export const getTutorial = (applicationFormId: string, sectionId: number): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct());
    const apiResponse = await getTutorialRep(applicationFormId, sectionId);
    dispatch(requestSuccessAct(apiResponse));
  } catch (err: any)
  {
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getTutorialFile = (applicationFormUid: string, sectionId: number, filename: string): AppThunk => async dispatch =>
{
  try
  {
    const apiResponse = await getTutorialFileRep(applicationFormUid, sectionId);

    saveAs(apiResponse, filename);
  } catch (err)
  {
    toastAxiosError(err as AxiosError);
    dispatch(requestFailedAct(err as AxiosError));
  }
}

export const getDashboardTutorial = (applicationFormUID: string): AppThunk => async dispatch => {
  try {
    dispatch(startRequestAct());
    const apiResponse = await getDashboardTutorialRep(applicationFormUID);
    dispatch(requestDashboardTutorialSuccessAct(apiResponse));
  } catch (err: any)
  {
    dispatch(requestFailedAct(err as AxiosError));
  }
}