import React, { useState } from "react";
import {
	FieldValues,
	UseFormRegister,
} from "react-hook-form";
import t, { changeLanguage } from "../../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../../components/Utils";
import { RootState } from "app/RootReducer";
import { useSelector } from "react-redux";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import TeacherRecommendationsRow from "./TeacherRecommendationsRow";
import { renderTextArea } from "components/Forms/FormsComponent";
import { TeacherComment, TeacherCommentTypeEnum } from "features/Entity/Teacher";

interface ComponentProps {
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	isReadOnly: boolean;
}

const ApplicantTeacherRecommendationForm: React.FC<ComponentProps> = ({
	register,
	errors,
	isReadOnly,
}) => {
	const isRequesting = useSelector((state: RootState) => state.teacherRdc.isRequesting);
	const teacher = useSelector((state: RootState) => state.teacherRdc.teacher);

	const [recommendationsHeadings, setRecommendationsHeadings] = useState<string[]>([]);
	const [teacherComment, setTeacherComment] = useState<TeacherComment | null | undefined>(null);
	const [teacherInEnglishComment, setTeacherInEnglishComment] = useState<TeacherComment | null | undefined>(null);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if (teacher) {
			let headings: string[] = [];

			teacher.teacherRecommendations.forEach(recommendation => {
				if (recommendation.teacherRecommendationCharacter?.label) {
					headings.push(recommendation.teacherRecommendationCharacter.label);
				}
			});

			setRecommendationsHeadings(headings);

			setTeacherComment(teacher.teacherComments.find(comm => comm.teacherCommentType.toString() === TeacherCommentTypeEnum.ApplicantMotivation.toString()));
			setTeacherInEnglishComment(teacher.teacherComments.find(comm => comm.teacherCommentType.toString() === TeacherCommentTypeEnum.ApplicantMotivationInEnglish.toString()));
		}
	}, [teacher]);

	return !isRequesting && teacher ? (
		<React.Fragment>
			<TeacherRecommendationsRow
				classes="col-md-6 col-sm-12 col-992-12"
				register={register}
				columns={[
					t("character"),
					t("excellent"),
					t("good"),
					t("average"),
					t("not_know"),
				]}
				headings={recommendationsHeadings}
				teacherRecommendations={teacher.teacherRecommendations}
				prefix={"teacherRecommendations"}
				teacher={teacher}
				isReadOnly={isReadOnly}
			/>
			{
				teacherComment !== null ?
					<React.Fragment>
						<input
							type="hidden"
							id={`teacherComments[0].id`}
							value={teacherComment?.id ?? 0}
							{...register(`teacherComments[0].id`)}
						/>
						<input
							type="hidden"
							id={`teacherComments[0].teacherCommentType`}
							value={TeacherCommentTypeEnum.ApplicantMotivation.toString()}
							{...register(`teacherComments[0].teacherCommentType`)}
						/>
						<input
							type="hidden"
							id={`teacherComments[0].teacherId`}
							value={teacherComment?.teacherId ?? teacher.id}
							{...register(`teacherComments[0].teacherId`)}
						/>
						{
							renderTextArea(
								t('teacher_applicant_motivation_letter'),
								`teacherComments[0].commentText`,
								teacherComment?.commentText ?? "",
								errors.teacherComments && errors.teacherComments[0] ? errors.teacherComments[0]?.commentText : undefined,
								register,
								false,
								"col-md-12 col-sm-12",
								false,
								isReadOnly,
								5,
								true,
							)
						}
					</React.Fragment>
					: ""
			}
			{
				teacherInEnglishComment !== null ?
					<React.Fragment>
						<input
							type="hidden"
							id={`teacherComments[1].id`}
							value={teacherInEnglishComment?.id ?? 0}
							{...register(`teacherComments[1].id`)}
						/>
						<input
							type="hidden"
							id={`teacherComments[1].teacherCommentType`}
							value={TeacherCommentTypeEnum.ApplicantMotivationInEnglish.toString()}
							{...register(`teacherComments[1].teacherCommentType`)}
						/>
						<input
							type="hidden"
							id={`teacherComments[1].teacherId`}
							value={teacherInEnglishComment?.teacherId ?? teacher.id}
							{...register(`teacherComments[1].teacherId`)}
						/>
						{
							renderTextArea(
								t('teacher_applicant_motivation_letter_english'),
								`teacherComments[1].commentText`,
								teacherInEnglishComment?.commentText ?? "",
								errors.teacherComments && errors.teacherComments[1] ? errors.teacherComments[1]?.commentText : undefined,
								register,
								false,
								"col-md-12 col-sm-12",
								false,
								isReadOnly,
								5,
								true,
							)
						}
					</React.Fragment>
					: ""
			}
		</React.Fragment>
	) : (
		<SpinnerOnTop />
	);
};

export default ApplicantTeacherRecommendationForm;
