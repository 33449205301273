import React from "react";
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch, UseFormGetValues, Control } from "react-hook-form";
import t from "components/I18N/TranslateHelpers";
import { RelSurveyQuestionTemplate, Survey } from "features/Entity/Survey/Survey";
import { QuestionTypeEnum } from "features/Entity/Survey/QuestionTypeEnum";
import QuestionMCOA from "./QuestionMCOA";
import QuestionFreeText from "./QuestionFreeText";
import QuestionMCMA from "./QuestionMCMA";
import { SurveyStatusEnum } from "features/Entity/Survey/SurveyStatusEnum";
import QuestionDate from "./QuestionDate";
import QuestionNote from "./QuestionNote";

interface ComponentProps {
	applicationFormId: string;
	survey: Survey;
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValue: UseFormGetValues<FieldValues>;
	isSurveyFormReadOnly: boolean;
	control: Control<FieldValues, any>;
}

const SurveyFormRendererDefault: React.FC<ComponentProps> = ({
	applicationFormId,
	survey,
	register,
	unregister,
	watch,
	setValue,
	getValue,
	isSurveyFormReadOnly,
	control,
}) => {
	return (
		<>
			<ul className="list-group">
				{survey &&
					<input
						type="hidden"
						id={`SURVEY-${survey.id}`}
						value={survey.id}
						{...register(`SURVEY-${survey.id}`)}
					/>
				}
				{survey &&
					survey?.surveyTemplate?.relSurveyQuestionTemplates
						.filter(rsqt => rsqt.status == SurveyStatusEnum.Active)
						.sort((a, b) => a.order <= b.order ? -1 : 1)
						.map((relSurveyQuestionTemplate: RelSurveyQuestionTemplate) => {
							if (relSurveyQuestionTemplate.questionTemplate.questionType == QuestionTypeEnum.MCOA) {
								return (
									<QuestionMCOA key={relSurveyQuestionTemplate.questionTemplate.id} survey={survey} questionTemplate={relSurveyQuestionTemplate.questionTemplate}
										register={register} unregister={unregister} watch={watch} setValue={setValue} getValue={getValue} isSurveyFormReadOnly={isSurveyFormReadOnly}/>
								)
							}
							if (relSurveyQuestionTemplate.questionTemplate.questionType == QuestionTypeEnum.MCMA) {
								return (
									<QuestionMCMA key={relSurveyQuestionTemplate.questionTemplate.id} survey={survey} questionTemplate={relSurveyQuestionTemplate.questionTemplate} register={register}
										isSurveyFormReadOnly={isSurveyFormReadOnly} />
								)
							}
							else if (relSurveyQuestionTemplate.questionTemplate.questionType == QuestionTypeEnum.FreeText) {
								return (
									<QuestionFreeText key={relSurveyQuestionTemplate.questionTemplate.id} survey={survey} questionTemplate={relSurveyQuestionTemplate.questionTemplate} register={register}
										isSurveyFormReadOnly={isSurveyFormReadOnly}/>
								)
							}
							else if (relSurveyQuestionTemplate.questionTemplate.questionType == QuestionTypeEnum.DATE) {
								return (
									<QuestionDate key={relSurveyQuestionTemplate.questionTemplate.id} survey={survey} questionTemplate={relSurveyQuestionTemplate.questionTemplate} register={register} showQuestion={true}
										isSurveyFormReadOnly={isSurveyFormReadOnly} control={control} />
								)
							}
							else if (relSurveyQuestionTemplate.questionTemplate.questionType == QuestionTypeEnum.NOTE) {
								return (
									<QuestionNote key={relSurveyQuestionTemplate.questionTemplate.id} survey={survey} questionTemplate={relSurveyQuestionTemplate.questionTemplate}
										register={register} isSurveyFormReadOnly={isSurveyFormReadOnly} />
								)
							}

						})}
			</ul>

		</>
	);
};

export default SurveyFormRendererDefault;
