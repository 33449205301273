import { badgeApprovedByWep } from "components/UtilsForPartner";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FRONT_URL } from "../../app/AppConfig";
import { RootState } from "../../app/store";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { SpinnerOnTop } from "../../components/SpinnerOnTop";
import { GetCurrentLocale, isScreen1200, translateAppFormSubLabel } from "../../components/Utils";
import { getApplicantFormApplicantName, getApplicationFormSectionUrl, getCurrentApplicationFormSection } from "../../components/UtilsForApplicationForm";
import { ApplicationFormSection, ApplicationFormSectionStatusColorEnum } from "../Entity/ApplicationFormSection";

const ApplicationFormSections: React.FC = () => {
	const locale = GetCurrentLocale();
	changeLanguage(locale);

	const isRequesting = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const sections = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSections);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);

	const [titlePage, setTitlePage] = useState<string>("");
	const currentAppForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	
	React.useEffect(() => {
		if (sections) {
			const selectedSection = getCurrentApplicationFormSection(sections);

			if (selectedSection) {
				setTitlePage(selectedSection.label);
			}
		}
	}, [sections]);

	const displaySections = () => {
		return (
			<div className="stepwizard">
				<div className="stepwizard-row setup-panel">
					{
						isRequesting ?
							<SpinnerOnTop />
							:
							sections && sections.map((sectionItem: ApplicationFormSection) => {
								const itemStatus = titlePage === sectionItem.label ? "active" : "";
								const sectionColor = 
									sectionItem.statusColor === ApplicationFormSectionStatusColorEnum.White ? "section-white" :
									sectionItem.statusColor === ApplicationFormSectionStatusColorEnum.Yellow ? "section-yellow" :
									sectionItem.statusColor === ApplicationFormSectionStatusColorEnum.Green ? "section-green" :
									sectionItem.statusColor === ApplicationFormSectionStatusColorEnum.Green_dark ? "section-green-dark" :
									sectionItem.statusColor ===	ApplicationFormSectionStatusColorEnum.Yellow_dark ? "section-yellow-dark" :
									sectionItem.statusColor === ApplicationFormSectionStatusColorEnum.Blue ? "section-blue" :
									sectionItem.statusColor === -1 ? "section-red" : null
								return (
									<div className={`stepwizard-step ${sectionColor} ${itemStatus} `} key={`${sectionItem.label}-${sectionItem.id}`}>
										<a className="border-circle rounded-circle-img" href={getApplicationFormSectionUrl(sectionItem)}>
											{
												sectionItem.imageUrl ?
													<img alt={`sectionimage-${sectionItem.label}`} src={sectionItem.label === t("finish") ? `../assets/icons/${sectionItem.imageUrl}` : FRONT_URL + sectionItem.imageUrl} width="50" height="50" />
													: <img alt={`sectionimage-${sectionItem.label}`} src={"../assets/icons/etape-1-student.png"} width="50" height="50" />
											}
										</a>
										<a className="inherit-color" href={getApplicationFormSectionUrl(sectionItem)}>
											<p>{sectionItem.label}</p>
										</a>
									</div>
								)
							})
					}
				</div>
			</div>
		)
	}


	return (
		currentAppForm  ?
		<div className="col-sm-12 applicationFormSections">
			<div className="card">
				<div className="card-header">
					<div className={isScreen1200 ? "header-top d-sm-flex justify-content-between align-items-center" : "header-top d-sm-flex align-items-center"}>
						{
							isScreen1200 ?
								<button className="btn btn-link width100 mb-0 text-left text-black" data-bs-toggle="collapse" data-bs-target={"#collapseApplicationFormSections"} aria-expanded="false" aria-controls={"collapseApplicationFormSections"}>
									<h4 className="mb-0">{t("application_form")}</h4>
									{
									 userInfo && badgeApprovedByWep(userInfo?.isSendingPartner, currentAppForm)
									}
								</button>
								:
								<>
									<h4 className="mb-0">
										{t("application_form")}
									</h4>
									{userInfo && badgeApprovedByWep(userInfo?.isSendingPartner, currentAppForm)}
								</>
						}
					</div>
					<div className="d-sm-flex justify-content-between align-items-start flex-column">
						<h6 className="mb-0">
							{currentAppForm ? `${getApplicantFormApplicantName(currentAppForm)} - ${currentAppForm.label}` : ""}
						</h6>
						<small>{currentAppForm ? translateAppFormSubLabel(currentAppForm, userInfo?.isStaff ?? false, userInfo?.isSendingPartner ?? false) : ""}</small>
					</div>
				</div>
				{
					isScreen1200 ?
						<div className="collapse" id="collapseApplicationFormSections" aria-labelledby="collapseApplicationFormSections" data-parent="#collapseApplicationFormSections">
							{displaySections()}
						</div>
						:
						displaySections()
				}
			</div>
		</div>	
		: null
	);
};

export default ApplicationFormSections;
