import React from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../app/store";
import { SpinnerOnTop } from "../../../components/SpinnerOnTop";
import { ApplicationFormSection } from "../../Entity/ApplicationFormSection";
import { getTutorial } from "../../Slice/TutorialSlice";
import { renderTutorial } from "components/UtilsTutorial";

interface ComponentProps {
    applicationFormId: string
    section: ApplicationFormSection
}

const ApplicationFormTutorial: React.FC<ComponentProps> = ({ applicationFormId, section }: ComponentProps) => {
    const isRequesting = useSelector((state: RootState) => state.tutorialRdc.isRequesting);
    const tutorial = useSelector((state: RootState) => state.tutorialRdc.tutorial);

    React.useEffect(() => {
        store.dispatch(getTutorial(applicationFormId, section.id));
    }, []
    );

    if (isRequesting) {
        return <SpinnerOnTop />;
    }

    return (
        tutorial ?
            renderTutorial(tutorial)
        : null
    );
}

export default ApplicationFormTutorial;