import React, { useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../app/RootReducer";
import { store } from "../../app/store";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { SpinnerOnTop } from "../../components/SpinnerOnTop";
import { getUserToken } from "../../components/UserUtils";
import { GetCurrentLocale, GetUrlParams, getHostnameType, isResponsive, redirectTo } from "../../components/Utils";
import { LoginFormData } from "../Entity/LoginFormData";
import { userLogin } from "../Slice/UserSlice";

const LoginPage: React.FC = () => {
	const locale = GetCurrentLocale();
	changeLanguage(locale);

	const redirectUrlRaw = GetUrlParams()["redirect"];
	const redirectUrl = redirectUrlRaw ? decodeURIComponent(redirectUrlRaw) : t("url_dashboard");

	const [showPassword, setShowPassword] = useState<boolean>(false);

	const isLoading = useSelector((state: RootState) => state.userRdc.isRequesting);
	const requestingSuccess = useSelector((state: RootState) => state.userRdc.isRequestSuccess);
	const isAuthenticated = useSelector((state: RootState) => state.userRdc.isAuthenticated);
	const requestError = useSelector((state: RootState) => state.userRdc.error);

	const userToken = getUserToken();

	const resolver: Resolver<LoginFormData> = async (values) => {
		return {
			values: values.email ? values : {},
			errors: !values.email
				? {
					email: {
						type: 'required',
						message: t("please_email"),
					},
				}
				: !values.password
					? {
						password: {
							type: 'required',
							message: t("please_pwd"),
						},
					}
					: {},
		};
	};

	const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>({ resolver });

	React.useEffect(() => {
		if (requestingSuccess && !isLoading && isAuthenticated) {
			redirectTo(redirectUrl, false);
		}
	}, [requestingSuccess]
	);

	React.useEffect(() => {
		if (userToken) {
			redirectTo(redirectUrl, false, 750);
		}
	}, []
	);

	const onSubmit = handleSubmit((formData) => store.dispatch(userLogin(formData)));

	return (
		<React.Fragment>
			<div className="container-fluid">
				<div className="row overflow-hidden">
					<div className={!isResponsive ? `col-xl-12 p-0 bg-image-home ${getHostnameType()}` : "col-xl-12 p-0"}>
						<div className="login-card">
							{
								userToken ?
									<div className="login-form">
										<h4>{t('login')}</h4>
										<h6>{t('already_logged_in')}</h6>
									</div>
									:
									<form className="login-form needs-validation" onSubmit={onSubmit}>
										<h4>{t('login')}</h4>
										<h6>{t('welcome_back')}</h6>
										{
											requestError ?
												<div className="text-danger">Invalid credentials</div>
												: ""
										}
										<div className="form-group pb-3">
											<label>{t("email_address")}</label>
											<div className="input-group">
												<span className="input-group-text">
													<i className="icon-email"></i>
												</span>
												<input
													className="form-control"
													type="email"
													placeholder="youremail@address.com"
													{...register("email")}
													disabled={isLoading}
												/>
												<div className="invalid-tooltip">
													{t("please_proper_mail")}
												</div>
											</div>
										</div>
										{errors?.email && <div className="text-danger">{errors.email.message}</div>}
										<div className="form-group pb-3">
											<label>Password</label>
											<div className="input-group">
												<span className="input-group-text">
													<i className="icon-lock"></i>
												</span>
												<input
													className="form-control"
													type={showPassword ? "text" : "password"}
													placeholder="*********"
													{...register("password")}
													disabled={isLoading}
												/>
												<div className="invalid-tooltip">{t("please_pwd")}</div>
												<div className="show-hide">
													<span className="show" onClick={() => setShowPassword(!showPassword)}> </span>
												</div>
											</div>
										</div>
										{errors?.password && <div className="text-danger">{errors.password.message}</div>}
										<div className="form-group">
											<div className="checkbox">
												<input id="checkbox1" type="checkbox" {...register("rememberMe")} />
												<label className="text-muted" htmlFor="checkbox1">
													{t('remember_password')}
												</label>
											</div>
											<a className="link" href={t("url_password_forgotten")} style={{ pointerEvents: isLoading ? "none" : "all" }}>
												{t('forgot_password')}
											</a>
										</div>
										<div className="form-group justify-content-end d-flex">
											{isLoading ? <SpinnerOnTop /> : ""}
											<button className="btn btn-primary mt-3" type="submit" disabled={isLoading}>
												{t('sign_in')}
											</button>
										</div>
									</form>
							}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default LoginPage;
