export interface SchoolTranscript
{
    id: number,
    applicationFormId: number,
    evaluationSystemType: EvaluationSystemTypeEnum|null,
    evaluationSystemComment: string|null,
    evaluationSystemScoreType: EvaluationSystemScoreTypeEnum|null,
    dateCompleteHighSchool: Date,
    nbYearsCompleteSecondary: number,
    nbYearsCompletedPriorHostCountry: number,
    applicantCurrentClassLevel: number,
    positionAtSchool: string|null,
    dateOf: Date,
    schoolId: number,
    school: School,
    schoolPeriods: SchoolPeriod[],
    nameOfSchoolOfficial: string,
    gradeAverage: string|null,
}

export interface School
{
    id: number,
    idCode: number,
    completeName: string|null,
    schoolEmail: string|null,
    streetAddress: string|null,
    postCode: string|null,
    city: string|null,
    state: string|null,
    country: string|null,
    initializedFields: string|null
}

export interface SchoolPeriod
{
    id: number,
    startDate: Date|null,
    endDate: Date|null,
    periodLabel: string|null,
    periodLevel: string|null,
    yearResults: boolean,
    relSchoolApplicationFormId: number,
    schoolPeriodTranscripts: SchoolPeriodTranscript[]
}

export interface SchoolPeriodTranscript
{
    id: number,
    subject: string|null,
    hoursByWeek: number,
    grade: string|null,
    order: number,
    schoolPeriodId: number
}

export enum EvaluationSystemTypeEnum
{
    Letters = 0,
    Score = 1,
    Other = 2
}

export enum EvaluationSystemScoreTypeEnum
{
    OutOf10 = 0,
    OutOf20 = 1,
    OutOf50 = 2,
    OutOf100 = 3
}
