import React, { useState } from "react";

export interface ChildProps {
	name: string,
	title: string,
	subtitle: string,
	body: JSX.Element,
	collapsed: boolean,
	showPageBreak?: boolean
}

const RenderCollapse: React.FC<ChildProps> = ({ name, title, body, collapsed, subtitle, showPageBreak }: ChildProps) => {

	const [collapse, setCollapse] = useState(!collapsed)

	const handleCollapse = () => {
		setCollapse(!collapse)
	}
	return (
		<>
			{
				showPageBreak ? <div className="page-break"></div> : null
			}
			<div className="card mb-3" id={"section-" + name}>
				<div className="card-header">
					<h5 className="mb-0 pl-0 title-collapse">
						<a href="#"
							className={collapse ? "btn btn-link collapsed width100" : "btn btn-link width100"}
							data-bs-toggle="collapse"
							data-bs-target={"#collapse" + name}
							aria-expanded={!collapse}
							aria-controls="collapseicon"
							onClick={() => handleCollapse()}
						>
							{title}
						</a>
						{
							subtitle ? <small>{subtitle}</small> : ""
						}
					</h5>
				</div>
				<div
					className={collapse ? "collapse show" : "collapse "}
					id={"collapse" + name}
					aria-labelledby={"collapse" + name}
					data-parent="#accordionap"
				>
					<div className="card-body">
						<div className="row rownopaddingwidth100">{body}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RenderCollapse;