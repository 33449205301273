export interface Applicant
{
  id: number,
  firstName: string|null,
  lastName: string|null,
  middleNames: string|null,
  gender: number,
  streetNumber: string|null,
  box: string|null,
  streetAddress: string|null,
  postcode: string|null,
  cityTown: string|null,
  provinceState: string|null,
  country: string|null,
  phone: string|null,
  mobile: string|null,
  email: string|null,
  cityOfBirth: string|null,
  nationality: string|null,
  codiceFiscale: string|null,
  proofOfIdCard: string|null,
  proofOfIdBirth: string|null,
  proofOfIdPassPort: string|null,
  nativeLanguage: string|null,
  initializedFields: string|null,
  siblings: Sibling[],
  avatar: string|null,
  avatarUrl: string|null,
  legalGuardianInfo: LegalGuardianInfo,
  hobbyApplicants: HobbyInfo[],
  applicantProfile: ApplicantProfile,
  hasMiddleName: boolean,
  birthDate: string|null,
  isFollowStudies: boolean|null,
  countryId: number|null,
  countryOfBirthId: number|null,
  nationalityId: number|null,
  motherTongueId: number|null,
  addressExtraInfo: string|null,
}

export interface HobbyInfo
{
  hobbyTypeName: string,
  hobbyId: number,
  hobbyName: string,
  interestType: InterestEnumType,
  isSelected: boolean
}

export enum InterestEnumType {
  Doing = 0,
  Interested = 1,
}

export interface Sibling
{
  id: number,
  firstName: string|null,
  lastName: string|null,
  siblingRelationshipType: SiblingRelationshipType,
  dob: Date,
  livingAtHome: boolean,
  siblingEducationLevelType: SiblingEducationLevelType
}

export interface LegalGuardianInfo
{
  id: number,
  spawnerType: number,
  spawnerTypeOther: string|null,
  legalGuardianInChargeType: number,
  legalguardianinchargetypeOther: string|null,
  initializedFields: string|null,
  firstLegalGuardianId: number,
  secondLegalGuardianId: number,
  firstLegalGuardian: LegalGuardian,
  secondLegalGuardian: LegalGuardian
}

export interface LegalGuardian
{
  id: number,
  firstName: string|null,
  lastName: string|null,
  dob: Date,
  job: string|null,
  gender: number,
  email: string|null,
  mobile: string|null,
  businessPhone: string|null,
  isParentLivingDifferentAddress: boolean,
  provinceState: string|null,
  initializedFields: string|null
  hasCustody: boolean,
  typeOfCustody: string,
  isMainContact: boolean,
  streetAddress: string|null,
  streetNumber: number|null,
  postcode: string|null,
  cityTown: string|null,
  country: string|null,
  countryId: number|null,
}

export interface ApplicantProfile
{
  id: number,
  otherPeopleLivingAtHome: string|null,
  emergencyContactName: string|null,
  emergencyContactHomePhoneNumber: string|null,
  emergencyContactMobilePhoneNumber: string|null,
  relationshipToTheStudent: string|null,
  emergencyContactEmail: string|null,
  otherInterests: string|null,
  isSmoker: boolean,
  isSmokerFamily: boolean,
  isSmokerReadyToStop: boolean,
  isSmokerFamilyOnlyOutside: boolean,
  otherLanguagesSpoken: string|null,
  initializedFields: string|null,
  musicalInstruments: string|null,
  isImmunizationRequirementsComplete: boolean|null,
}

// export enum SpawnerTypeEnum
// {
//   Couple = 0,
//   Separated = 1,
//   Divorced = 2,
//   Single = 3,
//   Widowed = 4,
//   Other = 5
// }

// export enum LegalGuardianInChargeEnumType 
// {
//   Father = 0,
//   Mother = 1,
//   Both = 2,
//   LegalGuardian = 3,
//   Other = 4
// }

// export enum GuardEnumType
// {
//   Exclusive = 0,
//   SuperExclusive = 1,
//   Other = 2
// }

// export enum LegalGuardianMainContactEnumType
// {
//   FirstLegalGuardian = 0,
//   SecondLegalGuardian = 1,
//   Both = 2,
//   LegalGuardian = 3,
//   Other = 4
// }

// export enum LegalGuardianEnumType
// {
//   Father = 0,
//   Mother = 1,
//   Other = 2
// }

export enum SiblingRelationshipType
{
  Brother = 0,
  Sister = 1,
  Half_Brother = 2,
  Half_Sister = 3,
  Step_Brother = 4,
  Step_Sister = 5
}

export enum SiblingEducationLevelType
{
  None = 0,
  Nursery = 1,
  Kindergarden = 2,
  Primary_School = 3,
  Secondary_School = 4,
  University_College = 5
}
