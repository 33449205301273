import React, { useMemo } from "react";
import {
	Control,
	FieldValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch,
} from "react-hook-form";
import {
	renderEmailWithPattern,
	renderInputText,
	renderRadio,
} from "../../../components/Forms/FormsComponent";
import t, { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import { genders, GetCurrentLocale, getSelectFromValue, isItalyCulture } from "../../../components/Utils";
import UploadWithPreview from "../../../components/Forms/UploadWithPreview";
import FileUploadField from "../../../components/Forms/FileUploadField";
import { Applicant } from "features/Entity/Applicant";
import { FRONT_URL } from "app/AppConfig";
import { RootState, store } from "app/store";
import { downloadApplicantFile } from "features/Slice/ApplicantSlice";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import { GetByteSize } from "components/Forms/UtilsForFormValidation";
import DatePickerField from "components/Forms/DatePickerField";
import SelectField from "components/Forms/SelectField";
import { getCountries } from "features/Slice/CountrySlice";
import { useSelector } from "react-redux";
import { getAllDataFromWep } from "components/UtilsForApplicationForm";
import { getNationalities } from "features/Slice/NationalitySlice";
import { getMotherTongues } from "features/Slice/MotherTongueSlice";
import { getMediasAllExtensions, getMediasFilesAndImages, getMediasImages } from "components/UtilsForUpload";

interface ComponentProps {
	applicationForm: ApplicationForm;
	register: UseFormRegister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	errors: { [x: string]: any };
	applicantData: Applicant;
	isReadOnly: boolean;
	setValue: UseFormSetValue<FieldValues>;
	control: Control<FieldValues, any>;
}

const ApplicantForm: React.FC<ComponentProps> = ({
	applicationForm,
	register,
	watch,
	errors,
	applicantData,
	isReadOnly,
	setValue,
	control
}) => {
	const locale = GetCurrentLocale();
	changeLanguage(locale);
	
	const allWepCountries = useSelector((state: RootState) => state.countriesRdc.countries);
	const allNationalities = useSelector((state: RootState) => state.nationalitiesRdc.nationalities);
	const allMotherTongues = useSelector((state: RootState) => state.motherTonguesRdc.motherTongues);

	React.useEffect(() => {
		store.dispatch(getCountries());
		store.dispatch(getNationalities());
		store.dispatch(getMotherTongues());
	}, []);
	
	const countrySelect = useMemo(() => {
		return getAllDataFromWep(allWepCountries)
	}, [allWepCountries]);

	const nationalitySelect = useMemo(() => {
		return getAllDataFromWep(allNationalities)
	}, [allNationalities]);

	const motherTonguesSelect = useMemo(() => {
		return getAllDataFromWep(allMotherTongues)
	}, [allMotherTongues]);

	const middleNames = watch("hasMiddleName");
	return (
		<>
			<input
				type="hidden"
				id="id"
				value={applicantData.id}
				{...register("id")}
			/>
			<div className="col-md-4 col-sm-12 xl-100">
				<div className="row">
					<UploadWithPreview
						id="avatar"
						classes="col-sm-12 col-md-12 user-profile justify-content-center d-flex"
						description=""
						label=""
						styleOfInput="rounded"
						image={
							applicantData.avatarUrl ? FRONT_URL + applicantData.avatarUrl : ""
						}
						register={register}
						disabled={isReadOnly}
						accept={getMediasImages()}
						maxSize={GetByteSize(5)}
						setValue={setValue}
					/>
					{renderInputText(
						t("first_name"),
						"firstName",
						applicantData.firstName,
						errors.firstName,
						register,
						true,
						"col-sm-12 col-md-12 mb-3 col-1024-6",
						false,
						applicantData.initializedFields?.includes("FirstName") || isReadOnly,
					)}
					{renderInputText(
						t("last_name"),
						"lastName",
						applicantData.lastName,
						errors.lastName,
						register,
						true,
						"col-sm-12 col-md-12 mb-3 col-1024-6",
						false,
						applicantData.initializedFields?.includes("LastName") || isReadOnly,
					)}
					<div className="col-sm-12 col-md-12 mb-3 col-1024-6">
						<div className="row">
						<div className="col-md-6">
						<div className="col-md-12">
							<label htmlFor={"middleNames"} className={"form-label required"}>
								{t("middle_names")}
							</label>
						</div>
							<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
								{renderRadio(
									t("yes"),
									"hasMiddleName",
									"true",
									false,
									register,
									false,
									undefined,
									[],
									applicantData.hasMiddleName,
									applicantData.initializedFields?.includes("HasMiddleName") || isReadOnly
								)}
								{renderRadio(
									t("no"),
									"hasMiddleName",
									"false",
									false,
									register,
									false,
									undefined,
									[],
									!applicantData.hasMiddleName,
									applicantData.initializedFields?.includes("HasMiddleName") || isReadOnly
								)}
							</div>
						</div>
						<div className="col-md-6">
						{middleNames === "true" ?
								renderInputText(
									"",
									"middleNames",
									applicantData.middleNames,
									errors.middleNames,
									register,
									true,
									"col-sm-12 col-md-12 pt-3 col-1024-6",
									false,
									applicantData.initializedFields?.includes("MiddleNames") || isReadOnly,
								)
							: null}
						</div>
						</div>
					</div>
					{renderEmailWithPattern(
						t("email"),
						applicantData.email,
						register,
						"email",
						true,
						errors.email,
						"col-sm-12 col-md-12 mb-3",
						applicantData.initializedFields?.includes("Email") || isReadOnly,
					)}
					<div className="mb-2">
						<DatePickerField
							id="birthDate"
							name="birthDate"
							label={t("birthdate")}
							defaultValue={
								applicantData.birthDate
									? new Date(applicantData.birthDate)
									: null
							}
							control={control}
							readonly={applicantData.initializedFields?.includes("BirthDate") || isReadOnly}
							required={true}
						/>
					</div>
					<div className="col-md-12 col-sm-12 mb-3 col-1024-6">
						<div className="col-md-12">
							<label htmlFor={"gender"} className={"form-label required"}>
								{t("gender")}
							</label>
						</div>
						<div className="col-md-12">
							<select
								{...register("gender")}
								className="form-select btn-square"
							>
								{genders.map((select) => (
									<option
										selected={applicantData.gender?.toString() === select.value}
										disabled={
											(applicantData.initializedFields?.includes("Gender") || isReadOnly) &&
											applicantData.gender?.toString() !== select.value
										}
										key={select.value}
										value={select.value}
									>
										{select.label}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-8 pt-4 col-sm-12 xl-100">
				<div className="row">
					{renderInputText(
						t("address"),
						"streetAddress",
						applicantData.streetAddress,
						errors.streetAddress,
						register,
						true,
						"col-sm-6 col-md-5 mb-3",
						false,
						applicantData.initializedFields?.includes("StreetAddress") || isReadOnly,
					)}
					{renderInputText(
						t("box"),
						"box",
						applicantData.box,
						errors.box,
						register,
						false,
						"col-sm-6 col-md-2 mb-3",
						false,
						applicantData.initializedFields?.includes("Box") || isReadOnly,
					)}
					{renderInputText(
						t("street_number"),
						"streetNumber",
						applicantData.streetNumber,
						errors.streetNumber,
						register,
						true,
						"col-sm-6 col-md-3 mb-3",
						false,
						applicantData.initializedFields?.includes("StreetNumber") || isReadOnly,
					)}
					{renderInputText(
						t("zip_code"),
						"postcode",
						applicantData.postcode,
						errors.postcode,
						register,
						true,
						"col-sm-6 col-md-2 mb-3",
						false,
						applicantData.initializedFields?.includes("Postcode") || isReadOnly,
					)}
					{renderInputText(
						t("address_info"),
						"addressExtraInfo",
						applicantData.addressExtraInfo,
						errors.addressExtraInfo,
						register,
						false,
						"col-sm-4 col-md-4 mb-3",
						false,
						applicantData.initializedFields?.includes("AddressExtraInfo") || isReadOnly,
					)}
					{renderInputText(
						t("city"),
						"cityTown",
						applicantData.cityTown,
						errors.cityTown,
						register,
						true,
						"col-sm-4 col-md-4 mb-3",
						false,
						applicantData.initializedFields?.includes("CityTown") || isReadOnly,
					)}
					
					{renderInputText(
						t("city_of_birth"),
						"cityOfBirth",
						applicantData.cityOfBirth,
						errors.cityOfBirth,
						register,
						true,
						"col-sm-4 col-md-4 mb-3",
						false,
						applicantData.initializedFields?.includes("CityOfBirth") || isReadOnly,
					)}
					{
						countrySelect.length > 0 &&
						<React.Fragment>
							<SelectField
								classes="col-sm-4 col-md-4 mb-3"
								name={`CountryId`}
								options={countrySelect}
								defaultValue={applicantData.countryId ? getSelectFromValue(applicantData.countryId, countrySelect) ?? null : null}
								multiple={false}
								label={t('country_residence')}
								required={true}
								readOnly={applicantData.initializedFields?.includes("CountryId") || isReadOnly}
								setValue={setValue}
							/>
							<SelectField
								classes="col-sm-4 col-md-4 mb-3"
								name={`CountryOfBirthId`}
								options={countrySelect}
								defaultValue={applicantData.countryOfBirthId ? getSelectFromValue(applicantData.countryOfBirthId, countrySelect) ?? null : null}
								multiple={false}
								label={t('country_birth')}
								required={true}
								readOnly={applicantData.initializedFields?.includes("CountryOfBirthId") || isReadOnly}
								setValue={setValue}
							/>
						</React.Fragment>
					}
					{renderInputText(
						t("phone"),
						"phone",
						applicantData.phone,
						errors.phone,
						register,
						false,
						"col-sm-4 col-md-4 mb-3",
						false,
						applicantData.initializedFields?.includes("Phone") || isReadOnly,
					)}
					{renderInputText(
						t("mobile"),
						"mobile",
						applicantData.mobile,
						errors.mobile,
						register,
						true,
						"col-sm-4 col-md-4 mb-3",
						false,
						applicantData.initializedFields?.includes("Mobile") || isReadOnly,
					)}
					{
						allNationalities &&
						<SelectField
							classes="col-sm-4 col-md-4 mb-3"
							name={`NationalityId`}
							options={nationalitySelect}
							defaultValue={applicantData.nationalityId ? getSelectFromValue(applicantData.nationalityId, nationalitySelect) ?? null : null}
							multiple={false}
							label={t('nationality')}
							required={true}
							readOnly={applicantData.initializedFields?.includes("NationalityId") || isReadOnly}
							setValue={setValue}
						/>
					}
					{
						allMotherTongues &&
						<SelectField
							classes="col-sm-4 col-md-4 mb-3"
							name={`MotherTongueId`}
							options={motherTonguesSelect}
							defaultValue={applicantData.motherTongueId ? getSelectFromValue(applicantData.motherTongueId, motherTonguesSelect) ?? null : null}
							multiple={false}
							label={t('native_language')}
							required={true}
							readOnly={applicantData.initializedFields?.includes("MotherTongueId") || isReadOnly}
							setValue={setValue}
						/>
					}
					<div className="col-sm-6 col-md-6 mb-3 col-50">
					<div className="col-md-12">
							<label htmlFor={"IsFollowStudies"} className={"form-label required"}>
								{t("still_follow_studies")}
							</label>
						</div>
						<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
							{renderRadio(
								t("yes"),
								"IsFollowStudies",
								"true",
								false,
								register,
								false,
								undefined,
								[],
								applicantData.isFollowStudies !== null && applicantData.isFollowStudies,
								applicantData.initializedFields?.includes("IsFollowStudies") || isReadOnly
							)}
							{renderRadio(
								t("no"),
								"IsFollowStudies",
								"false",
								false,
								register,
								false,
								undefined,
								[],
								applicantData.isFollowStudies !== null &&  !applicantData?.isFollowStudies,
								applicantData.initializedFields?.includes("IsFollowStudies") || isReadOnly
							)}
						</div>
					</div>
					{
						isItalyCulture(applicationForm) ?
							renderInputText(
								t("codice_fiscale"),
								"codiceFiscale",
								applicantData.codiceFiscale,
								errors.codiceFiscale,
								register,
								false,
								"col-sm-6 col-md-6 mb-3",
								false,
								applicantData.initializedFields?.includes("CodiceFiscale") || isReadOnly,
							)
							: null
					}
					<FileUploadField
						id="proofOfIdCard"
						label={t("proof_id_card")}
						description="You may only upload a pdf jpg png file."
						classes="col-md-6 col-sm-12 mb-3"
						required={true}
						disabled={isReadOnly}
						errorMessages=""
						isValid={false}
						name="proofOfIdCard"
						onFieldChange=""
						value={applicantData.proofOfIdCard ?? null}
						register={register}
						accept={getMediasFilesAndImages()}
						downloadFileAction={() => applicantData.proofOfIdCard ? store.dispatch(downloadApplicantFile(applicationForm.uid, applicantData.proofOfIdCard, "ProofOfIdCard")) : null}
					/>

					<FileUploadField
						id="proofOfIdBirth"
						label={t("proof_birth_certificate")}
						description="You may only upload a pdf jpg png file."
						classes="col-md-6 col-sm-12 mb-3"
						required={true}
						disabled={isReadOnly}
						errorMessages=""
						isValid={false}
						name="proofOfIdBirth"
						onFieldChange=""
						value={applicantData.proofOfIdBirth ?? null}
						register={register}
						accept={getMediasFilesAndImages()}
						downloadFileAction={() => applicantData.proofOfIdBirth ? store.dispatch(downloadApplicantFile(applicationForm.uid, applicantData.proofOfIdBirth, "ProofOfIdBirth")) : null}
					/>
					<FileUploadField
						id="proofOfIdPassPort"
						label={t("proof_id")}
						description="You may only upload a pdf jpg png file."
						classes="col-md-6 col-sm-12 mb-3"
						required={false}
						disabled={isReadOnly}
						errorMessages=""
						isValid={false}
						name="proofOfIdPassPort"
						onFieldChange=""
						value={applicantData.proofOfIdPassPort ?? null}
						register={register}
						accept={getMediasFilesAndImages()}
						downloadFileAction={() => applicantData.proofOfIdPassPort ? store.dispatch(downloadApplicantFile(applicationForm.uid, applicantData.proofOfIdPassPort, "ProofOfIdPassPort")) : null}
					/>
				</div>
			</div>
		</>
	);
};

export default ApplicantForm;
