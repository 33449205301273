import axios from "components/AxiosUtils";
import { RelAppFormExternalInfoConfiguration } from "features/Entity/ExternalInfo";
import { getUserToken } from "../../components/UserUtils";
import { FileInfo } from "features/Entity/Agreement";

export async function getExternalInfoConfigRep(applicationFormUid: string): Promise<RelAppFormExternalInfoConfiguration[]> {
    try
    {
        const response = await axios.get<RelAppFormExternalInfoConfiguration[]>(
            `/ExternalInfoConfiguration/GetRelsAppFormExternalInfoConfiguration/${applicationFormUid}`,
        );

        return response.data;
    }
    catch (err: any) 
    {
        throw err;
    }
}

export async function getExternalInfoConfigTemplateRep(externalInfoId: number, filename: string): Promise<FileInfo> {
    try
    {
        const response = await axios.get<ArrayBuffer>(
            `/ExternalInfoConfiguration/DownloadFile/${externalInfoId}`,
            {headers: {"Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        const fileInfo: FileInfo = {fileBlob: blob, filename: filename};

        return fileInfo;
    }
    catch (err: any) 
    {
        throw err;
    }
}

export async function getExternalInfoConfigUploadedFileRep(relId: number, filename: string): Promise<FileInfo> {
    try
    {
        const response = await axios.get<ArrayBuffer>(
            `/ExternalInfoConfiguration/DownloadFileUploaded/${relId}`,
            {headers: {"Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        const fileInfo: FileInfo = {fileBlob: blob, filename: filename};

        return fileInfo;
    }
    catch (err: any) 
    {
        throw err;
    }
}
