export interface ResetPasswordFormData
{
    newPassword: string;
    confirmNewPassword: string;
    resetToken: string;
    email: string;
}

export interface ResetPasswordToken
{
    email: string;
    resetToken: string;
}

export enum ResetPasswordPageType
{
    ResetPassword,
    ForgottenPassword,
    ActivateAccount
}