import React, { useMemo } from "react";
import {
	Control,
	FieldValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormUnregister,
	UseFormWatch,
} from "react-hook-form";
import {
	renderCheckbox,
	renderEmailWithPattern,
	renderInputText,
	renderRadio,
} from "../../../components/Forms/FormsComponent";
import t, { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, getSelectFromValue } from "../../../components/Utils";
import { Applicant } from "features/Entity/Applicant";
import DatePickerField from "components/Forms/DatePickerField";
import SelectField from "components/Forms/SelectField";
import { getAllDataFromWep } from "components/UtilsForApplicationForm";
import { RootState } from "app/RootReducer";
import { useSelector } from "react-redux";

interface ComponentProps {
	watch: UseFormWatch<FieldValues>;
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	errors: { [x: string]: any };
	applicantData: Applicant;
	control: Control<FieldValues, any>;
	setValue: UseFormSetValue<FieldValues>;
	isReadOnly: boolean;
}

const ApplicantLegalGuardianForm: React.FC<ComponentProps> = ({
	watch,
	register,
	unregister,
	errors,
	applicantData,
	control,
	setValue,
	isReadOnly
}) => {
	const locale = GetCurrentLocale();
	changeLanguage(locale);

	const parentAre = watch("legalGuardianInfo.spawnerType");
	const legalGuardianInChargeType = watch(
		"legalGuardianInfo.legalGuardianInChargeType",
	);

	const firstLegalGuardianDifferentAddress = watch('legalGuardianInfo.firstLegalGuardian.isParentLivingDifferentAddress')
	const secondLegalGuardianDifferentAddress = watch('legalGuardianInfo.secondLegalGuardian.isParentLivingDifferentAddress')
	const allWepCountries = useSelector((state: RootState) => state.countriesRdc.countries);

	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);

	React.useEffect(() => {
		setValue(
			"legalGuardianInfo.spawnerType",
			applicantData.legalGuardianInfo?.spawnerType !== null && applicantData.legalGuardianInfo?.spawnerType !== undefined
				? applicantData.legalGuardianInfo?.spawnerType?.toString()
				: undefined,
		);
		setValue(
			"legalGuardianInfo.legalGuardianInChargeType",
			applicantData.legalGuardianInfo?.legalGuardianInChargeType !== null && applicantData.legalGuardianInfo?.legalGuardianInChargeType !== undefined
				? applicantData.legalGuardianInfo?.legalGuardianInChargeType?.toString()
				: undefined,
		);
	}, []);

	const applicantFirstLegalGuardian =
		applicantData?.legalGuardianInfo?.firstLegalGuardian;
	const applicantSecondLegalGuardian =
		applicantData?.legalGuardianInfo?.secondLegalGuardian;


	const countrySelect = useMemo(() => {
		return getAllDataFromWep(allWepCountries)
	}, [allWepCountries]);

	return (
		<React.Fragment>
			<div className="row g-0 no-g-0-responsive">
				<input
					type="hidden"
					id="legalGuardianInfo.id"
					value={applicantData.legalGuardianInfo?.id ?? 0}
					{...register("legalGuardianInfo.id")}
				/>
				<input
					type="hidden"
					id="legalGuardianInfo.firstLegalGuardianId"
					value={applicantData.legalGuardianInfo?.firstLegalGuardianId ?? 0}
					{...register("legalGuardianInfo.firstLegalGuardianId")}
				/>
				<input
					type="hidden"
					id="legalGuardianInfo.secondLegalGuardianId"
					value={applicantData.legalGuardianInfo?.secondLegalGuardianId ?? 0}
					{...register("legalGuardianInfo.secondLegalGuardianId")}
				/>
				<div className="mb-3 col-lg-7 col-md-12 col-sm-12 col-1024-12">
					<label htmlFor="legalGuardianInfo.spawnerType" className="legalguardian-label form-label required">
						{t("parents_are")}
					</label>
					<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
						{renderRadio(
							t("couple"),
							"legalGuardianInfo.spawnerType",
							"0",
							true,
							register,
							false,
							unregister,
							[
								"legalGuardianInfo.legalGuardianInChargeType"
							],
							applicantData.legalGuardianInfo?.spawnerType === 0,
							applicantData.legalGuardianInfo?.initializedFields?.includes("SpawnerType") || isReadOnly
						)}
						{renderRadio(
							t("separated"),
							"legalGuardianInfo.spawnerType",
							"1",
							true,
							register,
							false,
							unregister,
							[
								"legalGuardianInfo.legalGuardianInChargeType"
							],
							applicantData.legalGuardianInfo?.spawnerType === 1,
							applicantData.legalGuardianInfo?.initializedFields?.includes("SpawnerType") || isReadOnly,
						)}
						{renderRadio(
							t("divorced"),
							"legalGuardianInfo.spawnerType",
							"2",
							true,
							register,
							false,
							unregister,
							[
								"legalGuardianInfo.legalGuardianInChargeType"
							],
							applicantData.legalGuardianInfo?.spawnerType === 2,
							applicantData.legalGuardianInfo?.initializedFields?.includes("SpawnerType") || isReadOnly
						)}
						{renderRadio(
							t("single_parent"),
							"legalGuardianInfo.spawnerType",
							"3",
							true,
							register,
							false,
							unregister,
							[
								"legalGuardianInfo.legalGuardianInChargeType"
							],
							applicantData.legalGuardianInfo?.spawnerType === 3,
							applicantData.legalGuardianInfo?.initializedFields?.includes("SpawnerType") || isReadOnly,
						)}
						{renderRadio(
							t("widower"),
							"legalGuardianInfo.spawnerType",
							"4",
							true,
							register,
							false,
							unregister,
							[
								"legalGuardianInfo.legalGuardianInChargeType"
							],
							applicantData.legalGuardianInfo?.spawnerType === 4,
							applicantData.legalGuardianInfo?.initializedFields?.includes("SpawnerType") || isReadOnly
						)}
						{renderRadio(
							t("other"),
							"legalGuardianInfo.spawnerType",
							"5",
							true,
							register,
							false,
							unregister,
							[
								"legalGuardianInfo.legalGuardianInChargeType"
							],
							applicantData.legalGuardianInfo?.spawnerType === 5,
							applicantData.legalGuardianInfo?.initializedFields?.includes("SpawnerType") || isReadOnly
						)}
					</div>
				</div>
				{parentAre === "5" ? (
					<div className="col-lg-5 col-md-12 mb-3 col-1024-12">
						{renderInputText(
							t("other"),
							"legalGuardianInfo.spawnerTypeOther",
							applicantData.legalGuardianInfo?.spawnerTypeOther,
							errors.legalGuardianInfo?.spawnerTypeOther,
							register,
							false,
							"",
							false,
							applicantData.legalGuardianInfo?.initializedFields?.includes("SpawnerTypeOther") || isReadOnly
						)}
					</div>
				) : null}
			</div>

			<div className="row g-0 no-g-0-responsive">
				{parentAre === "1" || parentAre === "2" ? (
					<div className="mb-3 col-lg-6 col-md-12  col-1024-12">
						<label htmlFor="legalGuardianInfo.legalGuardianInChargeType" className="legalguardian-label">
							{t("legal_custody")}
						</label>
						<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
							{renderRadio(
								t("father"),
								"legalGuardianInfo.legalGuardianInChargeType",
								"0",
								true,
								register,
								false,
								null,
								[],
								applicantData.legalGuardianInfo?.legalGuardianInChargeType === 0,
								applicantData.legalGuardianInfo?.initializedFields?.includes("LegalGuardianInChargeType") || isReadOnly
							)}
							{renderRadio(
								t("mother"),
								"legalGuardianInfo.legalGuardianInChargeType",
								"1",
								true,
								register,
								false,
								null,
								[],
								applicantData.legalGuardianInfo?.legalGuardianInChargeType === 1,
								applicantData.legalGuardianInfo?.initializedFields?.includes("LegalGuardianInChargeType") || isReadOnly
							)}
							{renderRadio(
								t("both"),
								"legalGuardianInfo.legalGuardianInChargeType",
								"2",
								true,
								register,
								false,
								unregister,
								[],
								applicantData.legalGuardianInfo?.legalGuardianInChargeType === 2,
								applicantData.legalGuardianInfo?.initializedFields?.includes("LegalGuardianInChargeType") || isReadOnly
							)}
							{renderRadio(
								t("other"),
								"legalGuardianInfo.legalGuardianInChargeType",
								"4",
								true,
								register,
								false,
								unregister,
								[],
								applicantData.legalGuardianInfo?.legalGuardianInChargeType === 4,
								applicantData.legalGuardianInfo?.initializedFields?.includes("LegalGuardianInChargeType") || isReadOnly
							)}
						</div>
					</div>
				) : null}
				{legalGuardianInChargeType === "4" ? (
					<div className="col-lg-6 col-md-12 mb-3 col-1024-12">
						{renderInputText(
							t("other"),
							"legalGuardianInfo.legalguardianinchargetypeOther",
							applicantData.legalGuardianInfo?.legalguardianinchargetypeOther,
							errors.legalGuardianInfo?.legalguardianinchargetypeOther,
							register,
							false,
							"",
							false,
							applicantData.legalGuardianInfo?.initializedFields?.includes("LegalguardianinchargetypeOther") || isReadOnly
						)}
					</div>
				) : null}
			</div>
			<div className="row width100 row-parents">
				<div className="col-md-6 col-sm-12 col-991-12 nopaddingl mb-3">
					<div className="section-parent-legal-guardian">
						{/*parent1*/}
						<div className="row g-0 no-g-0-responsive">
							<div className="col-md-12 mb-3">
								<label
									htmlFor="legalGuardianInfo.firstLegalGuardian.gender"
									className="form-label required legalguardian-label"
								>
									{t("parent_one_type")}
								</label>
								<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
									{renderRadio(
										t("female"),
										"legalGuardianInfo.firstLegalGuardian.gender",
										"0",
										true,
										register,
										false,
										null,
										[],
										applicantFirstLegalGuardian?.gender === 0,
										applicantFirstLegalGuardian?.initializedFields?.includes("Gender") || isReadOnly
									)}
									{renderRadio(
										t("male"),
										"legalGuardianInfo.firstLegalGuardian.gender",
										"1",
										true,
										register,
										false,
										null,
										[],
										applicantFirstLegalGuardian?.gender === 1,
										applicantFirstLegalGuardian?.initializedFields?.includes("Gender") || isReadOnly
									)}
									{renderRadio(
										t("prefer_not_say"),
										"legalGuardianInfo.firstLegalGuardian.gender",
										"2",
										true,
										register,
										false,
										null,
										[],
										applicantFirstLegalGuardian?.gender === 2,
										applicantFirstLegalGuardian?.initializedFields?.includes("Gender") || isReadOnly
									)}
								</div>
							</div>
							<div className="col-md-12 mb-3">
								{renderInputText(
									t("first_name"),
									"legalGuardianInfo.firstLegalGuardian.firstName",
									applicantFirstLegalGuardian?.firstName,
									errors.legalGuardianInfo?.firstLegalGuardian?.firstName,
									register,
									true,
									"",
									false,
									applicantFirstLegalGuardian?.initializedFields?.includes("FirstName") || isReadOnly
								)}
							</div>
							<div className="col-md-12 mb-3">
								{renderInputText(
									t("last_name"),
									"legalGuardianInfo.firstLegalGuardian.lastName",
									applicantFirstLegalGuardian?.lastName,
									errors.legalGuardianInfo?.firstLegalGuardian?.lastName,
									register,
									true,
									"",
									false,
									applicantFirstLegalGuardian?.initializedFields?.includes("LastName") || isReadOnly
								)}
							</div>
							<div className="col-md-12">
								{
									legalGuardianInChargeType !== "4" && parentAre !== "5" ?
										renderCheckbox(
											t("has_custody"),
											`legalGuardianInfo.firstLegalGuardian.hasCustody`,
											false,
											register,
											false,
											applicantFirstLegalGuardian?.hasCustody,
											"",
											isReadOnly
										)
										:
										renderInputText(
											t("type_custody"),
											"legalGuardianInfo.firstLegalGuardian.typeOfCustody",
											applicantFirstLegalGuardian?.typeOfCustody,
											errors.legalGuardianInfo?.firstLegalGuardian?.typeOfCustody,
											register,
											true,
											"mb-3",
											false,
											applicantFirstLegalGuardian?.initializedFields?.includes("TypeOfCustody") || isReadOnly
										)
								}
							</div>
							<div className="col-md-12 mb-3 sameHeightAsColumn">
								{
									parentAre !== "0" ?
										renderCheckbox(
											t("is_main_contact"),
											`legalGuardianInfo.firstLegalGuardian.isMainContact`,
											false,
											register,
											false,
											applicantFirstLegalGuardian?.isMainContact ?? false,
											"",
											isReadOnly
										)
										: null
								}
							</div>
							<div className="col-md-12 mb-3">
								{renderInputText(
									t("occupation"),
									"legalGuardianInfo.firstLegalGuardian.job",
									applicantFirstLegalGuardian?.job,
									errors.legalGuardianInfo?.firstLegalGuardian?.job,
									register,
									true,
									"",
									false,
									applicantFirstLegalGuardian?.initializedFields?.includes("Job") || isReadOnly
								)}
							</div>
							<div className="col-md-12 mb-3">
								<DatePickerField
									id="legalGuardianInfo.firstLegalGuardian.dob"
									name="legalGuardianInfo.firstLegalGuardian.dob"
									label={t("birthdate")}
									defaultValue={
										applicantFirstLegalGuardian?.dob
											? new Date(applicantFirstLegalGuardian.dob)
											: null
									}
									control={control}
									readonly={
										((applicantFirstLegalGuardian?.initializedFields?.includes("Dob")) ?? false) || isReadOnly
									}
									required={true}
								/>
							</div>
							<div className="col-md-12 mb-3">
								<label
									htmlFor="legalGuardianInfo.firstLegalGuardian.isParentLivingDifferentAddress"
									className="form-label required legalguardian-label"
								>
									{t("parents_live_same_address")}
								</label>
								<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
									{renderRadio(
										t("yes"),
										"legalGuardianInfo.firstLegalGuardian.isParentLivingDifferentAddress",
										true,
										true,
										register,
										false,
										null,
										[],
										applicantFirstLegalGuardian?.isParentLivingDifferentAddress,
										applicantFirstLegalGuardian?.initializedFields?.includes("IsParentLivingDifferentAddress") || isReadOnly
									)}
									{renderRadio(
										t("no"),
										"legalGuardianInfo.firstLegalGuardian.isParentLivingDifferentAddress",
										false,
										true,
										register,
										false,
										null,
										[],
										!applicantFirstLegalGuardian?.isParentLivingDifferentAddress,
										applicantFirstLegalGuardian?.initializedFields?.includes("IsParentLivingDifferentAddress") || isReadOnly
									)}
								</div>
								{
									firstLegalGuardianDifferentAddress === "true" ?
										<div className="row">
										{
											renderInputText(
												t("address"),
												"legalGuardianInfo.firstLegalGuardian.streetAddress",
												applicantFirstLegalGuardian?.streetAddress,
												errors.legalGuardianInfo?.firstLegalGuardian?.streetAddress,
												register,
												true,
												"col-md-6 pt-3",
												false,
												applicantFirstLegalGuardian?.initializedFields?.includes("StreetAddress") || isReadOnly
											)
										}
										{
											renderInputText(
												t("street_number"),
												"legalGuardianInfo.firstLegalGuardian.streetNumber",
												applicantFirstLegalGuardian?.streetNumber,
												errors.legalGuardianInfo?.firstLegalGuardian?.streetNumber,
												register,
												true,
												"col-md-3 pt-3",
												false,
												applicantFirstLegalGuardian?.initializedFields?.includes("StreetAddress") || isReadOnly
											)
										}
										{
											renderInputText(
												t("zip_code"),
												"legalGuardianInfo.firstLegalGuardian.postcode",
												applicantFirstLegalGuardian?.postcode,
												errors.legalGuardianInfo?.firstLegalGuardian?.postcode,
												register,
												true,
												"col-md-3 pt-3",
												false,
												applicantFirstLegalGuardian?.initializedFields?.includes("Postcode") || isReadOnly
											)
										}
										{
											renderInputText(
												t("city"),
												"legalGuardianInfo.firstLegalGuardian.cityTown",
												applicantFirstLegalGuardian?.cityTown,
												errors.legalGuardianInfo?.firstLegalGuardian?.cityTown,
												register,
												true,
												"col-md-6 pt-3",
												false,
												applicantFirstLegalGuardian?.initializedFields?.includes("CityTown") || isReadOnly
											)
										}
										{
											countrySelect.length > 0 &&
											<SelectField
												classes="col-md-6 pt-3"
												name={`legalGuardianInfo.firstLegalGuardian.countryId`}
												options={countrySelect}
												defaultValue={applicantFirstLegalGuardian?.countryId ? getSelectFromValue(applicantFirstLegalGuardian.countryId, countrySelect) ?? null : null}
												multiple={false}
												label={t('country')}
												required={true}
												readOnly={applicantFirstLegalGuardian?.initializedFields?.includes("CountryId") || isReadOnly}
												setValue={setValue}
											/>
										}
										</div>
										: null
								}
							</div>
							<div className="col-md-12 mb-3">
								{renderInputText(
									t("mobile_phone"),
									"legalGuardianInfo.firstLegalGuardian.mobile",
									applicantFirstLegalGuardian?.mobile,
									errors.legalGuardianInfo?.firstLegalGuardian?.mobile,
									register,
									true,
									"",
									false,
									applicantFirstLegalGuardian?.initializedFields?.includes("Mobile") || isReadOnly
								)}
							</div>
							<div className="col-md-12 mb-3">
								{renderInputText(
									t("business_phone"),
									"legalGuardianInfo.firstLegalGuardian.businessPhone",
									applicantFirstLegalGuardian?.businessPhone,
									errors.legalGuardianInfo?.firstLegalGuardian?.businessPhone,
									register,
									false,
									"",
									false,
									applicantFirstLegalGuardian?.initializedFields?.includes("BusinessPhone") || isReadOnly
								)}
							</div>
							<div className="col-md-12 mb-3">
								{renderEmailWithPattern(
									t("email"),
									applicantFirstLegalGuardian?.email,
									register,
									"legalGuardianInfo.firstLegalGuardian.email",
									true,
									errors.legalGuardianInfo?.firstLegalGuardian?.email,
									"",
									false,
									applicantFirstLegalGuardian?.initializedFields?.includes("Email") || isReadOnly
								)}
							</div>
						</div>
						{/*parent1 end*/}
					</div>
				</div>
				<div className="col-md-6 col-sm-12 col-991-12 nopaddingr">
					{parentAre === "0" ||
						parentAre === "1" && legalGuardianInChargeType !== "4" ||
						parentAre === "2" && legalGuardianInChargeType !== "4" ? (
						/*parent2*/
						<div className="section-parent-legal-guardian">
							<div className="row g-0">
								<div className="col-md-12 mb-3">
									<label
										htmlFor="legalGuardianInfo.secondLegalGuardian.gender"
										className="form-label required legalguardian-label"
									>
										{t("parent_two_type")}
									</label>
									<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
										{renderRadio(
											t("female"),
											"legalGuardianInfo.secondLegalGuardian.gender",
											"0",
											true,
											register,
											false,
											null,
											[],
											applicantSecondLegalGuardian?.gender === 0,
											applicantSecondLegalGuardian?.initializedFields?.includes("Gender") || isReadOnly
										)}
										{renderRadio(
											t("male"),
											"legalGuardianInfo.secondLegalGuardian.gender",
											"1",
											true,
											register,
											false,
											null,
											[],
											applicantSecondLegalGuardian?.gender === 1,
											applicantSecondLegalGuardian?.initializedFields?.includes("Gender") || isReadOnly
										)}
										{renderRadio(
											t("prefer_not_say"),
											"legalGuardianInfo.secondLegalGuardian.gender",
											"2",
											true,
											register,
											false,
											null,
											[],
											applicantSecondLegalGuardian?.gender === 2,
											applicantSecondLegalGuardian?.initializedFields?.includes("Gender") || isReadOnly
										)}
									</div>
								</div>
								<div className="col-md-12 mb-3">
									{renderInputText(
										t("first_name"),
										"legalGuardianInfo.secondLegalGuardian.firstName",
										applicantSecondLegalGuardian?.firstName,
										errors.legalGuardianInfo?.secondLegalGuardian?.firstName,
										register,
										true,
										"",
										false,
										applicantSecondLegalGuardian?.initializedFields?.includes("FirstName") || isReadOnly
									)}
								</div>
								<div className="col-md-12 mb-3">
									{renderInputText(
										t("last_name"),
										"legalGuardianInfo.secondLegalGuardian.lastName",
										applicantSecondLegalGuardian?.lastName,
										errors.legalGuardianInfo?.secondLegalGuardian?.lastName,
										register,
										true,
										"",
										false,
										applicantSecondLegalGuardian?.initializedFields?.includes("LastName") || isReadOnly
									)}
								</div>
								<div className="col-md-12">
									{
										renderCheckbox(
											t("has_custody"),
											`legalGuardianInfo.secondLegalGuardian.hasCustody`,
											false,
											register,
											false,
											applicantSecondLegalGuardian?.hasCustody,
											"",
											isReadOnly
										)
									}
								</div>
								<div className="col-md-12 mb-3">
									{
										parentAre !== "0" ?
											renderCheckbox(
												t("is_main_contact"),
												`isMainContactLegalGuardianMainContact`,
												false,
												register,
												false,
												applicantSecondLegalGuardian?.isMainContact,
												"",
												isReadOnly
											)
											: null
									}
								</div>
								<div className="col-md-12 mb-3">
									{renderInputText(
										t("occupation"),
										"legalGuardianInfo.secondLegalGuardian.job",
										applicantSecondLegalGuardian?.job,
										errors.legalGuardianInfo?.secondLegalGuardian?.job,
										register,
										true,
										"",
										false,
										applicantSecondLegalGuardian?.initializedFields?.includes("Job") || isReadOnly
									)}
								</div>
								<div className="col-md-12 mb-3">
									<DatePickerField
										id="legalGuardianInfo.secondLegalGuardian.dob"
										name="legalGuardianInfo.secondLegalGuardian.dob"
										label={t("birthdate")}
										defaultValue={
											applicantSecondLegalGuardian?.dob
												? new Date(applicantSecondLegalGuardian.dob)
												: null
										}
										control={control}
										readonly={
											((applicantSecondLegalGuardian?.initializedFields?.includes("Dob")) ?? false) || isReadOnly
										}
										required={true}
									/>
								</div>
								<div className="col-md-12 mb-3">
									<label
										htmlFor="legalGuardianInfo.secondLegalGuardian.isParentLivingDifferentAddress"
										className="form-label required legalguardian-label"
									>
										{t("parents_live_same_address")}
									</label>
									<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
										{renderRadio(
											t("yes"),
											"legalGuardianInfo.secondLegalGuardian.isParentLivingDifferentAddress",
											"true",
											true,
											register,
											false,
											null,
											[],
											applicantSecondLegalGuardian?.isParentLivingDifferentAddress,
											applicantSecondLegalGuardian?.initializedFields?.includes("IsParentLivingDifferentAddress") || isReadOnly
										)}
										{renderRadio(
											t("no"),
											"legalGuardianInfo.secondLegalGuardian.isParentLivingDifferentAddress",
											"false",
											true,
											register,
											false,
											null,
											[],
											!applicantSecondLegalGuardian?.isParentLivingDifferentAddress,
											applicantSecondLegalGuardian?.initializedFields?.includes("IsParentLivingDifferentAddress") || isReadOnly
										)}
									</div>
									{
										secondLegalGuardianDifferentAddress === "true" ?
										<div className="row">
										{
											renderInputText(
												t("address"),
												"legalGuardianInfo.secondLegalGuardian.streetAddress",
												applicantSecondLegalGuardian?.streetAddress,
												errors.legalGuardianInfo?.secondLegalGuardian?.streetAddress,
												register,
												true,
												"col-md-6 pt-3",
												false,
												applicantSecondLegalGuardian?.initializedFields?.includes("StreetAddress") || isReadOnly
											)
										}
										{
											renderInputText(
												t("street_number"),
												"legalGuardianInfo.secondLegalGuardian.streetNumber",
												applicantSecondLegalGuardian?.streetNumber,
												errors.legalGuardianInfo?.secondLegalGuardian?.streetNumber,
												register,
												true,
												"col-md-3 pt-3",
												false,
												applicantSecondLegalGuardian?.initializedFields?.includes("StreetAddress") || isReadOnly
											)
										}
										{
											renderInputText(
												t("zip_code"),
												"legalGuardianInfo.secondLegalGuardian.postcode",
												applicantSecondLegalGuardian?.postcode,
												errors.legalGuardianInfo?.secondLegalGuardian?.postcode,
												register,
												true,
												"col-md-3 pt-3",
												false,
												applicantSecondLegalGuardian?.initializedFields?.includes("Postcode") || isReadOnly
											)
										}
										{
											renderInputText(
												t("city"),
												"legalGuardianInfo.secondLegalGuardian.cityTown",
												applicantSecondLegalGuardian?.cityTown,
												errors.legalGuardianInfo?.secondLegalGuardian?.cityTown,
												register,
												true,
												"col-md-6 pt-3",
												false,
												applicantSecondLegalGuardian?.initializedFields?.includes("CityTown") || isReadOnly
											)
										}
										{
											countrySelect.length > 0 &&
											<SelectField
												classes="col-md-6 pt-3"
												name={`legalGuardianInfo.secondLegalGuardian.countryId`}
												options={countrySelect}
												defaultValue={applicantSecondLegalGuardian?.countryId ? getSelectFromValue(applicantSecondLegalGuardian.countryId, countrySelect) ?? null : null}
												multiple={false}
												label={t('country')}
												required={true}
												readOnly={applicantSecondLegalGuardian?.initializedFields?.includes("CountryId") || isReadOnly}
												setValue={setValue}
											/>
										}
										</div>
										: null
									}
								</div>
								<div className="col-md-12 mb-3">
									{renderInputText(
										t("mobile_phone"),
										"legalGuardianInfo.secondLegalGuardian.mobile",
										applicantSecondLegalGuardian?.mobile,
										errors.legalGuardianInfo?.secondLegalGuardian?.mobile,
										register,
										true,
										"",
										false,
										applicantSecondLegalGuardian?.initializedFields?.includes("Mobile") || isReadOnly
									)}
								</div>
								<div className="col-md-12 mb-3">
									{renderInputText(
										t("business_phone"),
										"legalGuardianInfo.secondLegalGuardian.businessPhone",
										applicantSecondLegalGuardian?.businessPhone,
										errors.legalGuardianInfo?.secondLegalGuardian
											?.businessPhone,
										register,
										false,
										"",
										false,
										applicantSecondLegalGuardian?.initializedFields?.includes("BusinessPhone") || isReadOnly
									)}
								</div>
								<div className="col-md-12 mb-3">
									{renderEmailWithPattern(
										t("email"),
										applicantSecondLegalGuardian?.email,
										register,
										"legalGuardianInfo.secondLegalGuardian.email",
										true,
										errors.legalGuardianInfo?.secondLegalGuardian?.email,
										"",
										false,
										applicantSecondLegalGuardian?.initializedFields?.includes("Email") || isReadOnly
									)}
								</div>
							</div>
							{/*endparent2*/}
						</div>
					) : null}
				</div>
			</div>
			<div className="page-break"></div>
		</React.Fragment>
	);
};

export default ApplicantLegalGuardianForm;
