import React from "react";
import {
	Control,
	FieldValues,
	useFieldArray,
	UseFormRegister,
} from "react-hook-form";
import {
	renderRadio,
	renderTextArea,
} from "./FormsComponent";
import t from "../I18N/TranslateHelpers";
import { Applicant, SiblingEducationLevelType, SiblingRelationshipType } from "features/Entity/Applicant";
import { educationLevel, isResponsive, relationship, SelectInterface } from "components/Utils";
import DatePickerField from "./DatePickerField";

interface ComponentProps {
	disabled: boolean;
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	control: Control<FieldValues, any>;
	applicantData: Applicant;
}

const AddNewFieldsRow: React.FC<ComponentProps> = ({
	disabled,
	errors,
	register,
	control,
	applicantData,
}: ComponentProps) => {
	const blankData = {
		id: 0,
		firstName: "",
		lastName: "",
		siblingRelationshipType: "",
		dob: "",
		livingAtHome: "false",
		siblingEducationLevelType: "",
	};

	const { fields, append, remove } = useFieldArray({
		control,
		name: "siblings",
	});


	React.useEffect(() => {
		if (applicantData?.siblings) {
			applicantData.siblings.forEach((sibling, index) => {
				append({
					id: sibling.id,
					firstName: sibling.firstName,
					lastName: sibling.lastName,
					siblingRelationshipType: sibling.siblingRelationshipType,
					dob: sibling.dob ? new Date(sibling.dob) : undefined,
					livingAtHome: sibling.livingAtHome ?? "false",
					siblingEducationLevelType: sibling.siblingEducationLevelType,
				});
			});
		}
	}, []);

	const renderPickerForDOB = (parentIndex: any) => {
		return (
			<DatePickerField
				id={`siblings[${parentIndex}].dob`}
				name={`siblings[${parentIndex}].dob`}
				label={isResponsive ? t("dob") : ""}
				defaultValue={
					applicantData.siblings[parentIndex]?.dob
						? new Date(applicantData.siblings[parentIndex].dob)
						: null
				}
				control={control}
				readonly={disabled}
				required={true}
				inputRequired={true}
				errors={errors}
			/>
		)
	}
	return (
		<>
			<ul>
				<div
					className={
						isResponsive ? "hidden" : "col-md-11 row"
					}
				>
					<div className="form-label col"><label className="form-label required">{t("first_name")}</label></div>
					<div className="form-label col"><label className="form-label required">{t("last_name")}</label></div>
					<div className="form-label col"><label className="form-label required">{t("relationship")}</label></div>
					<div className="form-label col"><label className="form-label required">{t("dob")}</label></div>
					<div className="form-label col"><label className="form-label required">{t("livingHome")}</label></div>
					<div className="form-label col"><label className="form-label required">{t("educationLevel")}</label></div>
				</div>

				{fields.map((datas, parentIndex: number) => {
					return (
						<li
							className={"align-items-start pb-2 row"}
							key={`siblings[${parentIndex}]`}
						>
							<div
								className={
										isResponsive ?
											"col-md-11 col-1024-12 column-count-6"
											: "col-md-11 col-1024-12 row"
								}
							>
									<input
										type="hidden"
										id={`siblings[${parentIndex}].id`}
										{...register(`siblings[${parentIndex}].id`)}
									/>
								
								<div className="col-siblings col">
									{isResponsive ? (
										<label
											className="form-label"
											htmlFor={`siblings[${parentIndex}].firstName`}
										>
											{t("first_name")}
										</label>
									) : null}
									<input
										disabled={disabled}
										className={errors.siblings && errors.siblings[parentIndex] && errors.siblings[parentIndex]?.firstName ? "form-control is-invalid" : "form-control"}
										type="text"
										key={`siblings[${parentIndex}].firstName`}
										defaultValue={applicantData.siblings[parentIndex] && applicantData.siblings[parentIndex].firstName || ""}
										id={`siblings[${parentIndex}].firstName`}
										placeholder={t("first_name")}
										{ ...register(`siblings[${parentIndex}].firstName`, { required: true }) }
									/>
								</div>
								<div className="col-siblings col">
									{isResponsive ? (
										<label
											className="form-label"
											htmlFor={`siblings[${parentIndex}].lastName`}
										>
											{t("last_name")}
										</label>
									) : null}
									<input
										disabled={disabled}
										className={errors.siblings && errors.siblings[parentIndex] && errors.siblings[parentIndex]?.lastName ? "form-control is-invalid" : "form-control"}
										type="text"
										key={`siblings[${parentIndex}].lastName`}
										defaultValue={applicantData.siblings[parentIndex] && applicantData.siblings[parentIndex].lastName || ""}
										id={`siblings[${parentIndex}].lastName`}
										placeholder={t("last_name")}
										{ ...register(`siblings[${parentIndex}].lastName`, { required: true }) }
									/>

								</div>
								<div className="col-siblings col">
									{isResponsive ? (
										<label
											className="form-label"
											htmlFor={`siblings[${parentIndex}].siblingRelationshipType`}
										>
											{t("relationship")}
										</label>
									) : null}
										<select
											{...register(`siblings[${parentIndex}].siblingRelationshipType`, { required: true })}
											className={errors.siblings && errors.siblings[parentIndex] && errors.siblings[parentIndex]?.siblingRelationshipType ? "form-select is-invalid" : "form-select"}
										>
											{relationship.map((select) => (
												<option key={select.value} value={select.value} disabled={disabled}>
													{select.label}
												</option>
											))}
										</select>
								</div>
										<div className="col-siblings col">
											{renderPickerForDOB(parentIndex)}
										</div>
									

								<div className="col-siblings col">
									{isResponsive ? (
										<label
											className="form-label"
											htmlFor={`siblings[${parentIndex}].livingAtHome`}
										>
											{t("livingHome")}
										</label>
									) : null}
									<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
										{renderRadio(
											t("yes"),
											`siblings[${parentIndex}].livingAtHome`,
											"true",
											true,
											register,
											false,
											undefined,
											[],
											applicantData.siblings[parentIndex]
												? applicantData.siblings[parentIndex].livingAtHome ?? false
												: false,
											disabled
										)}
										{renderRadio(
											t("no"),
											`siblings[${parentIndex}].livingAtHome`,
											"false",
											true,
											register,
											false,
											undefined,
											[],
											applicantData.siblings[parentIndex]
												? !applicantData.siblings[parentIndex].livingAtHome
												: false,
											disabled
										)}
									</div>
								</div>
								<div className="col-siblings col">
									{isResponsive ? (
										<label
											className="form-label"
											htmlFor={`siblings[${parentIndex}].siblingEducationLevelType`}
										>
											{t("educationLevel")}
										</label>
									) : null}

									<select
											{...register(`siblings[${parentIndex}].siblingEducationLevelType`, { required: true })}
											className={errors.siblings && errors.siblings[parentIndex] && errors.siblings[parentIndex]?.siblingEducationLevelType ? "form-control is-invalid" : "form-control"}
										>
											{educationLevel.map((select) => (
												<option key={select.value} value={select.value} disabled={disabled}
													selected={applicantData.siblings[parentIndex] ? applicantData.siblings[parentIndex].siblingEducationLevelType?.toString() === (select.value as SiblingEducationLevelType)?.toString() : false}
												>
													{select.label}
												</option>
											))}
										</select>
								</div>
							</div>
							<div className="col-md-1 col-1024-12 col-remove-row">
								<button
									type="button"
									className="btn btn-danger-outline widthauto"
									disabled={disabled}
									onClick={(e: React.MouseEvent) => {
										e.preventDefault();
										remove(parentIndex);
									}}
								>
									<i className="icofont icofont-ui-close"></i>
								</button>
							</div>
						</li>
					);
				})}
			</ul>
			<div className="row mb-3">
				<div className="col-md-12">
					<button
						type="button"
						className="btn btn-primary-outline widthauto"
						disabled={disabled}
						onClick={() => {
							append(blankData);
						}}
					>
						{t("add")}
					</button>
				</div>
			</div>
			{renderTextArea(
				t("other_people_living_at_home"),
				"applicantProfile.OtherPeopleLivingAtHome",
				applicantData.applicantProfile?.otherPeopleLivingAtHome,
				errors.applicantProfile?.otherPeopleLivingAtHome,
				register,
				false,
				"col-md-12 col-sm-12",
				false,
				applicantData.applicantProfile?.initializedFields?.includes("OtherPeopleLivingAtHome") || disabled,
				5,
				true,
			)}
		</>
	);
};

export default AddNewFieldsRow;
