import React from 'react';
import {
	customSelectStyles,
	FormatDateForUrl,
	SelectInterface,
} from "../Utils";
import t from "../I18N/TranslateHelpers";
import { GetEmailRegex } from "./UtilsForFormValidation";
import Select from "react-select";
import { UseFormRegister, FieldValues } from 'react-hook-form';

export function getSelectFromValue(
	baseValue: string | number,
	select: SelectInterface[],
): SelectInterface | null {
	const selectValue = select.find((value) => {
		return value.value.toString() === (baseValue ? baseValue.toString() : "0");
	});
	return selectValue !== undefined ? selectValue : null;
}

export const renderInputText = (
	label: string,
	name: string,
	defaultValue: string | number | null | undefined,
	errors: any,
	register: UseFormRegister<FieldValues> | undefined,
	required: boolean,
	classes: string,
	isDisabled?: boolean,
	readOnly?: boolean,
	onlyNumbers?: boolean,
) => {
	return (
		<div className={classes}>
			{
				label !== "" ? <label
					htmlFor={name}
					className={required ? "form-label required" : "form-label "}
				>
					{label}
				</label> : null
			}
			<input
				className="form-control"
				type={onlyNumbers ? "number" : "text"}
				id={name}
				defaultValue={defaultValue ?? ""}
				disabled={isDisabled}
				readOnly={readOnly}
				{...register ? { ...register(name) } : undefined}
			/>
		</div>
	);
};

export const renderEmailWithPattern = (
	label: string,
	defaultValue: string | null | undefined,
	register: UseFormRegister<FieldValues> | undefined,
	name: string,
	required: boolean,
	errors: any,
	classes: string,
	isDisabled?: boolean,
	readonly?: boolean
) => {
	return (
		<div className={classes}>
			<label className={required ? "form-label required" : "form-label"}>
				{label}
			</label>
			<div className="input-group">
				<span className="input-group-text">
					<i className="icon-email"></i>
				</span>
				<input
					readOnly={readonly ?? undefined}
					type="email"
					className={errors ? "form-control is-invalid" : "form-control"}
					defaultValue={defaultValue ?? ""}
					disabled={isDisabled}
					{...register ? { ...register(name, { pattern: GetEmailRegex() }) } : undefined}
				/>
			</div>
		</div>
	);
};

export const renderCheckbox = (
	label: string,
	name: string,
	required: boolean,
	register: UseFormRegister<FieldValues> | undefined,
	isLabelHidden: boolean,
	isChecked?: boolean,
	value?: string,
	disabled?: boolean
) => {
	return (
		<div className="inline-checkbox" key={label}>
			<label htmlFor={`${name}-${value}`} className={isLabelHidden ? "hidden" : ""}>
				<input
					disabled={disabled ?? false}
					className="checkbox_animated"
					value={value}
					id={`${name}-${value}`}
					type="checkbox"
					defaultChecked={isChecked ?? false}
					{...register ? { ...register(name) } : undefined}
				/>
				{label}
			</label>
		</div>
	);
};

export const renderRadio = (
	label: string,
	name: string,
	id: string | boolean,
	required: boolean,
	register: any,
	isLabelHidden: boolean,
	unregister?: any,
	fieldNoShow?: string[],
	isChecked?: boolean,
	readonly?: boolean
) => {
	return (
		<div className="radio radio-primary">
			<input
				id={`${name}-${id.toString()}`}
				type="radio"
				name={name}
				value={id}
				defaultChecked={isChecked}
				onClick={unregister ? () => unregister(fieldNoShow) : null}
				{...register ? { ...register(name) } : undefined}
				checked={readonly ? isChecked : undefined}
				disabled={readonly ? !isChecked : false}
			/>
			<label className={isLabelHidden ? "top-neg-13 mb-0" : "mb-0"} htmlFor={`${name}-${id.toString()}`}>
				<span className={isLabelHidden ? "hidden" : ""}>{label}</span>
			</label>
		</div>
	);
};

export const renderSimpleUploadFile = () => {
	return <input className="form-control" type="file" />;
};

export const renderTextArea = (
	label: string,
	name: string,
	defaultValue: string | number | null | undefined,
	errors: any,
	register: any,
	required: boolean,
	classes: string,
	isDisabled?: boolean,
	readOnly?: boolean,
	rows?: number,
	isLabelShow?: boolean,
) => {
	return (
		<div className={classes}>
			{
				isLabelShow ?
					label !== "" ? <label
						htmlFor={name}
						className={required ? "form-label required" : "form-label "}
					>
						{label}
					</label> : null
					: null
			}
					<textarea
						className={errors ? "form-control is-invalid" : "form-control"}
						type="text"
						id={name}
						defaultValue={defaultValue}
						disabled={isDisabled}
						readOnly={readOnly}
						rows={rows}
						{...register ? { ...register(name) } : undefined}
					/>
		</div>
	);
};


export interface MultiCheckboxRowsDataType {
	radioLabel: string;
	dataValue: string | number | null;
	dataId: number;
}
