import { RootState } from "app/RootReducer";
import { isResponsive, storeScrollPosition } from "components/Utils";
import { saveButton } from "components/UtilsForApplicationForm";
import { AddFieldRowData } from "features/Entity/AddFieldRowData";
import React from "react";
import { useState } from "react";
import {
	Control,
	FieldValues,
	useFieldArray,
	UseFormRegister,
} from "react-hook-form";
import { useSelector } from "react-redux";
import slugify from "slugify";
import { renderRadio } from "../../components/Forms/FormsComponent";
import t from "../../components/I18N/TranslateHelpers";
import SubmitButton from "./SubmitButton";

interface ComponentProps {
	disabled: boolean;
	classes: string;
	options: AddFieldRowData[];
	showNumberofLines: boolean;
	baseData: any;
	control: Control<FieldValues, any>;
	register: UseFormRegister<FieldValues>;
	prefix: string;
}

const AddNewFieldsRowDynamic: React.FC<ComponentProps> = ({
	disabled,
	classes,
	options,
	showNumberofLines,
	baseData,
	control,
	register,
	prefix,
}: ComponentProps) => {
	const [blankData] = useState({
		...getBlankData()
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: prefix,
	});

	const prefixSlug = slugify(prefix).replaceAll(".", "-");
	const visibleOptions = options.filter(option => option.type !== "hidden");
	const hiddenOptions = options.filter(option => option.type === "hidden");
	const isPostingForm = useSelector(
		(state: RootState) => state.applicationFormRdc.isRequestPost,
	);
	function getBlankData() {
		let data: any = { id: 0 };

		options.forEach(option => {
			data[option.name] = option.defaultValue;
		});

		return data;
	}

	React.useEffect(() => {
		if (fields.length === 0) {
			baseData.forEach((data: any) => {
				let objectToAppend: any = {};

				Object.keys(getBlankData()).forEach((objectKey: any) => {
					objectToAppend[objectKey] = data[objectKey];
				});

				append(objectToAppend);
			});
		}
	}, []);
	return (
		<div className={classes}>
			<ul className="row-dynamic">
				<div
					className={
						"col-md-11 column-count-" + Object.keys(visibleOptions).length
					}
				>
					{!isResponsive && visibleOptions.map((datas, index: number) => {
						return (
							<div className={index === 0 && showNumberofLines ? "form-label marginLeft30" : "form-label"} key={`${datas.name}-${index}`}>
								<label className="form-label required">{datas.label}</label>
							</div>
						);
					})}
				</div>
				{fields.map((datas, parentIndex: number) => {
					return (
						<li
							className={"align-items-end pb-2 row addNewRowDynamic g-0"}
							key={`dataFields[${parentIndex}.${parentIndex}]`}
						>
							{
								hiddenOptions.map(datas => (
									<input
										className="hidden"
										type="hidden"
										key={`${prefix}[${parentIndex}].${datas.name}`}
										defaultValue={0}
										id={`${prefix}[${parentIndex}].${datas.name}`}
										{...register(`${prefix}[${parentIndex}].${datas.name}`)}
									/>
								))
							}
							<div className={"col-md-11 column-count-" + Object.keys(visibleOptions).length}>
								{visibleOptions.map((datas, index: number) => {
									// console.log(baseData[index].subject)
									return (
										<div key={`${prefix}-${datas.name}-${index}`} className={showNumberofLines ? "d-flex" : "input-schools-print"}>
											{isResponsive ? (
												<label
													className="form-label"
													htmlFor={`${prefix}[${parentIndex}].${datas.name}`}
												>
													{datas.label}
												</label>
											) : null}
											{index === 0 ? showNumberofLines ? <div className="column-line">{parentIndex + 1}</div> : null : null}
											{datas.type === "text" ? (
												<input
													disabled={disabled}
													className="form-control"
													type="text"
													key={`${prefix}[${parentIndex}].${datas.name}`}
													defaultValue={baseData[parentIndex] && baseData[parentIndex][datas.name] ? baseData[parentIndex][datas.name] : ""}
													id={`${prefix}[${parentIndex}].${datas.name}`}
													placeholder={datas.label}
													{...register(`${prefix}[${parentIndex}].${datas.name}`)}
												/>
											) : datas.type === "number" ? (
												<input
													disabled={disabled}
													className="form-control"
													type="number"
													key={`${prefix}[${parentIndex}].${datas.name}`}
													defaultValue={baseData[parentIndex] && baseData[parentIndex][datas.name] ? baseData[parentIndex][datas.name] : ""}
													id={`${prefix}[${parentIndex}].${datas.name}`}
													placeholder={datas.label}
													{...register(`${prefix}[${parentIndex}].${datas.name}`,)}
												/>
											) : datas.type === "radio" ? (
												<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
													{datas.options?.map(
														(option: any, index: number) => {
															return (
																<React.Fragment key={index}>
																	{renderRadio(
																		option,
																		`${prefix}[${parentIndex}].${datas.name}`,
																		index.toString(),
																		false,
																		register,
																		false,
																		undefined,
																		undefined,
																		undefined,
																		disabled
																	)}
																</React.Fragment>
															);
														},
													)}
												</div>
											) : ""}
										</div>
									);
								})}
							</div>
							{
								!disabled ?
									<div className="col-md-1">
										<button
											type="button"
											className="btn btn-danger-outline widthauto"
											onClick={(e: React.MouseEvent) => {
												e.preventDefault();
												remove(parentIndex);
											}}
										>
											<i className="icofont icofont-ui-close"></i>
										</button>
									</div>
									: ""
							}
						</li>
					);
				})}
			</ul>
			{
				!disabled ?
					<div className="row pt-3">
						<div className="col-md-12">
							<button
								type="button"
								className="btn btn-primary-outline widthauto"
								onClick={() => {
									append(blankData);
								}}
							>
								{t("add")}
							</button>

							<div className="pull-right">
								{saveButton(false, (f) => storeScrollPosition(`submit-${prefixSlug}`), t('update'), `submit-${prefixSlug}`)}
							</div>
						</div>
					</div>
					: ""
			}
		</div>
	);
};

export default AddNewFieldsRowDynamic;
