import React from "react";
import {
	Control,
	FieldValues,
	UseFormRegister,
} from "react-hook-form";
import t, { changeLanguage } from "../../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../../components/Utils";
import {
	renderCheckbox,
	renderEmailWithPattern,
	renderInputText,
	renderRadio,
} from "../../../../components/Forms/FormsComponent";
import { RootState } from "app/RootReducer";
import { useSelector } from "react-redux";
import { store } from "app/store";
import { getTeacher } from "features/Slice/TeacherSlice";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { TeacherTypeEnum } from "features/Entity/Teacher";

interface ComponentProps {
	applicationFormId: string;
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	control: Control<FieldValues, any>;
	isReadOnly: boolean;
}

const ApplicantTeacherInfoForm: React.FC<ComponentProps> = ({
	applicationFormId,
	register,
	errors,
	control,
	isReadOnly
}) => {
	const isRequesting = useSelector(
		(state: RootState) => state.teacherRdc.isRequesting,
	);
	const teacher = useSelector((state: RootState) => state.teacherRdc.teacher);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getTeacher(applicationFormId, false));
	}, []);

	return (
		<div className="col-md-12">
			<div className="row">
				{
					isRequesting || !teacher ?
						<SpinnerOnTop />
						:
						<React.Fragment>
							<div className="col-md-12 mb-3 text-danger">{t("certify_report_is_true")}</div>
							<input
								type="hidden"
								id={`id`}
								value={teacher.id ?? 0}
								{...register(`id`)}
							/>
							<input
								type="hidden"
								id={`applicationFormId`}
								value={teacher.applicationFormId ?? 0}
								{...register(`applicationFormId`)}
							/>
							<input
								type="hidden"
								id={`schoolId`}
								value={teacher.schoolId ?? 0}
								{...register(`schoolId`)}
							/>
							<input
								type="hidden"
								id={`teacherType`}
								value={teacher.teacherType ?? TeacherTypeEnum.Other.toString()}
								{...register(`teacherType`)}
							/>
							{renderInputText(
								t("school_name_address"),
								"schoolNameAddress",
								teacher.schoolNameAddress ?? "",
								errors.schoolNameAddress,
								register,
								true,
								"col-md-6 col-sm-12 mb-3",
								false,
								true,
							)}
							{renderInputText(
								t("teacher_name"),
								"name",
								teacher.name,
								errors.name,
								register,
								true,
								"col-md-6 col-sm-12 mb-3",
								false,
								isReadOnly
							)}
							{renderInputText(
								t("teacher_position"),
								"positionAtSchool",
								teacher.positionAtSchool,
								errors.positionAtSchool,
								register,
								true,
								"col-md-6 col-sm-12 mb-3",
								false,
								isReadOnly
							)}
							{renderInputText(
								t("subjects_taught"),
								"subjectsTaught",
								teacher.subjectsTaught,
								errors.subjectsTaught,
								register,
								true,
								"col-md-6 col-sm-12 mb-3",
								false,
								isReadOnly
							)}
							{renderEmailWithPattern(
								t("teacher_email"),
								teacher.teacherEmail,
								register,
								"teacherEmail",
								true,
								errors.teacherEmail,
								"col-md-6 col-sm-12 mb-3",
								false,
								isReadOnly
							)}
							{renderInputText(
								t("phone"),
								"phoneNumber",
								teacher.phoneNumber,
								errors.phoneNumber,
								register,
								false,
								"col-md-6 col-sm-12 mb-3",
								false,
								isReadOnly
							)}

							<div className="col-md-12 pb-2">
								{t('give_consent')}
							</div>
							<div className="col-md-6 mb-3">
								<label htmlFor="" className="form-label required">{t("would_like_receive_email_presenting_wep")}</label>
								<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
									{renderRadio(
										t("yes"),
										"wepNewsInterest",
										"true",
										true,
										register,
										false,
										undefined,
										[],
										teacher.wepNewsInterest != null && teacher.wepNewsInterest,
										false
									)}
									{renderRadio(
										t("no"),
										"wepNewsInterest",
										"false",
										true,
										register,
										false,
										undefined,
										[],
										teacher.wepNewsInterest != null && !teacher.wepNewsInterest,
										false
									)}
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<label htmlFor="" className="form-label required">{t('subscribe_to_newsletter')}</label>
								<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
									{renderRadio(
										t("yes"),
										"isNewsletter",
										"true",
										true,
										register,
										false,
										undefined,
										[],
										teacher.isNewsletter != null && teacher.isNewsletter,
										false
									)}
									{renderRadio(
										t("no"),
										"isNewsletter",
										"false",
										true,
										register,
										false,
										undefined,
										[],
										teacher.isNewsletter != null && !teacher.isNewsletter,
										false
									)}
								</div>
							</div>
							<div className="col-md-12">
								<small>
									{t("data_never_shared", { siteurl: <a href="https://wep.org" target="_blank">wep.org</a> })}
								</small>
							</div>
						</React.Fragment>
				}
			</div>
		</div>
	);
};

export default ApplicantTeacherInfoForm;
