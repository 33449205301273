import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Letter, LetterTypeEnum } from "features/Entity/Letter";
import { getLettersRep } from "features/Repository/LetterRepository";
import { AppThunk } from "../../app/store";

interface LetterState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  letters: Letter[]|null
}

const initialState: LetterState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  letters: null
}

function startRequestRdc(state: LetterState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: LetterState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: LetterState, action: PayloadAction<Letter[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.letters = action.payload;
}

const LetterSlice = createSlice({
    name: 'Letters',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct
} = LetterSlice.actions;

export default LetterSlice.reducer;

export const getLetter = (applicationFormUid: string, letterType: LetterTypeEnum): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getLettersRep(applicationFormUid, letterType);
        dispatch(requestSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}
