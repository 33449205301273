import React from "react";
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch, UseFormGetValues, Control } from "react-hook-form";
import t from "components/I18N/TranslateHelpers";
import { Survey } from "features/Entity/Survey/Survey";
import QuestionDate from "./QuestionDate";
import QuestionMCMA from "./QuestionMCMA";
import QuestionMCOA from "./QuestionMCOA";
import QuestionNote from "./QuestionNote";
import QuestionFreeText from "./QuestionFreeText";

interface ComponentProps {
	applicationFormId: string;
	survey: Survey;
	register: UseFormRegister<FieldValues>;
	unregister: UseFormUnregister<FieldValues>;
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	getValue: UseFormGetValues<FieldValues>;
	isSurveyFormReadOnly: boolean;
	control: Control<FieldValues, any>;
}

const SurveyFormRendererImmunisationTB: React.FC<ComponentProps> = ({
	applicationFormId,
	survey,
	register,
	unregister,
	watch,
	setValue,
	getValue,
	isSurveyFormReadOnly,
	control
}) => {

	const surveyForOnly = survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
		(rsq) => rsq.questionTemplate.name == "SurveyForOnly",
	);
	const noteSurveyForOnly = surveyForOnly.length > 0 ? surveyForOnly[0] : null

	const vaccineTuberculin =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineTuberculin",
		);
	const questionVaccineTuberculin = vaccineTuberculin.length > 0 ? vaccineTuberculin[0] : null

	const testTuberculinDate =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "TestTuberculinDate",
		);
	const questionTestTuberculinDate = testTuberculinDate.length > 0 ? testTuberculinDate[0] : null

	const vaccineTBBloodTest =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineTBBloodTest",
		);
	const questionVaccineTBBloodTest = vaccineTBBloodTest.length > 0 ? vaccineTBBloodTest[0] : null

	const vaccineTBBloodTestDate =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineTBBloodTestDate",
		);
	const questionVaccineTBBloodTestDate = vaccineTBBloodTestDate.length > 0 ? vaccineTBBloodTestDate[0] : null

	const vaccineTBBloodTestIGRA =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "VaccineTBBloodTestIGRA",
		);
	const questionVaccineTBBloodTestIGRA = vaccineTBBloodTestIGRA.length > 0 ? vaccineTBBloodTestIGRA[0] : null

	const nameAdministeringPractitioner =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "NameAdministeringPractitioner",
		);
	const questionNameAdministeringPractitioner = nameAdministeringPractitioner.length > 0 ? nameAdministeringPractitioner[0] : null

	const signatureofAdministeringPractitioner =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "SignatureofAdministeringPractitioner",
		);
	const questionSignatureofAdministeringPractitioner = signatureofAdministeringPractitioner.length > 0 ? signatureofAdministeringPractitioner[0] : null

	const site =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "Site",
		);
	const questionSite = site.length > 0 ? site[0] : null

	const PPDTestResult = survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
		(rsq) => rsq.questionTemplate.name == "PPDTestResult",
	);
	const questionPPDTestResult = PPDTestResult.length > 0 ? PPDTestResult[0] : null

	const testDate = survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
		(rsq) => rsq.questionTemplate.name == "TestDate",
	);
	const questionTestDate = testDate.length > 0 ? testDate[0] : null

	const dateRead = survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
		(rsq) => rsq.questionTemplate.name == "DateRead",
	);
	const questionDateRead = dateRead.length > 0 ? dateRead[0] : null

	const induration = survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
		(rsq) => rsq.questionTemplate.name == "Induration",
	);
	const questionInduration = induration.length > 0 ? induration[0] : null

	
	const testingLocation = survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
		(rsq) => rsq.questionTemplate.name == "TestingLocation",
	);
	const questionTestingLocation = testingLocation.length > 0 ? testingLocation[0] : null
	
	const TBAlertForDeparture = survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
		(rsq) => rsq.questionTemplate.name == "TBAlertForDeparture",
	);
	const questionTBAlertForDeparture = TBAlertForDeparture.length > 0 ? TBAlertForDeparture[0] : null

	const chestXRayDate =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "ChestXRayDate",
		);
	const questionChestXRayDate = chestXRayDate.length > 0 ? chestXRayDate[0] : null

	const chestXRay =
		survey?.surveyTemplate?.relSurveyQuestionTemplates.filter(
			(rsq) => rsq.questionTemplate.name == "ChestXRay",
		);
	const questionChestXRay = chestXRay.length > 0 ? chestXRay[0] : null

	
	return (
		<>
			{
				noteSurveyForOnly !== null ?
					<QuestionNote
						key={noteSurveyForOnly.questionTemplate.id}
						survey={survey}
						questionTemplate={noteSurveyForOnly.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}

			{
				questionTBAlertForDeparture !== null ?
						<div className="alert alert-primary-opacity mb-3" role="alert">
							<QuestionNote
								key={questionTBAlertForDeparture.questionTemplate.id}
								survey={survey}
								questionTemplate={questionTBAlertForDeparture.questionTemplate}
								register={register}
								isSurveyFormReadOnly={isSurveyFormReadOnly}
							/>
					</div>
					: null
			}
			
			<div className="row g-0 questionVaccineTuberculin">
				<div className="col-md-6 nopadding d-grid">
					{
						questionVaccineTuberculin !== null ?
							<QuestionMCOA
								survey={survey}
								questionTemplate={questionVaccineTuberculin?.questionTemplate}
								register={register}
								unregister={unregister}
								watch={watch}
								setValue={setValue}
								getValue={getValue}
								isSurveyFormReadOnly={isSurveyFormReadOnly}
							/>
							: null
					}
				</div>
				<div className="col-md-6 nopadding d-grid">
					{
						questionTestTuberculinDate !== null ?
							<>
								<li className="list-group-item py-3 row d-flex g-0 align-items-center">
									<div className="col-md-6">
										<label className={questionTestTuberculinDate.questionTemplate.mandatory ? "form-label required mb-0" : "form-label mb-0"}>
											{questionTestTuberculinDate.questionTemplate.label}
										</label>
										<small className="block">{questionTestTuberculinDate.questionTemplate.description}</small>
									</div>
									<div className="col-md-6 col-radio-buttons">
										<QuestionDate
											survey={survey}
											questionTemplate={questionTestTuberculinDate?.questionTemplate}
											register={register}
											showQuestion={false}
											isSurveyFormReadOnly={isSurveyFormReadOnly}
											control={control}
										/>
									</div>
								</li>
							</>
							: null
					}
				</div>
			</div>
			<div className="row g-0 questionVaccineTBBloodTest pb-5">
				<div className="col-md-12">
					<li className="list-group-item py-1 row d-flex g-0 align-items-center bg-white">{t('or')}</li>
				</div>
				<div className="col-md-6 nopadding">
					{
						questionVaccineTBBloodTest !== null ?
							<QuestionMCOA
								survey={survey}
								questionTemplate={questionVaccineTBBloodTest?.questionTemplate}
								register={register}
								unregister={unregister}
								watch={watch}
								setValue={setValue}
								getValue={getValue}
								isSurveyFormReadOnly={isSurveyFormReadOnly}
							/>
							: null
					}
				</div>
				<div className="col-md-3 nopadding page-break-before">
					{
						questionVaccineTBBloodTestDate !== null ?

							<>
								<li className="list-group-item py-3 row d-flex g-0 align-items-center">
									<div className="col-md-6">
										<label className={questionVaccineTBBloodTestDate.questionTemplate.mandatory ? "form-label required mb-0" : "form-label mb-0"}>
											{questionVaccineTBBloodTestDate.questionTemplate.label}
										</label>
									</div>
									<div className="col-md-6 col-radio-buttons">
										<QuestionDate
											survey={survey}
											questionTemplate={questionVaccineTBBloodTestDate?.questionTemplate}
											register={register}
											showQuestion={false}
											isSurveyFormReadOnly={isSurveyFormReadOnly}
											control={control}
										/>
									</div>
								</li>
							</>
							: null
					}
				</div>
				<div className="col-md-3 nopaddingl questionVaccineTBBloodTestIGRA">
					{
						questionVaccineTBBloodTestIGRA !== null ?
							<QuestionMCMA
								survey={survey}
								questionTemplate={questionVaccineTBBloodTestIGRA?.questionTemplate}
								register={register}
								isSurveyFormReadOnly={isSurveyFormReadOnly}
								labelInline={false}
								description={""}
								withComment={true}
							/>
							: null
					}
				</div>
			</div>

			{
				questionNameAdministeringPractitioner !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionNameAdministeringPractitioner.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionSignatureofAdministeringPractitioner !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionSignatureofAdministeringPractitioner.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionSite !== null ?
					<QuestionMCOA
						survey={survey}
						questionTemplate={questionSite?.questionTemplate}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionPPDTestResult !== null ?
					<QuestionMCOA
						survey={survey}
						questionTemplate={questionPPDTestResult?.questionTemplate}
						register={register}
						unregister={unregister}
						watch={watch}
						setValue={setValue}
						getValue={getValue}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionTestDate !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionTestDate.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionDateRead !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionDateRead.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionInduration !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionInduration.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}
			{
				questionTestingLocation !== null ?
					<QuestionFreeText
						survey={survey}
						questionTemplate={questionTestingLocation.questionTemplate}
						register={register}
						isSurveyFormReadOnly={isSurveyFormReadOnly}
					/>
					: null
			}


<div className="row questionChestXRay g-0">
				<div className="col-md-6 nopadding">
					{
						questionChestXRay !== null ?
							<QuestionMCOA
								survey={survey}
								questionTemplate={questionChestXRay?.questionTemplate}
								register={register}
								unregister={unregister}
								watch={watch}
								setValue={setValue}
								getValue={getValue}
								isSurveyFormReadOnly={isSurveyFormReadOnly}
							/>
							: null
					}
				</div>
				<div className="col-md-6 nopadding questionChestXRayDate">
					{
						questionChestXRayDate !== null ?
							<>
								<li className="list-group-item py-3 row d-flex g-0 align-items-center">
									<div className="col-md-6">
										<label className={questionChestXRayDate.questionTemplate.mandatory ? "form-label required mb-0" : "form-label mb-0"}>
											{questionChestXRayDate.questionTemplate.label}
										</label>
									</div>
									<div className="col-md-6 col-radio-buttons">
										<QuestionDate
											survey={survey}
											questionTemplate={questionChestXRayDate?.questionTemplate}
											register={register}
											showQuestion={false}
											isSurveyFormReadOnly={isSurveyFormReadOnly}
											control={control}
										/>
									</div>
								</li>
							</>
							: null
					}
				</div>
			</div>
		</>
	)
};

export default SurveyFormRendererImmunisationTB;
