import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApplicationForm } from "features/Entity/ApplicationForm";
import { Pager } from "features/Entity/Pager";
import { getProgramsRep, getDurationsRep, getYearsRep, getDestinationCountrieRep, getApplicationFormsFilteredRep, getDeparturePeriodsRep, getPartnersRep } from "features/Repository/ApplicationFormSelectRepository";
import { FieldValues } from "react-hook-form";
import { AppThunk } from "../../app/store";

interface ApplicationFormSelectState {
  isRequestingPrograms: boolean,
  isRequestingDurations: boolean,
  isRequestingYears: boolean,
  isRequestingDestinationCountries: boolean,
  isRequestingApplicationForms: boolean,
  isRequestingDeparturePeriods: boolean,
  isRequestingPartners: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  programs: string[]|null,
  durations: string[]|null,
  years: number[]|null,
  destinationCountries: string[]|null,
  departurePeriods: string[]|null,
  applicationForms: Pager<ApplicationForm> | null,
  partners: string[] | null,
}

const initialState: ApplicationFormSelectState = {
  isRequestingPrograms: false,
  isRequestingDurations: false,
  isRequestingYears: false,
  isRequestingDestinationCountries: false,
  isRequestingApplicationForms: false,
  isRequestingDeparturePeriods: false,
  isRequestingPartners: false,
  isRequestFailed: false,
  error: null,
  programs: null,
  durations: null,
  years: null,
  destinationCountries: null,
  applicationForms: null,
  departurePeriods: null,
  partners: null,
}

function startRequestProgramsRdc(state: ApplicationFormSelectState) {
  state.isRequestingPrograms = true;
  state.error = null;
}

function startRequestDurationsRdc(state: ApplicationFormSelectState) {
  state.isRequestingDurations = true;
  state.error = null;
}

function startRequestYearsRdc(state: ApplicationFormSelectState) {
  state.isRequestingYears = true;
  state.error = null;
}

function startRequestDestinationCountriesRdc(state: ApplicationFormSelectState) {
  state.isRequestingDestinationCountries = true;
  state.error = null;
}

function startRequestApplicationFormsRdc(state: ApplicationFormSelectState) {
  state.isRequestingApplicationForms = true;
}

function startRequestDeparturePeriodsRdc(state: ApplicationFormSelectState) {
  state.isRequestingDeparturePeriods = true;
}

function startRequestPartnersRdc(state: ApplicationFormSelectState) {
  state.isRequestingPartners = true;
}
function requestFailedRdc(state: ApplicationFormSelectState, action: PayloadAction<AxiosError>) {
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestProgramsSuccessRdc(state: ApplicationFormSelectState, action: PayloadAction<string[]>) {
  state.isRequestingPrograms = false;
  state.error = null;
  state.programs = action.payload;
}

function requestDurationsSuccessRdc(state: ApplicationFormSelectState, action: PayloadAction<string[]>) {
  state.isRequestingDurations = false;
  state.error = null;
  state.durations = action.payload;
}

function requestYearsSuccessRdc(state: ApplicationFormSelectState, action: PayloadAction<number[]>) {
  state.isRequestingYears = false;
  state.error = null;
  state.years = action.payload;
}

function requestDestinationCountriesSuccessRdc(state: ApplicationFormSelectState, action: PayloadAction<string[]>) {
  state.isRequestingDestinationCountries = false;
  state.error = null;
  state.destinationCountries = action.payload;
}

function requestApplicationFormsSuccessRdc(state: ApplicationFormSelectState, action: PayloadAction<Pager<ApplicationForm>>) {
  state.isRequestingApplicationForms = false;
  state.error = null;
  state.applicationForms = action.payload;
}

function requestDeparturePeriodsSuccessRdc(state: ApplicationFormSelectState, action: PayloadAction<string[]>) {
  state.isRequestingDeparturePeriods = false;
  state.error = null;
  state.departurePeriods = action.payload;
}

function requestPartnersSuccessRdc(state: ApplicationFormSelectState, action: PayloadAction<string[]>) {
  state.isRequestingPartners = false;
  state.error = null;
  state.partners = action.payload;
}
const ApplicationFormSelectSlice = createSlice({
    name: 'ApplicationFormSelects',
    initialState: initialState,
    reducers: {
      requestFailedAct: requestFailedRdc,
      startRequestProgramsAct: startRequestProgramsRdc,
      startRequestDurationsAct: startRequestDurationsRdc,
      startRequestYearsAct: startRequestYearsRdc,
      startRequestDestinationCountriesAct: startRequestDestinationCountriesRdc,
      startRequestDeparturePeriodsAct: startRequestDeparturePeriodsRdc,
      requestProgramsSuccessAct: requestProgramsSuccessRdc,
      requestDurationsSuccessAct: requestDurationsSuccessRdc,
      requestYearsSuccessAct: requestYearsSuccessRdc,
      requestDestinationCountriesSuccessAct: requestDestinationCountriesSuccessRdc,
      startRequestApplicationFormsAct: startRequestApplicationFormsRdc,
      requestApplicationFormsSuccessAct: requestApplicationFormsSuccessRdc,
      requestDeparturePeriodsSuccessAct: requestDeparturePeriodsSuccessRdc,
      startRequestPartnersAct: startRequestPartnersRdc,
      requestPartnersSuccessAct: requestPartnersSuccessRdc,
    }
  }
)

export const {
  requestFailedAct,
  startRequestProgramsAct,
  startRequestDurationsAct,
  startRequestYearsAct,
  startRequestDestinationCountriesAct,
  requestProgramsSuccessAct,
  requestDurationsSuccessAct,
  requestYearsSuccessAct,
  requestDestinationCountriesSuccessAct,
  startRequestApplicationFormsAct,
  startRequestDeparturePeriodsAct,
  requestApplicationFormsSuccessAct,
  requestDeparturePeriodsSuccessAct,
  startRequestPartnersAct,
  requestPartnersSuccessAct,
} = ApplicationFormSelectSlice.actions;

export default ApplicationFormSelectSlice.reducer;

export const getPrograms = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestProgramsAct());
    const apiResponse = await getProgramsRep();
    dispatch(requestProgramsSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}

export const getDurations = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestDurationsAct());
    const apiResponse = await getDurationsRep();
    dispatch(requestDurationsSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}

export const getYears = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestYearsAct());
    const apiResponse = await getYearsRep();
    dispatch(requestYearsSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}

export const getDestinationCountries = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestDestinationCountriesAct());
    const apiResponse = await getDestinationCountrieRep();
    dispatch(requestDestinationCountriesSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}

export const getDeparturePeriods = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestDeparturePeriodsAct());
    const apiResponse = await getDeparturePeriodsRep();
    dispatch(requestDeparturePeriodsSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}

export const getApplicationFormsFiltered = (formData: FieldValues, limit: number, page: number): AppThunk => async dispatch => {
  try {
    dispatch(startRequestApplicationFormsAct());
    const apiResponse = await getApplicationFormsFilteredRep(formData, limit, page);
    dispatch(requestApplicationFormsSuccessAct(apiResponse));
  } catch (err) {
    dispatch(requestFailedAct(err as AxiosError));
  }
}
export const getPartners = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestDeparturePeriodsAct());
    const apiResponse = await getPartnersRep();
    dispatch(requestPartnersSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}