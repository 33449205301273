import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Document } from "features/Entity/Document";
import { getDocumentListRep, getDocumentRep } from "features/Repository/DocumentRepository";
import { AppThunk } from "../../app/store";
import { FileInfo } from "features/Entity/Agreement";
import { saveAs } from "file-saver";
import { AxiosError } from "axios";
import { toastAxiosError } from "components/Utils";

interface DocumentState {
  isRequestingList: boolean;
  isRequestSuccess: boolean;
  isRequestFailed: boolean;
  error: AxiosError | null;
  documentsList: Document[]|null;
}

const initialState: DocumentState = {
  isRequestingList: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  documentsList: null
}

function startRequestListRdc(state: DocumentState) {
  state.isRequestingList = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestListFailedRdc(state: DocumentState, action: PayloadAction<AxiosError>) {
  state.isRequestingList = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestElementFailedRdc(state: DocumentState, action: PayloadAction<AxiosError>) {
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestListSuccessRdc(state: DocumentState, action: PayloadAction<Document[]>) {
  state.isRequestingList = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.documentsList = action.payload;
}

function requestElementSuccessRdc(state: DocumentState, action: PayloadAction<FileInfo>) {
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;

  saveAs(action.payload.fileBlob, action.payload.filename);
}

const DocumentSlice = createSlice({
  name: 'Documents',
  initialState: initialState,
  reducers: {
    startRequestListAct: startRequestListRdc,
    requestListFailedAct: requestListFailedRdc,
    requestElementFailedAct: requestElementFailedRdc,
    requestListSuccessAct: requestListSuccessRdc,
    requestElementSuccessAct: requestElementSuccessRdc
  }
})

export const {
  startRequestListAct,
  requestListFailedAct,
  requestElementFailedAct,
  requestListSuccessAct,
  requestElementSuccessAct
} = DocumentSlice.actions;

export default DocumentSlice.reducer;

export const getDocumentsList = (applicationFormUid: string): AppThunk => async dispatch =>
{
  try
  {
    dispatch(startRequestListAct());
    const apiResponse = await getDocumentListRep(applicationFormUid);
    dispatch(requestListSuccessAct(apiResponse));
  } catch (err)
  {
    dispatch(requestListFailedAct(err as AxiosError));
  }
}

export const getDocument = (document: Document, cultureCode: string, filename: string): AppThunk => async dispatch =>
{
  try
  {
    const apiResponse = await getDocumentRep(document.documentUID, cultureCode, filename);

    dispatch(requestElementSuccessAct(apiResponse));
  } catch (err)
  {
    toastAxiosError(err as AxiosError);
    dispatch(requestElementFailedAct(err as AxiosError));
  }
}
