import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toastAxiosError } from "components/Utils";
import { saveAs } from "file-saver";
import { AppThunk } from "../../app/store";
import { Applicant } from "../Entity/Applicant";
import { getApplicantFileRep, getApplicantFormDtoRep } from "../Repository/ApplicantRepository";

interface ApplicantState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  applicantFormDto: Applicant|null
}

const initialState: ApplicantState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  applicantFormDto: null
}

function startRequestRdc(state: ApplicantState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: ApplicantState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestDtoSuccessRdc(state: ApplicantState, action: PayloadAction<Applicant>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.applicantFormDto = action.payload;
}

const ApplicantSlice = createSlice({
    name: 'Applicant',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestDtoSuccessAct: requestDtoSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestDtoSuccessAct,
  requestFailedAct
} = ApplicantSlice.actions;

export default ApplicantSlice.reducer;

export const getApplicantFormDto = (applicationFormUid: string, applicationFormSectionId: number): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getApplicantFormDtoRep(applicationFormUid, applicationFormSectionId);
        dispatch(requestDtoSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}

export const downloadApplicantFile = (applicationFormUid: string, filename: string, filetype: string): AppThunk => async dispatch =>
{
    try
    {
        const apiResponse = await getApplicantFileRep(applicationFormUid, filetype);

        saveAs(apiResponse, filename);
    } catch (err)
    {
        toastAxiosError(err as AxiosError);
        dispatch(requestFailedAct(err as AxiosError));
    }
}
