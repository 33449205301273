import { RootState } from "app/RootReducer";
import { getSurveyWidgetDisplayMode } from "components/Utils";
import { AnswerTemplate, QuestionTemplate, Survey } from "features/Entity/Survey/Survey";
import { SurveyWidgetDisplayMode } from "features/Entity/Survey/SurveyWidgetDisplayMode";
import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { useSelector } from "react-redux";
import { renderInputText, renderTextArea } from "./SurveyQuestionComponents";

interface ComponentProps {
    survey: Survey;
    questionTemplate: QuestionTemplate;
    register: UseFormRegister<FieldValues>;
    isSurveyFormReadOnly: boolean;
}

const QuestionFreeText: React.FC<ComponentProps> = ({
    survey,
    questionTemplate,
    register,
    isSurveyFormReadOnly,
}) => {
    const surveyActions = useSelector(
        (state: RootState) => state.surveyRdc.surveyActions,
    );
    function getValueText(answerTemplate: AnswerTemplate): string {
        var valueText = "";
        if (!survey) {
            return valueText;
        }
        survey.answers.forEach((answer) => {
            if ((answer.answerTemplateId == answerTemplate.id)) {
                valueText = answer.valueText;
                return;
            }
        });
        return valueText;
    }
    return (
        <li className="list-group-item py-3 row d-flex g-0 align-items-center" key={questionTemplate.code}>
            <div className="col-md-6">
                <label htmlFor={`SURVEY-${survey.id}.QUESTION-${questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionTemplate.answerTemplates[0].id}`} className={questionTemplate.mandatory ? "form-label required mb-0" : ""}>
                    {questionTemplate.label}
                </label>
                {
                    questionTemplate.description ?
                        <p>
                            <small>{questionTemplate.description}</small>
                        </p>
                        : null
                }
            </div>
            <div className="col-md-6">
                {
                    questionTemplate.answerTemplates[0].nbLines > 1 ?
                        renderTextArea(
                            `${questionTemplate.label}`,
                            `SURVEY-${survey.id}.QUESTION-${questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionTemplate.answerTemplates[0].id}`,
                            `SURVEY-${survey.id}.ANSWER-${questionTemplate.answerTemplates[0].id}`,
                            getValueText(questionTemplate.answerTemplates[0]),
                            "",
                            register,
                            false,
                            "",
                            false,
                            getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false,
                            questionTemplate.answerTemplates[0].nbLines,
                            false,
                            questionTemplate.answerTemplates[0].nbMaxCharacters
                        )
                        :
                        renderInputText(
                            `${questionTemplate.label}`,
                            `SURVEY-${survey.id}.QUESTION-${questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionTemplate.answerTemplates[0].id}`,
                            `SURVEY-${survey.id}.ANSWER-${questionTemplate.answerTemplates[0].id}`,
                            getValueText(questionTemplate.answerTemplates[0]),
                            "",
                            register,
                            false,
                            "",
                            false,
                            getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false,
                            false,
                            questionTemplate.answerTemplates[0].nbMaxCharacters
                        )
                }
            </div>
        </li>
    );
};

export default QuestionFreeText;
