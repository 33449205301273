import React  from 'react';
import Sidebar from "../features/Layout/Sidebar";
import { changeLanguage } from "../components/I18N/TranslateHelpers";
import { GetCurrentLocale, pathname, pathnamesWithNoHeaders } from "../components/Utils";

function AppSidebar() {
  const locale = GetCurrentLocale();
	changeLanguage(locale);
  return (
    !pathnamesWithNoHeaders().includes(pathname) ? <Sidebar/> : null
  );
}
export default AppSidebar;
