import axios  from "components/AxiosUtils";
import { Tutorial } from "../Entity/Tutorial";

export async function getTutorialRep(applicationFormId: string, sectionId: number): Promise<Tutorial | null> {
    try {
        const response = await axios.get<Tutorial>(
            `/Tutorial/${applicationFormId}/GetForApplicationFormAndSection/${sectionId}`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}

export async function getTutorialFileRep(applicationFormUid: string, sectionId: number): Promise<Blob>
{
    try
    {
        const response = await axios.get<ArrayBuffer>(
            `/Tutorial/DownloadFile/${applicationFormUid}?sectionId=${sectionId}`,
            {headers: {"Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        return blob;
    }
    catch (err: any)
    {
        throw err;
    }
}


export async function getDashboardTutorialRep(applicationFormUID: string): Promise<Tutorial | null> {
    try {
        const response = await axios.get<Tutorial>(
            `/Tutorial/GetForDashboard/${applicationFormUID}`,
        );

        return response.data;
    }
    catch (err: any) {
        throw err;
    }
}
