import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { SchoolTranscript } from "features/Entity/School";
import { getSchoolRep } from "features/Repository/SchoolRepository";
import { AppThunk } from "../../app/store";

interface SchoolState {
  isRequesting: boolean;
  isRequestSuccess: boolean;
  isRequestTypesSuccess: boolean;
  isRequestFailed: boolean;
  error: AxiosError | null;
  schoolTranscript: SchoolTranscript|null;
}

const initialState: SchoolState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestTypesSuccess: false,
  isRequestFailed: false,
  error: null,
  schoolTranscript: null
}

function startRequestRdc(state: SchoolState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
  state.schoolTranscript = null;
}

function requestFailedRdc(state: SchoolState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: SchoolState, action: PayloadAction<SchoolTranscript|null>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.schoolTranscript = action.payload;
}

const SchoolSlice = createSlice({
    name: 'Schools',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct
} = SchoolSlice.actions;

export default SchoolSlice.reducer;

export const getSchool = (applicationFormUid: string): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getSchoolRep(applicationFormUid);
        dispatch(requestSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}
