import React  from 'react';
import Footer from "../features/Layout/Footer";
import t, { changeLanguage } from "../components/I18N/TranslateHelpers";
import { GetCurrentLocale, pathname, pathnamesWithNoHeaders } from "../components/Utils";

function AppFooter() {
  const locale = GetCurrentLocale();
	changeLanguage(locale);
  return (
    !pathnamesWithNoHeaders().includes(pathname) ? <Footer/> : null
  );
}
export default AppFooter;
