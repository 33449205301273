import { listApplicationForms } from "components/UtilsForApplicationForm";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, SelectInterface } from "../../components/Utils";
import { RootState, store } from "app/store";
import { getDashboardApplicationFormList } from "features/Slice/ApplicationFormSlice";
import { ApplicationForm, ApplicationFormDashboardTab, DashboardSectionTypeEnum } from "features/Entity/ApplicationForm";
import SelectField from "components/Forms/SelectField";
import { SingleValue } from "react-select";
import { getDeparturePeriods, getYears } from "features/Slice/ApplicationFormSelectSlice";
import { SpinnerOnTop } from "components/SpinnerOnTop";

const PartnerDashboardApplicationFormOverview: React.FC = () => {

    const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
    const dashboardApplicationForms = useSelector((state: RootState) => state.applicationFormRdc.applicationFormsInDashboard);
    const isRequestingYears = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingYears);
    const departureYears = useSelector((state: RootState) => state.applicationFormSelectRdc.years);
    const isRequestingDeparturePeriods = useSelector((state: RootState) => state.applicationFormSelectRdc.isRequestingDeparturePeriods);
    const departurePeriods = useSelector((state: RootState) => state.applicationFormSelectRdc.departurePeriods);
    const isRequestingAppForms = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
    const [currentTabProgressPax, setCurrentTabProgressPax] = useState('tab-empty-pax');
    const [currentTabProgressPartner, setCurrentTabProgressPartner] = useState('tab-submitted-partner');
    const [currentTabProgressWep, setCurrentTabProgressWep] = useState('tab-submitted-to-wep');
    const appFormsSectionsButtons = [
        {label: t('active_student'), value: DashboardSectionTypeEnum.ActiveWithStudent},
        {label: t('active_partner', {userLastName: userInfo?.lastname ?? t("partner")}), value: DashboardSectionTypeEnum.ActiveWithPartner},
        {label: t('active_wep'), value: DashboardSectionTypeEnum.ActiveWithWep}
    ];

    const [yearSelected, setYearSelected] = useState<number|null>(null);
    const [departurePeriodSelected, setDeparturePeriodSelected] = useState<string|null>(null);
    const [selectedSection, setSelectedSection]= useState<DashboardSectionTypeEnum|null>(DashboardSectionTypeEnum.ActiveWithStudent);
    const [activeSection, setActiveSection] = useState<DashboardSectionTypeEnum|null>(DashboardSectionTypeEnum.ActiveWithStudent);
    const titleOfActiveTab =  appFormsSectionsButtons.filter((a) => a.value === activeSection)
    const yearsSelect: SelectInterface[] = useMemo(() => departureYears ? departureYears.map(depYear => {return {label: depYear.toString(), value: depYear}}) : [], [departureYears]);
    const departurePeriodsSelect: SelectInterface[] = useMemo(() => departurePeriods ? departurePeriods.map(depPeriod => {return {label: depPeriod, value: depPeriod}}) : [], [departurePeriods]);
    
    React.useEffect(() => {
        const locale = GetCurrentLocale();
        changeLanguage(locale);
        store.dispatch(getDeparturePeriods());
        store.dispatch(getYears());
        searchAppForms(DashboardSectionTypeEnum.ActiveWithStudent)
    }, []);

    const progressPax: ApplicationFormDashboardTab[] = [
        {
            name: 'tab-empty-pax',
            titleTab: t('empty'),
            description: t('application_forms_empty_by_pax'),
            listName: "empty",
            indexName: "pax-empty"
        },
        {
            name: 'tab-completed-pax',
            titleTab: t('completed'),
            description: t('application_forms_completed_by_pax'),
            listName: "completed",
            indexName: "pax-completed"
        },
        {
            name: 'tab-incomplete-pax',
            titleTab: t('incomplete'),
            description: t('application_forms_incomplete_by_pax'),
            listName: "incomplete",
            indexName: "pax-incomplete"
        },
    ];

    const progressPartner: ApplicationFormDashboardTab[] = [
        {
            name: 'tab-submitted-partner',
            titleTab: t('submitted'),
            description: t('application_forms_submitted_by_pax'),
            listName: "submitted",
            indexName: "partner-progress"
        },
        {
            name: 'tab-incomplete-by-wep',
            titleTab: t('incomplete'),
            description: t('application_forms_incomplete_by_partner'),
            listName: "incomplete",
            indexName: "partner-incomplete"
        },
        {
            name: 'tab-approved-partner',
            titleTab: t('approved_by') + ' partner',
            description: t('application_forms_approved_by_partner'),
            listName: "approved",
            indexName: "partner-approved"
        },
    ];

    const progressWep: ApplicationFormDashboardTab[] = [
        {
            name: 'tab-submitted-to-wep',
            titleTab: t('submitted') + " to WEP",
            description: t('application_forms_submitted_to_wep'),
            listName: "submittedToWep",
            indexName: "wep-submitted"
        },
        {
            name: 'tab-received-by-wep',
            titleTab: t('received') + " by WEP",
            description: t('application_forms_received_by_wep'),
            listName: "receivedByWep",
            indexName: "wep-received"
        },
        {
            name: 'tab-incomplete-by-wep',
            titleTab: t('incomplete_by') + " WEP",
            description: t('application_forms_incomplete_by_wep'),
            listName: "incompleteByWep",
            indexName: "wep-incomplete"
        },
        {
            name: 'tab-approved-by-wep',
            titleTab: t('approved_by') + " WEP",
            description: t('application_forms_approved_by_wep'),
            listName: "approvedByWep",
            indexName: "wep-approved"
        },
    ];

    const renderTabsStatus = (arrayData: ApplicationFormDashboardTab[], currentTabStatus: string, setCurrentTabStatus: React.Dispatch<React.SetStateAction<string>>) => {
        return (
            <>
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {
                            arrayData.map((tab, index: number) => {
                                return (
                                    <button onClick={() => setCurrentTabStatus(tab.name)} key={index} className={tab.name === currentTabStatus ? "nav-link active" : "nav-link"} id={tab.name + "-tab"} data-bs-toggle="tab" data-bs-target={"#" + tab.name + "-pax"} type="button" role="tab" aria-controls={tab.name} aria-selected="false">
                                        {tab.titleTab}
                                    </button>
                                )
                            })
                        }
                    </div>
                </nav>
                <div className="tab-content" id="tab-overview-partner">
                    {
                        arrayData.map((tab, index: number) => {
                            return (
                                <div key={index} className={tab.name === currentTabStatus ? "tab-pane fade show active" : "tab-pane fade"} id={tab.name} role="tabpanel" aria-labelledby={tab.name + "-tab"}>
                                    <div className="row g-0 align-items-center">
                                        <div className="col-md-10 px-2 py-1">
                                            {tab.description}
                                        </div>
                                    </div>
                                    <ul className="list-applicationforms">
                                        {
                                            dashboardApplicationForms[tab.indexName]
                                            && dashboardApplicationForms[tab.indexName].length > 0 ?
                                            dashboardApplicationForms[tab.indexName].map((applicationForm: ApplicationForm) =>
                                                    listApplicationForms(applicationForm, userInfo)
                                                )
                                                : <span className="py-2 block ml-2">{t('no_result')}</span>
                                        }
                                    </ul>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }

    const searchAppForms = (currentTab: DashboardSectionTypeEnum) => {
        setActiveSection(currentTab);
        const sectionDetails = currentTab === DashboardSectionTypeEnum.ActiveWithStudent ?
                                    progressPax
                                :
                                currentTab === DashboardSectionTypeEnum.ActiveWithPartner ?
                                    progressPartner
                                :
                                currentTab === DashboardSectionTypeEnum.ActiveWithWep ?
                                    progressWep
                                : null;

        if(sectionDetails)
        {   
            for(const sectionDetail of sectionDetails)
            {
                store.dispatch(getDashboardApplicationFormList(yearSelected ?? 0, sectionDetail.listName, departurePeriodSelected, currentTab, sectionDetail.indexName));
            }
        }
    }
    
    return (
        <>
            <div className="dashboard-2-main col-xl-12">
                <div className="card income-card pb-4 overflowVisible">
                    <div className="card-header pb-0">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h4 className="mb-0"> {t('summary_of_participant_applications')} </h4>
                            </div>
                            <div className="col-md-12">
                                <div className="card noborderl noborderr py-2 my-3">
                                    <div className="row">
                                        <div className="col-md-3 inline-flex align-items-center">
                                            <div className="col-md-6">
                                                <label className="mb-0"> {t('year_departure')} </label>
                                            </div>
                                            <div className="col-md-6">
                                                {
                                                    isRequestingYears ? <div className="small-spinner"><SpinnerOnTop/></div>
                                                    :
                                                    <SelectField
                                                        classes=""
                                                        name="departureYear"
                                                        options={yearsSelect}
                                                        defaultValue={null}
                                                        multiple={false}
                                                        label={""}
                                                        required={false}
                                                        readOnly={false}
                                                        customOnChange={newValue => {setYearSelected((newValue as SingleValue<SelectInterface>)?.value)}}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-3 inline-flex align-items-center">
                                            <div className="col-md-6"><label className="mb-0">{t('departure_period')} </label></div>
                                            <div className="col-md-6">
                                                {
                                                    isRequestingDeparturePeriods ? <div className="small-spinner"><SpinnerOnTop/></div>
                                                    :
                                                    <SelectField
                                                        classes=""
                                                        name="departurePeriod"
                                                        options={departurePeriodsSelect}
                                                        defaultValue={null}
                                                        multiple={false}
                                                        label={""}
                                                        required={false}
                                                        readOnly={false}
                                                        customOnChange={newValue => {setDeparturePeriodSelected((newValue as SingleValue<SelectInterface>)?.value)}}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4 inline-flex align-items-center">
                                            <div className="col-md-5">
                                                <label className="mb-0 form-label required"> {t('choose_section')} </label>
                                            </div>
                                            <div className="col-md-7">
                                                <SelectField
                                                    classes=""
                                                    name="appFormsSectionsButtons"
                                                    options={appFormsSectionsButtons}
                                                    defaultValue={appFormsSectionsButtons[0]}
                                                    multiple={false}
                                                    label={""}
                                                    required={false}
                                                    readOnly={false}
                                                    customOnChange={newValue => {setSelectedSection((newValue as SingleValue<SelectInterface>)?.value)}}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" disabled={selectedSection === null || selectedSection === undefined} className="pull-right btn btn-primary small d-flex align-items-center" onClick={() => selectedSection !== null && selectedSection !== undefined ? searchAppForms(selectedSection) : undefined}>
                                                <i className="icon-search mr-2"></i>{ t('search') }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <h4>{titleOfActiveTab[0].label}</h4>
                        {
                            isRequestingAppForms ? <SpinnerOnTop/> :
                            activeSection === DashboardSectionTypeEnum.ActiveWithStudent ?
                                renderTabsStatus(progressPax, currentTabProgressPax, setCurrentTabProgressPax)
                                :
                                activeSection === DashboardSectionTypeEnum.ActiveWithPartner ?
                                    renderTabsStatus(progressPartner, currentTabProgressPartner, setCurrentTabProgressPartner) :
                                    activeSection === DashboardSectionTypeEnum.ActiveWithWep ?
                                        renderTabsStatus(progressWep, currentTabProgressWep, setCurrentTabProgressWep)
                                        : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartnerDashboardApplicationFormOverview;
