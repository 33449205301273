import React from 'react';
import DashboardContainer from "../features/Dashboard/DashboardContainer"
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ApplicationFormContainer from "../features/ApplicationForm/ApplicationFormContainer";
import VirtualFolder from "../features/Pages/VirtualFolder";
import Documents from "../features/Pages/Documents";
import { GetCurrentLocale, updateTitle, pathname, pathnamesWithNoHeaders, updateFavicon } from "../components/Utils";
import { getUserToken } from "../components/UserUtils";
import { changeLanguage } from "../components/I18N/TranslateHelpers";
import { ToastContainer } from 'react-toastify';
import ApplicationFormSelectionPage from 'features/Pages/ApplicationFormSelectionPage';
import { store } from './store';
import { userLogout } from 'features/Slice/UserSlice';
import CreateApplicationForm from 'features/ApplicationForm/Sections/CreateApplicationForm';
import Toasts from 'components/Toasts/Toasts';

function App() {
  const locale = GetCurrentLocale();
  changeLanguage(locale);

  updateTitle();
  updateFavicon();

  if(!getUserToken() && !pathnamesWithNoHeaders().includes(pathname))
  {
    store.dispatch(userLogout(true));
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
			  <Toasts />
        <Routes>
          <Route path="/dashboard" element={<DashboardContainer />}></Route>
          <Route path="/application-form" element={<ApplicationFormSelectionPage />}></Route>
          <Route path="/application-form/*" element={<ApplicationFormContainer />}></Route>
          <Route path="/virtual-folder" element={<VirtualFolder />}></Route>
          <Route path="/documents" element={<Documents />}></Route>
          <Route path="/create-application-form" element={<CreateApplicationForm />}></Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
export default App;
