import axios  from "components/AxiosUtils";
import { ApplicationFormTemplate } from "features/Entity/ApplicationForm";

export async function getApplicationFormTemplatesRep(programType: number): Promise<ApplicationFormTemplate[]>{
    try{
        const response = await axios.get<ApplicationFormTemplate[]>(
            `/ApplicationFormTemplate?programType=${programType}`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}
