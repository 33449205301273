import { RootState } from "app/RootReducer";
import DatePickerField from "components/Forms/DatePickerField";
import { getSurveyWidgetDisplayMode, getValueText } from "components/Utils";
import { QuestionTemplate, Survey } from "features/Entity/Survey/Survey";
import { SurveyWidgetDisplayMode } from "features/Entity/Survey/SurveyWidgetDisplayMode";
import React from "react";
import { Control, FieldValues, UseFormRegister } from "react-hook-form";
import { useSelector } from "react-redux";

interface ComponentProps {
    survey: Survey;
    questionTemplate: QuestionTemplate;
    register: UseFormRegister<FieldValues>;
    showQuestion: boolean,
    isSurveyFormReadOnly: boolean;
    control: Control<FieldValues, any>;
}

const QuestionDate: React.FC<ComponentProps> = ({
    survey,
    questionTemplate,
    register,
    showQuestion,
    isSurveyFormReadOnly,
    control,
}) => {
    const surveyActions = useSelector(
        (state: RootState) => state.surveyRdc.surveyActions,
    );
    const datepicker = () => {
        return (
            <DatePickerField
                id={`SURVEY-${survey.id}.QUESTION-${questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionTemplate.answerTemplates[0].id}`}
                name={`SURVEY-${survey.id}.QUESTION-${questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionTemplate.answerTemplates[0].id}`}
                label={""}
                defaultValue={getValueText(questionTemplate.answerTemplates[0], survey) !== "" ? new Date(getValueText(questionTemplate.answerTemplates[0], survey)) : null}
                control={control}
                readonly={getSurveyWidgetDisplayMode(surveyActions, survey, isSurveyFormReadOnly) === SurveyWidgetDisplayMode.ReadOnly ? true : false}
                required={true}
            />
        )
    }
    return (
        showQuestion ?
            <li className="list-group-item py-3 row d-flex g-0 align-items-center" key={questionTemplate.code}>
                <div className="col-md-6">
                    <label htmlFor={`SURVEY-${survey.id}.QUESTION-${questionTemplate.id}-SURVEY-${survey.id}-FREETEXT-${questionTemplate.answerTemplates[0].id}`} className={questionTemplate.mandatory ? "form-label required" : "form-label"}>
                        {questionTemplate.label}
                        {
                            questionTemplate.description ?
                                <p>
                                    <small>{questionTemplate.description}</small>
                                </p>
                                : null
                        }
                    </label>
                </div>
                <div className="col-md-6">
                    {datepicker()}
                </div>
            </li>
            :
            datepicker()
    );
};

export default QuestionDate;
