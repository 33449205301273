import React, { useState } from "react";
import t, { changeLanguage } from "../../../components/I18N/TranslateHelpers";
import { applicationFormStatusByUser, GetCurrentLocale } from "../../../components/Utils";
import { useSelector } from "react-redux";
import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { ApplicationFormSection } from "features/Entity/ApplicationFormSection";
import { SectionStaffInfo } from "features/Entity/SectionStaffInfo";
import SubmitButton from "components/Forms/SubmitButton";
import { postApplicationFormSectionValidationStatus } from "features/Slice/ApplicationFormSlice";
import { ApplicationForm, ProgramTypeEnum } from "features/Entity/ApplicationForm";
import { showTextCommentPartner } from "components/UtilsForPartner";

interface ComponentProps {
	applicationForm: ApplicationForm;
	section: ApplicationFormSection;
}

const SectionStaffManagement: React.FC<ComponentProps> = ({
	applicationForm,
	section
}) => {
	const isRequesting = useSelector((state: RootState) => state.applicationFormRdc.isRequestingSectionStatus);
	const isPosting = useSelector((state: RootState) => state.applicationFormRdc.isRequestPost);
    const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);

	const [sectionStatus, setSectionStatus] = useState(applicationForm.applicationFormStatus.toString());
	const [sectionComment, setSectionComment] = useState<string|null>(null);
	//const [sectionFileUpload, setSectionFileUpload] = useState<FileList|undefined>(undefined);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
  		changeLanguage(locale);

		setSectionComment(section.validationComment);
		setSectionStatus(section.validationStatus.toString());
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function onFormSubmit()
	{
		if(section)
		{
			let formData: SectionStaffInfo =
			{
				appFormSectionId: section.id,
				validationStatus: (sectionStatus as any),
				validationComment: sectionComment,
				//uploadedFile: sectionFileUpload
			};

			store.dispatch(postApplicationFormSectionValidationStatus(formData, applicationForm.uid));
		}
		
		//await addFileUploadsToFormData(formData);
	}

	// function onFileUploadChange(id: any, files: FileList)
	// {
	// 	setSectionFileUpload(files);
	// }

	return (
		<React.Fragment>
			{
				isRequesting ?
					<SpinnerOnTop/>
				:
				section ?
					<React.Fragment>
						<div className="row">
							<div className="col-sm-6 col-md-6 mb-3">
								<label className="form-label">{t("current_section")}</label>
								<input
									className="form-control"
									type="text"
									disabled={true}
									readOnly={true}
									value={section.label}
								/>
							</div>
							<div className="col-sm-6 col-md-6 mb-3">
								<label htmlFor="status" className="form-label">{t("status")}</label>
	                                <select id="applicationFormStatus" defaultValue={section.validationStatus.toString()} name="applicationFormStatus" className="form-select" onChange={(newVal) => setSectionStatus(newVal.target.value)}>
	                                    {applicationFormStatusByUser(userInfo?.isStaff, applicationForm.programType, userInfo?.isSendingPartner, false, applicationForm.applicationFormStatus).map((select: any) => (
	                                        <option key={select.value} value={select.value}>
	                                            {select.label}
	                                        </option>
	                                    ))}
	                                </select>
							</div>
							<div className={"col-md-12 col-sm-12"}>
								<label htmlFor={"comment"} className="form-label">
									{t("comment") + " "}
									{userInfo && showTextCommentPartner(userInfo?.isSendingPartner)}
								</label>
								<textarea
									className="form-control"
									id="comment"
									name="comment"
									defaultValue={sectionComment ?? undefined}
									rows={3}
									onChange={e => setSectionComment(e.target.value)}
								/>
							</div>
							{
                                userInfo?.isSendingPartner && applicationForm.programType === ProgramTypeEnum.Inbound && applicationForm.statusOfValidator !== 0 ? 
                                    <>
                                        <div className='col-sm-6 col-md-6 col-xs-12 mb-3 mt-3'>
                                            <label className='mb-0'>{t('status_of_staff')} : </label> 
                                            {applicationFormStatusByUser(true, applicationForm.programType, false, false, applicationForm.applicationFormStatus).map((select: any) => (
                                                select.value === section.statusOfValidator ? " " + select.label : null
                                            ))}
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-xs-12 mb-3 mt-3">
												<label className='mb-0'>{t('comment_of_staff')} :</label>  {section.commentOfValidator}
                                        </div>
                                    </>
                                : null
                            }
						</div>
						<div className="form-group pb-3 d-flex justify-content-end">
							<div className="mr-3">
								<SubmitButton label={t('update_status')} isPostingForm={isPosting} buttonType="button" extraActionOnClick={() => onFormSubmit()} />
							</div>
						</div>
					</React.Fragment>
				: ""
			}
		</React.Fragment>
	);
};

export default SectionStaffManagement;
