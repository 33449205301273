import { FRONT_URL } from "app/AppConfig";
import { RootState, store } from "app/store";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { getApplicantFormApplicantName } from "components/UtilsForApplicationForm";
import { ApplicationForm, ProgramTypeEnum } from "features/Entity/ApplicationForm";
import { getCurrentAppForm } from "features/Slice/ApplicationFormSlice";
import { getGetCurrentUserInfo } from "features/Slice/UserSlice";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, isResponsive, pathname } from "../../components/Utils";

const Sidebar: React.FC = () => {
	const isRequesting = useSelector((state: RootState) => state.userRdc.isRequesting);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
	const currentAppForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	const isRequestingCurrentAppForm = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getGetCurrentUserInfo());
		if(!isRequestingCurrentAppForm)
		{
			store.dispatch(getCurrentAppForm());
		}
	}, []);

	const url = pathname.split("/");

	return (
		<header className={isResponsive ? "main-nav close_icon" : "main-nav"}>
			<div className="sidebar-user text-center">
				<div className="img-90 rounded-circle sidebar-avatar">
					<img
						src={currentAppForm && currentAppForm.avatarUrl ? (FRONT_URL + currentAppForm.avatarUrl) : "../assets/images/dashboard/1.png"}
						alt=""
					/>
				</div>
				{
					isRequesting ?
						<div className="block">
							<SpinnerOnTop />
						</div>
					: 
					userInfo && userInfo.isSendingPartner && pathname === t('url_dashboard') ?
						 null :
						<h6 className="mt-3 f-14 f-w-600">{currentAppForm && getApplicantFormApplicantName(currentAppForm)}</h6>						
				}
			</div>
			<nav>
				<div className="main-navbar">
					<div id="mainnav">
						<ul className="nav-menu custom-scrollbar">
							<li className="back-btn">
								<div className="mobile-back text-end">
									<span>Back</span>
									<i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
								</div>
							</li>
							<li className="dropdown">
								<a className={pathname.startsWith(t('url_dashboard')) ? "nav-link menu-title active" : "nav-link menu-title"} href={t("url_dashboard") + window.location.search}>
									<svg
										viewBox="0 0 24 24"
										width="24"
										height="24"
										stroke="currentColor"
										strokeWidth="2"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
										className="css-i6dzq1"
									>
										<path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
										<polyline points="9 22 9 12 15 12 15 22"></polyline>
									</svg>
									<span>{t("dashboard")}</span>
									<div className="according-menu"><i className="fa fa-angle-right"></i></div>
								</a>
							</li>

							<li className="dropdown">
								<a
									className={("/" + url[1]).startsWith(t('url_application_form')) ? "nav-link menu-title active" : "nav-link menu-title"}
									href={t("url_application_form_base") + window.location.search}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
										className="feather feather-edit"
									>
										<path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
										<polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
									</svg>
									<span>{t("applications")} </span>
									<div className="according-menu"><i className="fa fa-angle-right"></i></div>
								</a>
							</li>
							<li className="dropdown">
								<a
									className={pathname.startsWith(t('url_virtual_forlder')) ? "nav-link menu-title active" : "nav-link menu-title"}
									href={t("url_virtual_forlder") + window.location.search}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
										className="feather feather-image"
									>
										<rect
											x="3"
											y="3"
											width="18"
											height="18"
											rx="2"
											ry="2"
										></rect>
										<circle cx="8.5" cy="8.5" r="1.5"></circle>
										<polyline points="21 15 16 10 5 21"></polyline>
									</svg>
									<span>{t("virtual_folder")}</span>
									<div className="according-menu"><i className="fa fa-angle-right"></i></div>
								</a>
							</li>
							{
								userInfo?.isSendingPartner || userInfo?.isStaff || ( currentAppForm?.programType !== ProgramTypeEnum.Inbound)?
								<li className="dropdown">
									<a className={pathname.startsWith(t('url_documents')) ? "nav-link menu-title active" : "nav-link menu-title"} href={t("url_documents") + window.location.search}>
										<svg
											viewBox="0 0 24 24"
											width="24"
											height="24"
											stroke="currentColor"
											strokeWidth="2"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											className="css-i6dzq1"
										>
											<path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
											<polyline points="14 2 14 8 20 8"></polyline>
											<line x1="16" y1="13" x2="8" y2="13"></line>
											<line x1="16" y1="17" x2="8" y2="17"></line>
											<polyline points="10 9 9 9 8 9"></polyline>
										</svg>
										<span>{t("documents")}</span>
										<div className="according-menu"><i className="fa fa-angle-right"></i></div>
									</a>
								</li>
							: null
							}
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};
export default Sidebar;
