import React, { useState } from "react";
import { UseFormRegister, FieldValues, UseFormSetValue } from "react-hook-form";
import { toast } from "react-toastify";
import t from "../../components/I18N/TranslateHelpers";
import { saveButton } from "../UtilsForApplicationForm";
import { FormatBytes } from "./UtilsForFormValidation";
import { FRONT_URL } from "app/AppConfig";
import { addErrorToaster } from "components/Utils";

interface ComponentProps {
	id: string;
	classes: string;
	description: string;
	label: string;
	styleOfInput: string;
	image: string;
	register: UseFormRegister<FieldValues>;
	disabled?: boolean;
	accept?: string;
	descriptionId?: string;
	submit?: (formData: any) => Promise<void>;
	hideDescription?: boolean;
	maxSize?: number;
	setValue: UseFormSetValue<FieldValues>;
	descriptionRequired?: boolean;
	downloadButton?: () => JSX.Element;
	disabledButton?: boolean;
}
const UploadWithPreview: React.FC<ComponentProps> = ({ id, classes, description, label, styleOfInput, image, register, disabledButton, disabled, accept, descriptionId, submit, hideDescription, maxSize, setValue, descriptionRequired, downloadButton }: ComponentProps) => {
	React.useEffect(() => {
		setValue(id, null);
	}, []);

	const [file, setFile] = useState("");

	const handleChange = (filelist: FileList) => {
		if (filelist.length === 0) return;
	
		const file = filelist[0];
		const acceptedFormats = accept?.split(',').map((format: string) => format.trim().toLowerCase());
		const fileExtension = file.name.split('.').pop()?.toLowerCase();
		const fileType = file.type.toLowerCase();

		let isAcceptedFormat = false;
		if (acceptedFormats && fileExtension) {
			isAcceptedFormat = acceptedFormats.includes(fileType) || acceptedFormats.includes(`.${fileExtension}`);
		}

		if (!isAcceptedFormat) {
		  addErrorToaster(t('invalid_format'));
		  return;
		}
	
		setValue(id, filelist);
		setFile(URL.createObjectURL(file));
	};
	const uploadInput = () => {
		return (
			<input
				disabled={disabled}
				className="file-upload-avatar"
				id={id}
				type="file"
				accept={accept}
				onChange={(e) => {
					if (e.target.files && e.target.files[0]) {
						const file = e.target.files[0];
						if (file) {
							if (maxSize) {
								if (maxSize >= file.size) {
									handleChange(e.target.files);
								}
								else {
									toast.error(t("file_too_large", { maxSize: FormatBytes(maxSize) }));
								}
							}
							else {
								handleChange(e.target.files);
							}
						}
					}
				}}
			/>
		);
	};

	const labelInput = () => {
		return !disabled ?
			<label htmlFor={id} className="icon-wrapper change-avatar-profile">
				<i className="icofont icofont-pencil-alt-5"></i>
			</label>
			:
			""
			;
	};
	const existFile = file.length > 0
	const existFileName: string = existFile ? file : image !== "" ? FRONT_URL + image : "https://via.placeholder.com/1024x512?text=Upload%20your%20file%20here";
	const existAvatarName: string = existFile ? file : image !== "" ? image : "../assets/images/dashboard/1.png";
	return styleOfInput !== "rounded" ? (
		<div className={"section-photo-album mb-4 " + classes}>
			<header className="photo-album-header info">
				<div className="title-header">{label}</div>
			</header>
			<main className="row">
				<div className="col-md-12">
					<div className="col-sm-12 user-profile kanban-box">
						<div className="height-auto width100 content-photo">
							<div className="userpro-box p-0">
								<div className="img-wrraper position-relative mb-3">
									<div className="avatar mb-0">
										{uploadInput()}
										<img
											className="img-fluid"
											alt=""
											src={existFileName}
										/>
									</div>
									{labelInput()}
								</div>
							</div>
							{
								hideDescription && description === "" ?
									""
									:
									<React.Fragment>
										<label className={`form-label ${descriptionRequired ? " required" : ""}`} htmlFor="descriptionAlbum">
											{t("description")}
										</label>
										{
											descriptionId ?
												<textarea className="form-control" {...register(descriptionId)} id={descriptionId ?? "descriptionAlbum"} name={descriptionId ?? "descriptionAlbum"} rows={5} defaultValue={description !== "" ? description : undefined} placeholder={t("enter_details")} disabled={disabled}></textarea>
												:
												<textarea className="form-control" id={"descriptionAlbum"} name={"descriptionAlbum"} rows={5} defaultValue={description !== "" ? description : undefined} placeholder={t("enter_details")} disabled={disabled}></textarea>
										}
									</React.Fragment>
							}
							<div className="col-md-12">
								<div className="pt-4 d-flex">
									{
										<div className="col-md-2 d-flex">
											{downloadButton ? downloadButton() : null}
										</div>
									}
									{
										!disabled &&
											<div className="col-md-10 d-flex justify-content-end">
												{saveButton(true, submit ?? undefined, undefined, undefined, "button", disabledButton)}
											</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	) : (
		<div className={"user-profile " + classes}>
			<div className="profile-header height-auto p-0">
				<div className="userpro-box p-0">
					<div className="img-wrraper">
						<div
							className="avatar"
							style={{ backgroundImage: 'url(' + existAvatarName + ')' }}
						>
							<div className="border-image">{uploadInput()}</div>
						</div>
						{labelInput()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadWithPreview;
