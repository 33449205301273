import axios from 'axios';
import {GetBaseAPIUrl} from "../components/Utils";
import { removeUserTokenFromLocalStorage, setUserTokenInLocalStorage } from "components/UserUtils";

// Set up Axios instance
const api = axios.create({
  baseURL: GetBaseAPIUrl(),
  withCredentials: true // Include credentials with requests
});

// Add a response interceptor to handle token expiration
api.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    if(!originalRequest._retry && originalRequest.url !== '/Account/Refresh'){
      originalRequest._retry = true;
        const response = await api.post('/Account/Refresh',);
      if (response.status === 204) {
        setUserTokenInLocalStorage();
        return api(originalRequest);
      }
    }
    removeUserTokenFromLocalStorage();
  }
  return Promise.reject(error);
});

export default api;