import React from "react";
import {
	Control,
	FieldValues,
	UseFormRegister,
} from "react-hook-form";
import t, {
	changeLanguage,
} from "../../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../../components/Utils";
import {
	renderCheckbox,
	renderEmailWithPattern,
	renderInputText,
} from "../../../../components/Forms/FormsComponent";
import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { getTeacher } from "features/Slice/TeacherSlice";
import { useSelector } from "react-redux";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { TeacherTypeEnum } from "features/Entity/Teacher";
import { renderRadio } from "../../../../components/Forms/FormsComponent";
interface ComponentProps {
	applicationFormId: string;
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	control: Control<FieldValues, any>;
	isReadOnly: boolean;
}

const ApplicantLanguageTeacherInfoForm: React.FC<ComponentProps> = ({
	applicationFormId,
	register,
	errors,
	control,
	isReadOnly
}) => {
	const isRequesting = useSelector((state: RootState) => state.teacherRdc.isRequesting);
	const languageTeacher = useSelector((state: RootState) => state.teacherRdc.teacher);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		if(!isRequesting && !languageTeacher)
		{
			store.dispatch(getTeacher(applicationFormId, true));
		}
	}, []);

	return (
		<div className="col-md-12">
			{
				isRequesting || !languageTeacher ?
					<SpinnerOnTop />
					:
					<React.Fragment>
						<div className="row">
							<div className="col-md-12 mb-3 text-danger">{t("certify_report_is_true")}</div>
							{renderInputText(
								t("school_name_address"),
								"schoolNameAddress",
								languageTeacher.schoolNameAddress ?? "",
								errors.schoolNameAddress,
								register,
								true,
								"col-md-6 col-sm-12 mb-3",
								false,
								true
							)}
							<input
								type="hidden"
								id={`id`}
								value={languageTeacher.id ?? 0}
								{...register(`id`)}
							/>
							<input
								type="hidden"
								id={`applicationFormId`}
								value={languageTeacher.applicationFormId ?? 0}
								{...register(`applicationFormId`)}
							/>
							<input
								type="hidden"
								id={`schoolId`}
								value={languageTeacher.schoolId ?? 0}
								{...register(`schoolId`)}
							/>
							<input
								type="hidden"
								id={`teacherType`}
								value={languageTeacher.teacherType ?? TeacherTypeEnum.LanguageTeacher.toString()}
								{...register(`teacherType`)}
							/>
							{renderInputText(
								t("teacher_name"),
								"name",
								languageTeacher.name,
								errors.name,
								register,
								true,
								"col-md-6 col-sm-6 mb-3",
								false,
								isReadOnly
							)}
							{renderInputText(
								t("teacher_position"),
								"positionAtSchool",
								languageTeacher.positionAtSchool,
								errors.positionAtSchool,
								register,
								true,
								"col-md-6 col-sm-6 mb-3",
								false,
								isReadOnly
							)}
							{renderInputText(
								t("subjects_taught"),
								"subjectsTaught",
								languageTeacher.subjectsTaught,
								errors.subjectsTaught,
								register,
								true,
								"col-md-6 col-sm-6 mb-3",
								false,
								isReadOnly
							)}
							{renderEmailWithPattern(
								t("teacher_email"),
								languageTeacher.teacherEmail,
								register,
								"teacherEmail",
								true,
								errors.languageTeacherSchoolEmail,
								"col-md-6 col-sm-6 mb-3",
								false,
								isReadOnly
							)}
							{renderInputText(
								t("phone"),
								"phoneNumber",
								languageTeacher.phoneNumber,
								errors.phoneNumber,
								register,
								false,
								"col-md-6 col-sm-6 mb-3",
								false,
								isReadOnly
							)}
							<div className="col-md-12 pb-2">
								{t('give_consent')}
							</div>
							<div className="col-md-6 mb-3">
								<label htmlFor="" className="form-label required">{t("would_like_receive_email_presenting_wep")}</label>
								<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
									{renderRadio(
										t("yes"),
										"wepNewsInterest",
										"true",
										true,
										register,
										false,
										undefined,
										[],
										languageTeacher.wepNewsInterest != null && languageTeacher.wepNewsInterest,
										false
									)}
									{renderRadio(
										t("no"),
										"wepNewsInterest",
										"false",
										true,
										register,
										false,
										undefined,
										[],
										languageTeacher.wepNewsInterest != null && !languageTeacher.wepNewsInterest,
										false
									)}
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<label htmlFor="" className="form-label required">{t('subscribe_to_newsletter')}</label>
								<div className="form-group m-checkbox-inline mb-0 custom-radio-ml">
									{renderRadio(
										t("yes"),
										"isNewsletter",
										"true",
										true,
										register,
										false,
										undefined,
										[],
										languageTeacher.isNewsletter != null && languageTeacher.isNewsletter,
										false
									)}
									{renderRadio(
										t("no"),
										"isNewsletter",
										"false",
										true,
										register,
										false,
										undefined,
										[],
										languageTeacher.isNewsletter != null && !languageTeacher.isNewsletter,
										false
									)}
								</div>
							</div>
							<div className="col-md-12">
								<small>
									{t("data_never_shared", { siteurl: <a href="https://wep.org" target="_blank">wep.org</a> })}
								</small>
							</div>
						</div>
					</React.Fragment>
			}
		</div>
	);
};

export default ApplicantLanguageTeacherInfoForm;
