export class LocalParams {

  static getItem(itemName: string) : string|null {
    return(localStorage.getItem(itemName));
  }

  static setItem(itemName: string, itemValue: string) : void {
    return(localStorage.setItem(itemName, itemValue));
  }

  static removeItem(itemName: string) : void {
    return(localStorage.removeItem(itemName));
  }
}
