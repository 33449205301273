import React from "react";
import {
	Control,
	FieldValues,
	UseFormRegister,
	UseFormSetValue,
} from "react-hook-form";
import t, { changeLanguage } from "../../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, isItalyCulture } from "../../../../components/Utils";
import {
	renderEmailWithPattern,
	renderInputText,
} from "components/Forms/FormsComponent";
import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { getSchool } from "features/Slice/SchoolSlice";
import { useSelector } from "react-redux";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { ApplicationForm } from "features/Entity/ApplicationForm";

interface ComponentProps {
	applicationForm: ApplicationForm;
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	control: Control<FieldValues, any>;
	setValue: UseFormSetValue<FieldValues>;
	isReadOnly: boolean;
}

const ApplicantSchoolInfoForm: React.FC<ComponentProps> = ({
	applicationForm,
	register,
	errors,
	control,
	setValue,
	isReadOnly
}) => {
	const isRequesting = useSelector((state: RootState) => state.schoolRdc.isRequesting);
	const schoolTranscript = useSelector((state: RootState) => state.schoolRdc.schoolTranscript);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		store.dispatch(getSchool(applicationForm.uid));
	}, []);

	return (
		<React.Fragment>
			{
				isRequesting || !schoolTranscript ?
					<SpinnerOnTop />
					:
					<React.Fragment>
						<input
							type="hidden"
							id="id"
							value={schoolTranscript.school?.id ?? 0}
							{...register("school.id")}
						/>
						{
							isItalyCulture(applicationForm) ?
								renderInputText(
									t("id_code"),
									"school.idCode",
									schoolTranscript.school?.idCode,
									errors.school?.idCode,
									register,
									true,
									"col-lg-3 col-md-6 col-sm-12 mb-3",
									false,
									schoolTranscript.school?.initializedFields?.includes("IdCode") || isReadOnly
								)
								: ""
						}
						{renderInputText(
							t("complete_name_school"),
							"school.completeName",
							schoolTranscript.school?.completeName,
							errors.school?.completeName,
							register,
							true,
							"col-lg-3 col-md-6 col-sm-12 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("CompleteName") || isReadOnly
						)}
						{renderEmailWithPattern(
							t("school_email"),
							schoolTranscript.school?.schoolEmail,
							register,
							"school.schoolEmail",
							true,
							errors.school?.schoolEmail,
							"col-lg-3 col-md-6 col-sm-12 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("SchoolEmail") || isReadOnly
						)}
						{renderInputText(
							t("address"),
							"school.streetAddress",
							schoolTranscript.school?.streetAddress,
							errors.school?.streetAddress,
							register,
							true,
							"col-lg-3 col-md-6 col-sm-12 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("StreetAddress") || isReadOnly
						)}
						{renderInputText(
							t("zip_code"),
							"school.postCode",
							schoolTranscript.school?.postCode,
							errors.school?.postCode,
							register,
							true,
							"col-sm-6 col-md-3 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("PostCode") || isReadOnly
						)}
						{renderInputText(
							t("city"),
							"school.city",
							schoolTranscript.school?.city,
							errors.school?.city,
							register,
							true,
							"col-sm-6 col-md-3 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("City") || isReadOnly
						)}
						{renderInputText(
							t("state"),
							"school.state",
							schoolTranscript.school?.state,
							errors.school?.state,
							register,
							true,
							"col-sm-6 col-md-3 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("State") || isReadOnly
						)}
						{renderInputText(
							t("country"),
							"school.country",
							schoolTranscript.school?.country,
							errors.school?.country,
							register,
							true,
							"col-sm-6 col-md-3 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("Country") || isReadOnly
						)}

						{renderInputText(
							t("school_official_name"),
							"nameOfSchoolOfficial",
							schoolTranscript.nameOfSchoolOfficial,
							errors.nameOfSchoolOfficial,
							register,
							true,
							"col-lg-3 col-md-6 col-sm-12 mb-3",
							false,
							isReadOnly
						)}
						{renderInputText(
							t("position_at_school"),
							"positionAtSchool",
							schoolTranscript.positionAtSchool,
							errors.positionAtSchool,
							register,
							true,
							"col-md-3 col-sm-6 mb-3",
							false,
							schoolTranscript.school?.initializedFields?.includes("PositionAtSchool") || isReadOnly
						)}
					</React.Fragment>
			}
		</React.Fragment>
	);
};

export default ApplicantSchoolInfoForm;
