import React, { useState } from "react";
import {
	FieldValues,
	UseFormRegister,
} from "react-hook-form";
import t, {
	changeLanguage,
} from "../../../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../../../components/Utils";
import { RootState } from "app/RootReducer";
import { TeacherComment, TeacherCommentTypeEnum } from "features/Entity/Teacher";
import { useSelector } from "react-redux";
import { renderTextArea } from "components/Forms/FormsComponent";
import TeacherRecommendationsRow from "../Teacher/TeacherRecommendationsRow";
import { SpinnerOnTop } from "components/SpinnerOnTop";

interface ComponentProps {
	applicationFormId: string;
	register: UseFormRegister<FieldValues>;
	errors: { [x: string]: any };
	isReadOnly: boolean;
}

const ApplicantLanguageTeacherRecommendationForm: React.FC<ComponentProps> = ({
	applicationFormId,
	register,
	errors,
	isReadOnly,
}) => {
	const isRequesting = useSelector((state: RootState) => state.teacherRdc.isRequesting);
	const languageTeacher = useSelector((state: RootState) => state.teacherRdc.teacher);

	const [recommendationsHeadings, setRecommendationsHeadings] = useState<string[]>([]);
	const [teacherComment, setTeacherComment] = useState<TeacherComment | null | undefined>(null);
	const [teacherInEnglishComment, setTeacherInEnglishComment] = useState<TeacherComment | null | undefined>(null);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if (languageTeacher) {
			let headings: string[] = [];

			languageTeacher.languageTeacherRecommendations.forEach(recommendation => {
				if (recommendation.languageTeacherRecommendationProficiency?.label) {
					headings.push(recommendation.languageTeacherRecommendationProficiency.label);
				}
			});

			setRecommendationsHeadings(headings);

			setTeacherComment(languageTeacher.teacherComments.find(comm => comm.teacherCommentType.toString() === TeacherCommentTypeEnum.LanguageApplicationMotivation.toString()));
			setTeacherInEnglishComment(languageTeacher.teacherComments.find(comm => comm.teacherCommentType.toString() === TeacherCommentTypeEnum.LanguageApplicationMotivationInEnglish.toString()));
		}
	}, [languageTeacher]);

	return (
		<React.Fragment>
			{
				isRequesting || !languageTeacher ?
					<SpinnerOnTop />
					:
					<React.Fragment>
						<TeacherRecommendationsRow
							classes="col-md-6 col-sm-12 col-991-12"
							register={register}
							columns={[t("language_proficiency"), t("excellent"), t("good"), t("average")]}
							headings={recommendationsHeadings}
							teacherRecommendations={languageTeacher.languageTeacherRecommendations}
							prefix={"languageTeacherRecommendations"}
							teacher={languageTeacher}
							isReadOnly={isReadOnly}
						/>
						{
							teacherComment !== null ?
								<React.Fragment>
									<input
										type="hidden"
										id={`teacherComments[0].id`}
										value={teacherComment?.id ?? 0}
										{...register(`teacherComments[0].id`)}
									/>
									<input
										type="hidden"
										id={`teacherComments[0].teacherCommentType`}
										value={TeacherCommentTypeEnum.LanguageApplicationMotivation.toString()}
										{...register(`teacherComments[0].teacherCommentType`)}
									/>
									<input
										type="hidden"
										id={`teacherComments[0].teacherId`}
										value={teacherComment?.teacherId ?? languageTeacher.id}
										{...register(`teacherComments[0].teacherId`)}
									/>
									{
										renderTextArea(
											t('teacher_applicant_motivation_letter'),
											`teacherComments[0].commentText`,
											teacherComment?.commentText ?? "",
											errors.teacherComments && errors.teacherComments[0] ? errors.teacherComments[0]?.commentText : undefined,
											register,
											false,
											"col-md-12 col-sm-12 mb-3",
											false,
											isReadOnly,
											5,
											true
										)
									}
								</React.Fragment>
								: ""
						}
						{
							teacherInEnglishComment !== null ?
								<React.Fragment>
									<input
										type="hidden"
										id={`teacherComments[1].id`}
										value={teacherInEnglishComment?.id ?? 0}
										{...register(`teacherComments[1].id`)}
									/>
									<input
										type="hidden"
										id={`teacherComments[1].teacherCommentType`}
										value={TeacherCommentTypeEnum.LanguageApplicationMotivationInEnglish.toString()}
										{...register(`teacherComments[1].teacherCommentType`)}
									/>
									<input
										type="hidden"
										id={`teacherComments[1].teacherId`}
										value={teacherInEnglishComment?.teacherId ?? languageTeacher.id}
										{...register(`teacherComments[1].teacherId`)}
									/>
									{
										renderTextArea(
											t('teacher_applicant_motivation_letter_english'),
											`teacherComments[1].commentText`,
											teacherInEnglishComment?.commentText ?? "",
											errors.teacherComments && errors.teacherComments[1] ? errors.teacherComments[1]?.commentText : undefined,
											register,
											false,
											"col-md-12 col-sm-12 mb-3",
											false,
											isReadOnly,
											5,
											true
										)
									}
								</React.Fragment>
								: ""
						}
					</React.Fragment>
			}
		</React.Fragment>
	);
};

export default ApplicantLanguageTeacherRecommendationForm;
