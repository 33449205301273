import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { SpinnerOnTop } from "components/SpinnerOnTop";
import { getAlerts, markAlertAsRead } from "features/Slice/AlertSlice";
import React from "react";
import { useSelector } from "react-redux";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { FormatDateForDisplay, GetCurrentLocale } from "../../components/Utils";

interface ComponentProps {
	applicationFormUid: string | null;
}

const Alerts: React.FC<ComponentProps> = ({ applicationFormUid }) => {
	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if (applicationFormUid) {
			store.dispatch(getAlerts(applicationFormUid));
		}
	}, [applicationFormUid]);

	const isRequestingAlerts = useSelector((state: RootState) => state.alertRdc.isRequesting);
	const alerts = useSelector((state: RootState) => state.alertRdc.alerts);

	return (
		isRequestingAlerts ?
			<SpinnerOnTop />
		:
		alerts ?
			<div className="col-xl-6 col-50 box-col-6 des-xl-50">
				<div className="alerts-container card">
					<div className="card-header pb-0">
						<div className="header-top d-sm-flex align-items-center">
							<h4 className="pb-3">{t('alerts')}</h4>
						</div>
					</div>
					<div className="card-body p-3 pt-0">
						{
							alerts.filter(al => !al.isRead).map(alrt => (
								<div
									className="card alert outline-orange alert-dismissible fade show mb-3"
									role="alert"
								>
									<div className="row">
										<div className="col-md-12">
											<div className="ribbon ribbon-bookmark ribbon-right ribbon-orange">
												{alrt.dateOf ? FormatDateForDisplay(alrt.dateOf, true) : null}
												<button
													onClick={() => applicationFormUid ? store.dispatch(markAlertAsRead(applicationFormUid, alrt.id)) : undefined}
													className="btn-close"
													type="button"
													data-bs-dismiss="alert"
													aria-label="Close"
													data-bs-original-title=""
													title=""
												></button>
											</div>
											<h3>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
													className="feather feather-alert-triangle"
												>
													<path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
													<line x1="12" y1="9" x2="12" y2="13"></line>
													<line x1="12" y1="17" x2="12" y2="17"></line>
												</svg>{" "}
												{alrt.title}
											</h3>
										</div>
										<div className="col-md-12">
											<p>
												{alrt.subtitle}
											</p>
										</div>
									</div>
								</div>
							))
						}
					</div>
				</div>
			</div>
		: null
	);
};

export default Alerts;
