import React, { useMemo, useRef, useState } from 'react';
import t from "../../../components/I18N/TranslateHelpers";
import { renderInputText, renderTextArea } from 'components/Forms/FormsComponent';
import { applicationFormStatusByUser, FormatDateForDisplay } from 'components/Utils';
import { ApplicationForm, ProgramTypeEnum } from 'features/Entity/ApplicationForm';
import { store } from 'app/store';
import { deactivateApplicationForm, getAppFormSectionPDF, postApplicationFormStaffStatus, deactivateApplicationFormPaxUsers } from 'features/Slice/ApplicationFormSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'app/RootReducer';
import { SpinnerOnTop } from 'components/SpinnerOnTop';
import { downloadAgreementsPDF, downloadPhotoAlbum, downloadSectionsPDF, downloadVirtualFolder, downloadBioInfo, exportDataToOMGT, synchronizeApplicationFormStatus, notifyAppFormUserOfAccount, downloadSectionsConcatPDF, downloadAgreementsConcatPDF } from 'features/Slice/StaffDefineSlice';
import { ApplicationFormSectionStatusEnum } from 'features/Entity/SectionStaffInfo';
import { Modal } from '@mui/material';
import { getApplicationFormSectionUrl } from 'components/UtilsForApplicationForm';
import { ApplicationFormSection } from 'features/Entity/ApplicationFormSection';
import ApplicationStatus from 'features/Dashboard/ApplicationStatus';
import { buttonSubmitAppForm, closeModalAndReloadAppForm, getIncompleteSteps, showTextCommentPartner, submitAppFormModal } from 'components/UtilsForPartner';

interface ComponentProps {
    applicationForm: ApplicationForm;
    section: ApplicationFormSection;
}

const ApplicationFormDefine: React.FC<ComponentProps> = ({ applicationForm, section }: ComponentProps) => {
    const [applicationFormSynchronizedStatus, setApplicationFormSynchronizedStatus] = useState(applicationForm.applicationFormStatus.toString());
    const [applicationFormCurrentStatus, setApplicationFormCurrentStatus] = useState(applicationForm.applicationFormStatus.toString());
    const [applicationFormCurrentComment, setApplicationFormCurrentComment] = useState(applicationForm.validationComment ?? "");
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [disableApplicationFormUsersModalOpen, setdisableApplicationFormUsersModalOpen] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const isSyncingApplicationForm = useSelector((state: RootState) => state.staffDefineRdc.isSyncing);
    const isUpdatingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequestPost);
    const applicationFormSections = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSections);
    const isRequesting = useSelector((state: RootState) => state.staffDefineRdc.ongoingActions);
    const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);
    const isPostingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequestPost);
    const applicationFormPosted = useSelector((state: RootState) => state.applicationFormRdc.isRequestApplicationFormPostSuccess)
    const [modalOpen, setModalOpen] = useState(false);
    const [openCancelMenu, setOpenCancelMenu] = useState(false)


    React.useEffect(() => {
        if (applicationFormPosted) {
            closeModalAndReloadAppForm(modalOpen, setModalOpen, applicationForm)
        }
    }, [applicationFormPosted]);

    const downloadsBySection = useMemo(() => {
        if(applicationFormSections && applicationFormSections.length > 0)
        {
            return applicationFormSections.filter(sec => sec.canDownloadPDFFromDashboard).map(section => {
                const actionName = `downloadSection${section.label}`;
                return {
                id: `section-${section.id}`,
                label: t("download_section", {sectionName: section.label}),
                icon: <i className="icofont icofont-download-alt mr-2"></i>,
                actionName: actionName,
                onClick: () => store.dispatch(getAppFormSectionPDF(applicationForm.uid, section.id, false, false, section.label))
            }})
        }
        else
        {
            return [];
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationFormSections]);

    const staffLinks = [
        {
            id: "dl-0",
            label: t("download_sections"),
            icon: <i className="icofont icofont-download-alt mr-2"></i>,
            actionName: "downloadSectionsPDF",
            onClick: () => store.dispatch(downloadSectionsPDF(applicationForm.uid, "downloadSectionsPDF"))
        },
        {
            id: "dl-4",
            label: t('download_sections_one_pdf'),
            icon: <i className="icofont icofont-download-alt mr-2"></i>,
            onClick: () => store.dispatch(downloadSectionsConcatPDF(applicationForm.uid, "downloadSectionsConcatPDF")),
            actionName: "downloadSectionsConcatPDF",
        },
        {
            id: "dl-1",
            label: t("download_agreements"),
            icon: <i className="icofont icofont-download-alt mr-2"></i>,
            onClick: () => store.dispatch(downloadAgreementsPDF(applicationForm.uid, "downloadAgreementsPDF")),
            actionName: "downloadAgreementsPDF",
        },

        {
            id: "dl-5",
            label: t("download_agreements_one_pdf"),
            icon: <i className="icofont icofont-download-alt mr-2"></i>,
            onClick: () => store.dispatch(downloadAgreementsConcatPDF(applicationForm.uid, "downloadAgreementsConcatPDF")),
            actionName: "downloadAgreementsConcatPDF",
        },
        {
            id: "dl-2",
            label: t("download_album"),
            icon: <i className="icofont icofont-download-alt mr-2"></i>,
            onClick: () => store.dispatch(downloadPhotoAlbum(applicationForm.uid, "downloadPhotoAlbum")),
            actionName: "downloadPhotoAlbum",
        },
        {
            id: "dl-3",
            label: t("download_virtual_folder"),
            icon: <i className="icofont icofont-download-alt mr-2"></i>,
            onClick: () => store.dispatch(downloadVirtualFolder(applicationForm.uid, "downloadVirtualFolder")),
            actionName: "downloadVirtualFolder",
        },
        {
            id: "dl-6",
            label: t("download_bio_info"),
            icon: <i className="icofont icofont-download-alt mr-2"></i>,
            onClick: () => store.dispatch(downloadBioInfo(applicationForm.uid, "downloadBioInfo")),
            actionName: "downloadBioInfo",
        },
        ...downloadsBySection
    ];

    function submit() {
        const formData = { applicationFormStatus: applicationFormCurrentStatus, validationComment: applicationFormCurrentComment, uid: applicationForm.uid };
        setApplicationFormSynchronizedStatus(applicationFormCurrentStatus);

        store.dispatch(postApplicationFormStaffStatus(formData));
    }

    function syncWithOmgt() {
        if (applicationFormSynchronizedStatus) {
            store.dispatch(synchronizeApplicationFormStatus(applicationForm.uid, parseInt(applicationFormSynchronizedStatus)));
        }
    }

    function exportOmgt() {
        setExportModalOpen(false);
        if (applicationFormSynchronizedStatus) {
            store.dispatch(exportDataToOMGT(applicationForm.uid));
        }
    }

    function notifyUserOfAccount() {
        setNotificationModalOpen(false);
        store.dispatch(notifyAppFormUserOfAccount(applicationForm.uid));
    }

    function DisableApplicationFormPaxUsers() {
        setdisableApplicationFormUsersModalOpen(false);
        store.dispatch(deactivateApplicationFormPaxUsers(applicationForm.uid, true));
    }

    const [errorCancel, setErrorCancel] = useState("");
    const [cancelComment, setCancelComment] = useState("");
    const [isDisableAccount, setIsDisableAccount] = useState(false);
    
    const submitWhyCancelAppForm = (event:any) => {
        event.preventDefault();
        if (cancelComment.trim() === '') {
          setErrorCancel(t('required_field'));
          return;
        }
        store.dispatch(deactivateApplicationForm(cancelComment, applicationForm.uid, isDisableAccount));
        setOpenCancelModal(false);
        setErrorCancel("");
        setIsDisableAccount(false)
      }

    function closeModal()
    {
        setExportModalOpen(false);
        setNotificationModalOpen(false);
        setOpenCancelModal(false)
        setdisableApplicationFormUsersModalOpen(false);
    }

    const sectionsWithoutFinishAndDefineStep = applicationFormSections?.filter(applicationFormSection => !getApplicationFormSectionUrl(applicationFormSection).startsWith(t("url_application_finish")) && !getApplicationFormSectionUrl(applicationFormSection).startsWith(t("url_application_form_define"))) ?? [];
    const incompleteSteps = userInfo && getIncompleteSteps(sectionsWithoutFinishAndDefineStep, userInfo, applicationForm)
    const isIncomplete = incompleteSteps && incompleteSteps.length > 0;
    const isAlreadySubmitted = 
      applicationForm.statusOfValidator === ApplicationFormSectionStatusEnum.Approved || applicationForm.statusOfValidator === ApplicationFormSectionStatusEnum.Submitted

      const confirmDisableAppForUsersModal = () => (
        <Modal open={ disableApplicationFormUsersModalOpen }>
          <div className='card modal-dialog'>
            <div className="modal-header">
              <h5 className="modal-title" id="deactivateConfirmModalTitle">{ t('confirm_disable_appform_pax_users_title') }</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <p>{ t('confirm_disable_appform_pax_users_text') }</p>
              <div className="d-flex">
                <button className='btn btn-primary mr-3' type='button' onClick={() => closeModal()}>
                  {t('cancel')}
                </button>
                <button className='btn btn-primary-outline' type='button' onClick={() => DisableApplicationFormPaxUsers() }>
                  {t('disable_appform_pax_users')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );

    const confirmModal = () => (
        <Modal open={exportModalOpen || notificationModalOpen}>
          <div className='card modal-dialog'>
            <div className="modal-header">
              <h5 className="modal-title" id="confirmModalTitle">{t(exportModalOpen ? 'confirm_export_modal_title' : 'confirm_notify_user_title')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <p>{t(exportModalOpen ? "confirm_export_modal_text" : "confirm_notify_user_text")}</p>
              <div className="d-flex">
                <button className='btn btn-primary mr-3' type='button'
                  onClick={() => closeModal()}
                >
                  {t('cancel')}
                </button>
                <button className='btn btn-primary-outline' type='button'
                  onClick={() => exportModalOpen ? exportOmgt() : notifyUserOfAccount()}
                >
                  {t(exportModalOpen ? 'export_omgt' : 'send')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      );
    const cancelModal = () => (
        <Modal open={openCancelModal}>
        <div className='card modal-dialog'>
          <div className="modal-header">
            <h5 className="modal-title" id="cancelModalTitle">{!isDisableAccount ? t('cancel_app_form') : t('cancel_app_form_account')}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className="form-group">
                <label htmlFor="cancelComment" className='form-label required'>{t("comment")}</label>
                <textarea
                id="cancelComment"
                className="form-control mb-3"
                value={cancelComment}
                onChange={(e) => setCancelComment(e.target.value)}
                required
                />
                {errorCancel && <p className="text-danger">{errorCancel}</p>}
            </div>
            <div className="d-flex">
                <button className='btn btn-primary-outline' onClick={submitWhyCancelAppForm}>
                {t('send')}
                </button>
            </div>
          </div>
        </div>
      </Modal>
    )
    return (
        <>
            {
                userInfo?.isSendingPartner || userInfo?.isStaff ?
                        <div className="row pb-3">
                            <ApplicationStatus/>
                        </div>
                :
                    null
            }
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 pb-3">
                    <div className="card">
                        <div className="card-header faq-header pb-0">
                            <h4 className="ps-0 d-flex">
                                {t('actions')}
                            </h4>
                        </div>
                        <div className="card-body faq-body py-0 pb-3 box-define-actions">

                            {confirmModal()}
                            {cancelModal()}
                            {confirmDisableAppForUsersModal()}

                            <div className="navigation-option">
                                {/* <button onClick={() => getComponents()}>
                                    Get Component
                                </button> */}
                                <ul className="ul-style-none">
                                    {
                                        staffLinks.map((link) => {
                                            return (
                                                <li key={link.id}>
                                                    <button type="button" onClick={() => link.onClick()}>
                                                        {link.icon} {link.label}
                                                        {isRequesting.includes(link.actionName) ?
                                                            <div className="small-spinner">
                                                                <SpinnerOnTop />
                                                            </div> : ""}
                                                    </button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 pb-3 dashboard-program-info">
                    <div className="card mb-4">
                        <div className="card-body padding20mobile">
                            <div className="row">
                                {renderInputText(
                                    t('program'),
                                    "program",
                                    applicationForm.label,
                                    "",
                                    undefined,
                                    false,
                                    "col-sm-6 col-md-6 col-xs-12 mb-3",
                                    false,
                                    true
                                )}
                                {renderInputText(
                                    t('destination_country'),
                                    "destinationCouuntry",
                                    applicationForm.destinationCouuntry,
                                    "",
                                    undefined,
                                    false,
                                    "col-lg-3 col-sm-6 col-md-6 col-xs-12 mb-3",
                                    false,
                                    true
                                )}
                                {renderInputText(
                                    t('duration'),
                                    "duration",
                                    applicationForm.duration,
                                    "",
                                    undefined,
                                    false,
                                    "col-lg-3 col-sm-6 col-md-6 col-xs-12 mb-3",
                                    false,
                                    true
                                )}
                                <div className='col-lg-3 col-sm-6 col-md-6 col-xs-12 mb-3'>
                                    <label className="form-label required" htmlFor="applicationFormStatus">{t('status')}</label>
                                    <select id="applicationFormStatus" defaultValue={applicationFormCurrentStatus} name="applicationFormStatus" className="form-select" onChange={(newVal) => setApplicationFormCurrentStatus(newVal.target.value)}>
                                        {applicationFormStatusByUser(userInfo?.isStaff, applicationForm.programType, userInfo?.isSendingPartner, true, applicationForm.applicationFormStatus).map((select: any) => (
                                            <option key={select.value} value={select.value}>
                                                {select.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {renderInputText(
                                    t('departure_period'),
                                    "departurePeriod",
                                    applicationForm.departurePeriod ?? null,
                                    "",
                                    undefined,
                                    false,
                                    "col-lg-3 col-sm-6 col-md-6 col-xs-12 mb-3",
                                    false,
                                    true
                                )}

                                {renderInputText(
                                    t('year_departure'),
                                    "yearOfDeparture",
                                    applicationForm.yearOfDeparture,
                                    "",
                                    undefined,
                                    false,
                                    "col-lg-3 col-sm-6 col-md-6 col-xs-12 mb-3",
                                    false,
                                    true
                                )}

                                {renderInputText(
                                    t('culture'),
                                    "cultureCode",
                                    applicationForm.cultureCode,
                                    "",
                                    undefined,
                                    false,
                                    "col-lg-3 col-sm-6 col-md-6 col-xs-12 mb-3",
                                    false,
                                    true
                                )}
                                <div className={"col-md-12 col-sm-12"}>
                                    <label htmlFor="comment" className="form-label required">
                                        {t("comments") + " "}
									    {userInfo && showTextCommentPartner(userInfo?.isSendingPartner)}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        name={"comment"}
                                        id={"comment"}
                                        rows={5}
                                        value={applicationFormCurrentComment}
                                        onChange={(newVal) => setApplicationFormCurrentComment(newVal.target.value)}
                                    />
                                </div>
                                {
                                    userInfo?.isSendingPartner && applicationForm.programType === ProgramTypeEnum.Inbound && applicationForm.statusOfValidator !== 0 ? 
                                        <>
                                            <div className='col-sm-6 col-md-6 col-xs-12 mb-3 mt-3'>
                                                <label className='mb-0'>{t('status_of_staff')} : </label> 
                                                {applicationFormStatusByUser(true, applicationForm.programType, false, false, applicationForm.applicationFormStatus).map((select: any) => (
                                                    select.value === applicationForm.statusOfValidator ? " " + select.label : null
                                                ))}
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-xs-12 mb-3 mt-3">
                                                <label className='mb-0'>{ t('comment_of_staff')} :</label>  {applicationForm.commentOfValidator}
                                            </div>
                                        </>
                                    : null
                                }
                            </div>
                            <div className="row">
                                <strong>
                                    {applicationForm.dataExportedToSupplierOn && `${t('data_exported_to_supplier_on')} ${FormatDateForDisplay(applicationForm.dataExportedToSupplierOn, true)}`}
                                </strong>
                            </div>
                            <div className="row">
                                <strong>
                                    {applicationForm.statusSynchronizedWithSupplierOn && `${t('status_sync_with_supplier_on')} ${FormatDateForDisplay(applicationForm.statusSynchronizedWithSupplierOn, true)}`}
                                </strong>
                            </div>
                            <div className="row">
                                <div className="col-md-12 footer-button-container">
                                    {
                                        isUpdatingApplicationForm || isSyncingApplicationForm ?
                                            <SpinnerOnTop />
                                            : null
                                    }
                                    <div className="py-2 d-flex justify-content-end">
                                        <div className="form-group d-flex justify-content-end buttons-submit">
                                            {
                                                applicationForm.programType === ProgramTypeEnum.Outbound && !userInfo?.isSendingPartner ?
                                                    applicationFormSynchronizedStatus === ApplicationFormSectionStatusEnum.Approved.toString() || applicationFormSynchronizedStatus === ApplicationFormSectionStatusEnum.Incomplete.toString() ?
                                                        <React.Fragment>
                                                            {
                                                                applicationFormSynchronizedStatus === ApplicationFormSectionStatusEnum.Approved.toString() ?
                                                                    <div className="mr-3">

                                                                        <button type="button" disabled={isSyncingApplicationForm} className="btn btn-primary" onClick={() => setExportModalOpen(true)} data-bs-original-title="" title="">
                                                                            <i className="icofont icofont-upload-alt mr-2"></i> {t("export_omgt")}
                                                                        </button>
                                                                    </div>
                                                                :null
                                                            }
                                                            <div className="mr-3">
                                                                <button type="button" disabled={isSyncingApplicationForm} className="btn btn-primary" onClick={() => syncWithOmgt()} data-bs-original-title="" title="">
                                                                    <i className="icofont icofont-refresh mr-2"></i> {t("sync_omgt")}
                                                                </button>
                                                            </div>
                                                        </React.Fragment>
                                                    :null
                                                : null
                                            }
                                            <button type="button" disabled={isUpdatingApplicationForm} className="btn btn-primary" onClick={() => submit()} data-bs-original-title="" title="">
                                                <i className="icofont icofont-save mr-2"></i> {t("update")}
                                            </button>
                                            {
                                                userInfo?.isSendingPartner ? (
                                                    <>
                                                    <button type="button" disabled={isSyncingApplicationForm} className="btn btn-primary ml-3" onClick={() => setNotificationModalOpen(true)} data-bs-original-title="" title="">
                                                        <i className="icofont icofont-email mr-2"></i> {t("send_activation_email")}
                                                    </button>
                                                    {
                                                        applicationForm.applicationFormStatus !== ApplicationFormSectionStatusEnum.Cancel ?
                                                            <div className='btn-group mr-3 dropup'>
                                                            <button type="button" id="cancelAppForm" className="btn btn-danger-outline fs14 ml-3 dropdown-toggle" onClick={() => setOpenCancelMenu(!openCancelMenu)}>
                                                                <i className="icofont icofont-close mr-2"></i> <span className='mr-2'>{t('cancel')}</span>
                                                            </button>
                                                            {
                                                            openCancelMenu &&
                                                            <div className="dropdown-menu show">
                                                                <label className='with-link' onClick={() => setOpenCancelModal(true)}>{t('application_form')}</label>
                                                                <div className="dropdown-divider"></div>
                                                                <label className='with-link' onClick={() =>[ setOpenCancelModal(true), setIsDisableAccount(true)]}>{t('application_form_account')}</label>
                                                            </div>
                                                            }
                                                            </div>
                                                            :                                                            
                                                            applicationForm.hasUserPaxLgActive ?                                                            
                                                                <button type="button" disabled={isSyncingApplicationForm} className="btn btn-danger-outline ml-3 fs14" onClick={() => setdisableApplicationFormUsersModalOpen(true)} data-bs-original-title="" title="">
                                                                    <i className="icofont icofont-close mr-2"></i> <span className='mr-2'> {t("deactivate_pax_users")}</span>
                                                                </button>
                                                            
                                                            :
                                                            null
                                                            
                                                    }
                                                    </>
                                                )
                                                    : null
                                            }
                                            {
                                                userInfo?.isSendingPartner && !isAlreadySubmitted && !isIncomplete ?
                                                    <> 
                                                        {
                                                            submitAppFormModal(modalOpen, setModalOpen, applicationForm, isPostingApplicationForm, userInfo)
                                                        }
                                                        {
                                                            buttonSubmitAppForm(userInfo, setModalOpen)
                                                        }
                                                    </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ApplicationFormDefine
