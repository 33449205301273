import { Agreement, FileInfo } from "features/Entity/Agreement";
import axios  from "components/AxiosUtils";

export async function getAgreementRep(agreementUid: string, cultureCode: string, filename: string): Promise<FileInfo>
{
    try
    {
        const response = await axios.get<ArrayBuffer>(
            `/Agreement/DownloadFile/${agreementUid}?cultureCode=${cultureCode}`,
            {headers: { "Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        const fileInfo: FileInfo = {fileBlob: blob, index: agreementUid, filename: filename};

        return fileInfo;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getUserDownloadedFileRep(applicationFormUid: string, agreementUid: string, filename: string): Promise<FileInfo>
{
    try
    {
        const response = await axios.post<ArrayBuffer>(
            `/Agreement/DownloadUserFile/${applicationFormUid}/${agreementUid}`,
            undefined,
            {headers: {"Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        const fileInfo: FileInfo = {fileBlob: blob, index: agreementUid, filename: filename};

        return fileInfo;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getAgreementListRep(applicationFormUid: string): Promise<Agreement[]>
{
    try
    {
        const response = await axios.get<Agreement[]>(
            `/Agreement/GetListForApplicationForm/${applicationFormUid}`
        );

        return response.data;
    }
    catch (err: any) 
    {
        throw err;
    }
}

export async function postAgreementSingleFileRep(applicationFormUid: string, agreementUid: string, agreementFile: FileList, sectionId: number): Promise<void>
{
    try
    {
        await axios.post(
            `/Agreement/UploadUserFile/${applicationFormUid}/${agreementUid}/${sectionId}`,
            agreementFile,
        );
    }
    catch (err: any)
    {
        throw err;
    }
}
