import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AppThunk } from "../../app/store";
import { Survey, SurveyAction } from "../Entity/Survey/Survey";
import { getSurveyRep } from "../Repository/SurveyRepository";

interface ISurveyDictionary {
  [index: string]: Survey;
}

interface ISurveyActionDictionary {
  [index: string]: SurveyAction|null;
}

interface SurveyState {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  surveys: ISurveyDictionary,
  surveyActions: ISurveyActionDictionary
}

const initialState: SurveyState = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  surveys: {} as ISurveyDictionary,
  surveyActions: {} as ISurveyActionDictionary
}

function startRequestRdc(state: SurveyState) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: SurveyState, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: SurveyState, action: PayloadAction<Survey>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.surveys[action.payload.surveyTemplate.code] = action.payload;
}

function addSurveyActionRdc(state: SurveyState, action: PayloadAction<SurveyAction[]>) {
  action.payload.forEach(surveyAction => {
    state.surveyActions[surveyAction.id] = surveyAction;
  });

}
function removeSurveyActionRdc(state: SurveyState, action: PayloadAction<SurveyAction[]>) {
  action.payload.forEach(surveyAction => {
    state.surveyActions[surveyAction.id] = null;
  });
}

const SurveySlice = createSlice({
    name: 'Surveys',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestSuccessAct: requestSuccessRdc,
      requestFailedAct: requestFailedRdc,
      addSurveyActionAct: addSurveyActionRdc,
      removeSurveyActionAct: removeSurveyActionRdc
    }
  }
)

export const {
  startRequestAct,
  requestSuccessAct,
  requestFailedAct,
  addSurveyActionAct,
  removeSurveyActionAct
} = SurveySlice.actions;

export default SurveySlice.reducer;

export const getSurvey = (applicationFormUid: string, surveyCode: string): AppThunk => async dispatch =>
{
    try
    {
        dispatch(startRequestAct());
        const apiResponse = await getSurveyRep(applicationFormUid, surveyCode);
        dispatch(requestSuccessAct(apiResponse));
    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}

export const addSurveyAction = (surveyAction: SurveyAction[]): AppThunk => async dispatch =>
{
    try
    {
        dispatch(addSurveyActionAct(surveyAction));

    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}

export const removeSurveyAction = (surveyAction: SurveyAction[]): AppThunk => async dispatch =>
{
    try
    {
        dispatch(removeSurveyActionAct(surveyAction));

    } catch (err)
    {
        dispatch(requestFailedAct(err as AxiosError));
    }
}