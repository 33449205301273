import { configureStore, ThunkAction, Action, applyMiddleware } from '@reduxjs/toolkit';
import RootReducer from "./RootReducer";
import { tokenExpiredCheckMiddleware } from './middlewares';

export const store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV === 'development',
  enhancers: [applyMiddleware(tokenExpiredCheckMiddleware)]
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;
