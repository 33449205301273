import React, { useEffect, useState } from "react";
import t, { changeLanguage } from "../I18N/TranslateHelpers";
import { GetCurrentLocale, addErrorToaster } from "../Utils";
import { FileError, useDropzone } from "react-dropzone";
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { toast } from "react-toastify";

export interface ChildProps {
	nbOfFiles: number,
	id: string,
	label?: string,
	description?: string,
	classes?: string,
	required?: boolean,
	disabled?: boolean,
	errorMessages?: string,
	isValid?: boolean,
	name: string,
	onFieldChange?: string,
	value?: string | null,
	register: UseFormRegister<FieldValues>,
	accept?: any,
	setValue: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
	onSubmit: (formData: any) => void,
	displayButton: boolean,
	existingFilesDownload?: JSX.Element,
	isResponsive?: boolean
}

const DropzoneComponent: React.FC<ChildProps> = ({
	nbOfFiles,
	id,
	label,
	description,
	classes,
	required,
	disabled,
	errorMessages,
	isValid,
	name,
	onFieldChange,
	value,
	register,
	accept,
	setValue,
	getValues,
	onSubmit,
	displayButton,
	existingFilesDownload,
	isResponsive
}: ChildProps) => {
	const locale = GetCurrentLocale();
	changeLanguage(locale);

	const maxUploadSizeInBytes = 200000000;

	const img = {
		display: "block",
		width: "auto",
		height: "100%",
	};

	const validateExtensionType = (file: any, accept: string, addErrorToaster: any): FileError | null => {
		const acceptedFormats = accept.split(',').map((format: string) => format.trim());
		const fileExtension = file.name.split('.').pop()?.toLowerCase();
		const isAcceptedFormat = acceptedFormats.includes(file.type) ||
								 acceptedFormats.includes(`.${fileExtension}`);
		if (!isAcceptedFormat) {
			addErrorToaster(`${t('invalid_format')}`);
			return {
				code: 'file-invalid-type',
				message: `File type not accepted: ${file.name}`
			};
		}
	
		return null;
	};
	
	const acceptReformatedForDropzone = accept.split(', ').reduce((acc: { [x: string]: any; }, format: string | number) => {
		acc[format] = []; 
		return acc;
	  }, {});

	const [files, setFiles] = useState<any[]>([]);
	const { getRootProps, getInputProps } = useDropzone({
		disabled: disabled,
		accept: acceptReformatedForDropzone,
		maxSize: maxUploadSizeInBytes,
		maxFiles: nbOfFiles,
		onDropRejected: (fileRejections, event) => {
			const firstFile = fileRejections[0];
			if (firstFile && firstFile.errors[0].code === 'file-too-large') {
				addErrorToaster(t("upload_size_limit", {maxSizeInMb: maxUploadSizeInBytes/1000000}));
			} 
		},
		validator: (file: any) => validateExtensionType(file, accept, addErrorToaster),
		onDrop: (acceptedFiles: any[]) => {
			setFiles(
				acceptedFiles.map((file: any) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file)
					})
				)
			);

			const dt = new DataTransfer();

			acceptedFiles.forEach((file: File) => {
				dt.items.add(file);
			});

			setValue(id, dt.files);
		}
	});

	const thumbs = files.map((file: any) => (
		<div className="uploaded-file" key={file.name}
		>
			<div className="thumb-dropzone">
				<i className="fa fa-file-text-o txt-primary"></i>
				<span>{file.name}</span>
				{/* <img
					src={file.preview}
					style={img}
					alt={file.name}
					onLoad={() => {
						URL.revokeObjectURL(file.preview);
					}}
				/> */}
			</div>
		</div>
	));

	useEffect(() => {
		return () =>
			files.forEach((file: any) => URL.revokeObjectURL(file.preview));
	}, []);

	function submitSingleDropzone() {
		const uploadedFiles: FileList | undefined = getValues(id);

		if (uploadedFiles && uploadedFiles.length > 0) {
			onSubmit(uploadedFiles);
		}
		else {
			toast.warning(t("no_file_to_upload"));
		}
	}
	return (
		<React.Fragment>
			{
				!disabled ?
					<div {...getRootProps({ className: classes + " dropzone" })}>
						<input style={{ display: "none" }}
							type="file"
							multiple={true}
							id={id}
						/>
						<input {...getInputProps()} />
						<div className="row">
							<div className={thumbs.length > 0 ? "col-md-6" : "col-md-12"}>
								<div className="text-center">
									<i className="icon-cloud-up"></i>
									<h6 className="mb-0">{t('drop_files')}</h6>
								</div>
							</div>
							<div className="col-md-6">
								{thumbs}
							</div>
						</div>
					</div>
					: ""
			}
			<div className="row mb-2 mt-3 align-items-center">
				<div className="pull-left col-md-8 col-sm-12">
					{
						existingFilesDownload ?? <></>
					}
				</div>
				{
					displayButton && !disabled ?
						<div className="pull-right col-md-4 col-sm-12">
							<div className="btn btn-primary-outline btn-sm d-flex align-items-center justify-content-center" onClick={submitSingleDropzone}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="15"
									height="15"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-upload mr-2"
								>
									<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
									<polyline points="17 8 12 3 7 8"></polyline>
									<line x1="12" y1="3" x2="12" y2="15"></line>
								</svg>
								{t("save")}
							</div>
						</div>
						: ""
				}
			</div>
		</React.Fragment>
	);
};

export default DropzoneComponent;
