import { SpinnerOnTop } from "components/SpinnerOnTop";
import React from "react";

interface ComponentProps
{
	isPostingForm: boolean;
    label: string;
    extraActionOnClick?: () => void;
    buttonType?: "button" | "submit" | "reset";
}

const SubmitButton: React.FC<ComponentProps> = ({isPostingForm, label, extraActionOnClick, buttonType}: ComponentProps) => {
    return (
        <React.Fragment>
            {
                isPostingForm ?    
                    <div className="mt-3 pull-left">
                        <SpinnerOnTop/>
                    </div>
                : ""
            }
            <button disabled={isPostingForm} className="btn btn-primary mt-3 mb-4" type={buttonType ?? "submit"} onClick={extraActionOnClick}>
                {label}
            </button>
        </React.Fragment>
    );
}

export default SubmitButton
