import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Nationality } from "features/Entity/Nationality";
import { getNationalitiesRep } from "features/Repository/NationalityRepository";
import { AppThunk } from "../../app/store";

interface NationalitySlice {
  isRequesting: boolean,
  isRequestSuccess: boolean,
  isRequestFailed: boolean,
  error: AxiosError | null,
  nationalities: Nationality[]|null
}

const initialState: NationalitySlice = {
  isRequesting: false,
  isRequestSuccess: false,
  isRequestFailed: false,
  error: null,
  nationalities: null
}

function startRequestRdc(state: NationalitySlice) {
  state.isRequesting = true;
  state.isRequestFailed = false;
  state.error = null;
}

function requestFailedRdc(state: NationalitySlice, action: PayloadAction<AxiosError>) {
  state.isRequesting = false;
  state.isRequestSuccess = false;
  state.isRequestFailed = true;
  state.error = action.payload;
}

function requestSuccessRdc(state: NationalitySlice, action: PayloadAction<Nationality[]>) {
  state.isRequesting = false;
  state.isRequestSuccess = true;
  state.isRequestFailed = false;
  state.error = null;
  state.nationalities = action.payload;
}

const NationalitySlice = createSlice({
    name: 'nationalities',
    initialState: initialState,
    reducers: {
      startRequestAct: startRequestRdc,
      requestFailedAct: requestFailedRdc,
      requestSuccessAct: requestSuccessRdc
    }
  }
)

export const {
  startRequestAct,
  requestFailedAct,
  requestSuccessAct
} = NationalitySlice.actions;

export default NationalitySlice.reducer;

export const getNationalities = (): AppThunk => async dispatch =>
{
  try{
    dispatch(startRequestAct());
    const apiResponse = await getNationalitiesRep();
    dispatch(requestSuccessAct(apiResponse));
  }catch(err)
  {
    dispatch(requestFailedAct(err as AxiosError))
  }
}