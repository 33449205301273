import { createAppFormButton } from "components/UtilsForPartner";
import PageTitle from "features/Pages/PageTitle";
import React  from "react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../app/store";
import { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale } from "../../components/Utils";
import ApplicationFormSections from "../ApplicationForm/ApplicationFormSections";
import { getApplicationFormSections, getCurrentAppForm } from "../Slice/ApplicationFormSlice";
import Alerts from "./Alerts";
import ApplicationStatus from "./ApplicationStatus";
import Contact from "./Contact";
import DashboardTutorial from "./DashboardTutorial";
import PartnerDashboardApplicationFormOverview from "./PartnerDashboardApplicationFormOverview";

const DashboardContainer: React.FC = () => {

	const applicationForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);
	const isRequestingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequesting);
	const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if(applicationForm){
			store.dispatch(getApplicationFormSections(applicationForm));
		}else if(!isRequestingApplicationForm){
			store.dispatch(getCurrentAppForm());
		}
	}, [applicationForm]);

	return (
		<div className="row starter-main">
			{
				userInfo?.isSendingPartner || userInfo?.isStaff ?
				<div className="row">
					<div className="col-md-6"><PageTitle title={"Dashboard"} /></div>
					<div className="col-md-6">
						{
							userInfo?.isSendingPartner ?
								<div className="col-md-12 pb-3">
									{createAppFormButton()}
								</div>
								: null
						}
					</div>
				</div>
			: null
			}
			{
				userInfo && !userInfo.isSendingPartner && !userInfo.isStaff ?
					<>
						<DashboardTutorial/>
						<ApplicationFormSections/>
						<ApplicationStatus/>
					</>
				:
					userInfo?.isSendingPartner ?
						<PartnerDashboardApplicationFormOverview/>
					: null
			}
			
			<Alerts applicationFormUid={applicationForm?.uid ?? null}/>
			{
				applicationForm && !userInfo?.isSendingPartner && !userInfo?.isStaff ?
					<Contact applicationForm={applicationForm}/>
				:null
			}
		</div>
	);
};

export default DashboardContainer;
