import { RootState } from "app/RootReducer";
import { store } from "app/store";
import { GetCurrentLocale } from "components/Utils";
import { renderTutorial } from "components/UtilsTutorial";
import React from "react";
import { useSelector } from "react-redux";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { getDashboardTutorial } from "../Slice/TutorialSlice";

const DashboardTutorial: React.FC = () => {

    const dashboardTutorial = useSelector((state: RootState) => state.tutorialRdc.dashboardTutorial);
	const applicationForm = useSelector((state: RootState) => state.applicationFormRdc.applicationForm);

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);
	}, []);

	React.useEffect(() => {
		if(applicationForm){
			store.dispatch(getDashboardTutorial(applicationForm.uid))
		}
	}, [applicationForm]);

	return (
		dashboardTutorial ?

		<div className="col-xl-12">
			<div className="alerts-container card">
				<div className="card-header pb-0">
					<div className="header-top d-sm-flex align-items-center">
						<h4 className="pb-0">{t('tutorial')}</h4>
					</div>
				</div>
				<div className="card-body">
					{renderTutorial(dashboardTutorial)}
				</div>
			</div>
		</div>

		: null
	);
};

export default DashboardTutorial;
