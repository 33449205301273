import React from "react";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { GetCurrentLocale, pathname } from "../../components/Utils";

export interface ChildProps {
	title: string,
}

const PageTitle: React.FC<ChildProps> = ({ title }: ChildProps) => {
	const locale = GetCurrentLocale();
	changeLanguage(locale);

	const breadCrumb = pathname.split('/');

	let isDashboard = false;
	if(pathname === t('url_dashboard')){
		isDashboard = true
	}

	return (
		<div className={ isDashboard ? "page-header pb-0" : "page-header"}>
			<div className="row default-according style-1 steps-accordion">
				<div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
					<h2>{title}</h2>
					<ol className="breadcrumb">
						{
							breadCrumb.map((level: string, index: number) => {
								return (
									!isDashboard ?
									<li className="breadcrumb-item" key={index}>
										{
											level === "" && index === 0 ?
												<a href={t('url_dashboard')}>
													{t('home')}
												</a>
												:
												level !== "" && index === 2 ?
													title
													:
													level.replaceAll('-', ' ')
										}
									</li>
									:
									null
								)
							})
						}

					</ol>
				</div>
			</div>
		</div>
	);
};

export default PageTitle;
