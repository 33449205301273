import { ResetPasswordPageType } from "features/Entity/ResetPasswordFormData";
import ActivateAccount from "features/ResetPassword/ActivateAccount";
import React from "react";
import t, { changeLanguage } from "../../components/I18N/TranslateHelpers";
import { getUserToken } from "../../components/UserUtils";
import { GetCurrentLocale, getHostnameType, isResponsive, redirectTo } from "../../components/Utils";
import ForgottenPasswordForm from "../ResetPassword/ForgottenPasswordForm";
import ResetPasswordForm from "../ResetPassword/ResetPasswordForm";

interface ComponentProps {
	pageType: ResetPasswordPageType;
}

const ForgottenPasswordPage: React.FC<ComponentProps> = ({ pageType }: ComponentProps) => {
	const userToken = getUserToken();

	React.useEffect(() => {
		const locale = GetCurrentLocale();
		changeLanguage(locale);

		if (userToken) {
			redirectTo(t("url_dashboard"), false, 750);
		}
	}, []
	);

	return (
		<div className="container-fluid">
			<div className="row overflow-hidden">
				<div className={!isResponsive ? `col-xl-12 p-0 bg-image-home ${getHostnameType()}` : "col-xl-12 p-0"}>
					<div className="login-card">
						{
							userToken ?
								<div className="theme-form login-form">
									<h4>{t('login')}</h4>
									<h6>{t('already_logged_in')}</h6>
								</div>
								:
								pageType === ResetPasswordPageType.ResetPassword ?
									<ResetPasswordForm />
									: pageType === ResetPasswordPageType.ActivateAccount ?
										<ActivateAccount />
										:
										<ForgottenPasswordForm />
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgottenPasswordPage;
