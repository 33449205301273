import axios  from "components/AxiosUtils";
import { getFileNameFromContentDisposition } from "components/Utils";

export async function downloadSectionsPDFRep(applicationFormUid: string): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/ApplicationFormSection/GetPdfs/${applicationFormUid}`,
            { headers: {  "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );
        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);
        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "AllSections.zip"};
    }
    catch (err: any) {
        throw err;
    }
}

export async function downloadAgreementsPDFRep(applicationFormUid: string): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/Agreement/GetAllAgreements/${applicationFormUid}`,
            { headers: {  "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);

        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "Agreements.zip"};
    }
    catch (err: any) {
        throw err;
    }
}

export async function downloadAllFilesRep(applicationFormUid: string): Promise<Blob> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/TBA/${applicationFormUid}`,
            { headers: { "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        return blob;
    }
    catch (err: any) {
        throw err;
    }
}

export async function generateTemporaryLinkWithAllFilesRep(applicationFormUid: string): Promise<Blob> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/TBA/${applicationFormUid}`,
            { headers: {  "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        return blob;
    }
    catch (err: any) {
        throw err;
    }
}

export async function downloadSeparateTemplatesRep(applicationFormUid: string): Promise<Blob> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/TBA/${applicationFormUid}`,
            { headers: { "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        return blob;
    }
    catch (err: any) {
        throw err;
    }
}

export async function synchronizeApplicationFormStatusRep(formData: { appFormUid: string, applicationFormStatus: number }): Promise<void> {
    try {
        await axios.post(
            `/ApplicationForm/synchronizeApplicationFormStatus`,
            formData,
        );
    }
    catch (err: any) {
        throw err;
    }
}

export async function exportDataToOMGTRep(applicationFormUid: string): Promise<void> {
    try {
        await axios.post(
            `/ApplicationForm/ExportDataToWEP/${applicationFormUid}`,
            {},
        );
    }
    catch (err: any) {
        throw err;
    }
}


export async function DownloadVirtualFolderRep(applicationFormUid: string): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/DocumentFolder/DownloadVirtualFolder/${applicationFormUid}`,
            { headers: {  "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);

        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "VirtualFolder.zip"};
    }
    catch (err: any) {
        throw err;
    }
}
export async function DownloadBioInfoRep(applicationFormUid: string): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/Applicant/DownloadBioInfo/${applicationFormUid}`,
            { headers: {  "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);

        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "BioInfo.zip"};
    }
    catch (err: any) {
        throw err;
    }
}

export async function DownloadPhotoAlbumRep(applicationFormUid: string): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/MediaAlbum/DownloadPhotoAlbum/${applicationFormUid}`,
            { headers: { "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);

        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "PhotoAlbum.zip"};
    }
    catch (err: any) {
        throw err;
    }
}

export async function notifyAppFormUserOfAccountRep(applicationFormUid: string): Promise<void> {
    try {
        await axios.post<ArrayBuffer>(
            `/ApplicationForm/NotifyAppFormUserOfAccount?uid=${applicationFormUid}`,
            {},
        );
    }
    catch (err: any) {
        throw err;
    }
}

export async function downloadSectionsConcatPDFRep(applicationFormUid: string): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/ApplicationFormSection/GetConcatPdfs/${applicationFormUid}`,
            { headers: {  "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);

        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "allSections.pdf"};
    }
    catch (err: any) {
        throw err;
    }
}

export async function downloadAgreementsConcatPDFRep(applicationFormUid: string): Promise<{blob: Blob, filename: string}> {
    try {
        const response = await axios.get<ArrayBuffer>(
            `/Agreement/GetConcatAgreementPdfs/${applicationFormUid}`,
            { headers: {  "Content-Type": "application/octet-stream" }, responseType: 'arraybuffer' }
        );

        const filename = getFileNameFromContentDisposition(response.headers["content-disposition"]);

        const blob = new Blob([new Uint8Array(response.data)]);

        return {blob: blob, filename: filename ?? "Agreements.pdf"};
    }
    catch (err: any) {
        throw err;
    }
}
