import axios  from "components/AxiosUtils";
import { MotherTongue } from "features/Entity/MotherTongue";


export async function getMotherTonguesRep(): Promise<MotherTongue[]>{
    try{
        const response = await axios.get<MotherTongue[]>(
            `/MotherTongue/GetList`,
        );

        return response.data;
    }
    catch (err : any)
    {
        throw err;
    }
}
