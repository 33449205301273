import React, { useState } from 'react';
import { ApplicationFormSection } from '../../Entity/ApplicationFormSection';
import t from '../../../components/I18N/TranslateHelpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { ApplicationFormSectionStatusEnum } from 'features/Entity/SectionStaffInfo';
import { ApplicationForm, ProgramTypeEnum } from 'features/Entity/ApplicationForm';
import { FormatDateForDisplay } from 'components/Utils';
import { getApplicationFormSectionUrl } from 'components/UtilsForApplicationForm';
import { buttonSubmitAppForm, closeModalAndReloadAppForm, getIncompleteSteps, submitAppFormModal } from 'components/UtilsForPartner';
interface ComponentProps {
  applicationForm: ApplicationForm;
  section: ApplicationFormSection;
}

const ApplicationFormFinalStep: React.FC<ComponentProps> = ({
  applicationForm,
  section
}: ComponentProps) => {
  const applicationFormSections = useSelector((state: RootState) => state.applicationFormRdc.applicationFormSections);
  const isPostingApplicationForm = useSelector((state: RootState) => state.applicationFormRdc.isRequestPost);
  const applicationFormPosted = useSelector((state: RootState) => state.applicationFormRdc.isRequestApplicationFormPostSuccess);
  const userInfo = useSelector((state: RootState) => state.userRdc.userInfo);

  const [modalOpen, setModalOpen] = useState(false);

  React.useEffect(() => {
    if (applicationFormPosted) {
        closeModalAndReloadAppForm(modalOpen, setModalOpen, applicationForm)
    }
}, [applicationFormPosted]);

  const sectionsWithoutCurrentAndDefineStep =  applicationFormSections?.filter(applicationFormSection => !getApplicationFormSectionUrl(applicationFormSection).startsWith(t("url_application_finish")) && !getApplicationFormSectionUrl(applicationFormSection).startsWith(t("url_application_form_define"))) ?? [];
  const incompleteSteps = userInfo && getIncompleteSteps(sectionsWithoutCurrentAndDefineStep, userInfo, applicationForm)
  const isIncomplete = incompleteSteps && incompleteSteps.length > 0;
  const isAlreadySubmitted = applicationForm.statusOfValidator === ApplicationFormSectionStatusEnum.Approved || applicationForm.statusOfValidator === ApplicationFormSectionStatusEnum.Submitted

  return (
    <div className='col-sm-12 mb-5'>
      <div className='card'>
        {
          userInfo?.isStaff && applicationForm.programType === ProgramTypeEnum.Inbound ?
            <div className="alert alert-warning py-3 mb-0">{t('as_staff_you_cant_submit')}</div>
          :
          isAlreadySubmitted ?
            <>
              <div className='card-header'>
                <div className='header-top d-sm-flex justify-content-between align-items-center'>
                  <h4 className='mb-0'>{t('application_form_already_submitted')}</h4>
                </div>
              </div>
              <div className='card-body'>
                <p>{t("application_form_already_submitted_text")}</p>
                {
                  userInfo?.isSendingPartner ?
                    applicationForm.submittedByPartnerOn ?
                    <>
                      <p>{t('application_form_submitted_on')} {FormatDateForDisplay(applicationForm.submittedByPartnerOn, true)}</p>
                      {
                        applicationForm.submittedOn ?
                        <p>{t('application_form_submitted_by_pax_on')} {FormatDateForDisplay(applicationForm.submittedOn, true)}</p> 
                        : null
                      }
                    </>
                  :
                  null 
                  :
                   applicationForm.submittedOn ?
                   <p>{t('application_form_submitted_on')} {FormatDateForDisplay(applicationForm.submittedOn, true)}</p>
                   : ""
                }
              </div>
            </>
            : applicationFormPosted ? (
              <>
                <div className='card-header'>
                  <div className='header-top d-sm-flex justify-content-between align-items-center'>
                    <h4 className='mb-0'>{t('application_form_submitted')}</h4>
                  </div>
                </div>
                <div className='card-body'>
                  <p>{t('application_form_successfully_submitted')}</p>
                  <p>{t('staff_will_check')}</p>
                </div>
              </>
            )
              : isIncomplete ? (
                <>
                  <div className='card-header'>
                    <div className='header-top d-sm-flex justify-content-between align-items-center'>
                      <h4 className='mb-0'>{t('application_incomplete')}</h4>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className="col-md-12 mb-2">
                    {
                      !userInfo?.isSendingPartner ?
                      <strong>{t('make_sure_steps')}</strong>
                      :
                      <strong>{t('make_sure_steps_partner')}</strong>
                    }
                    </div>
                    <ul className='bg-gray-odd column-count-2'>
                      {
                        incompleteSteps.map(section =>(
                          <li className='py-3 cursor-pointer ' onClick={() => getApplicationFormSectionUrl(section)} key={section.label}>
                            <a href={getApplicationFormSectionUrl(section)}>
                                {section.label}
                                <span className="pull-right"><i className="icon-arrow-circle-right fontsize25"></i></span>
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <div className='card-header'>
                    <div className='header-top d-sm-flex justify-content-between align-items-center'>
                      <h4 className='mb-0'>{t('application_complete')}</h4>
                    </div>
                  </div>
                  <div className='card-body'>
                    {userInfo && submitAppFormModal(modalOpen, setModalOpen, applicationForm, isPostingApplicationForm, userInfo)}
                    <div className='row align-items-center'>
                      <div className='col-md-8'>{t('all_sections_complete')}</div>
                      <div className='col-md-4'>
                        <div className='d-flex justify-content-end'>
                          {
                            userInfo && buttonSubmitAppForm(userInfo, setModalOpen)
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
        }
      </div>
    </div>
  )
}

export default ApplicationFormFinalStep
