import axios  from "components/AxiosUtils";
import { FileInfo } from "features/Entity/Agreement";
import { Document } from "features/Entity/Document";

export async function getDocumentRep(documentUid: string, cultureCode: string, filename: string): Promise<FileInfo>
{
    try
    {
        const response = await axios.get<ArrayBuffer>(
            `/Document/GetFile/${documentUid}?cultureCode=${cultureCode}`,
            {headers: {"Content-Type": "application/octet-stream"}, responseType: 'arraybuffer'}
        );

        const blob = new Blob([new Uint8Array(response.data)]);

        const fileInfo: FileInfo = {fileBlob: blob, index: documentUid, filename: filename};

        return fileInfo;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getDocumentListRep(applicationFormUid: string): Promise<Document[]>
{
    try
    {
        const response = await axios.get<Document[]>(
            `/Document/GetListForApplicationForm/${applicationFormUid}`,
        );

        return response.data;
    }
    catch (err: any) 
    {
        throw err;
    }
}
