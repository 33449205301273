import axios from "axios";
import { WEPProducts } from "features/Entity/WEPProducts";
import {WEP_API_AUTHORIZATION_TOKEN, WEP_API_BASE_URL, WEP_API_GET_PRODUCTS_URL, WEP_API_GET_PRODUCTS_YEAR_URL} from "../../app/AppConfig";

export async function getWEPProductYearsRep(cultureCode: string, partnerEmail: string): Promise<string[]> {
    try
    {
        const response = await axios.get<string[]>(
            `${WEP_API_BASE_URL}${WEP_API_GET_PRODUCTS_YEAR_URL}?cultureCode=${cultureCode}&partnerEmail=${partnerEmail}`,
            {headers: { 
                Authorization: `Bearer ${WEP_API_AUTHORIZATION_TOKEN}`, 
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods': 'GET',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            }}
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}

export async function getWEPProductsRep(cultureCode: string, year: string, partnerEmail: string): Promise<WEPProducts[]> {
    try
    {
        const response = await axios.get<WEPProducts[]>(
            `${WEP_API_BASE_URL}${WEP_API_GET_PRODUCTS_URL}?cultureCode=${cultureCode}&year=${year}&partnerEmail=${partnerEmail}`,
            {headers: { Authorization: `Bearer ${WEP_API_AUTHORIZATION_TOKEN}`, 'Access-Control-Allow-Origin' : '*'}}
        );

        return response.data;
    }
    catch (err: any)
    {
        throw err;
    }
}
