import React from 'react';
import {
	FormatDateForUrl,
	SelectInterface,
} from "components/Utils";
import { FieldValues, UseFormRegister } from 'react-hook-form';
import t from "../../components/I18N/TranslateHelpers";

export const renderRadio = (
	label: string,
	name: string,
	id: string,
	required: boolean,
	register: any,
	setValue: any,
	isLabelHidden: boolean,
	setData: (questionScrutted: string) => void,
	unregister?: any,
	fieldNoShow?: string[],
	isChecked?: boolean,
	readOnly?: boolean
) => {
	const radioButton = register(name);
	return (
		<div key={`${name}-${id}`} className="radio radio-primary">
			<input
				id={`${name}-${id}`}
				type="radio"
				name={name}
				value={id}
				defaultChecked={isChecked}
				disabled={readOnly}
				onChange={(e) => {
					radioButton.onChange(e);
					setData(e.target.value);
				}}
				onBlur={radioButton.onBlur}
				ref={radioButton.ref}
			/>

			<label className={isLabelHidden ? "top-neg-13 mb-0" : "mb-0"} htmlFor={`${name}-${id}`}>
				<span className={isLabelHidden ? "hidden" : ""}>{label}</span>
			</label>
		</div>
	);
};

export const renderCheckbox = (
	label: string,
	name: string,
	id: string,
	required: boolean,
	register: any,
	isLabelHidden: boolean,
	isChecked?: boolean,
	value?: string,
	description?: string,
	readOnly?: boolean
) => {
	return (
		<div className="inline-checkbox" key={label}>
			<input
				className="checkbox_animated"
				value={id}
				id={`${name}-${id}`}
				name={name}
				type="checkbox"
				defaultChecked={isChecked ?? false}
				disabled={readOnly}
				{...register(name)}
			/>
			<label htmlFor={`${name}-${id}`} className={isLabelHidden ? "hidden" : ""}>
				{label}
			</label>
			{description ? <small>{description}</small> : ""}
		</div>
	);
};

export const renderSelect = (
	currentLocale: string,
	label: string,
	name: string,
	id: string,
	required: boolean,
	register: any,
	options: SelectInterface[],
	selectedValue: any,
	classes: string,
	readOnly: boolean
) => {
	return (
		<div className={classes}>
			<label htmlFor={name} className={required ? "form-label required" : "form-label"}>
				{label}
			</label>
			<select {...register(name)} disabled={readOnly} className="form-select">
				<option disabled selected={!selectedValue || selectedValue === null || selectedValue === undefined} value={undefined} style={{display: "none"}}></option>
				{options.map(select => (
					<option
						disabled={readOnly ?? false}
						selected={selectedValue === select.value}
						key={select.value}
						value={select.value}
					>
						{select.label}
					</option>
				))}
			</select>
		</div>
	);
};

export const renderInputText = (
	label: string,
	name: string,
	id: string,
	defaultValue: string | number | null | undefined,
	errors: any,
	register: any,
	required: boolean,
	classes: string,
	isDisabled?: boolean,
	readOnly?: boolean,
	showLabel?: boolean,
	nbMaxCharacters?: number
) => {
	if (showLabel) {
		return (
			<div className={classes}>
				{
					label !== "" ? <label
						htmlFor={name}
						className={required ? "form-label required" : "form-label "}
					>
						{label}
					</label> : null
				}
				<input
					className={errors ? "form-control is-invalid" : "form-control"}
					type="text"
					id={id}
					name={name}
					defaultValue={defaultValue}
					disabled={isDisabled}
					readOnly={readOnly}
					{...register(name)}
					maxLength={nbMaxCharacters ?? undefined}
				/>
				{nbMaxCharacters ? <small>{t('character_limit', {maxLength: nbMaxCharacters})}</small> : ""}
			</div>
		);
	}
	else {
		return (
			<div className={classes}>
				<input
					className={errors ? "form-control is-invalid" : "form-control"}
					type="text"
					id={id}
					name={name}
					defaultValue={defaultValue}
					disabled={isDisabled}
					readOnly={readOnly}
					{...register(name)}
					maxLength={nbMaxCharacters ?? undefined}
				/>
				{nbMaxCharacters ? <small>{t('character_limit', {maxLength: nbMaxCharacters})}</small> : ""}
			</div>
		);
	}
};

export const renderTextArea = (
	label: string,
	name: string,
	id: string,
	defaultValue: string | number | null | undefined,
	errors: any,
	register: any,
	required: boolean,
	classes: string,
	isDisabled?: boolean,
	readOnly?: boolean,
	rows?: number,
	showLabel?: boolean,
	nbMaxCharacters?: number
) => {
	return (
		<div className={classes}>
			{
				showLabel ?
					label !== "" ? <label
						htmlFor={name}
						className={required ? "form-label required" : "form-label "}
					>
						{label}
					</label> : null
					: null
			}
					<>
						<textarea
							className={errors ? "form-control is-invalid" : "form-control"}
							type="text"
							id={name}
							defaultValue={defaultValue}
							disabled={isDisabled}
							readOnly={readOnly}
							rows={rows}
							{...register ? { ...register(name) } : undefined}
							maxLength={nbMaxCharacters}
						/>
						{nbMaxCharacters ? <small>{t('character_limit', {maxLength: nbMaxCharacters})}</small> : ""}
					</>
		</div>
	)
};