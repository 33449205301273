import { isResponsive } from "components/Utils";
import { LanguageTeacherRecommendation, Teacher, TeacherRecommendation, TeacherTypeEnum } from "features/Entity/Teacher";
import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { renderRadio } from "../../../../components/Forms/FormsComponent";

interface ComponentProps {
	classes?: string;
	columns: string[];
	headings: string[];
	register: UseFormRegister<FieldValues>;
	teacherRecommendations: TeacherRecommendation[] | LanguageTeacherRecommendation[];
	prefix: string;
	teacher: Teacher;
	isReadOnly: boolean;
}

const TeacherRecommendationsRow: React.FC<ComponentProps> = ({ classes, columns, headings, register, teacherRecommendations, prefix, teacher, isReadOnly }: ComponentProps) => {
	return (
		<div className={classes !== undefined ? "table-responsive mb-3 " + classes : "table-responsive mb-3"}>
			<table className="table table-hover table-striped">
				{
					!isResponsive ?
						<thead>
							<tr>
								{columns.map((label: string, index: number) => {
									return <th scope="col" key={index} className={index !== 0 ? "text-center" : ""}>{label}</th>;
								})}
							</tr>
						</thead>
						: null
				}
				<tbody>
					{headings.map((labelName: any, parentIndex: number) => {
						const isTeacher = teacher.teacherType === TeacherTypeEnum.Other;
						const filledData = isTeacher ? (teacherRecommendations as TeacherRecommendation[]).find(data => data.teacherRecommendationCharacter?.label === labelName)
							: (teacherRecommendations as LanguageTeacherRecommendation[]).find(data => data.languageTeacherRecommendationProficiency?.label === labelName);

						return (
							<React.Fragment key={parentIndex}>
								<input
									type="hidden"
									id={`${prefix}[${parentIndex}].applicationFormId`}
									value={filledData?.applicationFormId ?? teacher.applicationFormId}
									{...register(`${prefix}[${parentIndex}].applicationFormId`)}
								/>
								<input
									type="hidden"
									id={`${prefix}[${parentIndex}].id`}
									value={filledData?.id ?? 0}
									{...register(`${prefix}[${parentIndex}].id`)}
								/>
								<input
									type="hidden"
									id={`${prefix}[${parentIndex}].teacherId`}
									value={filledData?.teacherId ?? teacher.id}
									{...register(`${prefix}[${parentIndex}].teacherId`)}
								/>
								<input
									type="hidden"
									id={`${prefix}[${parentIndex}].${isTeacher ? "teacherRecommendationCharacterId" : "languageTeacherRecommendationProficiencyId"}`}
									value={isTeacher ?
										((filledData as TeacherRecommendation | undefined)?.teacherRecommendationCharacterId ?? (filledData as TeacherRecommendation | undefined)?.teacherRecommendationCharacter?.id ?? 0)
										: ((filledData as LanguageTeacherRecommendation | undefined)?.languageTeacherRecommendationProficiencyId ?? ((filledData as LanguageTeacherRecommendation | undefined)?.languageTeacherRecommendationProficiency?.id ?? 0))}
									{...register(`${prefix}[${parentIndex}].${isTeacher ? "teacherRecommendationCharacterId" : "languageTeacherRecommendationProficiencyId"}`)}
								/>
								<tr key={parentIndex + "-" + labelName}>
									<td>{labelName}</td>
									{columns.map((label: string, index: number) => {
										const checked = filledData?.evaluationType?.toString() === (index - 1).toString() ?? false;
										return (
											index > 0 ?
												<td className="text-center" key={index}>
													{
														renderRadio(
															label,
															`${prefix}[${parentIndex}].evaluationType`,
															`${index - 1}`,
															false,
															register,
															!isResponsive,
															null,
															undefined,
															checked,
															isReadOnly
														)
													}
												</td>
												: ""
										);
									})}
								</tr>
							</React.Fragment>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default TeacherRecommendationsRow;
