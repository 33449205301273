import React from "react";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { SurveyActionTypeEnum } from "features/Entity/Survey/SurveyActionTypeEnum";

export interface ChildProps {
	surveyActionIds: string,
	body: JSX.Element,
}

const RenderConditional: React.FC<ChildProps> = ({ surveyActionIds, body }: ChildProps) => {

	const surveyActions = useSelector(
		(state: RootState) => state.surveyRdc.surveyActions,
	);

	let surveyActionIdsArray: string[] = [];

	if (surveyActionIds.indexOf(",") > -1) {
		surveyActionIdsArray = surveyActionIds.split(",");
	}
	else {
		surveyActionIdsArray.push(surveyActionIds);
	}

	if (!surveyActions) {
		return (<><h1>NO SURVEY ACTIONS</h1></>);
	}

	let bodyToReturn: number = 0;

	surveyActionIdsArray.map(surveyActionId => {

		if (surveyActions[surveyActionId]?.surveyActionType == SurveyActionTypeEnum.DisplaySurvey) {
			bodyToReturn = 1;
			return;
		}
		else if (surveyActions[surveyActionId]?.surveyActionType == SurveyActionTypeEnum.DisplaySurveyReadOnly) {
			bodyToReturn = 1;
			return;
		}
		else if (surveyActions[surveyActionId]?.surveyActionType == SurveyActionTypeEnum.HideSurvey) {
			bodyToReturn = 2;
			return;
		}
	}
	);
	return (
		<div className={bodyToReturn === 1 ? "block" : "hidden"}>
			{body}
		</div>
	);

}

export default RenderConditional;