import { ToastType } from "../components/Toasts/ToastType";
import { getUserToken } from "../components/UserUtils";
import { storeToast } from "../components/Utils";
import {userLogout} from "../features/Slice/UserSlice";
import { store } from "./store";
import {  pathname, pathnamesWithNoHeaders} from "../components/Utils";

export const tokenExpiredCheckMiddleware = ({dispatch, getState}: any) =>{
  return (next: any) => (action: any) => {
    if(action.type && action.type.toLowerCase().includes("logout"))
    {
      return next(action);
    }
    else
    {
      if(!getUserToken() && !pathnamesWithNoHeaders().includes(pathname))
      {
       
            storeToast(ToastType.ERROR, "expired_session");
            store.dispatch(userLogout(true));

      }
    }
    return next(action);
  }
}
