import { ApplicationFormSectionStatusEnum } from "./SectionStaffInfo";

export interface ApplicationFormSection {
  id: number;
  label: string;
  imageUrl: string;
  orderNumber: number;
  validationStatus: ApplicationFormSectionStatusEnum;
  validationComment: string | null;
  uploadedFile: string | null;
  needSignatureInPDF: boolean;
  canReuploadSectionPDF: boolean;
  needStampInPDF: boolean;
  signatureLabelInPDF: string | null;
  stampLabelInPDF: string | null;
  isReuploadSectionPDFRequired: boolean;
  canDownloadPDFFromDashboard: boolean;
  statusColor: number;
  statusOfValidator: number;
  commentOfValidator: string | null;
  isReadOnly: boolean;
  isPDFDownloadable: boolean;
}

export interface ApplicationFormDetail {
  form: [];
}

export interface SectionFileUpload {
  appFormUID: string;
  sectionId: number;
  file: File;
}

export enum ApplicationFormSectionStatusColorEnum
{
    White = 0,
    Yellow = 1,
    Green = 2,
    Green_dark = 3,
    Yellow_dark = 4,
    Blue = 5
}